import React from 'react'

import { Grid, Link } from '@material-ui/core'

import Image from 'components/image'
import Note from 'components/box/note'
import Warning from 'components/box/warning'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const EmailInviteSent = (props: Props) => {
  const tooLongInPage =
    props.dateStarted &&
    new Date().getTime() - new Date(props.dateStarted).getTime() > 3 * 86400 * 1000
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Invitation Requested</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      {tooLongInPage ? (
        <Grid item>
          <Warning>
            It looks like you didn’t receive an invitation in the past 72 hours. Please request
            assistance from the Assurance team.
          </Warning>
        </Grid>
      ) : (
        <Grid item>
          {/*
           *
           * Regular value: 3 business days
           * During AEP: 14 business days during AEP
           *
           */}
          <Note>
            <strong>Note:</strong> It can take up to 14 business days during AEP for our team to
            send an invitation to you.
          </Note>
        </Grid>
      )}
      <Grid item>
        The Assurance team is processing your invitation. Please check your{' '}
        <Link target='_blank' rel='noreferrer' href='https://outlook.office365.com/mail/'>
          <strong>@Assurance.com</strong>
        </Link>{' '}
        email account for:
        <br />
        <br />
        <div>
          <strong>From</strong>: DoNotRespond@humana.com
        </div>
        <div>
          <strong>Subject</strong>: Your Attention is Required - Humana Background Authorization
        </div>
        <br />
        Once you receive the invitation, come back to this page and see what to do next.
      </Grid>
      {!tooLongInPage && (
        <Grid item>
          <Image
            alt='reference-image'
            src='/images/carriers/medicare/humana/appointment/Humana Invitation Example.jpg'
            style={{ width: '90%' }}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default EmailInviteSent
