import React, { useState } from 'react'

import { Divider, FormControlLabel, Grid, Link, Radio, RadioGroup } from '@material-ui/core'

import Button from 'components/button'
import Note from 'components/box/note'
import { carrierFullName } from 'store/carriers'

import { PlateProps } from '../../../commons/plate'
import TimeToComplete from '../../../commons/time_to_complete'
import { join_life_email } from '../common'

enum CarrierStatus {
  ReleaseLetter = 'releaseLetter',
  UnknownUpline = 'unknownUpline',
  NoReleaseLetter = 'noReleaseLetter'
}

export interface Props extends PlateProps {
  pageData?: {
    carrierStatus: CarrierStatus
  }
  nextLabel: string
}

const PreviouslyAppointed = (props: Props) => {
  const { carrierName, pageData, doNext, nextLabel } = props

  const [choice, setChoice] = useState<CarrierStatus | undefined>(pageData?.carrierStatus)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChoice(e.target.value as CarrierStatus)
  }

  const handleNext = () => {
    const data = {
      carrierStatus: choice
    }
    doNext?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>
          Eligibility - Previously Appointed with {carrierFullName(carrierName)}
        </h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        We just pulled the information from the National Insurance Producer Registry (NIPR).
      </Grid>
      <Grid item>
        <Note>
          <strong>Current Status:</strong> You are appointed with {carrierFullName(carrierName)}{' '}
          under an upline that is not Assurance IQ.
        </Note>
      </Grid>
      <Grid item>
        <strong>A release letter is required to proceed.</strong>
        <RadioGroup value={choice || ''} onChange={handleChange}>
          <FormControlLabel
            value={CarrierStatus.ReleaseLetter}
            control={<Radio color='primary' />}
            label='I have a Transfer Release Form from my previous agency'
          />
          <FormControlLabel
            value={CarrierStatus.NoReleaseLetter}
            control={<Radio color='primary' />}
            label='I do not have a Transfer Release Form from my previous agency'
          />
          <FormControlLabel
            value={CarrierStatus.UnknownUpline}
            control={<Radio color='primary' />}
            label="I don't know who my upline is right now"
          />
        </RadioGroup>
      </Grid>
      <Divider style={{ margin: '1rem 0' }} />
      {choice === CarrierStatus.ReleaseLetter && (
        <>
          <Grid item>
            Assurance cannot submit your release letter on your behalf. Please be prepared to share
            the release letter ready with the carrier as you start the appointment process. Marking
            this incorrectly and starting the paperwork will delay the appointment process. So,
            please monitor your Assurance inbox.
          </Grid>
          <br />
          <Grid container item justifyContent='flex-end'>
            <Button onClick={handleNext}>Next: {nextLabel}</Button>
          </Grid>
        </>
      )}
      {choice === CarrierStatus.NoReleaseLetter && (
        <Grid item>
          You will need to obtain a Transfer Release Form from your upline agency to continue with
          appointments. The release letter must be on FMO letterhead and signed by the principal.
          Your appointment request could be cancelled if you do not have a valid Release Form.
          <br />
          <br />
          When you obtain this, please click <strong>Cancel Appointment</strong> in the top right
          corner of this screen. You can start this <strong>Eligibility Check</strong> again and
          continue the appointment process.
        </Grid>
      )}
      {choice === CarrierStatus.UnknownUpline && (
        <Grid item>
          If you are unsure of who your current upline is, please email:{' '}
          <Link target='_blank' href={'mailto:' + join_life_email}>
            {join_life_email}
          </Link>
          .
          <br />
          <br />
          After you clarify, please click <strong>Cancel Appointment</strong> in the top right
          corner of this screen. You can start this <strong>Eligibility Check</strong>
          again and continue the appointment process.
        </Grid>
      )}
    </Grid>
  )
}

export default PreviouslyAppointed
