import { Action, action } from 'easy-peasy'

export interface AhipStatusApiData {
  id: number | string
  year: number
  isConfirmed: boolean
}

export interface AhipStatusModel {
  items: AhipStatusApiData[]
  load: Action<AhipStatusModel, AhipStatusApiData[]>
  update: Action<AhipStatusModel, AhipStatusApiData>
  create: Action<AhipStatusModel, AhipStatusApiData>
}

export const ahipStatusModel: AhipStatusModel = {
  items: [] as AhipStatusApiData[],
  load: action((state, payload) => {
    state.items = payload
  }),
  update: action((state, payload) => {
    state.items = state.items.map(row => (row.id === payload.id ? payload : row))
  }),
  create: action((state, payload) => {
    state.items = [...state.items, payload]
  })
}
