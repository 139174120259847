import React from 'react'

import Grid from '@material-ui/core/Grid'
import { Link } from '@material-ui/core'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'
import { getIconForProgressStatus } from '../../commons/stages/item'
import withCancellationHandling from './with_cancellation_handling'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    requestDenied?: boolean
    [key: string]: any
  }
}

const CarrierReview = (props: Props) => {
  const { progressItems } = props

  const appointment = progressItems.find(item => item.name === 'Paperwork')!

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Legal & General Carrier Review</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        Well done! Sit back, relax, and we will update your progress.
        <br />
        <br />
        Note: This process could take up to 10 days. If it has been longer than that, please reach
        out to the contracting team at{' '}
        <Link target='_blank' href={'mailto:lifecarrierappointments@assurance.com'}>
          lifecarrierappointments@assurance.com
        </Link>{' '}
        and they will inquire on your behalf.
      </Grid>
      <Grid item container>
        {getIconForProgressStatus(appointment.status)} Appointment Paperwork
      </Grid>
    </Grid>
  )
}

export default withCancellationHandling(CarrierReview)
