import workflowFetch from './workflow_fetch'

const cancelCarrierAppointment = (
  carrierRequestId: string,
  success: any,
  error: any,
  query: any
) => {
  workflowFetch({
    url: `/${carrierRequestId}/cancel`,
    withAuth: true,
    data: {
      cancellationReason: 'adminCancelled',
      cancelledBy: 'admin'
    }
  })
    .then(d => d.json())
    .then(data => {
      success()
      if (query) query.refetch().then(_ => {})
    })
    .catch(() => {
      if (error) error()
    })
}

export default cancelCarrierAppointment
