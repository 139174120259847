import React from 'react'
import { useHistory } from 'react-router-dom'

import { Link } from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LOI, routeCarrierToCarrierEnum } from 'store/carriers'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import Accordion from '../commons/checklist_accordion'
import Button from '../../../components/button'
import { CarrierNoteMap } from './request_assistance'
import InstructionsWrapper from '../commons/instructions_wrapper'

interface Props {
  carrier: string
  loi: string
  planYear: number
}

const RecertificationChecklist = (props: Props) => {
  const carrier = routeCarrierToCarrierEnum(props.carrier)
  const loi = props.loi.toUpperCase() as LOI

  const history = useHistory()

  const goBack = () => {
    history.push('/carrier_appointments')
  }

  const planYear = +props.planYear
  const prevPlanYear = planYear - 1

  return (
    <div>
      <Button variant='text' onClick={goBack}>
        <FontAwesomeIcon
          style={{ marginRight: '0.5rem', fontSize: '1.4rem' }}
          icon={faChevronLeft}
        />
        <u>Back to All Carriers</u>
      </Button>
      <InstructionsWrapper
        style={{ marginTop: '1rem' }}
        heading={'Carrier Appointment Recertifications Process'}
        carrierName={carrier}
        loi={loi}
        planYear={props.planYear}
      >
        <Accordion
          title={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              Instructions for the recertifications process
            </span>
          }
        >
          All agents who are {prevPlanYear} Appointed need to recertify to get ready for this
          upcoming Annual Enrollment Period (AEP). After you recertify, you will be able to sell the
          carrier’s {planYear} insurance products. <br />
          <br />
          Anthem, Humana, UHC, and WellCare will show {planYear} RTS as soon as systems sync. Aetna{' '}
          status is delayed and will show {planYear} RTS for you early September.
          <br />
          <br />
          Please check instructions on{' '}
          <Link
            rel='noreferrer'
            target='_blank'
            href={
              'https://help.assurance.com/en/articles/8208793-2024-medicare-carrier-recertification-process'
            }
          >
            <b>How to get {planYear} Medicare Advantage Certified</b>
          </Link>
          <br />
          <br />
          After completion, please allow up to 7 business days for systems to sync between the{' '}
          carrier, Sunfire, National Insurance Producer Registry (NIPR), and Assurance IQ systems.
        </Accordion>
        {CarrierNoteMap[carrier]}
      </InstructionsWrapper>
    </div>
  )
}

export default RecertificationChecklist
