import React, { useState } from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import CheckboxButton from 'components/checkbox_button'
import Image from 'components/image'
import { RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'

import Accordion from '../commons/checklist_accordion'
import { PlateProps } from '../commons/plate'
import TimeToComplete from '../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const getIcon = (checked?: boolean) => {
  const props = {
    style: {
      width: '1.25rem',
      height: '1.25rem'
    }
  }
  return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
}

const BackgroundCheck = (props: Props) => {
  const { plateName, doUpdate, pageData = {} } = props

  const { userInput = {} } = pageData

  const [completed, setCompleted] = useState(userInput.completedBackgroundCheck)

  const handleNext = () => {
    const data = {
      pageData: {
        userInput: {
          completedBackgroundCheck: completed
        }
      },
      progress: {
        name: plateName,
        status: completed ? 'timer' : 'error'
      },
      nextProgress: 'ReviewByHumana',
      nextPage: {
        name: 'ReviewByHumana'
      }
    }
    doUpdate?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Complete Background Check</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <Accordion
          icon={getIcon(completed)}
          title={
            <span style={{ display: 'flex', alignItems: 'center' }}>1. Start Background Check</span>
          }
        >
          Please check your Assurance email account for an email.
          <br />
          <br />
          <strong>From:</strong> donotrespond@humana.com
          <br />
          <strong>Subject:</strong> Your Attention is Required - Humana Background Authorization
          <br />
          <br />
          Click on the ‘please <strong>click here</strong> to proceed’ link to get your background
          check started.
          <Image
            alt='reference-image'
            src='/images/carriers/medicare/humana/appointment/Humana Background Paperwork.jpg'
            style={{ width: '90%' }}
          />
          <Grid container item justify='flex-end'>
            <CheckboxButton
              checked={completed || false}
              onChange={value => setCompleted(value)}
              label={'I Completed my Background Check'}
            />
          </Grid>
        </Accordion>
      </Grid>
      <Grid container item justify='flex-end'>
        <Button onClick={handleNext}>Next: Review by Humana</Button>
      </Grid>
    </Grid>
  )
}

export default BackgroundCheck
