import Appointment from './appointment'
import CanceledByAgent from './canceled_by_agent'
import Cancelled from './cancelled'
import CarrierReview from './carrier_review'
import Certification from './certification'
import DataSync from './data_sync'
import Eligibility from './eligibility'
import Invitation from './invitation'

export const PlateLabelMap = {
  Eligibility: 'Check Eligibility',
  Invitation: 'Request Invite',
  Appointment: 'Complete Appointment',
  Certification: 'Complete Certification',
  CarrierReview: 'Review by Anthem',
  DataSync: 'Sync Data',
  InComplete: 'Incomplete',
  CanceledByAgent: 'Canceled by Agent',
  Cancelled: 'Cancelled',
  Exception: 'Exception'
}

export const PlateComponentMap = {
  Eligibility: Eligibility,
  Invitation: Invitation,
  Appointment: Appointment,
  Certification: Certification,
  CarrierReview: CarrierReview,
  DataSync: DataSync,
  CanceledByAgent,
  Cancelled
}
