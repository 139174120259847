import React from 'react'

import { InvalidIcon, RoundCheckedIcon } from 'components/icons'
import Table from 'components/table'
import { styleDate } from 'screens/certification/utils'
import styled from 'styled-components'

import { CertificationFinalExamInfo } from '../../ahip.d'

interface Props {
  page_data: CertificationFinalExamInfo
}

const Legend = styled.div`
  display: flex;
  align-items: center;
`

const AttemptsTable = (props: Props) => {
  const { page_data } = props

  const columns = [
    {
      title: 'Attempt',
      field: 'attempt'
    },
    {
      title: 'Date',
      field: 'date'
    },
    {
      title: 'Score',
      field: 'score'
    },
    {
      title: 'Pass/Fail',
      field: 'passed'
    }
  ]

  const data = page_data?.quiz_data.map(d => {
    return {
      attempt: d.attempt || <></>,
      date: styleDate(d.date),
      score: d.score || <></>,
      passed: d.passed ? (
        <Legend>
          <RoundCheckedIcon /> &nbsp;Pass
        </Legend>
      ) : (
        <Legend>
          <InvalidIcon /> &nbsp;Fail
        </Legend>
      )
    }
  })

  return <Table columns={columns} data={data} />
}

export default AttemptsTable
