import React from 'react'

import { Grid, Link } from '@material-ui/core'

import Image from 'components/image'
import Note from 'components/box/note'
import Warning from 'components/box/warning'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const EmailInviteSent = (props: Props) => {
  const tooLongInPage =
    props.dateStarted &&
    new Date().getTime() - new Date(props.dateStarted).getTime() > 3 * 86400 * 1000
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Invitation - Confirm Email Invite</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      {tooLongInPage ? (
        <Grid item>
          <Warning>
            Hmm, it's been over 72 hours and it looks like we haven't sent your request to WellCare
            yet. Please request assistance from the Assurance team by clicking the Need Help button
            the bottom right corner of your screen.
          </Warning>
        </Grid>
      ) : (
        <Grid item>
          {/*
           *
           * Regular value: 7 business days
           * During AEP: 14 business days during AEP
           *
           */}
          <Note>
            <strong>Note:</strong> It can take up to 14 business days during AEP for WellCare to
            send the invitation to you or for WellCare to process your NPN once Assurance IQ sends
            in your request. It’s a little manual - bear with us!
          </Note>
        </Grid>
      )}
      <Grid item>
        We have sent a request to continue your appointment with WellCare.
        <br />
        <br />
        Often times, agents don't receive an email as indicated below. If punching in your NPN
        number works when creating a new account, then it means WellCare has processed your
        invitation (without an official email to you). You can then click to the next step of the
        process.
        <br />
        <br />
        Other times, agents get a Welcome email from WellCare. Please check your{' '}
        <Link target='_blank' rel='noreferrer' href='https://outlook.office365.com/mail/'>
          <strong>@Assurance.com</strong>
        </Link>{' '}
        email account for:
        <br />
        <br />
        <div>
          <strong>From</strong>: producerservices@wellcare.com
        </div>
        <div>
          <strong>Subject</strong>: Welcome to Centene!
        </div>
      </Grid>
      {!tooLongInPage && (
        <Grid item>
          <Image
            alt='reference-image'
            src='/images/carriers/medicare/well_care/appointment/confirm_email_invite.jpg'
            style={{ width: '90%' }}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default EmailInviteSent
