import React, { useEffect, useState } from 'react'

import { Divider, Grid } from '@material-ui/core'

import Button from 'components/button'
import { FollowLink, LinkIcon } from 'components/icons'
import Warning from 'components/box/warning'
import { useStoreState } from 'store'

import { AhipWorflowActions, AhipWorkflowStepDetails } from '../../ahip.d'
import { CertificateProviders } from '../../../certification.d'
import CompleteFinalExamAccordion from './complete_final_exam'
import CompleteModulesAccordion from './complete_modules'
import { updateWorkflowDetails } from '../../api'

interface Props {
  getWorkflowUpdates: () => Promise<void>
  year: number
  page_data: unknown
}

const CompleteCertification = (props: Props) => {
  const { year, getWorkflowUpdates, page_data } = props
  const [modules, setModules] = useState()
  const [finalExam, setFinalExam] = useState()
  const userInfo = useStoreState(state => state.userInfo)

  const handleClick = () => {
    syncData()
  }

  const syncData = async () => {
    await updateWorkflowDetails({
      year: year,
      userInfoId: userInfo.id,
      action: AhipWorflowActions.SYNC_DATA,
      certificateProvider: CertificateProviders.ahip,
      authToken: localStorage.getItem('authToken')
    })
    await getWorkflowUpdates()
  }

  useEffect(() => {
    syncData()
  }, [])

  return (
    <>
      <Grid
        container
        direction='column'
        alignItems='stretch'
        style={{
          margin: '2% 26% 0 26%'
        }}
        spacing={2}
      >
        <Grid item>
          <h1 style={{ fontSize: '24px', paddingBottom: '12px' }}>Certification</h1>
        </Grid>
        <Grid item>
          We just pulled information from the AHIP database. Your AHIP certification is linked to
          Assurance
        </Grid>
        <Grid style={{ paddingBottom: '12px' }} item>
          <Warning>
            <strong>Status</strong> AHIP Certification is not complete. After you have completed
            your examination and learning modules, click <strong>Sync Data</strong> and we will
            update your information.
          </Warning>
        </Grid>
        <Grid item>
          Visit the{' '}
          <a
            href={(page_data as { ahip_acc_link: string })?.ahip_acc_link || '#'}
            target='_blank'
            rel='noreferrer'
          >
            AHIP portal
          </a>{' '}
          and log into your AHIP account to complete your certification. Return to this page once
          you've completed your certification and click <strong>Sync Data</strong>.
        </Grid>
        <Grid item>
          <Divider orientation='horizontal' style={{ marginTop: '25px' }} />
        </Grid>
        <Grid item style={{ marginTop: '25px' }}>
          <CompleteFinalExamAccordion final_exam={finalExam} />
        </Grid>
        <Grid item style={{ marginTop: '25px' }}>
          <CompleteModulesAccordion modules={modules} />
        </Grid>
        <Grid container direction='column' alignItems='flex-end' style={{ marginBottom: '60px' }}>
          <Grid item>
            <Button
              onClick={handleClick}
              style={{ marginTop: '40px' }}
              startIcon={
                <>
                  <img
                    style={{ width: '24px' }}
                    alt='link-icon'
                    src='/images/certification/ahip/link.png'
                  />
                </>
              }
            >
              Sync Data
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default CompleteCertification
