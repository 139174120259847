import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Typography } from '@material-ui/core'

import Box from 'components/box'
import CarrierDropdown from 'components/carrier_dropdown'
import { ChangeEvent } from 'components/input'
import { Loading } from 'components/loading'
import MultiSelect from 'components/multi_select'
import SearchInput from 'components/search_input'
import Select from 'components/select'
import _ from 'lodash'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'

import AppointmentRequestsTable from './appointment_requests_table'
import OWNERS_QUERY from '../agent_owners/owners.gql'
import SupportedLoi, { canonicalizeLoi } from '../../loi_data'

import styles from './agent_appointment_requests.module.scss'

export interface AppointmentRequestFilters {
  agent?: string
  planYear?: number
  stage: string[]
  owners: string[]
  carrier?: string
  loi: string
}

const ErrorNotice = styled.div`
  position: fixed;
  z-index: 10;
  left: 50%;
  margin-left: -255px;
  width: 510px;
  text-align: center;
  padding: 16px;
  background: #f8d7da;
  border: 1px solid #eaabb1;
  color: #930103;
  border-radius: 4px;
`

const InfoNotice = styled.div`
  position: fixed;
  z-index: 10;
  left: 50%;
  margin-left: -255px;
  width: 510px;
  text-align: center;
  padding: 16px;
  background: #dff8ed;
  border: 1px solid #b1ebd2;
  color: #006830;
  border-radius: 4px;
`

const StageFilters = [
  { label: 'Eligibility', value: 'ELIGIBILITY' },
  { label: 'Invitation', value: 'INVITATION' },
  { label: 'Background Check', value: 'BACKGROUND_CHECK' },
  { label: 'Data Sync', value: 'DATA_SYNC' },
  { label: 'Onboarded', value: 'ONBOARDED' }
]

const DefaultFilters: AppointmentRequestFilters = {
  agent: '',
  planYear: new Date().getUTCFullYear(),
  stage: [],
  owners: [],
  loi: 'med_advantage'
}

const AgentAppointmentRequests = () => {
  const [errorNotice, setErrorNotice] = useState<string | null>(null)
  const [infoNotice, setInfoNotice] = useState<string | null>(null)
  const [filters, setFilters] = useState<AppointmentRequestFilters>(DefaultFilters)
  const [agentOwners, setAgentOwners] = useState([])

  const { setValue, trigger, register, watch } = useForm<Record<string, any>>({})

  const [loading, setLoading] = useState(false)

  const currentYear = new Date().getUTCFullYear()
  const [carrier, setCarrier] = useState<{
    name: string
    id: string
  }>({ name: 'all', id: 'all' })

  const handleCarrierChange = (id: string, name: string) => {
    setCarrier({
      name,
      id
    })
  }

  const handleChange = (event: ChangeEvent) => {
    // Multi select is not standard in that it passes an array of values instead of the event
    const field = event.name!
    const value = event.value
    if (field === 'agentSearch') {
      filters.agent = value
      setFilters(filters)
    }
    setValue(field as any, value)
    trigger(field)
  }

  const handleMultiSelectChange = values => {
    filters.stage = values
    setFilters(filters)
    setValue('stageFilters' as any, values)
    trigger('stageFilters')
  }

  const updateFilter = (key: string, value: any) => {
    setFilters({
      ...filters,
      [key]: value
    })
  }

  const handleLoiChange = (loi: string) => {
    filters.loi = loi
    setFilters(filters)
    setCarrier({ name: 'all', id: 'all' })
  }

  const ownerQuery = useQuery(OWNERS_QUERY)

  const getAgentOwners = () => {
    setLoading(true)
    ownerQuery
      .refetch()
      .then(response => {
        const agentOwners = response.data.agentOwners
        setAgentOwners(agentOwners)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }

  useEffect(() => {
    const FORM_FIELDS = {
      agentSearch: { required: false },
      stageFilter: { required: false }
    }
    for (const [field, rule] of Object.entries(FORM_FIELDS)) {
      register(field, rule)
    }
  }, [register])

  useEffect(() => {
    getAgentOwners()
  }, [])

  const formValues = watch()

  return (
    <div className={styles.root}>
      {errorNotice && <ErrorNotice>{errorNotice}</ErrorNotice>}
      {infoNotice && <InfoNotice>{infoNotice}</InfoNotice>}
      <div className={styles.header}>
        <Typography variant='h6' component='h2'>
          <strong>Agent Appointments {loading && <Loading />}</strong>
        </Typography>
      </div>
      <Box marginTop='1rem' spacing='1.5rem' display='flex' alignItems='flex-end'>
        <div>
          <span style={{ fontWeight: 600 }}>Plan Year</span>
          <Select
            style={{ minWidth: '200px', marginTop: '0.25rem' }}
            selected={filters.planYear}
            options={[currentYear - 1, currentYear, currentYear + 1]}
            keySelector={year => year}
            labelSelector={year => year + (year === currentYear ? ' (Current Year)' : '')}
            onChange={planYear => {
              updateFilter('planYear', planYear)
            }}
          />
        </div>
        <div>
          <span style={{ fontWeight: 600 }}>Line of Insurance</span>
          <Select
            style={{ minWidth: '200px', marginTop: '0.25rem' }}
            selected={filters.loi}
            options={SupportedLoi}
            keySelector={loi => loi['value']}
            labelSelector={loi => _.capitalize(loi['label'])}
            logoSelector={loi => loi['icon']}
            onChange={handleLoiChange}
          />
        </div>
        <div>
          <span style={{ fontWeight: 600 }}>Carrier</span>
          <CarrierDropdown
            showAll
            style={{ marginTop: '0.25rem' }}
            selected={carrier.id}
            onChange={handleCarrierChange}
            loi={filters.loi}
          />
        </div>
        <div>
          <span style={{ fontWeight: 600 }}>Stage</span>
          <MultiSelect
            selected={filters.stage}
            style={{ marginTop: '0.25rem' }}
            name={'stageFilter'}
            onChange={handleMultiSelectChange}
            labelSelector={option => option.label}
            titleSelector={selected => selected?.map(row => row.label).join(', ')}
            keySelector={option => option.value}
            options={StageFilters}
          />
        </div>
        <div>
          <span style={{ minWidth: '200px', fontWeight: 600 }}>Filter by Onboarding Owner</span>
          <MultiSelect
            style={{ marginTop: '0.25rem' }}
            selected={filters.owners}
            name={'ownerFilter'}
            onChange={selected => updateFilter('owners', selected)}
            labelSelector={option => option.label}
            titleSelector={selected => selected?.map(row => row.label).join(', ')}
            keySelector={option => option.value}
            options={agentOwners.map(owner => ({ label: owner, value: owner }))}
          />
        </div>
        <SearchInput
          placeholder='Search for an agent'
          style={{ width: '18vw', marginLeft: 'auto' }}
          name='agentSearch'
          onChange={handleChange}
          value={formValues.agentSearch}
        />
      </Box>
      <AppointmentRequestsTable
        filters={{
          ...filters,
          loi: canonicalizeLoi(filters.loi) || '',
          carrier: carrier.name === 'all' ? undefined : carrier.name
        }}
        setLoading={setLoading}
        setInfoNotice={setInfoNotice}
        setErrorNotice={setErrorNotice}
      />
    </div>
  )
}

export default AgentAppointmentRequests
