import React, { useState } from 'react'

import { Grid } from '@material-ui/core'
import Link from '@material-ui/core/Link'

import Box from 'components/box'
import Button from 'components/button'
import CheckboxButton from 'components/checkbox_button'
import Image from 'components/image'
import Message from 'components/message/Message'
import { RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'

import Accordion from '../../commons/checklist_accordion'
import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    userInput?: {
      checkEmail?: boolean
      loggedIn?: boolean
      completedPaperwork?: boolean
    }
  }
}

const getIcon = (checked?: boolean) => {
  const props = {
    style: {
      width: '1.25rem',
      height: '1.25rem'
    }
  }
  return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
}

const Appointment = (props: Props) => {
  const { plateName, doUpdate, pageData = {} } = props

  const { userInput = {} } = pageData

  const [checkEmail, setCheckEmail] = useState(userInput.checkEmail)
  const [logged, setLogged] = useState(userInput.loggedIn)
  const [completed, setCompleted] = useState(userInput.completedPaperwork)
  const [showError, setShowError] = useState(false)

  const handleNext = () => {
    const allCompleted = checkEmail && logged && completed
    if (!allCompleted) {
      setShowError(true)
      return
    }
    const data = {
      pageData: {
        userInput: {
          checkEmail,
          loggedIn: logged,
          completedPaperwork: completed
        }
      },
      progress: {
        name: plateName,
        status: allCompleted ? 'checked' : 'error'
      },
      nextProgress: 'CarrierReview',
      nextPage: {
        name: 'CarrierReview'
      }
    }
    doUpdate?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Appointment - Paperwork</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item container direction='column' spacing={1}>
        {showError && !checkEmail && (
          <Grid item>
            <Message type='warning'>You must complete this action item</Message>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(checkEmail)}
            title={<span style={{ display: 'flex', alignItems: 'center' }}>1. Check Email</span>}
          >
            Please check your Assurance email account for an email from UHC ACA. It will show as:
            <br />
            <div>
              <div>
                <strong>From:</strong>{' '}
                <Link target='_blank' rel='noreferrer' href='mailto:UHC_Contracting@sircon.com'>
                  UHC_Contracting@sircon.com
                </Link>
              </div>
              <div>
                <strong>Subject:</strong> ASSURANCE IQ LLC invites you to review and sign an
                UnitedHealthCare individual & Family Exchange Plans contracting request
              </div>
              <div>
                <strong>Recruiter Name:</strong> ASSURANCE IQ
              </div>
            </div>
            <div>
              a. We have initiated your subagent appointment through our agency. You should have
              received an email from{' '}
              <Link target={'_blank'} rel={'noreferrer'} href={'mailto:UHC_Contracting@sircon.com'}>
                UHC_Contracting@sircon.com
              </Link>{' '}
              in your email inbox. Click on Please check your Assurance email account for an email
              from “Get Started with Sircon”.
              <br />
              <br />
              Example of email below. If you do not receive an email in your inbox, please check
              your spam/junk folder as well.
              <br />
              <br />
              Note: Please complete contracting as soon as you receive your email as the{' '}
              <strong>invitation link is only active for two weeks.</strong>
            </div>
            <div>
              Example of email below. If you do not receive an email in your inbox, please check
              your spam/junk folder as well.
              <br />
              <Image
                style={{ width: '90%', marginTop: '0.5rem' }}
                alt='onboarding email invitation'
                src='/images/carriers/health/united_health_care_aca/smartflow/check_email.png'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={checkEmail || false}
                onChange={value => setCheckEmail(value)}
                label='I Received a Registration Email'
              />
            </Grid>
          </Accordion>
        </Grid>
        {showError && !logged && (
          <Grid item style={{ marginTop: '1rem' }}>
            <Message type='warning'>You must complete this action item</Message>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(logged)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                2. Log Into Producer Center
              </span>
            }
          >
            <div>
              a. Once you click on <strong>"Get Started with Sircon"</strong>, you will be
              redirected to{' '}
              <Link target='_blank' rel={'noreferrer'} href='https://platform.sircon.com/#/login'>
                UHC ACA Producer Center
              </Link>
              .
              <br />
              <br />
              i. Create a new account or sign in using your current account credentials.
              <Image
                style={{ width: '90%', marginTop: '0.5rem' }}
                alt='login'
                src='/images/carriers/health/united_health_care_aca/smartflow/login.png'
              />
              <br />
              ii. The first time you sign in, you will be giving a tour of the services. You can
              review all the different services that the UHC platform offers and click{' '}
              <strong>“Get Started”</strong>
              at the end or just click “Skip the Tour” to skip.
              <Image
                style={{ width: '90%', marginTop: '0.5rem' }}
                alt='dashboard'
                src='/images/carriers/health/united_health_care_aca/smartflow/dashboard.png'
              />
              <Image
                style={{ width: '90%', marginTop: '1rem' }}
                alt='dashboard 2'
                src='/images/carriers/health/united_health_care_aca/smartflow/dashboard_2.png'
              />
              <br />
              iii. Next you will see a page that will test your browser requirements. This is
              optional and you can skip it by clicking <strong>“Get Started”</strong> at the bottom
              of the page.
              <Image
                style={{ width: '90%', marginTop: '0.5rem' }}
                alt='get started'
                src='/images/carriers/health/united_health_care_aca/smartflow/get_started.png'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={logged || false}
                onChange={value => setLogged(value)}
                label='I’ve Logged Into Producer Center'
              />
            </Grid>
          </Accordion>
        </Grid>
        {showError && !completed && (
          <Grid item style={{ marginTop: '1rem' }}>
            <Message type='warning'>You must complete this action item</Message>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(completed)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                3. Complete and Submit Paperwork
              </span>
            }
          >
            <div>
              b. Once logged into the Producer Center and get past the browser requirements page,
              you can start the appointment application. Please follow and complete each step
              accurately.
              <br />
              <br />
              i. Start with your interview questions. Please provide your Resident State License
              information. Once you click <strong>"Continue"</strong>, the system will look your
              information on the NIPR website.
              <br />
              <br />
              Note: If there is an error, you will have to go back and correct any information and
              try again.
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='resident license'
                src='/images/carriers/health/united_health_care_aca/smartflow/resident_license_info.png'
              />
            </div>
            <div>
              c. Complete all your demographics information and click “Continue” when done.
              <br />
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='demographics information'
                src='/images/carriers/health/united_health_care_aca/smartflow/demographics_info.png'
              />
            </div>
            <div>
              d. Continue entering your Producer information on the next page. Keep your E&O license
              information handy as you will need to provide it in this step. Click “Continue” when
              done.
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='error and omissions info'
                src='/images/carriers/health/united_health_care_aca/smartflow/e_and_o.png'
              />
            </div>
            <div>
              e. This page you verify all the states that you are requesting an appointment in. No
              action is needed here, please review and click “Continue”. Note: Please note that the
              states available are: AL,AZ,FL,GA,IL,LA,MI,NC,OK,TN,TX,VA
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='requested states verification'
                src='/images/carriers/health/united_health_care_aca/smartflow/verify_requested_states.png'
              />
            </div>
            <div>
              f. Next, verify your resident state and indicate your resident County.
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='choose county'
                src='/images/carriers/health/united_health_care_aca/smartflow/resident_state.png'
              />
            </div>
            <div>
              g. You are almost there! Next up is background questions. Please answer all the
              questions as accurately as you can. Note: For all the questions you had answered
              “Yes”, you would need to upload all the supporting documents.
              <Image
                style={{ marginTop: '1rem', border: '1px solid black' }}
                alt='background questions'
                src='/images/carriers/health/united_health_care_aca/smartflow/background_questions.png'
              />
              <Image
                style={{ marginTop: '1rem', border: '1px solid black' }}
                alt='supporting documents'
                src='/images/carriers/health/united_health_care_aca/smartflow/supporting_docs.png'
              />
            </div>
            <div>
              h. Review, acknoledge and E-sign your documents. Note: If there is any errors on any
              pages, you can click on <strong>"Start Over"</strong> and return to the beginning of
              the data collection process and correct, all your previous progress is saved.
              <Image
                style={{ marginTop: '1rem', border: '1px solid black' }}
                alt='review documents'
                src='/images/carriers/health/united_health_care_aca/smartflow/review_docs.png'
              />
            </div>
            <div>
              i. Once you have signed your documents, the next screen allows you to print off the
              documents for your records. Once you have secured copies for your records, your can{' '}
              <strong>“Exit</strong>” the application. This will submit your application for
              processing with UHC. Note: This is an optional step and we recommend saving these
              documents for your records.
              <Image
                style={{ marginTop: '1rem', border: '1px solid black' }}
                alt='save documents'
                src='/images/carriers/health/united_health_care_aca/smartflow/save_docs.png'
              />
            </div>
            <div>
              j. From here, sit back and relax! UHC will process your application and send back a
              series of emails confirming each step.
              <br />
              <br />
              i. Once you submit your application, you will receive a confirmation email from UHC.
              No action needed.
              <Image
                style={{ marginTop: '1rem', border: '1px solid black', width: '80%' }}
                alt='confirmation email'
                src='/images/carriers/health/united_health_care_aca/smartflow/confirmation_email.png'
              />
              <br />
              ii. Once you are approved by UHC for ACA, you will be issued your writing number in a
              follow up email.
              <br />
              <br />
              Optional: You can register and get a One Healthcare ID. This is optional. If you
              decided to register, you will not need update your direct deposit details as Assurance
              pays your commissions.
              <Image
                style={{ marginTop: '1rem', border: '1px solid black', width: '80%' }}
                alt='writing number issue email'
                src='/images/carriers/health/united_health_care_aca/smartflow/writing_number_issue.png'
              />
              <br />
              iii. Lastly, look out for the welcome email from UHC. This email explains how you can
              register on their site to complete enrollments. <strong>Please note</strong> - You
              would only do this if there is an issue with HealthSherpa. All applications should
              always be processed through HealthSherpa.
              <Image
                style={{ marginTop: '1rem', border: '1px solid black' }}
                alt='welcome email'
                src='/images/carriers/health/united_health_care_aca/smartflow/welcome_email.png'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={completed || false}
                onChange={value => setCompleted(value)}
                label='I Completed and Submitted All Required Paperwork'
              />
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container item justify='flex-end'>
        <Box width='100%' display='flex' alignItems='center' justifyContent='flex-end'>
          {showError && ![checkEmail, logged, completed].every(d => d) && (
            <Box mr='1rem'>
              <Message type={'warning'}>Action items incomplete</Message>
            </Box>
          )}
          <Button onClick={handleNext}>Next: Carrier Review</Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Appointment
