import React from 'react'

import { Grid } from '@material-ui/core'

import Note from 'components/box/note'
import TimeToComplete from 'screens/carrier_appointments/commons/time_to_complete'
import { carrierFullName } from 'store/carriers'

import { PlateProps } from '../../../commons/plate'

const ApiInProgress = (props: PlateProps) => {
  const { carrierName } = props

  const carrier = carrierFullName(carrierName)

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Appointment Paperworks</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <Note>
          Please wait, while we are communicating with {carrier} to initiate your request for
          appointment. This might take a few minutes to complete.
        </Note>
      </Grid>
    </Grid>
  )
}

export default ApiInProgress
