import React from 'react'

import { Link } from '@material-ui/core'

import Note from 'components/box/note'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper from '../commons/instructions_wrapper'

interface Props {
  carrierName: string
  loi: string
}

const UhcMedicareChecklist = (props: Props) => {
  return (
    <InstructionsWrapper {...props}>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Request for an Appointment Invitation
          </span>
        }
      >
        Let us know you want to get started with carrier contracting using one of the below options.
        <br />
        <ul>
          <li>
            For Assurance W2 Full-Time Agent (you are part of a hub): Please fill out the{' '}
            <Link
              target='_blank'
              rel='noreferrer'
              href='https://assuranceiq.formstack.com/forms/bpodecember2020appointmentinfo'
            >
              Hub Invitation Request Form
            </Link>
            . Fill it one time for all Medicare Advantage Carriers.
          </li>
          <br />
          <li>
            For Assurance 1099 On-Demand Agent (work on your own terms): Please fill out the{' '}
            <Link
              target='_blank'
              rel='noreferrer'
              href='https://assuranceiq.formstack.com/forms/uhc_appointment_request'
            >
              United Healthcare Invitation Request Form
            </Link>
            .
          </li>
        </ul>
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            2. Complete Appointment Paperwork
          </span>
        }
      >
        <strong>Instructions:</strong>
        <br />
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360034307672-How-do-I-get-appointed-with-UHC-'
        >
          Assurance Help: How do I get appointed with UHC?
        </Link>
        <br />
        <br />
        If you meet UHC requirements, you will receive a welcome email from UHC to complete
        paperwork through their{' '}
        <Link target='_blank' rel='noreferrer' href='https://px.sircon.com/getPassword.do?'>
          Producer Express system
        </Link>
        . Go to your email and click that button because it has your unique information to set up a
        password. After completion, the Assurance team needs to review and it can take up to 5
        business days.
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>3. Complete Certifications</span>
        }
      >
        Once your background check is completed, you will receive another email from UHC with a
        PARTY ID to register on{' '}
        <Link target='_blank' rel='noreferrer' href='http://www.uhcjarvis.com/'>
          UHC's Agent Portal
        </Link>
        .
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>4. Wait for data to sync</span>
        }
      >
        If you are experiencing difficulties with the Medicare onboarding experience on the
        Assurance Platform, please check out our{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360050154871-UHC-FAQ-v-2020-'
        >
          UHC FAQs
        </Link>{' '}
        Alternatively, you can reach out to our team through the 'Need Help' button in the bottom
        right corner of your screen.
        <Note>
          If you have questions on form completion, please contact the{' '}
          <strong>UHC Licensing and Credentialing</strong> at <strong>1-888-381-8581</strong> or
          email at{' '}
          <strong>
            <Link target='_blank' rel='noreferrer' href='mailto:phd@uhc.com'>
              phd@uhc.com
            </Link>
          </strong>
          .
        </Note>
      </Accordion>
    </InstructionsWrapper>
  )
}

export default UhcMedicareChecklist
