import React from 'react'

import { Link } from '@material-ui/core'

import Note from 'components/box/note'
import Warning from 'components/box/warning'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper from '../commons/instructions_wrapper'

interface Props {
  carrierName: string
  loi: string
}

const AnthemMedicareChecklist = (props: Props) => {
  return (
    <InstructionsWrapper carrierName={props.carrierName} loi={props.loi}>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Request for an Appointment Invitation
          </span>
        }
      >
        Let us know you want to get started with carrier contracting:
        <br />
        <ul>
          <li>
            For all agents, please fill out the{' '}
            <Link
              target='_blank'
              rel='noreferrer'
              href='https://assuranceiq.formstack.com/forms/medicare_anthem_request_appointment_id'
            >
              Anthem Invitation Request Form
            </Link>
          </li>
        </ul>
        <Warning>
          <strong>Caution:</strong> Please do not initiate your own appointment or contracting
          paperwork with Anthem. If you initiate your own appointment with Anthem and fill out
          paperwork, you've indicated your interest in working with Anthem directly. With that, we
          will not be reimbursing you for your state appointment fees paid to Anthem.
        </Warning>
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            2. Complete Appointment Process
          </span>
        }
      >
        <strong>Full Instructions:</strong>
        <br />
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360044563811-How-do-I-get-appointed-with-Anthem-#h_01EE94PRKDCS65H7Z3CB2HZN2S'
        >
          Assurance Help: How do I get appointed with Anthem? Part 1: Complete Appointment Process
        </Link>
        <br />
        <br />
        If you meet Anthem's requirements, you will receive a welcome email from Anthem to complete
        appointment paperwork through{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://brokerportal.anthem.com/apps/ptb/login'
        >
          Anthem's Producer Toolbox website
        </Link>
        . Follow your own appointment ID progress by looking at its status on the Anthem site.
        <Warning>
          In Anthem’s system, <strong>do not</strong> select the follow states and brands:
          <ul>
            <li>CA - Large Group</li>
            <li>NV - Large Group</li>
            <li>CO - Large Group</li>
            <li>WI - WCIC</li>
            <li>NJ - Unicare</li>
          </ul>
        </Warning>
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            3. Complete Certification Process
          </span>
        }
      >
        <strong>Instructions:</strong>
        <br />
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360044563811-How-do-I-get-appointed-with-Anthem-#h_01EE94PZN5VQKRWEZAXHG58TG2'
        >
          Assurance Help: How do I get appointed with Anthem? Part 2: Complete Certification Process
        </Link>
        <br />
        <br />
        You need to complete the certifications on{' '}
        <Link href='https://anthem.cmpsystem.com/ext/ahip/login.php'>Anthem's Learning System</Link>
        . The code is “<strong>EXTERNAL-EMO</strong>”.{' '}
        <span style={{ color: '#018846' }}>
          <strong>You can start this without an invitation so do this right away.</strong>
        </span>
      </Accordion>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>4. Wait for Data to Sync</span>
        }
      >
        If you are experiencing difficulties with the Medicare onboarding experience on the
        Assurance Platform, please check out our{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360049585452-ANTHEM-FAQS-v-2020-'
        >
          Anthem FAQs.
        </Link>
        <br />
        Alternatively, you can reach out to our team through the 'Need Help' button in the bottom
        right corner of your screen.
        <Note>
          If you have questions on form completion, please contact the{' '}
          <strong>Anthem Licensing and Credentialing</strong> at <strong>1-877-304-6470</strong>.
          <br />
          <br />
          If you are experiencing technical issues, please contact the{' '}
          <strong>Anthem Help Desk</strong> at <strong>1-888-268-4361</strong>.
        </Note>
      </Accordion>
    </InstructionsWrapper>
  )
}

export default AnthemMedicareChecklist
