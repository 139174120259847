import React from 'react'

import { Link } from '@material-ui/core'

import { Carrier } from 'store/carriers'
import Note from 'components/box/note'

const AnthemNote = () => (
  <Note>
    If you are experiencing difficulties with the Medicare onboarding experience on the Assurance
    Platform, please check out our Anthem FAQs or let us know at{' '}
    <strong>join-medicare@assurance.com</strong>.
    <br />
    <br />
    If you have questions on form completion, please contact the{' '}
    <strong>Anthem Licensing and Credentialing</strong> at <strong>877-304-6470</strong>.
    <br />
    <br />
    If you are experiencing technical issues, please contact the <strong>
      Anthem Help Desk
    </strong>{' '}
    at <strong>1-888-268-4361</strong>.
  </Note>
)

const AetnaNote = () => (
  <Note>
    If you are experiencing difficulties with the Medicare onboarding experience on the Assurance
    Platform, please check let us know at <strong>join-medicare@assurance.com</strong> or reach out
    to our team through the 'Need Help' button in the bottom right corner of your screen.
    <br />
    <br />
    If you have questions on form completion, please contact the <strong>Aetna</strong> at{' '}
    <strong>1-866-714-9301</strong>.
  </Note>
)

const WellCareNote = () => (
  <Note>
    If you are experiencing difficulties with the Medicare onboarding experience on the Assurance
    Platform, please check out our{' '}
    <Link
      target='_blank'
      rel='noreferrer'
      href='https://help.assurance.com/hc/en-us/articles/360050156991-WellCare-FAQ-v-2020-'
    >
      WellCare FAQs
    </Link>{' '}
    Alternatively, you can reach out to our team through the 'Need Help' button in the bottom right
    corner of your screen.
    <br />
    <br />
    If you have questions on form completion, please contact the{' '}
    <strong>WellCare licensing team</strong> at <strong>1-866-822-1339</strong>.
  </Note>
)

const UnitedHealthCareNote = () => (
  <Note>
    If you are experiencing difficulties with the Medicare onboarding experience on the Assurance
    Platform, please check out our{' '}
    <Link
      target='_blank'
      rel='noreferrer'
      href='https://help.assurance.com/hc/en-us/articles/360050154871-UHC-FAQ-v-2020-'
    >
      UHC FAQs
    </Link>
    .
    <br />
    <br />
    Alternatively, you can reach out to our team through the 'Need Help' button in the bottom right
    corner of your screen.
    <br />
    <br />
    If you have questions on form completion, please contact the{' '}
    <strong>UHC Licensing and Credentialing</strong> at <strong>1-888-381-8581</strong> or email at{' '}
    <strong>
      <Link target='_blank' rel='noreferrer' href='mailto:phd@uhc.com'>
        phd@uhc.com
      </Link>
    </strong>
    .
  </Note>
)

const HumanaNote = () => (
  <Note>
    If you are experiencing difficulties with the Medicare onboarding experience on the Assurance
    Platform, please check out our{' '}
    <Link
      target='_blank'
      rel='noreferrer'
      href='https://help.assurance.com/hc/en-us/articles/360049621952-Humana-FAQ-v-2020-'
    >
      Humana FAQs
    </Link>{' '}
    <br />
    <br />
    Alternatively, you can reach out to our team through the 'Need Help' button in the bottom right
    corner of your screen.
    <br />
    <br />
    If you have questions on form completion, please contact the{' '}
    <strong>Humana Agent Support</strong> at <strong>1-800-309-3163</strong> or email the{' '}
    <Link target='_blank' rel='noreferrer' href='mailto:AgentSupport@Humana.com'>
      <strong>Humana Help Desk</strong>
    </Link>{' '}
    at <strong>AgentSupport@Humana.com</strong>.
  </Note>
)

const SureBridgeNote = () => (
  <Note>
    If you are experiencing difficulties with the Medicare onboarding experience on the Assurance
    Platform, please click the 'Need Help' button in the bottom right corner of your screen.
    <br />
    <br />
    If you have questions on form completion, please contact{' '}
    <strong>Surebridge Licensing and Credentialing</strong> at <strong>1-888-797-4447</strong>.
  </Note>
)

const MutualOfOmahaNote = () => (
  <Note>
    If you are experiencing difficulties with the Medicare onboarding experience on the Assurance
    Platform, please click the 'Need Help' button in the bottom right corner of your screen.
    <br />
    <br />
    If you have questions on form completion, please contact{' '}
    <strong>Mutual of Omaha Licensing and Credentialing</strong> at <strong>1-800-867-6873</strong>.
  </Note>
)

const CarrierNoteMap = {
  [Carrier.Aetna]: <AetnaNote />,
  [Carrier.Anthem]: <AnthemNote />,
  [Carrier.WellCare]: <WellCareNote />,
  [Carrier.UnitedHealthCare]: <UnitedHealthCareNote />,
  [Carrier.Humana]: <HumanaNote />,
  [Carrier.MutualOfOmaha]: <MutualOfOmahaNote />,
  [Carrier.SureBridge]: <SureBridgeNote />
}

export {
  CarrierNoteMap,
  AetnaNote,
  AnthemNote,
  HumanaNote,
  SureBridgeNote,
  UnitedHealthCareNote,
  WellCareNote,
  MutualOfOmahaNote
}
