import React, { useEffect, useState } from 'react'

import { useStoreState } from 'store'

import {
  AhipStageKeys,
  AhipWorflowActions,
  AhipWorkFlowData,
  AhipWorkflowProps,
  AhipWorkflowStepDetails,
  CertificationFinalExamInfo,
  CertificationInfoStatus
} from '../ahip.d'
import {
  AhipWorkFlowUpdateData,
  CertificateProviders,
  CertificateWorkflowStatus
} from '../../certification.d'
import CompleteCertification from './complete_certification'
import CreateAndLinkAccount from './create_link_account'
import GetStarted from './get_started'
import SyncData from './sync_data'
import { getWorkflowDetails, updateWorkflowDetails } from '../api'
import { mapStageStatus } from '../../utils'

const Workflow = (props: AhipWorkflowProps) => {
  const { onStageUpdate, year } = props
  const userInfo = useStoreState(state => state.userInfo)
  const [page, setPage] = useState('loading')
  const [pageData, setPageData] = useState<AhipWorkflowStepDetails>({
    page_data: {},
    page_name: AhipStageKeys.CREATE_LINK_ACCOUNT,
    page_status: CertificateWorkflowStatus.NOT_STARTED
  })
  const [finalData, setFinalData] = useState<CertificationFinalExamInfo>({
    final_score: 0,
    completion_date: '',
    quiz_data: [],
    status: {} as CertificationInfoStatus
  })
  const getWorkflowUpdates = async () => {
    const response = await getWorkflowDetails({
      year: year,
      userInfoId: userInfo.id,
      certificateProvider: CertificateProviders.ahip,
      authToken: localStorage.getItem('authToken')
    })
    const workflowData: AhipWorkFlowData = await response.json()
    if (workflowData && workflowData.steps) {
      const progressItems = workflowData.steps.map(s => ({
        hidden: false,
        key: s.page_name,
        status: mapStageStatus(s.page_status)
      }))

      const current_progress = workflowData.steps.find(s => s.page_status === 'InProgress')
      setPage(current_progress?.page_name || 'loading')
      switch (workflowData.workflow_status) {
        case CertificateWorkflowStatus.IN_PROGRESS: {
          setPageData(current_progress?.page_data)
          onStageUpdate(progressItems, current_progress?.page_name)
          break
        }
        case CertificateWorkflowStatus.COMPLETED: {
          const finalData = workflowData.steps.find(
            s => s.page_name === AhipStageKeys.SYNC_DATA
          )?.page_data
          onStageUpdate(progressItems, AhipStageKeys.SYNC_DATA)
          setFinalData(finalData)
          setPage(AhipStageKeys.SYNC_DATA)
          break
        }
      }
    } else {
      setPage('NotStarted')
    }
  }

  useEffect(() => {
    getWorkflowUpdates()
  }, [])

  const get_started_clicked = async () => {
    const response = await updateWorkflowDetails({
      year: year,
      userInfoId: userInfo.id,
      action: AhipWorflowActions.GET_STARTED,
      certificateProvider: CertificateProviders.ahip,
      authToken: localStorage.getItem('authToken')
    })
    const workflowUpdateData: AhipWorkFlowUpdateData = await response.json()
    if (
      workflowUpdateData &&
      workflowUpdateData.workflow_status === CertificateWorkflowStatus.IN_PROGRESS &&
      workflowUpdateData.current_step_page_name === AhipStageKeys.CREATE_LINK_ACCOUNT
    ) {
      getWorkflowUpdates()
    }
  }

  switch (page) {
    case 'NotStarted':
      return <GetStarted year={year} onStart={get_started_clicked} />
    case AhipStageKeys.CREATE_LINK_ACCOUNT:
      return (
        <CreateAndLinkAccount
          year={year}
          getWorkflowUpdates={getWorkflowUpdates}
          page_data={pageData}
        />
      )
    case AhipStageKeys.COMPLETE_CERTIFICATION:
      return (
        <CompleteCertification
          year={year}
          page_data={pageData}
          getWorkflowUpdates={getWorkflowUpdates}
        />
      )
    case AhipStageKeys.SYNC_DATA:
      return <SyncData year={year} page_data={finalData} />
    default:
      return <h1>AHIP - Error loading page</h1>
  }
}

export default Workflow
