import workflowFetch from '../query/rest/workflow_fetch'

const commaFormattedNumber = (value?: number | string) => {
  value = '' + (value == null ? '' : value)
  if (value === '') {
    return ''
  }
  const result: string[] = []
  for (let end = value.length - 1; end >= 0; end -= 3) {
    result.push(value.slice(Math.max(0, end - 2), end + 1))
  }
  return result.reverse().join(',')
}

const parseInputDate = (date: string) => {
  const [year, month, day] = date.split('-')
  return new Date(+year, +month - 1, +day)
}

export const get_yy_mm_dd_hh_mm = (date: Date) => {
  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
    .toISOString()
    .split('T')[0]

  const time = new Date()
    .toTimeString()
    .split(' ')[0]
    .split(':')
    .slice(0, -1) // remove second
    .join(':')

  return localDate + ' at ' + time
}

export const get_s3_url = (bucketPrefixedFileKey: string) => {
  const [bucket, ...remaining] = bucketPrefixedFileKey.split('/')
  const fileKey = remaining.join('/')
  const url = `https://${bucket}.s3.amazonaws.com`
  return url + '/' + fileKey
}

export const get_file = (file_url: string) => {
  return workflowFetch({
    url: '/pre_signed_url',
    withAuth: true,
    data: {
      url: file_url
    }
  })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      const errorVal = error.text()
      console.log(errorVal)
    })
}

export const isIframeView = window.self !== window.top

export { commaFormattedNumber, parseInputDate }
