import React from 'react'

import { Divider, Grid, Link } from '@material-ui/core'

import Image from 'components/image'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

export interface ReviewBackgroundProps extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const ReviewBackground = (props: ReviewBackgroundProps) => {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Review of Background Check by Humana</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        Your background check is in review by the Humana team. Check your email frequently to see if
        there are any actions to take per your Background Check. Humana’s team will communicate with
        you there.
      </Grid>
      <Grid item>
        <Divider style={{ margin: '0.5rem 0' }} />
      </Grid>
      <Grid item>
        When you get approved, you’ll receive an email in your{' '}
        <Link target='_blank' rel='noreferrer' href='https://outlook.office365.com/mail/'>
          <strong>{'{@Assurance.com}'}</strong>
        </Link>{' '}
        inbox that contains your Humana SAN Number needed to complete your certification training.
        <br />
        <br />
        Once you receive this, come back to this page to see what to do next.
        <br />
        <br />
        <strong>From:</strong> DoNotRespond@humana.com
        <br />
        <strong>Subject:</strong> Welcome to Humana - Next Steps
      </Grid>
      <Grid item>
        <Image
          alt='reference-image'
          src='/images/carriers/medicare/humana/appointment/Humana Certification.jpg'
          style={{ width: '90%' }}
        />
      </Grid>
    </Grid>
  )
}

export default ReviewBackground
