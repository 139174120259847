import DataSync from 'screens/carrier_appointments/commons/flow/plates/data_sync'

import CarrierReview from './carrier_review'
import Eligibility from './eligibility'
import Invitation from './invitation'
import Paperwork from './paperwork'

export const PlateLabelMap = {
  Eligibility: 'Check Eligibility',
  Invitation: 'Request Invite',
  Paperwork: 'Complete Paperwork',
  CarrierReview: 'Review by UHO',
  DataSync: 'Sync Data',
  CanceledByAgent: 'Canceled by Agent',
  Cancelled: 'Cancelled',
  Exception: 'Exception Error'
}

export const PlateComponentMap = {
  Eligibility,
  Invitation,
  Paperwork,
  CarrierReview,
  DataSync
}
