import React from 'react'

import { Link } from '@material-ui/core'

import Note from 'components/box/note'
import Warning from 'components/box/warning'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper from '../commons/instructions_wrapper'

interface Props {
  carrierName: string
  loi: string
}

const AnthemHealthChecklist = (props: Props) => {
  return (
    <InstructionsWrapper {...props}>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Request for an Appointment Invitation
          </span>
        }
      >
        In order to continue to sell Anthem products on the Assurance platform, agents must get
        appointed with Anthem.
        <br />
        <br />
        Let us know you want an invitation to get started with appointment by filling out the{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://assuranceiq.formstack.com/forms/anthem_bcbs_appointment_request'
        >
          Anthem Invitation Request Form
        </Link>
        .
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            2. Complete Appointment Process
          </span>
        }
      >
        <strong>Instructions:</strong>
        <br />
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360048891952-How-Do-I-Get-Appointed-With-Anthem-BCBS-to-Offer-ACA-Plans-'
        >
          Assurance Help: How do I get appointed with Anthem?
        </Link>
        <br />
        <br />
        If you meet Anthem's requirements, you will receive a welcome email from Anthem to complete
        appointment paperwork through{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://brokerportal.anthem.com/apps/ptb/login'
        >
          Anthem's Producer Toolbox website
        </Link>
        . Follow your own appointment ID progress by looking at its status on the Anthem site.
        <Warning>
          In your Producer Toolbox, wait for the Assurance IQ agency initiated appointment IDs. It
          should say "Created By: cyarboi or osiumbata". Do not click on "Start new appointment'
          because you will go direct with Anthem.
          <br />
          <br />
          If you initiate your own appointment with Anthem on their own website and fill out
          paperwork, you've indicated your interest in working with Anthem directly. With that, we
          will not be reimbursing you for your state appointment fees paid to Anthem.
        </Warning>
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            3. Check ACA Appointment Status
          </span>
        }
      >
        Your Affordable Care Act (ACA) appointment status is in one place.{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://join.assurance.com/agent/aca_appointments'
        >
          Check my ACA appointment statuses
        </Link>
        .
      </Accordion>
      <Note>
        If you have questions on form completion, please contact the{' '}
        <strong>Anthem Licensing and Credentialing</strong> at <strong>1-877-304-6470</strong>.
        <br />
        <br />
        If you are experiencing technical issues, please contact the{' '}
        <strong>Anthem Help Desk</strong> at <strong>1-888-268-4361</strong>.
      </Note>
    </InstructionsWrapper>
  )
}

export default AnthemHealthChecklist
