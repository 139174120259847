import { CertificateWorkflowStatus, StageItemStatus } from './certification.d'

const styleDate = (date: string | undefined) => {
  return new Date(date ? date.replace('T', '; ') : '').toLocaleString()
}

const formatDateForFfm = (date: string | undefined) => {
  if (date === '') return 'No data'
  return new Date(date ? date : '').toISOString().split('T')[0]
}

const mapStageStatus = (page_status: string) => {
  switch (page_status) {
    case CertificateWorkflowStatus.NOT_STARTED:
      return StageItemStatus.EMPTY
    case CertificateWorkflowStatus.IN_PROGRESS:
      return StageItemStatus.SOLID
    case CertificateWorkflowStatus.COMPLETED:
      return StageItemStatus.CHECKED
    default:
      return StageItemStatus.EMPTY
  }
}

export { styleDate, formatDateForFfm, mapStageStatus }
