import React from 'react'

import { Grid } from '@material-ui/core'

import Warning from 'components/box/warning'

import Button from '../../../../components/button'
import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {}

const BackgroundInformationRequestExpired = (props: Props) => {
  const { doRestart } = props

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Background Information Request Expired</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <Warning>
          <strong>Note:</strong> Unfortunately, the Background Check invitation we sent to you has
          expired now. You have 30 days to complete it from the time we send the invitation to you.
          You will have to start the appointment process again.
        </Warning>
      </Grid>
      <Grid container item justify='flex-end' spacing={1}>
        <Grid item>
          <Button onClick={doRestart}>Restart Appointment Process</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BackgroundInformationRequestExpired
