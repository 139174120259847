import React, { useState } from 'react'

import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { withStyles } from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

interface Props {
  icon?: React.ReactNode
  title?: React.ReactNode
  className?: string
  children?: React.ReactNode
  expanded?: boolean
}

const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 1rem;
`

const Content = styled.div`
  > * + * {
    margin-top: 1rem;
  }
`

const Chevron = styled.div`
  display: flex;
  align-items: center;
  transition: 0.3s transform;
  font-size: 1rem;
  svg path {
    stroke: grey;
    fill: grey;
  }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid #cccccc;
`

const Accordion = withStyles({
  root: {
    border: '1px solid #cccccc',
    borderRadius: '0.25rem',
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    }
  },
  expanded: {}
})(MuiAccordion)

const Div = styled(Accordion)<{ showContent: boolean }>`
  &.MuiPaper-root {
    box-shadow: none;
  }
  ${props =>
    props.showContent &&
    `
    ${Label} {
      font-weight: 600;
    }
    ${Chevron} {
        stroke-width: 30px;
        svg path {
            stroke: #091720;
            fill: #091720;
        }
    }
  `}

  &:hover {
    ${Label} {
      font-weight: 600;
    }
    ${Chevron} {
      stroke-width: 30px;
      svg path {
        stroke: #091720;
        fill: #091720;
      }
    }
  }

  ${Label}, ${Chevron} {
    color: #091720;
    cursor: pointer;
  }
`

const AccordionSummary = withStyles(theme => ({
  root: {
    padding: 0,
    //marginBottom: -1,
    minHeight: 'auto',
    '&$expanded': {
      minHeight: 'auto'
    }
  },
  content: {
    margin: 0,
    '& > *': {
      padding: '1rem'
    },
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {}
}))(MuiAccordionSummary)

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: '1rem',
    borderTop: '1px solid #ccc'
  }
}))(MuiAccordionDetails)

const ChecklistAccordion = (props: Props) => {
  const { icon, title, className, children, expanded } = props

  const [showContent, setShowContent] = useState(expanded == null ? true : expanded)

  return (
    <Div
      className={className}
      expanded={showContent}
      onChange={(e, v) => {
        setShowContent(v)
      }}
    >
      <AccordionSummary>
        {icon && <Icon>{icon}</Icon>}
        <Label>{title}</Label>
        <Chevron style={{ marginLeft: 'auto' }}>
          <FontAwesomeIcon
            style={{
              transformOrigin: 'center',
              transition: '0.300s',
              transform: `rotateZ(${showContent ? 0 : 180}deg)`
            }}
            icon={faChevronUp}
          />
        </Chevron>
      </AccordionSummary>
      <AccordionDetails>
        <Content style={{ width: '100%' }}>{children}</Content>
      </AccordionDetails>
    </Div>
  )
}

export default ChecklistAccordion
export type AccordionProps = Props
