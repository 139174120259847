import React from 'react'

import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded'
import { createTheme } from '@material-ui/core/styles'

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#0071b7'
    },
    secondary: {
      main: '#fafafa'
    },
    error: {
      main: '#DD2D30'
    },
    warning: {
      main: '#DD2D30'
    },
    info: {
      main: '#071F2B'
    },
    success: {
      main: '#008846;'
    },
    background: {
      default: '#FFF'
    },
    text: {
      primary: '#051f2c'
    }
  },
  typography: {
    fontSize: 16,
    button: {
      textTransform: 'none'
    },
    body1: {
      fontSize: 16
    },
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  props: {
    MuiButton: {
      disableElevation: false,
      disableRipple: true
    },
    MuiRadio: {
      color: 'primary',
      checkedIcon: <CheckCircleRounded />
    },
    MuiLink: {
      underline: 'always'
    }
  },
  overrides: {
    MuiButton: {
      outlined: {
        border: '1px solid rgba(51,51,51,0.16)',
        '&:focus': {
          border: '1px solid rgba(51,51,51,0.36)'
        },
        '& .MuiButton-label >:first-child': {
          marginRight: '0.33rem'
        }
      }
    },
    MuiListItemText: {
      root: {
        marginTop: 0,
        marginBottom: 0
      },
      primary: {
        fontSize: '1rem'
      }
    },
    MuiMenu: {
      paper: {
        minWidth: '180px',
        border: '1px solid rgba(51, 51, 51, 0.16)',
        borderRadius: '4px',
        boxShadow: '1px 3px 8px grey'
      }
    },
    MuiInputBase: {
      root: {
        fontSize: '1rem',
        '&:before, &:after': {
          display: 'none'
        }
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '1rem'
      }
    },
    MuiRadio: {
      root: {
        padding: '0.375rem',
        '& .MuiSvgIcon-root': {
          height: '1.2rem',
          width: '1.2rem'
        }
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        color: 'white',
        '&.Mui-checked': {
          color: 'white'
        },
        '& .MuiSvgIcon-root': {
          height: '1.2rem',
          width: '1.2rem'
        }
      },
      root: {
        padding: 0
      }
    },
    MuiDialogActions: {
      root: {
        padding: '1.5rem'
      }
    },
    MuiPaper: {
      outlined: {
        border: '1px solid #ccc'
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: '#ccc'
      }
    }
  }
})

export default theme
