import React from 'react'

import { Dialog, DialogContent, DialogTitle, Typography, makeStyles } from '@material-ui/core'

import Box from 'components/box'
import Button from 'components/button'
import { Loading } from 'components/loading'
import Table from 'components/table'
import { useQuery } from '@apollo/client'

import { StateAdd } from '../types'
import { TEMPLATE_DETAIL_QUERY } from '../graphql/template'
import { getCarrierIconPath } from '../../../carrier_appointments/carrier_data'

const useStyles = makeStyles(theme => ({
  paper: { minWidth: '50vw' },
  blue: {
    color: theme.palette.primary.main
  }
}))

interface Props {
  onClose: () => void
  stateAdd: StateAdd
}

const ReviewModal = (props: Props) => {
  const { stateAdd } = props

  const classes = useStyles()

  const handleClose = () => {
    props.onClose()
  }

  const query = useQuery(TEMPLATE_DETAIL_QUERY, {
    variables: {
      id: stateAdd.id
    }
  })

  const data = query.data?.stateAddsTemplate

  const template = JSON.parse(data?.template || '{}')

  const columns = Object.keys(template)

  return (
    <Dialog onClose={handleClose} open={true} classes={{ paper: classes.paper }}>
      <DialogTitle>
        <strong>Review State Add Template{query.loading && <Loading />}</strong>
      </DialogTitle>
      <DialogContent dividers>
        <Box spacing='1rem'>
          <Box spacing='1rem' display='flex' alignItems='center' justifyContent='space-between'>
            <img
              alt='carrier'
              src={getCarrierIconPath(stateAdd.carrier.name, stateAdd.carrier.loi)}
            />
            <Box display='flex' spacing='1rem' alignItems='center'>
              <span>Template needs an update?</span>
              <Button
                onClick={() => {
                  window.open(
                    'https://assuranceiq.atlassian.net/servicedesk/customer/portal/3/group/62',
                    '_blank'
                  )
                }}
              >
                Request Update
              </Button>
            </Box>
          </Box>
          <Typography variant='body2' color='textSecondary'>
            Template Last Updated:{' '}
            {new Date(data?.updatedAt || stateAdd.updatedAt).toLocaleString()}
          </Typography>
          <Table
            style={{ marginTop: '1.5rem' }}
            rowKeySelector={row => row.key}
            columns={columns.map(key => ({
              title: key,
              field: key
            }))}
            data={[template]}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ReviewModal
