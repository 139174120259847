import React, { useState } from 'react'

import { Grid } from '@material-ui/core'
import Link from '@material-ui/core/Link'

import Button from 'components/button'
import CheckboxButton from 'components/checkbox_button'
import Image from 'components/image'
import { LinkIcon, RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'
import Note from 'components/box/note'
import Warning from 'components/box/warning'
import styled from 'styled-components'

import Accordion from '../commons/checklist_accordion'
import { PlateProps } from '../commons/plate'
import TimeToComplete from '../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    userInput?: {
      checkEmail?: boolean
      loggedIn?: boolean
      completedPaperwork?: boolean
    }
  }
}

const getIcon = (checked?: boolean) => {
  const props = {
    style: {
      width: '1.25rem',
      height: '1.25rem'
    }
  }
  return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
}

const Ol = styled.ol`
  > li {
    padding: 0.5rem 0;
  }
`

const Appointment = (props: Props) => {
  const { plateName, doUpdate, pageData = {} } = props

  const { userInput = {} } = pageData

  const [checkEmail, setCheckEmail] = useState(userInput.checkEmail)
  const [logged, setLogged] = useState(userInput.loggedIn)
  const [completed, setCompleted] = useState(userInput.completedPaperwork)

  const handleNext = () => {
    const allCompleted = checkEmail && logged && completed
    const data = {
      pageData: {
        userInput: {
          checkEmail,
          loggedIn: logged,
          completedPaperwork: completed
        }
      },
      progress: {
        name: plateName,
        status: allCompleted ? 'timer' : 'error'
      },
      nextProgress: 'Certification',
      nextPage: {
        name: 'Certification'
      }
    }
    doUpdate?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Warning>
          In your Producer Toolbox, wait for the Assurance IQ Agency initiated Appointment IDs. Do
          not click on “Start New Appointment” because you will go direct with Anthem or Another FMO
        </Warning>
      </Grid>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Appointment - Paperwork Process</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item container direction='column' spacing={1}>
        <Grid item>
          <Note>
            <Link
              rel='noreferrer'
              target='_blank'
              href={'/images/carriers/medicare/anthem/smartflow/flow.mp4'}
            >
              Please click this link to see a video tutorial of the appointment process
            </Link>
          </Note>
        </Grid>
        <Grid item>
          <Accordion
            icon={getIcon(checkEmail)}
            title={<span style={{ display: 'flex', alignItems: 'center' }}>1. Check Email</span>}
          >
            Please check your Assurance email account for an email from Anthem. It will show as:
            <br />
            <div>
              <div>
                <strong>From:</strong> software.support@anthem.com
              </div>
              <div>
                <strong>Subject:</strong> Your new Appointment
              </div>
              <div>
                <strong>Recruiter Name:</strong> ASSURANCE IQ
              </div>
            </div>
            <div>
              a. We have initiated your subagent appointment through our agency. You should have
              received an email from{' '}
              <Link
                target={'_blank'}
                rel={'noreferrer'}
                href={'mailto:software.support@anthem.com'}
              >
                software.support@anthem.com
              </Link>{' '}
              with the subject line <strong>“Your new Appointment”</strong> in your email inbox.
            </div>
            <div>
              Example of email below. If you do not receive an email in your inbox, please check
              your spam/junk folder as well.
              <br />
              <Image
                style={{ width: '70%', marginTop: '0.5rem' }}
                alt='example appointment email'
                src='/images/carriers/medicare/anthem/smartflow/anthem2.png'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={checkEmail || false}
                onChange={value => setCheckEmail(value)}
                label='I Received a Registration Email'
              />
            </Grid>
          </Accordion>
        </Grid>
        <Grid item>
          <Accordion
            icon={getIcon(logged)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                2. Log Into Producer Toolbox, or Register an Account to Producer Toolbox
              </span>
            }
          >
            <div>
              a. Once you click on the link in the email, you will be redirected to{' '}
              <Link
                target='_blank'
                rel={'noreferrer'}
                href='https://brokerportal.anthem.com/apps/ptb/login'
              >
                anthem.com Producer Toolbox
              </Link>
              .
              <br />
              <br />
              These are three scenarios to be aware of.
              <Ol>
                <li>
                  If you are an existing actively appointed agent and have a username and password
                  to anthem.com Producer Toolbox, please log in with your existing username and
                  password.
                </li>
                <li>
                  If you are an existing actively appointed agent BUT have not registered to the
                  Producer Toolbox, please click on <strong>“Register Now”</strong>.
                </li>
                <li>
                  If you are not appointed with Anthem and/or Anthem brands and need to start the
                  appointment process as a NEW agent, scroll down the screen and locate the{' '}
                  <strong>“Ready to become an Anthem Broker”</strong> box. Click on Just use our{' '}
                  <strong>“Producer Appointment Tool”</strong>.
                </li>
              </Ol>
              <Image
                style={{ width: '50%', marginTop: '0.5rem' }}
                alt='login or register'
                src='/images/carriers/medicare/anthem/smartflow/Anthem 3.png'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={logged || false}
                onChange={value => setLogged(value)}
                label="I've Logged Into Producer Express"
              />
            </Grid>
          </Accordion>
        </Grid>
        <Grid item>
          <Accordion
            icon={getIcon(completed)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                3. Complete and Submit Paperwork
              </span>
            }
          >
            <div>
              b. Once logged into the Producer Toolbox, we can start the appointment application.
              <br />
              <br />
              <ol>
                <li>On the upper righthand corner, locate and click on the profile icon.</li>
                <li>A dropdown menu will appear, click on “Account Details”.</li>
                <li>
                  Under Appointments/Appointment IDs, click on the blue numeric appointment number.
                  This is the EMO subagent appointment application prompted by your upline.
                </li>
                <li>Print (optional)</li>
              </ol>
              <br />
              i. Do NOT click on “Start a New Appointment”.
              <Image
                style={{ marginTop: '1rem', width: '80%' }}
                alt='clik on appointment id'
                src='/images/carriers/medicare/anthem/smartflow/Anthem 4.png'
              />
            </div>
            <div>
              c. Enter your information into the required fields in the{' '}
              <strong>“Basic Info”</strong> section and click <strong>“Continue”</strong>. Reminder:{' '}
              <strong>"Are you the principal of an agency or general agency?"</strong> Answer{' '}
              <strong>“NO”</strong>
              <Image
                style={{ marginTop: '1rem' }}
                alt='fill up basic information'
                src='/images/carriers/medicare/anthem/smartflow/Anthem 5.png'
              />
            </div>
            <div>
              d. Enter your address information and click “Continue to Appointments” when complete.
              <Image
                style={{ marginTop: '1rem' }}
                alt='fill up address information'
                src='/images/carriers/medicare/anthem/smartflow/Anthem 6.png'
              />
            </div>
            <div>
              e. Proceed to select states and associated brands for your appointments.
              <Warning style={{ marginTop: '1rem' }}>
                <strong>Do Not Select This:</strong>
                <div style={{ marginTop: '1rem' }}>
                  <div>CA- Large Group</div>
                  <div>NV- Large Group</div>
                  <div>CO- Large Group</div>
                  <div>WI- WCIC</div>
                  <div>NJ- Unicare</div>
                </div>
              </Warning>
              <Image
                style={{ marginTop: '1rem' }}
                alt='select states'
                src='/images/carriers/medicare/anthem/smartflow/Anthem 7.png'
              />
            </div>
            <div>
              f. Complete the questions in the <strong>“Business Survey”</strong> section. Provide
              any additional information and attach any required documents. Once completed, click{' '}
              <strong>“Continue to Forms”</strong>.
              <Image
                style={{ marginTop: '1rem' }}
                alt='complete business survey section'
                src='/images/carriers/medicare/anthem/smartflow/Anthem 8.png'
              />
            </div>
            <div>
              g. Fill out all the forms.
              <Note style={{ marginTop: '1rem' }}>
                <strong>Important:</strong> The appointment application process consist of five
                tabs.
                <strong>All five tabs must be populated, submitted, and/or acknowledged.</strong>
                After clicking on <strong>“I Acknowledge”</strong> at the end{' '}
                <strong>“Forms”</strong>,scroll back up to the top. Although the site indicated 4/4
                completed and congratulations,{' '}
                <strong>you MUST proceed to payment by clicking on the “Payment” tab</strong>.
                Although there may be no fees,{' '}
                <strong>
                  you still MUST click on submit in order for the appointment application to be
                  submitted to Anthem
                </strong>
                . Completing 4 of 4 steps will not be submitted to Anthem for review/processing.
              </Note>
              <Image
                style={{ marginTop: '1rem' }}
                alt='fill out forms'
                src='/images/carriers/medicare/anthem/smartflow/Anthem 9.png'
              />
            </div>
            <div>
              h. Click <strong>“I Acknowledge”</strong> and <strong>“Back to Overview”</strong> once
              all forms show completed.
            </div>
            <div>
              i. Go back to your dashboard, click on that appointment ID again, and then click
              'Continue to Payment' and get to the final Payments tab where it asks you to make the
              payment of $0. Click 'Submit'.
              <Image
                style={{ marginTop: '1rem' }}
                alt='payment procedure'
                src='/images/carriers/medicare/anthem/smartflow/Anthem 10.png'
              />
              <Image
                style={{ marginTop: '1rem' }}
                alt='payment procedure'
                src='/images/carriers/medicare/anthem/smartflow/Anthem 11.png'
              />
              <Image
                style={{ marginTop: '1rem' }}
                alt='payment procedure'
                src='/images/carriers/medicare/anthem/smartflow/Anthem 12.png'
              />
              <br />
              This will finally submit your paperwork to Anthem!
              <Note style={{ marginTop: '1rem' }}>
                <strong>Double-check:</strong> You'll know you have completed this once you see{' '}
                <strong>“IN REVIEW”</strong> on your Appointment page. Watch a little video to learn
                how to read your Appointment Status in Producer Toolbox.
              </Note>
              <Image
                style={{ marginTop: '1rem' }}
                alt='in review stage'
                src='/images/carriers/medicare/anthem/smartflow/Anthem 13.png'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={completed || false}
                onChange={value => setCompleted(value)}
                label='I Completed and Submitted All Required Paperwork'
              />
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container item justify='flex-end'>
        <Button onClick={handleNext}>Next: Certification</Button>
      </Grid>
    </Grid>
  )
}

export default Appointment
