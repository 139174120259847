import React from 'react'
import ReactDOM from 'react-dom'

import { Auth0Provider } from '@auth0/auth0-react'
import { StoreProvider } from 'easy-peasy'

import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_RESPONSE_TYPE } from './utils/environment-variables'
import { App } from './App'
import Main from './screens/main'
import store from './store'

ReactDOM.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    responseType={AUTH0_RESPONSE_TYPE}
    /*scope={AUTH0_SCOPE}*/
    /*audience={AUTH0_AUDIENCE}*/
    redirectUri={window.document.location.origin}
    post
  >
    <StoreProvider store={store}>
      <React.StrictMode>
        <App>
          <Main />
        </App>
      </React.StrictMode>
    </StoreProvider>
  </Auth0Provider>,
  document.getElementById('root')
)
