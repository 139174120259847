import React from 'react'

import styled from 'styled-components'

const Main = styled.div`
  border: 1px solid #cccccc;
  box-shadow: 0px 6px 8px rgba(51, 51, 51, 0.16), 0px 2px 12px rgba(51, 51, 51, 0.16),
    0px 2px 4px rgba(51, 51, 51, 0.16);
  border-radius: 4px;
  display: inline-block;
  vertical-align: top;
  background: #fff;
  width: 212px;
  height: 220px;
  position: relative;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

interface Props {
  style?: React.CSSProperties
  children?: React.ReactNode
  cardId?: string
  className?: string
}

const BaseCard = (props: Props) => {
  const { className, style, children, cardId } = props
  return (
    <Main className={className} style={style} id={cardId}>
      <Wrapper>{children}</Wrapper>
    </Main>
  )
}

export default BaseCard
