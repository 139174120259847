import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { isIframeView } from '../utils/utils'

export const PostIframeURL = () => {
  const location = useLocation()
  const CRM_URL = 'https://assurance.com'

  useEffect(() => {
    if (!isIframeView) return

    window.parent.postMessage(
      {
        type: 'GET_IFRAME_URL',
        url: window.location.href
      },
      process.env.REACT_APP_DD_ENV === 'production' ? CRM_URL : '*'
    )
  }, [location])

  return null
}
