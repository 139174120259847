import React from 'react'

import { Button as MaterialButton, ButtonProps, darken, makeStyles } from '@material-ui/core'

interface Props extends ButtonProps {
  className?: string
  target?: string
}

const useStyles = makeStyles(theme => ({
  root: (props: Props) => ({
    boxShadow:
      props.variant === 'outlined' || props.variant === 'text'
        ? undefined
        : '0px 2px 2px rgba(51, 51, 51, 0.16), 0px 2px 4px rgba(51, 51, 51, 0.16), 0px 1px 8px rgba(51, 51, 51, 0.16)',
    padding: '0.5rem 1rem',
    '&:hover': {
      background:
        props.color === 'secondary'
          ? darken(theme.palette.secondary.main, 0.05)
          : props.color === 'primary'
          ? darken(theme.palette.primary.main, 0.2)
          : undefined
    }
  })
}))

const Button = (props: Props) => {
  const { className, color = 'primary', variant = 'contained' } = props

  const classes = useStyles(props)

  return (
    <MaterialButton
      {...props}
      className={[classes.root, className].join(' ')}
      color={color}
      variant={variant}
    />
  )
}

export default Button
