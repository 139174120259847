import gql from 'graphql-tag'

const AGENT_APPOINTMENT_REQUESTS = gql`
  query carrierAppointmentRequests(
    $limit: Int!
    $offset: Int!
    $filter: CarrierAppointmentRequestFilterInput!
    $sortBy: CarrierAppointmentRequestSortingInput
  ) {
    carrierAppointmentRequests(first: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      totalCount
      edges {
        node {
          id
          user {
            id
            assuranceAgentId
            email
            firstName
            lastName
            owner
          }
          carrier {
            id
            name
            avaliableState
            loi
            states
          }
          planYear
          createdAt
          bpmnCurrentTaskId
          bpmnCurrentProcessId
          status
          parameters
          cancellationReason
          cancellationDate
          histories {
            id
            stage
            action
            detailInfo
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`

export default AGENT_APPOINTMENT_REQUESTS
