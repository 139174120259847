import React from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'

interface Props {
  className?: string
  year?: number
  style?: React.CSSProperties
  onStart?: () => void
}

const GetStarted = (props: Props) => {
  const { className, year, style, onStart } = props

  return (
    <Grid container direction='column' alignItems='center' spacing={4}>
      <Grid item>
        <h1 style={{ fontSize: '24px', paddingBottom: '12px' }}>AHIP Certification for {year}</h1>
        <span>
          In order to sell Medicare Advantage plans, Assurance and our carrier partners mandate
          <br />
          that you complete the current year's AHIP Certification. Use this section of your
          dashboard
          <br />
          to sign up for AHIP and stay up-to-date on your AHIP certification progress and
          completion.
        </span>
      </Grid>
      <Grid item>
        <Button style={{ minWidth: '216px' }} onClick={onStart}>
          Get Started
        </Button>
      </Grid>
    </Grid>
  )
}

export default GetStarted
