import React from 'react'

import { Link } from '@material-ui/core'

import Note from 'components/box/note'
import Warning from 'components/box/warning'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper from '../commons/instructions_wrapper'

interface Props {
  carrierName: string
  loi: string
}

const Checklist = (props: Props) => {
  return (
    <InstructionsWrapper {...props}>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Complete Appointment Process
          </span>
        }
      >
        Please fill out{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://join.assurance.com/agent/sign_moo_paperwork'
        >
          the Mutual of Omaha Contracting paperwork by clicking here.
        </Link>{' '}
        <br />
        We will automatically send the file to Mutual of Omaha's team upon completion. When filling
        out the paperwork, make sure you select the states you are licensed in. Once you submit,
        your completed form will be located on your agent profile.
        <Warning>
          <strong>Caution:</strong> If you initiate your appointment with Mutual of Omaha by
          following the above link, you've indicated your interest in working with Mutual of Omaha
          under the Assurance IQ hierarchy. Assurance does not give immediate releases.
        </Warning>
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>2. Wait for Data to Sync</span>
        }
      >
        If you are experiencing difficulties with the Medicare onboarding experience on the
        Assurance Platform, please reach out to our team through the 'Need Help' button in the
        bottom right corner of your screen.
        <br />
        <Note>
          If you have questions on form completion, please contact{' '}
          <strong>Mutual of Omaha Licensing and Credentialing</strong> at{' '}
          <strong>1-800-867-6873</strong>.
        </Note>
      </Accordion>
    </InstructionsWrapper>
  )
}

export default Checklist
