import React from 'react'

import { Pagination as BasePagination, PaginationItem, PaginationProps } from '@material-ui/lab'
import { ChevronRightRounded } from '@material-ui/icons'
import { IconButton, makeStyles } from '@material-ui/core'

import Box from '../box'
import { DoubleChevronIcon } from '../icons'

const useStyles = makeStyles(theme => ({
  action: {
    borderRadius: 0,
    padding: 0,
    '& .MuiSvgIcon-root': {
      fontSize: '2rem'
    }
  },
  button: {
    borderRadius: 0,
    padding: '0.5rem 1rem'
  }
}))

const Pagination = (props: PaginationProps) => {
  const { style, ...rest } = props

  const classes = useStyles()

  return (
    <Box display='flex' alignItems='center' style={style}>
      <BasePagination
        showFirstButton
        showLastButton
        {...rest}
        renderItem={p => {
          if (['first', 'last', 'next', 'previous'].includes(p.type)) {
            return (
              <IconButton
                classes={{ root: classes.action }}
                color='inherit'
                disabled={p.disabled}
                onClick={p.onClick}
              >
                {['first', 'last'].includes(p.type) ? (
                  <DoubleChevronIcon
                    style={{ transform: p.type === 'first' ? 'rotate(180deg)' : void 0 }}
                  />
                ) : (
                  <ChevronRightRounded
                    style={{ transform: p.type === 'previous' ? 'rotate(180deg)' : void 0 }}
                  />
                )}
              </IconButton>
            )
          }
          return <PaginationItem classes={{ root: classes.button }} {...p} />
        }}
      />
    </Box>
  )
}

export default Pagination
