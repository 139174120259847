import React from 'react'

import { Grid, makeStyles } from '@material-ui/core'

interface Props {
  type?: 'success' | 'warning' | undefined
  children?: React.ReactNode
}

const IconMap = {
  success: '/images/checkmark.svg',
  warning: '/images/warning_fill.svg'
}

const useStyles = makeStyles(theme => ({
  root: (props: Props) => ({
    color: props.type && theme.palette[props.type]?.main
  })
}))

const Message = (props: Props) => {
  const src = props.type ? IconMap[props.type] : void 0

  const classes = useStyles(props)

  return (
    <Grid className={classes.root} container alignItems='center' direction='row' spacing={1}>
      {src && (
        <Grid item style={{ display: 'flex' }}>
          <img style={{ width: '1.5rem' }} src={src} />
        </Grid>
      )}
      <Grid item>{props.children}</Grid>
    </Grid>
  )
}

export default Message
