import React from 'react'

import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps } from '@material-ui/core'

import Box from '../box'

export interface CheckboxProps extends MuiCheckboxProps {
  label?: React.ReactNode
}

const Checkbox = (props: CheckboxProps) => {
  const { checked, label, onChange } = props

  return (
    <Box display='flex' alignItems='center' spacing='0.5rem'>
      <MuiCheckbox
        icon={
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M1.66667 0H18.3333C19.2538 0 20 0.746192 20 1.66667V18.3333C20 19.2538 19.2538 20 18.3333 20H1.66667C0.746192 20 0 19.2538 0 18.3333V1.66667C0 0.746192 0.746192 0 1.66667 0ZM18.125 18.3333C18.2401 18.3333 18.3333 18.2401 18.3333 18.125V1.875C18.3333 1.75994 18.2401 1.66667 18.125 1.66667H1.875C1.81982 1.66645 1.76683 1.68821 1.72774 1.72715C1.68864 1.76609 1.66667 1.81899 1.66667 1.87417V18.125C1.66645 18.1803 1.68832 18.2334 1.72744 18.2726C1.76656 18.3117 1.81968 18.3336 1.875 18.3333H18.125Z'
              fill='#333333'
            />
          </svg>
        }
        checkedIcon={
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M1.2875 0H18.7125C19.4232 0.0009182 19.9991 0.576814 20 1.2875V18.7117C19.9995 19.4227 19.4235 19.9991 18.7125 20H1.2875C0.576489 19.9991 0.000459013 19.4227 0 18.7117V1.2875C0.0009182 0.576814 0.576814 0.0009182 1.2875 0ZM9.83754 14.4534L15.6709 6.53669L15.675 6.53586C15.9482 6.16537 15.8693 5.64359 15.4988 5.37044C15.1283 5.09729 14.6065 5.1762 14.3334 5.54669L9.01004 12.7667L5.52087 9.97502C5.28896 9.78394 4.97213 9.73251 4.69169 9.84043C4.41124 9.94835 4.21061 10.1989 4.16661 10.4961C4.12261 10.7934 4.24205 11.0913 4.47921 11.2759L8.64587 14.6092C8.82401 14.748 9.05035 14.8097 9.27429 14.7804C9.49823 14.7511 9.7011 14.6333 9.83754 14.4534Z'
              fill='white'
            />
          </svg>
        }
        checked={checked || false}
        onChange={e => onChange?.(e, e.target.checked)}
      />
      {label && <div>{label}</div>}
    </Box>
  )
}

export default Checkbox
