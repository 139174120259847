import React from 'react'

import { FormControl, Input, Select as MuiSelect } from '@material-ui/core'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/styles'
import { withStyles } from '@material-ui/core/styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { CheckedIcon, UncheckedIcon } from './icons'

const useStyles = makeStyles((theme: any) => ({
  formControl: {
    width: '100%',
    minWidth: 120
  }
}))

const StyledMenuItem = withStyles(theme => ({
  root: {
    padding: '10px 20px',
    '& > img': {
      marginRight: '10px'
    }
  }
}))(MenuItem)

const StyledSelect = withStyles(theme => ({
  root: {
    border: '1px solid rgba(51, 51, 51, 0.16)',
    padding: '0.4375rem 1rem',
    display: 'flex',
    lineHeight: 1.5,
    '&.MuiSelect-outlined': {
      paddingRight: '48px' // for chevron
    },
    '&:hover': {
      border: '1px solid rgba(51, 51, 51, 0.36)'
    },
    borderRadius: '4px',
    '&:focus': {
      borderRadius: '4px'
    }
  },
  icon: {
    transition: 'transform .3s',
    right: '1rem',
    color: 'black',
    '& svg': {
      top: 'auto'
    }
  }
}))(MuiSelect)

interface Props<OptionType> {
  className?: string
  style?: Record<string, string | number>
  options: OptionType[]
  selected: (string | number)[]
  keySelector: (option: OptionType) => string | number
  titleSelector?: (option?: OptionType[]) => React.ReactNode
  labelSelector: (options: OptionType) => React.ReactNode
  onChange?: (option: any) => void
  name?: string
}

const ALL = 'ALL_' + Math.random()

const MultiSelect = <OptionType,>(props: Props<OptionType>) => {
  const {
    className,
    options,
    style,
    selected,
    labelSelector,
    keySelector,
    titleSelector,
    onChange,
    name
  } = props

  const classes = useStyles()

  const allSelected = options.every(option => selected.includes(keySelector(option)))

  const handleChange = function (event) {
    if (event.target.value.includes(ALL)) {
      const newSelected = allSelected ? [] : options.map(option => keySelector(option))
      onChange?.(newSelected)
    } else {
      onChange?.(event.target.value)
    }
  }

  return (
    <div className={className} style={style}>
      <FormControl variant='outlined' className={classes.formControl}>
        <StyledSelect
          multiple
          name={name}
          value={selected}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected: (string | number)[]) => {
            if (titleSelector) {
              return titleSelector(options.filter(option => selected.includes(keySelector(option))))
            }
            return selected.length + ' item' + (selected.length > 1 ? 's' : '') + ' selected'
          }}
          IconComponent={props => {
            return (
              <div className={props.className}>
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            )
          }}
          MenuProps={{
            autoFocus: !allSelected,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
        >
          <StyledMenuItem value={ALL}>
            <ListItemText primary={allSelected ? 'Clear All' : 'Select All'} />
          </StyledMenuItem>
          {options.map(option => (
            <StyledMenuItem value={keySelector(option)} key={keySelector(option)}>
              {selected.indexOf(keySelector(option)) !== -1 ? (
                <CheckedIcon style={{ width: '16px' }} />
              ) : (
                <UncheckedIcon style={{ width: '16px' }} />
              )}
              <ListItemText primary={labelSelector(option)} />
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  )
}

export default MultiSelect
