import Joyride, { EVENTS, STATUS, Step } from 'react-joyride'
import React, { useState } from 'react'

interface Props {
  onFinish?: () => void
  tutorialSteps: Step[]
}

const Tutorial = (props: Props) => {
  const { tutorialSteps, onFinish } = props

  const [steps] = useState(tutorialSteps)

  const callback = data => {
    const { action, status, type } = data
    console.log({ action, status, type })
    if (action === 'close' || [STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      if (onFinish) {
        onFinish()
      }
    }
  }

  return (
    <Joyride
      callback={callback}
      steps={steps}
      run
      disableCloseOnEsc
      showSkipButton
      continuous
      showProgress
      locale={{
        last: 'Finish'
      }}
      styles={{
        buttonBack: {
          color: '#fff'
        },
        options: {
          arrowColor: '#474747',
          backgroundColor: '#474747',
          overlayColor: 'rgba(0, 0, 0, 0.5)',
          primaryColor: '#000',
          textColor: '#fff',
          width: 450,
          zIndex: 1000
        },
        tooltipContainer: {
          lineHeight: 1.4,
          textAlign: 'left'
        }
      }}
    />
  )
}

Tutorial.defaultProps = {}

export default Tutorial
