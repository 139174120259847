import React, { useEffect, useState } from 'react'

import { Link, Tooltip, Typography } from '@material-ui/core'

import { BinocularsIcon, DownloadIcon, RoundCheckedIcon, Spinner } from 'components/icons'
import Box from 'components/box'
import Button from 'components/button'
import { Carrier, LOI } from 'store/carriers'
import CarrierDropdown from 'components/carrier_dropdown'
import LOIDropdown, { getConservativeLoi } from 'components/loi_dropdown'
import LabeledComponent from 'components/labeled_component'
import { Loading } from 'components/loading'
import Pagination from 'components/pagination'
import Table, { SortConfig } from 'components/table'
import _ from 'lodash'
import { get_file, get_s3_url } from 'utils/utils'
import { useMutation, useQuery } from '@apollo/client'

import { ADD_NEW } from './graphql/add_new'
import PresignedUrlLink from '../../../components/presigned_url_link/presigned_url_link'
import { QUERY } from './graphql/query'
import ReviewModal from './review'
import { StateAdd } from './types'
import { getCarrierIconPath } from '../../carrier_appointments/carrier_data'
import { getLOIIconPath } from '../../loi_data'
import { useSnackbar } from '../../../utils/snackbar'

const PAGE_LIMIT = 30

const getSortBy = (sortConfig?: SortConfig) => {
  if (!sortConfig) {
    return sortConfig
  }

  const TableColumnToSqlField = {
    createdAt: 'DATE_CREATED',
    carrierName: 'CARRIER'
  }

  return {
    field: TableColumnToSqlField[sortConfig.field],
    direction: sortConfig.desc ? 'DESC' : 'ASC'
  }
}

const ALL = {
  name: 'all',
  id: 'all'
}

const StateAdds = () => {
  const [loi, setLoi] = useState(LOI.Medicare)
  const [carrier, setCarrier] = useState<{ name: string; id: string }>(ALL)
  const [currentPage, setCurrentPage] = useState(1)

  const [reviewModal, setReviewModal] = useState<string | undefined>(void 0)

  const handleCarrierChange = (id: string, name: Carrier) => {
    setCarrier({ name, id })
  }

  const handleLoiChange = (loi: LOI) => {
    setLoi(loi)
    setCarrier(ALL)
  }

  const [sortConfig, setSortConfig] = useState<SortConfig | undefined>({
    field: 'createdAt',
    desc: true
  })

  const variables = {
    first: PAGE_LIMIT,
    offset: (currentPage - 1) * PAGE_LIMIT,
    sortBy: getSortBy(sortConfig),
    filters: {
      loi,
      carrier: carrier && carrier.id !== 'all' ? carrier.name : void 0
    }
  }

  const [newIds, setNewIds] = useState<string[]>([])

  const snackbar = useSnackbar()

  const [createNewStateAdd, { loading: creating }] = useMutation(ADD_NEW, {
    onCompleted: data => {
      const errors = data.stateAddCreate.stateAddCreateErrors || []
      const success = errors.length === 0
      if (success) {
        const id = data.stateAddCreate.carrierStateAdd.id
        setNewIds([id])
        query.refetch()
      }
    },
    onError: error => {
      console.error(error)
      snackbar({
        severity: 'error',
        message: error.message
      })
    }
  })

  const handleCreateNewStateAdd = () => {
    if (carrier.id === 'all') {
      setTimeout(() => {
        snackbar({
          severity: 'error',
          message: 'Please select a carrier.'
        })
      })
    } else {
      createNewStateAdd({
        variables: {
          input: {
            carrier: carrier.id
          }
        }
      })
    }
  }

  const query = useQuery(QUERY, {
    variables
  })

  useEffect(() => {
    query.refetch()
  }, [JSON.stringify(variables)])

  const stateAdds: StateAdd[] = _.get(query?.data, 'stateAdds.edges', []).map(row => ({
    ...row.node,
    metadata: row.node.metadata ? JSON.parse(row.node.metadata) : {}
  }))

  const totalCount: number = _.get(query?.data, 'stateAdds.totalCount', 0)
  const totalPages = Math.ceil(totalCount / PAGE_LIMIT)

  return (
    <Box spacing='1rem'>
      <Box display='flex'>
        <Typography variant='h6' component='h2'>
          <strong>State Adds {query.loading && <Loading />}</strong>
        </Typography>
      </Box>
      <Box display='flex' justifyContent='space-between'>
        <Box spacing='1rem' display='flex'>
          <Box display='flex' justifyContent='space-between'>
            <LabeledComponent label='Line of Insurance'>
              <LOIDropdown
                loi={loi}
                onChange={handleLoiChange}
                hiddenSelector={loi => loi !== LOI.Medicare}
              />
            </LabeledComponent>
          </Box>
          <Box display='flex' justifyContent='space-between'>
            <LabeledComponent label='Carrier'>
              <CarrierDropdown
                showAll={true}
                selected={carrier.id}
                loi={getConservativeLoi(loi) || ''}
                hiddenSelector={(carrierId, carrierName) =>
                  carrierId !== 'all' &&
                  ![Carrier.WellCare, Carrier.Humana].includes(carrierName as Carrier)
                }
                onChange={handleCarrierChange}
              />
            </LabeledComponent>
          </Box>
        </Box>
        <Box spacing='1rem' display='flex' alignItems='flex-end'>
          <Button
            onClick={handleCreateNewStateAdd}
            startIcon={creating ? <Spinner /> : void 0}
            color={carrier.id !== 'all' ? 'primary' : 'secondary'}
            disabled={creating}
          >
            {creating ? 'Processing New State Add File' : 'Create a New State Add File'}
          </Button>
        </Box>
      </Box>
      <Table
        style={{ marginTop: '1.5rem' }}
        rowKeySelector={row => row.id}
        sort={sortConfig}
        onHeaderClick={(_, cfg) => setSortConfig(cfg)}
        rowStyleSelector={row => (newIds.includes(row.id) ? { backgroundColor: '#EFF7E9' } : {})}
        columns={[
          {
            title: '',
            field: '',
            style: { verticalAlign: 'middle' },
            render: row => (newIds.includes(row.id) ? <RoundCheckedIcon /> : void 0)
          },
          {
            title: 'Date Created',
            field: 'createdAt',
            sortable: true,
            style: { verticalAlign: 'middle' },
            render: row => new Date(row.createdAt).toLocaleString()
          },
          {
            title: 'File Name',
            field: 'fileUrl',
            style: { verticalAlign: 'middle' },
            render: row => <PresignedUrlLink url={row.fileUrl} />
          },
          {
            title: 'LOI',
            field: 'loi',
            style: { verticalAlign: 'middle' },
            render: row => (
              <Box display='flex' spacing='0.5rem' alignItems='center'>
                <img
                  style={{ height: '1.5rem' }}
                  alt={row.carrier.name}
                  src={getLOIIconPath(row.carrier.loi)}
                />
                <span>{row.carrier.loi}</span>
              </Box>
            )
          },
          {
            title: 'Carrier',
            field: 'carrierName',
            sortable: true,
            style: { verticalAlign: 'middle' },
            render: row => (
              <img
                style={{ height: '1.5rem' }}
                alt={row.carrier.name}
                src={getCarrierIconPath(row.carrier.name, row.carrier.loi)}
              />
            )
          },
          {
            title: 'Actions',
            field: 'action',
            render: row => {
              return (
                <Box display='flex' spacing='0.25rem'>
                  <Button
                    startIcon={<BinocularsIcon />}
                    variant='text'
                    onClick={() => {
                      setReviewModal(row.id)
                    }}
                  >
                    <u>Review</u>
                  </Button>
                  <Button startIcon={<DownloadIcon />} variant='text'>
                    <PresignedUrlLink url={row.fileUrl} />
                  </Button>
                </Box>
              )
            }
          }
        ]}
        data={stateAdds.map(row => ({ ...row, carrierName: row.carrier.name }))}
      />
      <Pagination
        style={{
          marginTop: '0.25rem'
        }}
        count={totalPages}
        page={currentPage}
        onChange={(event, page) => {
          setCurrentPage(page)
        }}
      />
      {reviewModal && (
        <ReviewModal
          stateAdd={stateAdds.find(row => row.id === reviewModal)!}
          onClose={() => setReviewModal(void 0)}
        />
      )}
    </Box>
  )
}

export default StateAdds
