import React, { useState } from 'react'

import { Grid, Typography } from '@material-ui/core'

import Box from 'components/box'
import Button from 'components/button'
import Image from 'components/image'
import { Loading } from 'components/loading'
import Message from 'components/message/Message'
import Note from 'components/box/note'
import { RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'

import Accordion from '../../../commons/checklist_accordion'
import { PlateProps } from '../../../commons/plate'
import TimeToComplete from '../../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    agent_type?: 'ondemand' | 'w2' | string
    training_requirement?: boolean
  }
}

const getIcon = (checked?: boolean) => {
  const props = {
    style: {
      width: '1.25rem',
      height: '1.25rem'
    }
  }
  return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
}

const AiqEligibilityTraining = (props: Props) => {
  const { plateName, doNext, pageData } = props

  const agentType = pageData?.agent_type

  const agentTypeLoaded = agentType != null

  const training = !!pageData?.training_requirement

  const [showError, setShowError] = useState(false)

  const allCompleted = agentTypeLoaded && training

  const handleNext = () => {
    if (!allCompleted) {
      setShowError(true)
      return
    }
    doNext?.('next', {}, pageData)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Assurance IQ Eligibility & Training</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <Note>
          <strong>Note:</strong> To start the appointment paperwork, you need to complete the
          mandatory training. Please complete the necessary requirements and these sections should
          update automatically.
        </Note>
      </Grid>
      {showError && (
        <Grid item>
          <Message type='warning'>You must complete this action item</Message>
        </Grid>
      )}
      {showError && !training && (
        <Grid item>
          <Message type='warning'>You must complete this action item</Message>
        </Grid>
      )}
      <Grid item>
        <Accordion
          icon={getIcon(training)}
          title={<span style={{ display: 'flex', alignItems: 'center' }}>1. Training</span>}
        >
          <div>
            <Box mb={2} component={Typography}>
              1. Log into Axonify by clicking on the button below.
            </Box>
            <Button color='secondary' target='_blank' href='https://aiq.axonify.com/training/'>
              Training Link
            </Button>
            <Image
              style={{ width: '100%', marginTop: '1rem' }}
              alt='axonify login'
              src='/images/carriers/life/mutual_of_omaha/smartflow/axonify_login.png'
            />
            <br />
            <p>
              2. You should have been assigned a Training Path of Mutual Of Omaha. Click on the path
              and complete all the modules. Once you complete the training, this step will be
              checked automatically and you will be able to move on to the next step.
            </p>
            <Image
              style={{ width: '100%', marginTop: '0.5rem' }}
              alt='moo training path'
              src='/images/carriers/life/mutual_of_omaha/smartflow/moo_training.png'
            />
            <Box mt={1}>
              If you do not see the training path assigned to you and this step is not marked
              completed, please reach out to the contracting team to further assist you.
            </Box>
          </div>
        </Accordion>
      </Grid>
      <Grid container item justify='flex-end'>
        <Box width='100%' display='flex' alignItems='center' justifyContent='flex-end'>
          {showError && !allCompleted && (
            <Box mr='1rem'>
              <Message type={'warning'}>Action items incomplete</Message>
            </Box>
          )}
          <Button disabled={!agentTypeLoaded} onClick={handleNext}>
            Next: Paperwork
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default AiqEligibilityTraining
