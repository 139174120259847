import React from 'react'

import ChecklistAccordion from 'screens/carrier_appointments/commons/checklist_accordion'
import { InvalidIcon, RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'
import Table from 'components/table'
import { styleDate } from 'screens/certification/utils'
import styled from 'styled-components'

import { CertificationInfoStatus, CompleteCertificationData } from '../../ahip.d'

const Legend = styled.div`
  display: flex;
  align-items: center;
`

const CompleteFinalExamAccordion = (props: CompleteCertificationData) => {
  const { final_exam } = props
  const getIcon = () => {
    const checked = final_exam?.status === CertificationInfoStatus.COMPLETED.valueOf()
    const props = {
      style: {
        width: '1.25rem',
        height: '1.25rem'
      }
    }
    return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
  }
  const finalExamColumns = [
    {
      title: 'Final Exam Completion Date',
      field: 'completionDate'
    },
    {
      title: 'Score',
      field: 'score'
    }
  ]
  const finalExamData = [
    {
      completionDate: styleDate(final_exam?.completion_date) || <>&nbsp;</>,
      score: final_exam?.final_score || <>&nbsp;</>
    }
  ]
  const finalExamQuizColumns = [
    {
      title: 'Attempt',
      field: 'attempt'
    },
    {
      title: 'Date',
      field: 'date'
    },
    {
      title: 'Score',
      field: 'score'
    },
    {
      title: 'Pass/Fail',
      field: 'passed'
    }
  ]

  const finalExamQuizData = final_exam?.quiz_data.map(d => {
    return {
      attempt: d.attempt || <></>,
      date: styleDate(d.date),
      score: d.score || <></>,
      passed: d.passed ? (
        <Legend>
          <RoundCheckedIcon /> &nbsp;Pass
        </Legend>
      ) : (
        <Legend>
          <InvalidIcon /> &nbsp;Fail
        </Legend>
      )
    }
  }) || [
    {
      attempt: <>&nbsp;</>,
      date: '',
      score: <>&nbsp;</>,
      passed: <></>
    }
  ]

  return (
    <ChecklistAccordion icon={getIcon()} title={<span>1. Complete final exam</span>}>
      <Table columns={finalExamColumns} data={finalExamData} />
      <Table columns={finalExamQuizColumns} data={finalExamQuizData} />
    </ChecklistAccordion>
  )
}

export default CompleteFinalExamAccordion
