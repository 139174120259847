import { Action, action } from 'easy-peasy'

import { CarrierInfo } from './carriers'

export enum CarrierAppointmentRequestStatus {
  InProgress = 'INPROGRESS',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED'
}

export interface CarrierAppointmentRequest {
  id: string
  planYear: number
  status: CarrierAppointmentRequestStatus
  carrier: CarrierInfo
}

export interface CarrierAppointmentRequestsModel {
  items: CarrierAppointmentRequest[]
  load: Action<CarrierAppointmentRequestsModel, CarrierAppointmentRequest[]>
}

export const carrierAppointmentRequestsModel: CarrierAppointmentRequestsModel = {
  items: [] as CarrierAppointmentRequest[],
  load: action((state, payload) => {
    // we only want to store one appointment request for a given plan year and carrier
    payload.forEach(appointmentRequest => {
      state.items = state.items.filter(
        row =>
          row.planYear !== appointmentRequest.planYear ||
          row.carrier.id !== appointmentRequest.carrier.id
      )
      state.items.push(appointmentRequest)
    })
  })
}
