import gql from 'graphql-tag'

const USER_QUERY = gql`
  {
    me {
      id
      npn
      email
      assuranceAgentId
      firstName
      lastName
      activeStates
      totalStates
      intercomUserHash
      isNiprPulled
      loi
      skills {
        name
        loi
        status
        isEnabled
        qualifiedOverride
      }
      callCenter
      carrierData
      ahipStatus {
        id
        year
        isConfirmed
      }
      errorsAndOmissions {
        id
        carrierName
        accountId
        policyNumber
        coverageAmountInCent
        perOccrAmountInCent
        startDate
        endDate
        year
        certificate
      }
      carriers(first: 100) {
        edges {
          node {
            id
            name
            loi
            states
            avaliableState
            carrierTags {
              id
              name
              loi
            }
          }
        }
      }
      permissionGroups {
        id
        name
      }
      stateLicenses(first: 100) {
        edges {
          node {
            id
            state
            status
            number
            isResidentState
            classCode
            className
            dateIssued
            dateExpire
            medicareEligibility
            lifeEligibility
            healthEligibility
            propertyEligibility
            variableEligibility
            casualtyEligibility
            loas(first: 100) {
              edges {
                node {
                  status
                  loaName
                  loaCode
                  dateIssued
                  dateExpire
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`

export default USER_QUERY
