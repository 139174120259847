import gql from 'graphql-tag'

const AGENTS_QUERY = gql`
  query agentsQuery(
    $filter: AgentFilterInput
    $offset: Int
    $limit: Int!
    $sortBy: UserSortingInput
  ) {
    agents(first: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      totalCount
      edges {
        node {
          id
          npn
          firstName
          lastName
          email
          assuranceAgentId
          owner
          bpoSiteName
          callCenter
          loi
          isFlexAgent
        }
      }
    }
  }
`

export default AGENTS_QUERY
