import { Action, action } from 'easy-peasy'

import { Invitation, PageNumberToInvitations } from './admin'

export interface InvitationModel {
  pageNumberToInvitations: PageNumberToInvitations
  load: Action<InvitationModel, { pageNumber: number; invitations: Invitation[] }>
  updateInvitation: Action<InvitationModel, { pageNumber: number; invitation: Invitation }>
}

export const invitationModel: InvitationModel = {
  pageNumberToInvitations: {} as PageNumberToInvitations,
  load: action((state, payload) => {
    state.pageNumberToInvitations = {
      ...state.pageNumberToInvitations,
      [payload.pageNumber]: payload.invitations
    }
  }),
  updateInvitation: action((state, payload) => {
    // to update an invitation in a page
    const oldInvitations: Invitation[] = state.pageNumberToInvitations[payload.pageNumber]
    const newInvitations = oldInvitations.map(row =>
      row.id === payload.invitation.id ? payload.invitation : row
    )
    state.pageNumberToInvitations = {
      ...state.pageNumberToInvitations,
      [payload.pageNumber]: newInvitations
    }
  })
}
