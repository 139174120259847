import React, { useState } from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import CheckboxButton from 'components/checkbox_button'
import Image from 'components/image'
import Note from 'components/box/note'
import { RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'

import Accordion from '../commons/checklist_accordion'
import { PlateProps } from '../commons/plate'
import TimeToComplete from '../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    userInput?: {
      checkEmail?: boolean
      loggedIn?: boolean
      completedPaperwork?: boolean
    }
  }
}

const getIcon = (checked?: boolean) => {
  const props = {
    style: {
      width: '1.25rem',
      height: '1.25rem'
    }
  }
  return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
}

const Appointment = (props: Props) => {
  const { plateName, doUpdate, pageData = {} } = props

  const { userInput = {} } = pageData

  const [checkEmail, setCheckEmail] = useState(userInput.checkEmail)
  const [logged, setLogged] = useState(userInput.loggedIn)
  const [completed, setCompleted] = useState(userInput.completedPaperwork)

  const handleNext = () => {
    const allCompleted = checkEmail && logged && completed
    const data = {
      pageData: {
        userInput: {
          checkEmail,
          loggedIn: logged,
          completedPaperwork: completed
        }
      },
      progress: {
        name: plateName,
        status: allCompleted ? 'timer' : 'error'
      },
      nextProgress: 'Certification',
      nextPage: {
        name: 'Certification'
      }
    }
    doUpdate?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Note>
          <strong>Note:</strong> To continue with your appointment paperwork you must have an email
          invitation. If you don’t see an email invitation in your Assurance email inbox in the next
          72 hours, please contact an Assurance representative by using the 'Need Help' button at
          the bottom corner of your screen.
        </Note>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Appointment - Paperwork Process</h2>
      </Grid>
      <Grid item container direction='column' spacing={1}>
        <Grid item>
          <Accordion
            icon={getIcon(checkEmail)}
            title={<span style={{ display: 'flex', alignItems: 'center' }}>1. Check Email</span>}
          >
            Please check your Assurance email account for an email.
            <br />
            <div>
              <div>
                From: <strong>donotreply@contracting.aetna.com</strong>
              </div>
              <div>
                Subject: <strong>“You have been invited to contract with Aetna Medicare!”</strong>
              </div>
              <div>
                Recruiter Name: <strong>ASSURANCE IQ</strong>
              </div>
            </div>
            <br />
            a. Click on “Click here to get started” URL link in the email.
            <br />
            <Image
              style={{ width: '50%' }}
              alt='check_email_image'
              src='/images/carriers/medicare/aetna/appointment/check_email.jpg'
            />
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={checkEmail || false}
                onChange={value => setCheckEmail(value)}
                label='Received a Registration Email'
              />
            </Grid>
          </Accordion>
        </Grid>
        <Grid item>
          <Accordion
            icon={getIcon(logged)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                2. Log Into Producer World, or Register an Account to Producer World
              </span>
            }
          >
            Please check your Assurance email account for an email.
            <br />
            a. Either log into Producer World, or register for a Producer World account by clicking
            on the unique link inside the email.
            <br />
            <br />
            Sometimes, you may experience errors inside the Aetna portal. The most common one is
            that you have duplicate invitations sent to you. In that case, delete all but the latest
            one sent by Assurance IQ. You can only proceed when there is ONE open invitation.
            <br />
            b. Follow the instructions on the screen.
            <Image
              alt='log_into_producer_world_step_1'
              src='/images/carriers/medicare/aetna/appointment/log_into_producer_world_1.jpg'
            />
            <Image
              alt='log_into_producer_world_step_2'
              src='/images/carriers/medicare/aetna/appointment/log_into_producer_world_2.jpg'
            />
            <Image
              alt='log_into_producer_world_step_3'
              src='/images/carriers/medicare/aetna/appointment/log_into_producer_world_3.jpg'
            />
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={logged || false}
                onChange={value => setLogged(value)}
                label='I’ve Logged Into Producer World'
              />
            </Grid>
          </Accordion>
        </Grid>
        <Grid item>
          <Accordion
            icon={getIcon(completed)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                3. Complete and Submit Paperwork
              </span>
            }
          >
            <div>
              a. Once logged in, navigate to the “Open cases assigned to me” page and click on the
              “Case Key” link.
              <br />
              <br />
              <Image
                alt='complete_paperwork_step_1'
                src='/images/carriers/medicare/aetna/appointment/complete_paperwork_1.jpg'
              />
            </div>
            <div>
              b. You will be presented with a confirmation screen. Please click on the 'Background
              Questionnaire' tab.
              <br />
              <br />
              <Image
                alt='complete_paperwork_step_2'
                src='/images/carriers/medicare/aetna/appointment/complete_paperwork_2.jpg'
              />
            </div>
            <div>
              c. Answer all of the questions truthfully and then click 'Submit'.
              <br />
              <br />
              <Image
                alt='complete_paperwork_step_3'
                src='/images/carriers/medicare/aetna/appointment/complete_paperwork_3.jpg'
              />
            </div>
            <div>
              d. A PDF will load that will require you to fill out some information click “Start” to
              begin.
              <br />
              <br />
              <Image
                alt='complete_paperwork_step_4'
                src='/images/carriers/medicare/aetna/appointment/complete_paperwork_4.jpg'
              />
            </div>
            <div>
              e. Use the 'Next' button to guide you through the PDF. Some information to be filled
              out include your Social Security Number, Driver’s License, and State information.
              <br />
              <br />
              <Image
                alt='complete_paperwork_step_5'
                src='/images/carriers/medicare/aetna/appointment/complete_paperwork_5.jpg'
              />
            </div>
            <div>
              f. To finish the application click 'Signature' to sign the document. After that, click
              'Submit'.
              <br />
              <br />
              <Image
                alt='complete_paperwork_step_6'
                src='/images/carriers/medicare/aetna/appointment/complete_paperwork_6.jpg'
              />
            </div>
            <div>
              g. A popup will appear confirming you want to submit, click 'Yes'.
              <br />
              <br />
              <Image
                style={{ width: '60%' }}
                alt='complete_paperwork_step_7'
                src='/images/carriers/medicare/aetna/appointment/complete_paperwork_7.jpg'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={completed || false}
                onChange={value => setCompleted(value)}
                label='I Completed and Submitted All Required Paperwork'
              />
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container item justify='flex-end'>
        <Button onClick={handleNext}>Next: Certification</Button>
      </Grid>
    </Grid>
  )
}

export default Appointment
