import React from 'react'

import { Grid, Link } from '@material-ui/core'

import Button from 'components/button'
import Warning from 'components/box/warning'

import { PlateProps } from '../commons/plate'
import { get_yy_mm_dd_hh_mm } from '../../../utils/utils'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const Cancelled = (props: Props) => {
  const { cancelledBy, cancellationDate, doRestart } = props

  const date = cancellationDate ? get_yy_mm_dd_hh_mm(new Date(cancellationDate)) : '-'

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Carrier Appointment Canceled</h2>
      </Grid>
      <Grid item>
        <Warning>
          <strong>Note:</strong> The invitation we sent you was canceled by Humana's System.
        </Warning>
      </Grid>
      <Grid item>
        It has been noted in our system that on <strong>{date}</strong> {cancelledBy} canceled your
        appointment process with Humana.
        <br />
        <br />
        If you believe this was done in error, please notify us by using the purple 'Needs Help'
        button in the bottom right corner of your screen. We will look into your case and advise on
        next steps.
      </Grid>
      <Grid container item justify='flex-end' spacing={1}>
        <Grid item>
          <Button onClick={doRestart}>Restart Eligibility Check</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Cancelled
