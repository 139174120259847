import React from 'react'

import { Link } from '@material-ui/core'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper from '../commons/instructions_wrapper'

interface Props {
  carrierName: string
  loi: string
}

const Checklist = (props: Props) => {
  return (
    <InstructionsWrapper {...props}>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Complete Appointment Process
          </span>
        }
      >
        <strong>Instructions:</strong>
        <br />
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/en/articles/8208768-how-do-i-get-appointed-with-hcsc-health-care-service-corporation'
        >
          Assurance Help: How do I get appointed with HCSC?
        </Link>
        <br />
        <br />
        Assurance is offering HCSC plans in the Blue Cross and Blue Shield states of IL, MT, NM, OK,
        and TX.
        <br />
        <br />
        If you are currently AHIP certified and licensed in one or more of the HCSC states listed
        (IL, MT, NM, OK, TX), please submit your appointment request by filling out{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://assuranceiq.formstack.com/forms/hcsc_medicare_advantage_appointment_request'
        >
          HCSC Appointment Request Form.
        </Link>
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>2. Application Process</span>
        }
      >
        An invitation will be emailed to you from Producer_Service_Center@hcsc.net with a link to
        provide:
        <br />
        <ol>
          <li>Answers to the 10 background questions.</li>
          <li>E&O insurance information for Assurance.</li>
          <li>Signature on our Code of Conduct statement.</li>
        </ol>
        <br />
        Tips:
        <ul>
          <li>
            There are certain times when you may need to use the Assurance address or information.
            If your application is giving errors, you should try with the following information:
            <br />
            <em>
              Assurance IQ, LLC Address: 920 5th Avenue, Suite 3600 Seattle, WA 98104 Phone number
              (844) 334-1498
            </em>
          </li>
          <li>
            If you answer yes to any of the background questions, you are expected to upload
            supporting documentation for further consideration.
          </li>
          <li>
            Invites sometimes go the spam folders, be sure to check before requesting a new link.
          </li>
          <li>
            Keep the onboarding request identification number in the event you have to submit an
            escalation (OB followed by 5 numbers OB-12345)
          </li>
        </ul>
      </Accordion>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>3. Certification Process</span>
        }
      >
        <b>Log in:</b> If you have worked with HCSC in the past and you have your HCSC User ID and
        Password, please visit{' '}
        <Link target='_blank' rel='noreferrer' href='https://hcsc.cmpsystem.com/page/login'>
          The HCSC Login Page
        </Link>{' '}
        and log in.
        <br />
        <br />
        If you don’t remember your User ID or Password, you will need to contact{' '}
        <Link target='_blank' rel='noreferrer' href='mailto:bcmrxcertification@hcsc.net'>
          bcmrxcertification@hcsc.net
        </Link>{' '}
        for further assistance.
        <br />
        <br />
        If you need to create a new account: Start by navigating to{' '}
        <Link target='_blank' rel='noreferrer' href='https://hcsc.cmpsystem.com/page/login'>
          HCSC Login Page
        </Link>{' '}
        and click on "create an account"
        <br />
        <br />
        Once you are logged in, you will need to complete your listed certification tasks, which
        will include the following:
        <ul>
          <li>2024 AHIP Training</li>
          <li>My Certifications:</li>
          <ul>
            <li>2023/2024 Sales Agent Requirements Course</li>
            <li>2023/2024 MAPD/PDP Course</li>
            <li>2023/2024 MAPD/PDP Exam</li>
            <li>2024 SU: Sub Producer Curriculum Certificate</li>
            <li>Producer Certification Form</li>
          </ul>
        </ul>
      </Accordion>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>4. Check Appointment Status</span>
        }
      >
        All of your Medicare Advantage appointments are housed in one place.{' '}
        <Link rel='noreferrer' href='/carrier_appointments'>
          Check MA carrier appointment status
        </Link>
        .
      </Accordion>
    </InstructionsWrapper>
  )
}

export default Checklist
