import React, { Dispatch, SetStateAction, useState } from 'react'

import { Grid } from '@material-ui/core'

import { AccountProps } from './create_link_account.d'

const AhipAccountFlow = (props: AccountProps) => {
  const { page_data, allowContinue, setAllowContinue } = props
  const [checked, setChecked] = useState(false)
  const handleChange = () => {
    setChecked(!checked)
    setAllowContinue(!allowContinue)
  }

  return (
    <>
      <Grid item>
        <strong>Step 1:</strong> To link your data with Assurance, please log into your AHIP account
        using this{' '}
        <a
          href={(page_data as { ahip_acc_link: string })?.ahip_acc_link || '#'}
          target='_blank'
          rel='noreferrer'
        >
          link
        </a>{' '}
        and click <strong>Continue</strong>.
      </Grid>
      <Grid item>
        <img
          style={{ width: '685.27px' }}
          alt='ahip-certification-yes-account-step-1'
          src='/images/certification/ahip/ahip-create_link_account-no_user_info-yes_account_1.png'
        />
      </Grid>
      <Grid item>
        <strong>Step 2:</strong> Then click <strong>Transmit</strong> to send your data to
        Assurance. When you have completed those actions, click{' '}
        <strong>Link AHIP & Proceed to Certification</strong>.
      </Grid>
      <Grid item>
        <img
          style={{ width: '685.27px' }}
          alt='ahip-certification-yes-account-step-2'
          src='/images/certification/ahip/ahip-create_link_account-no_user_info-yes_account_2.png'
        />
      </Grid>
      <Grid item>
        <input type='checkbox' checked={checked} onChange={() => handleChange()} />
        <span> I logged into my AHIP account and transmitted my data</span>
      </Grid>
    </>
  )
}

export default AhipAccountFlow
