import React, { useEffect, useState } from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import Box from 'components/box'
import Dropdown from 'components/dropdown'
import { Loading } from 'components/loading'
import { MasqueradeIcon } from 'components/icons'
import MultiSelect from 'components/multi_select'
import Pagination from 'components/pagination'
import SearchInput from 'components/search_input'
import Select from 'components/select'
import Table, { SortConfig } from 'components/table'
import _ from 'lodash'
import styled from 'styled-components'
import { useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from 'utils/snackbar'

import AGENTS_QUERY from './agents.gql'
import Button from '../../../components/button'
import FIELDS_CHOICES_QUERY from './field_choices.gql'
import NIPR_REPLAY_MUTATION from './nipr_replay.gql'
import SupportedLoi from '../../loi_data'

import styles from './agent_directory.module.scss'

const CustomTable = styled(Table)`
  td {
    vertical-align: middle;
    padding: 0rem 1rem;
  }
`

interface AgentInfo {
  firstName: string
  lastName: string
  assuranceAgentId: string
  id: string
  npn: string
  owner: string
  email: string
  loi?: [string]
}

interface ReplayInfo {
  replay: boolean
  agentInfo?: AgentInfo
}

const getSortBy = (sortConfig?: SortConfig) => {
  if (!sortConfig) {
    return sortConfig
  }

  const TableColumnToSqlField = {
    firstName: 'FIRST_NAME',
    lastName: 'LAST_NAME',
    email: 'EMAIL',
    owner: 'OWNER',
    callCenter: 'CALL_CENTER'
  }

  return {
    field: TableColumnToSqlField[sortConfig.field],
    direction: sortConfig.desc ? 'DESC' : 'ASC'
  }
}

const AgentDirectory = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [showNiprReplayAgent, setShowNiprReplay] = useState<ReplayInfo>({
    replay: false
  })

  const [sortConfig, setSortConfig] = useState<SortConfig | undefined>({
    field: 'email'
  })

  const setSnackbar = useSnackbar()

  const PAGE_LIMIT = 30

  const [search, setSearch] = useState('')

  const DefaultFilters = {
    search: '',
    owners: [],
    loi: '',
    callCenters: []
  }

  const [filters, setFilters] = useState<Record<string, any>>(DefaultFilters)

  const updateFilter = (key: string, value: any) => {
    setFilters({
      ...filters,
      [key]: value
    })
  }

  const [checked, setChecked] = useState(false)
  function setCheckFlexAgent(value) {
    const filterFlexAgent = !value ? 'True' : 'False'
    updateFilter('isFlexAgent', filterFlexAgent)

    return !value
  }

  const query = useQuery(AGENTS_QUERY, {
    variables: {
      offset: Math.max(currentPage - 1, 0) * PAGE_LIMIT,
      limit: PAGE_LIMIT,
      filter: filters,
      sortBy: getSortBy(sortConfig)
    }
  })

  const agentData = query.data?.agents.edges.map(row => row.node) || []

  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    if (!query.data) {
      return
    }
    setTotalPages(Math.ceil(query.data.agents.totalCount / PAGE_LIMIT))
  }, [query.data])

  const [niprReplay] = useMutation(NIPR_REPLAY_MUTATION, {
    onCompleted: data => {
      const errors = data.niprReplay.errors
      const success = errors.length === 0
      if (success) {
        setSnackbar({
          message: 'Replay successful for ' + showNiprReplayAgent.agentInfo?.email,
          severity: 'success'
        })
      } else {
        setSnackbar({
          message: 'Replay failed for ' + showNiprReplayAgent.agentInfo?.email,
          severity: 'error'
        })
      }
    },
    onError: error => {
      console.error(error)
      setSnackbar({
        message: 'Replay failed for ' + showNiprReplayAgent.agentInfo?.email,
        severity: 'error'
      })
    }
  })

  useEffect(() => {
    setCurrentPage(Math.max(1, Math.min(totalPages, currentPage)))
  }, [currentPage, totalPages])

  const choicesQuery = useQuery(FIELDS_CHOICES_QUERY)

  const agentOwners: string[] = choicesQuery.data?.agentOwners || []
  const callCenters: string[] = choicesQuery.data?.callCenters || []

  const handleChange = e => {
    setSearch(e.value)
    updateFilter('search', e.value)
  }

  const minAgentIdChars = Math.max(...agentData.map(row => (row.assuranceAgentId || '').length))

  const handleNiprReplay = () => {
    niprReplay({
      variables: {
        input: {
          agentInfo: [
            {
              agentId: showNiprReplayAgent.agentInfo?.assuranceAgentId,
              npn: showNiprReplayAgent.agentInfo?.npn
            }
          ]
        }
      }
    })
    setShowNiprReplay({
      ...showNiprReplayAgent,
      replay: false
    })
  }

  return (
    <div>
      <div>
        <Typography variant='h6' component='h2'>
          <strong>Agent Directory</strong> {(choicesQuery.loading || query.loading) && <Loading />}
        </Typography>
      </div>
      <Box marginTop='1rem' display='flex' alignItems='flex-end'>
        <div>
          <span style={{ minWidth: '200px', fontWeight: 600 }}>Filter by Onboarding Owner</span>
          <MultiSelect
            style={{ marginTop: '0.25rem' }}
            selected={filters.owners}
            onChange={selected => updateFilter('owners', selected)}
            labelSelector={option => option.label}
            titleSelector={selected => selected?.map(row => row.label).join(', ')}
            keySelector={option => option.value}
            options={agentOwners.map(owner => ({ label: owner, value: owner }))}
          />
        </div>
        <div className={styles.leftMargin}>
          <span style={{ fontWeight: 600 }}>Line of Insurance</span>
          <Select
            style={{ minWidth: '200px', marginTop: '0.25rem' }}
            selected={filters.loi}
            options={SupportedLoi}
            keySelector={loi => loi.value}
            labelSelector={loi => loi.label}
            logoSelector={loi => loi.icon}
            onChange={loi => {
              updateFilter('loi', loi)
            }}
          />
        </div>
        <div className={styles.leftMargin}>
          <span style={{ fontWeight: 600 }}>Call Center</span>
          <MultiSelect
            style={{ minWidth: '200px', marginTop: '0.25rem' }}
            selected={filters.callCenters}
            options={callCenters}
            keySelector={row => row}
            labelSelector={row => row}
            onChange={selected => {
              updateFilter('callCenters', selected)
            }}
          />
        </div>
        <div className={styles.checkFlexAgent}>
          <input
            className={styles.checkboxFlexAgent}
            type='checkbox'
            checked={checked}
            onChange={() => setChecked(setCheckFlexAgent)}
          />
          <span className={styles.titleFlexAgent}>Only flex agents</span>
        </div>
        <SearchInput
          placeholder='Search for an agent by first name, last name, npn, or email'
          style={{ width: '30vw', marginLeft: 'auto' }}
          onChange={handleChange}
          value={search}
        />
      </Box>
      <CustomTable
        style={{ marginTop: '1.5rem' }}
        sort={sortConfig}
        rowKeySelector={agentInfo => agentInfo.email}
        onHeaderClick={(_, cfg) => setSortConfig(cfg)}
        columns={[
          {
            title: 'First Name',
            field: 'firstName',
            sortable: true,
            render: row => <span>{row.firstName}</span>
          },
          {
            title: 'Last Name',
            field: 'lastName',
            sortable: true,
            render: row => <span>{row.lastName}</span>
          },
          {
            title: 'Assurance ID',
            field: 'assuranceAgentId',
            render: row => (
              <Box display='flex' alignItems='center' spacing='1rem'>
                <Link
                  href={'/?agent_id=' + row.assuranceAgentId}
                  target='_blank'
                  rel='noreferrer'
                  style={{ minWidth: minAgentIdChars + 'ch' }}
                >
                  {row.assuranceAgentId}
                </Link>
                <MasqueradeIcon />
              </Box>
            )
          },
          {
            title: 'Assurance Email',
            field: 'email',
            sortable: true,
            render: row => (
              <Link target='_blank' rel='noreferrer' href={'mailto:' + row.email}>
                {row.email}
              </Link>
            )
          },
          {
            title: 'LOI',
            field: 'loi',
            render: row =>
              row.loi?.map(loi => {
                const selectedLoi = SupportedLoi.find(l => l.value === loi)
                return (
                  <Box key={loi} display='flex' alignItems='center' spacing={'0.5rem'}>
                    {selectedLoi && <img src={selectedLoi.icon} alt='logo' />}
                    <span>{_.capitalize(selectedLoi?.label || loi)}</span>
                  </Box>
                )
              })
          },
          {
            title: 'Call Center',
            field: 'callCenter',
            sortable: true
          },
          {
            title: 'Onboarding Owner',
            field: 'owner',
            sortable: true,
            render: row => <span>{row.owner}</span>
          },
          {
            title: 'Action',
            field: 'action',
            render: row => {
              return (
                <Dropdown
                  selected={'action'}
                  onChange={option => {
                    switch (option) {
                      case 'niprReplay':
                        setShowNiprReplay({
                          replay: true,
                          agentInfo: row
                        })
                        break
                      default:
                        break
                    }
                  }}
                  buttonRender={() => (
                    <IconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true'>
                      <MoreVertIcon htmlColor={'black'} />
                    </IconButton>
                  )}
                  labelSelector={option => option.label}
                  keySelector={option => option.value}
                  options={[
                    {
                      label: 'NIPR Replay',
                      value: 'niprReplay'
                    }
                  ]}
                />
              )
            }
          }
          /*
          {
            title: 'Personal Email',
            field: 'personalEmail',
            sortable: true,
            render: row => (
              <Link target='_blank' rel='noreferrer' href={'mailto:' + row.personalEmail}>
                {row.personalEmail}
              </Link>
            )
          }
           */
        ]}
        data={agentData}
      />
      <Pagination
        style={{
          marginTop: '0.25rem'
        }}
        count={totalPages}
        page={currentPage}
        onChange={(event, page) => {
          setCurrentPage(page)
        }}
      />
      <Dialog
        onClose={() => setShowNiprReplay({ ...showNiprReplayAgent, replay: false })}
        open={showNiprReplayAgent.replay}
      >
        <DialogTitle>
          <strong>NIPR Replay Confirmation</strong>
        </DialogTitle>
        <DialogContent dividers>
          NIPR Replay does a real-time hit against NIPR to extract the agent’s latest state licenses
          from the NIPR system. Occasionally, there may be delays or discrepancies that deem this
          action necessary. Every NIPR Replay is a cost to Assurance. It is advised that you only
          perform NIPR Replay when there is a discrepancy in the particular Agent's State Licenses.
          When in doubt, please check with the Contracting and Licensing Team.
        </DialogContent>
        <DialogActions>
          <Button
            variant='text'
            onClick={() => setShowNiprReplay({ ...showNiprReplayAgent, replay: false })}
          >
            <u>Cancel</u>
          </Button>
          <Button onClick={handleNiprReplay}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AgentDirectory
