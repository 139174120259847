import React from 'react'

import { Link } from '@material-ui/core'

import { S3_BUCKET_URL } from 'utils/environment-variables'
import { commaFormattedNumber } from 'utils/utils'
import styled from 'styled-components'

interface Props {
  carrierName: string
  certificate: string
  accountId: string
  policyNumber: number
  coverageAmountInCent: number
  perOccrAmountInCent: number
  startDate: string
  endDate: string
}

const LabeledValue = (props: { className?: string; label: string; value: string }) => {
  return (
    <div className={props.className}>
      <label>
        <strong>{props.label}</strong>
      </label>
      <div style={{ marginTop: '0.25rem' }}>{props.value || <>&nbsp;</>}</div>
    </div>
  )
}

const CarrierName = styled(LabeledValue)``

const AccountId = styled(LabeledValue)``

const PolicyNumber = styled(LabeledValue)``

const AggregateCoverageAmount = styled(LabeledValue)``

const PerOccurrenceCoverageAmount = styled(LabeledValue)``

const PolicyStartDate = styled(LabeledValue)``

const PolicyEndDate = styled(LabeledValue)``

const Certificate = styled(LabeledValue)``

const Main = styled.div`
  display: grid;
  gap: 1rem 1.5rem;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'carriername carriername'
    'accountid policynumber'
    'aggcovg percovg'
    'startdate enddate'
    'certificate certificate';

  ${CarrierName} {
    grid-area: carriername;
  }
  ${AccountId} {
    grid-area: accountid;
  }
  ${PolicyNumber} {
    grid-area: policynumber;
  }
  ${AggregateCoverageAmount} {
    grid-area: aggcovg;
  }
  ${PerOccurrenceCoverageAmount} {
    grid-area: percovg;
  }
  ${PolicyStartDate} {
    grid-area: startdate;
  }
  ${PolicyEndDate} {
    grid-area: enddate;
  }
  ${Certificate} {
    grid-area: certificate;
  }
`

const ReadOnlyForm = (props: Props) => {
  const { certificate = '' } = props

  return (
    <Main>
      <CarrierName label='Carrier Name' value={props.carrierName} />
      <AccountId label='Account Id' value={props.accountId} />
      <PolicyNumber label='Policy Number' value={props.policyNumber} />
      <AggregateCoverageAmount
        label='Aggregate Coverage Amount'
        value={'$' + commaFormattedNumber(props.coverageAmountInCent)}
      />
      <PerOccurrenceCoverageAmount
        label='Per Occurrence Aggregate Coverage Amount'
        value={'$' + commaFormattedNumber(props.perOccrAmountInCent)}
      />
      <PolicyStartDate label='Policy Start Date' value={props.startDate} />
      <PolicyEndDate label='Policy End Date' value={props.endDate} />
      <Certificate
        label='Certificate'
        value={
          <Link
            style={{ fontSize: '0.875rem', fontWeight: 600 }}
            href={S3_BUCKET_URL + '/' + certificate}
            target='_blank'
            rel='noreferrer'
          >
            {certificate.split('/').slice(-1)[0]}
          </Link>
        }
      />
    </Main>
  )
}

export default ReadOnlyForm
