import React, { useState } from 'react'

import { Grid, Link } from '@material-ui/core'

import Button from 'components/button'
import CheckboxButton from 'components/checkbox_button'
import Image from 'components/image'
import InfoBox from 'components/box/info_box'
import { RoundCheckedIcon, RoundUncheckedIcon, WarningIcon } from 'components/icons'
import { carrierFullName } from 'store/carriers'

import Accordion from '../../../commons/checklist_accordion'
import { PlateProps } from '../../../commons/plate'
import TimeToComplete from '../../../commons/time_to_complete'

const getIcon = (checked?: boolean) => {
  const props = {
    style: {
      width: '1.25rem',
      height: '1.25rem'
    }
  }
  return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
}

const redTextStyle = { color: '#ff4237' }
const warningIconStyle = {
  width: '1.25rem',
  marginRight: '0.375rem'
}

const ApiCompleted = (props: PlateProps) => {
  const { carrierName, doNext, pageData = {} } = props

  const { userInput = {} } = pageData

  const [checkEmail, setCheckEmail] = useState(userInput.checkEmail)
  const [completeDocuSign, setCompleteDocuSign] = useState(userInput.completeDocuSign)
  const [digitalSignature, setDigitalSignature] = useState(userInput.digitalSignature)
  const [nextClicked, setNextClicked] = useState(false)

  const carrier = carrierFullName(carrierName)

  const handleNext = () => {
    setNextClicked(true)
    const allCompleted = checkEmail && completeDocuSign && digitalSignature
    if (allCompleted) doNext?.('next')
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Appointment Paperwork</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <InfoBox>
          We have initiated your appointment requests with {carrier}. An email will be sent to your
          Assurance Inbox from DocuSign on behalf of the carrier to complete and submit your
          appointment paperwork. Please complete the paperwork{' '}
          <strong>promptly and accurately</strong>. If you do not receive the email to start
          appointment paperwork within 3 days of starting this step, please email the Assurance
          Contracting Team at lifecarrierappointments@assurance.com.
        </InfoBox>
      </Grid>
      <Grid item container>
        {nextClicked && !checkEmail && (
          <Grid item container>
            <Grid item>
              <WarningIcon style={warningIconStyle} />
            </Grid>
            <Grid item style={redTextStyle}>
              You must complete this action item
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Accordion icon={getIcon(checkEmail)} title='1. Check Assurance Email'>
            Please check your Assurance email account for email to complete and submit paperwork
            from DocuSign on behalf of the {carrier}.
            <br />
            <div>
              <div>
                <strong>From:</strong>{' '}
                <Link target='_blank' href={'mailto:dse_NA3@docusign.net'}>
                  dse_NA3@docusign.net
                </Link>
              </div>
              <div>
                <strong>Subject:</strong> Lumico Appointment Application
              </div>
              <div>
                <strong>Recruiter Name:</strong> ASSURANCE IQ
              </div>
            </div>
            <Grid container item justifyContent='flex-end'>
              <CheckboxButton
                checked={checkEmail || false}
                onChange={value => setCheckEmail(value)}
                label="I've received the DocuSign email"
              />
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid item container>
        {nextClicked && !completeDocuSign && (
          <Grid item container>
            <Grid item>
              <WarningIcon style={warningIconStyle} />
            </Grid>
            <Grid item style={redTextStyle}>
              You must complete this action item
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(completeDocuSign)}
            title='2. Click on DocuSign Link and Complete the Paperwork'
          >
            <div>
              a. Start by clicking "Review Documents" Link on the email received from DocuSign. This
              will direct you to the Lumico Appointment Form. Please complete the from accurately.
              <Image
                style={{ width: '70%', marginTop: '0.75rem' }}
                alt='example appointment email'
                src='/images/carriers/life/lumico/smartflow/review.png'
              />
              <Image
                style={{ width: '70%', marginTop: '0.75rem' }}
                alt='example appointment email'
                src='/images/carriers/life/lumico/smartflow/form.png'
              />
            </div>
            <div>
              b. Answer all the background questions carefully. If the answer to any of the
              background questions is yes, then please be prepared to share supporting documents
              with the carrier. Please collect all the supporting documents that will provide
              additional information to help with the appointment process and share with the carrier
              when requested.
              <br />
              <br />
              Note: Ensure that you add any relevant details directly on the paperwork itself. The
              supporting documents need to be shared with the carrier directly when requested.
              <Image
                style={{ width: '70%', marginTop: '0.75rem' }}
                alt='example appointment email'
                src='/images/carriers/life/lumico/smartflow/questions.png'
              />
            </div>
            <Grid container item justifyContent='flex-end'>
              <CheckboxButton
                checked={completeDocuSign || false}
                onChange={value => setCompleteDocuSign(value)}
                label="I've completed the appointment form including the background questions"
              />
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid item container>
        {nextClicked && !digitalSignature && (
          <Grid item container>
            <Grid item>
              <WarningIcon style={warningIconStyle} />
            </Grid>
            <Grid item style={redTextStyle}>
              You must complete this action item
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(digitalSignature)}
            title='3. Generate and Paste the Digital Signature'
          >
            c. Please do not miss generating the e-signature as instructed on the last page of the
            DocuSign. {carrier} needs this signature as it will be affixed to Lumico policies that
            you submit in the future.
            <br />
            <br />
            <div>
              i{')'} Review the instructions on the last page of the DocuSign Form and click on the
              link as advised. <strong>Make sure your pop-up blockers are off</strong> as this link
              opens in a different chrome window.
              <Image
                style={{ width: '70%', marginTop: '0.75rem' }}
                alt='example appointment email'
                src='/images/carriers/life/lumico/smartflow/instructions.png'
              />
            </div>
            <div>
              ii{')'} Follow the prompts to complete your digital signature. You will end up on a
              page that will display a 32-character code. This is your digital signature.{' '}
              <strong> DO NOT manually type this as it can lead to errors</strong>. Please copy the
              code as highlighted in Green below.
              <Image
                style={{ width: '70%', marginTop: '0.75rem' }}
                alt='example appointment email'
                src='/images/carriers/life/lumico/smartflow/sig_capture.png'
              />
            </div>
            <div>
              iii{')'} Once the 32-character signature code is copied,{' '}
              <strong> PASTE it in step #6</strong> of the Signature step at the very end of the
              DocuSign Form.
              <Image
                style={{ width: '70%', marginTop: '0.75rem' }}
                alt='example appointment email'
                src='/images/carriers/life/lumico/smartflow/step6.png'
              />
            </div>
            <Grid container item justifyContent='flex-end'>
              <CheckboxButton
                checked={digitalSignature || false}
                onChange={value => setDigitalSignature(value)}
                label="I've generated and pasted the digital signature "
              />
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container item justifyContent='flex-end'>
        {nextClicked && !(checkEmail && completeDocuSign && digitalSignature) && (
          <Grid item>
            <Grid container>
              <Grid item>
                <WarningIcon style={warningIconStyle} />
              </Grid>
              <Grid item style={redTextStyle}>
                Action Items Incomplete
              </Grid>
            </Grid>
          </Grid>
        )}
        <Button onClick={handleNext}>Next: Carrier Review</Button>
      </Grid>
    </Grid>
  )
}

export default ApiCompleted
