import React from 'react'

import { Theme, lighten } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import Box from '../box'
import Button from '../button'

const useStyles = makeStyles((theme: Theme) => ({
  blueBox: {
    display: 'flex',
    border: '1px solid ' + lighten(theme.palette.primary.main, 0.7),
    padding: '1rem',
    background: '#E0F1FA',
    color: theme.palette.primary.dark,
    borderRadius: '0.25rem'
  },
  button: {
    background: theme.palette.primary.dark
  }
}))

interface Props {
  children?: React.ReactNode
  onShowTutorial: () => void
}

export const SeeTutorialButton = ({ onClick }: { onClick: () => void }) => {
  const classes = useStyles()

  return (
    <Button className={classes.button} color='primary' onClick={onClick}>
      <strong>See Tutorial</strong>
    </Button>
  )
}

const ShowTutorialBox = (props: Props) => {
  const { children, onShowTutorial } = props

  const classes = useStyles()

  return (
    <Box className={classes.blueBox} display='flex' spacing='1rem' alignItems='center'>
      <Box flex={1}>{children}</Box>
      <SeeTutorialButton onClick={onShowTutorial} />
    </Box>
  )
}

export default ShowTutorialBox
