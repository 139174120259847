import React from 'react'

import styled from 'styled-components'

import Input, { Props } from '../input'

const Main = styled(Input)`
  label input {
    border-radius: 0.25rem;
    background-image: url(/images/search.svg);
    background-repeat: no-repeat;
    background-position: 3% 50%;
    padding-left: 2.5rem;
  }
`

const SearchInput = (props: Props) => {
  return <Main {...props} type='search' />
}

export default SearchInput
