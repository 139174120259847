import React from 'react'

import { Grid, Link } from '@material-ui/core'

import Image from 'components/image'
import Note from 'components/box/note'
import Warning from 'components/box/warning'

import { PlateProps } from '../../../commons/plate'
import TimeToComplete from '../../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const EmailInviteSent = (props: Props) => {
  const tooLongInPage =
    props.dateStarted &&
    new Date().getTime() - new Date(props.dateStarted).getTime() > 3 * 86400 * 1000
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Invitation - Confirm Email Invite</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      {tooLongInPage ? (
        <Grid item>
          <Warning>
            It looks like you didn’t receive an invitation in the past 72 hours. Please request
            assistance from the Assurance team.
          </Warning>
        </Grid>
      ) : (
        <Grid item>
          <Note>
            <strong>Note:</strong> It can take up to 48 hours to get the appointment ID. After you
            get it, you will be taken to Anthem's Producer Toolbox:
            https://brokerportal.anthem.com/apps/ptb/login
          </Note>
        </Grid>
      )}
      <Grid item>
        Assurance IQ's licensing team has requested for an invitation on your behalf. Please check
        your{' '}
        <Link target='_blank' rel='noreferrer' href='https://outlook.office365.com/mail/'>
          <strong>@Assurance.com</strong>
        </Link>{' '}
        email account for:
        <br />
        <br />
        <div>
          <strong>From:</strong> software.support@anthem.com
        </div>
        <div>
          <strong>Subject:</strong> Your new Appointment
        </div>
        <br />
        Once you receive the invitation, come back to this page and see what to do next.
      </Grid>
      {!tooLongInPage && (
        <Grid container item>
          <Image
            alt='invitation email'
            src='/images/carriers/medicare/anthem/smartflow/anthem1.png'
            style={{ width: '80%' }}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default EmailInviteSent
