import React from 'react'

import { Link } from '@material-ui/core'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper, { CommonChecklistProps } from '../commons/instructions_wrapper'

interface Props extends CommonChecklistProps {}

const Checklist = (props: Props) => {
  return (
    <InstructionsWrapper {...props}>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Complete Appointment Process
          </span>
        }
      >
        <strong>Instructions:</strong>
        <p>
          <Link
            target='_blank'
            rel='noreferrer'
            href='https://help.assurance.com/hc/en-us/articles/4408791712916-How-to-get-appointed-to-sell-Molina-Healthcare'
          >
            Assurance Help: How do I get appointed with Molina Healthcare for ACA?
          </Link>
        </p>
        <p>
          Please use this&nbsp;
          <Link
            target='_blank'
            rel='noreferrer'
            href='https://assuranceiq.formstack.com/forms/molina_aca'
          >
            Molina Appointment Link
          </Link>
          &nbsp;to begin your appointment request. Use this article as your guide to complete the
          onboarding so there are no delays with your appointments.
        </p>
        <p>
          Once you complete the request to onboard, please allow 24 to 48 hours to receive your
          invitation email to begin your onboarding process.
        </p>
        <p>
          You will receive an email from&nbsp;
          <Link target='_blank' rel='noreferrer' href='mailto:molianhealthcare@vertafore.com'>
            molianhealthcare@vertafore.com
          </Link>
        </p>
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>2. Application Submission</span>
        }
      >
        <strong>Instructions:</strong>
        <p>
          <Link
            target='_blank'
            rel='noreferrer'
            href='https://help.assurance.com/hc/en-us/articles/4408791712916-How-to-get-appointed-to-sell-Molina-Healthcare'
          >
            Assurance Help: How do I get appointed with Molina Healthcare for ACA?
          </Link>
        </p>
        <p>
          You will receive an email Molina Marketplace Broker Onboarding Application. This means
          your contract has been submitted for onboarding review. You have successfully submitted
          your contract- Congratulations.
        </p>
      </Accordion>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>3. Check Appointment Status</span>
        }
      >
        All of your Affordable Care Act (ACA) appointments are housed in one place.{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://join.assurance.com/agent/aca_appointments'
        >
          Check ACA carrier appointment status.
        </Link>
      </Accordion>
    </InstructionsWrapper>
  )
}

export default Checklist
