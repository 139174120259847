import React from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import Warning from 'components/box/warning'

import { PlateProps } from '../../../plate'
import TimeToComplete from '../../../time_to_complete'
import { carrierFullName } from '../../../../../../store/carriers'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const TerminatedWithCause = (props: Props) => {
  const { carrierName } = props

  const fullCarrierName = carrierFullName(carrierName)

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>
          Eligibility - Previously Appointed with {fullCarrierName}
        </h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        According to the National Insurance Producer Registry (NIPR), you were appointed with{' '}
        {fullCarrierName} in the past.
      </Grid>
      <Grid item>
        <Warning>
          <strong>Current Status:</strong> {fullCarrierName} has deemed you “Termination - With
          Cause” and therefore, you cannot continue with appointments under Assurance.
        </Warning>
      </Grid>
      <Grid item>
        To appeal this, the Assurance Licensing team will need to appeal to {fullCarrierName}'s
        Broker Services Team.
      </Grid>
      <Grid container item justify='flex-end'>
        <form
          target='_blank'
          method='get'
          action='https://assuranceiq.formstack.com/forms/appointment_manager__request_assistance_from_assurance'
        >
          <Button type='submit'>Request Assistance from Assurance</Button>
        </form>
      </Grid>
    </Grid>
  )
}

export default TerminatedWithCause
