import React from 'react'

import { Grid, Link } from '@material-ui/core'

import Note from 'components/box/note'
import { PlateProps } from 'screens/carrier_appointments/commons/plate'
import TimeToComplete from 'screens/carrier_appointments/commons/time_to_complete'
import Warning from 'components/box/warning'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const EmailInviteSent = (props: Props) => {
  const moreThan72HoursPassed = (dateStarted: string) => {
    const today = new Date().getTime()
    const originalDate = new Date(dateStarted).getTime()
    const seventyTwoHours = 3 * 86400 * 1000
    return today - originalDate >= seventyTwoHours
  }

  const tooLongInPage = props.dateStarted && moreThan72HoursPassed(props.dateStarted)
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Invitation - Confirm Email Invite</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        Please check your{' '}
        <Link target='_blank' rel='noreferrer' href='https://outlook.office365.com/mail/'>
          <strong>@Assurance.com</strong>
        </Link>{' '}
        email account for:
        <br />
        <br />
        <div>
          <strong>From</strong>: producer_service_center@psc.hcsc.health
        </div>
        <div>
          <strong>Subject</strong>: Onboarding Invitation
        </div>
        <div>
          <strong>Recruiter Name:</strong> ASSURANCE IQ
        </div>
        <br />
        Once you receive the invitation, come back to this page and see what to do next.
      </Grid>
      {tooLongInPage && (
        <Grid item>
          <Warning>
            It looks like you didn’t receive an invitation in the past 72 hours. Please request
            assistance from the Assurance team.
          </Warning>
        </Grid>
      )}
    </Grid>
  )
}

export default EmailInviteSent
