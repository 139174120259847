import gql from 'graphql-tag'

const CARRIER_RTS_FILE_MUTATION = gql`
  mutation ($input: CarrierRtsFileInput!) {
    carrierRtsFileCreate(input: $input) {
      carrierRtsFile {
        id
        createdAt
        updatedAt
        privateMetadata
        metadata
        fileUrl
        carrier {
          id
          name
          avaliableState
          loi
          states
        }
        uploader {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`

export default CARRIER_RTS_FILE_MUTATION
