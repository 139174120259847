import React, { useState } from 'react'

import { Grid } from '@material-ui/core'
import Link from '@material-ui/core/Link'

import Button from 'components/button'
import { Carrier } from 'store/carriers'
import Note from 'components/box/note'

import ConfirmRequestInvitation from '../../../commons/flow/plates/confirm_request_invitation'
import { PlateProps } from '../../../commons/plate'
import TimeToComplete from '../../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const RequestEmailInvite = (props: Props) => {
  const { doNext } = props

  const [confirmDialog, setConfirmDialog] = useState(false)

  const handleNext = () => {
    doNext?.('next')
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Invitation - Request Email Invite</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        You qualify to be appointed with UHC ACA under the Assurance hierarchy. This appointment
        invitation is for the following product(s):
        <ul style={{ marginTop: '.5rem', paddingInlineStart: '1.25rem' }}>
          <li>UHC ACA product</li>
        </ul>
        <br />
        To continue your appointment process, please request an invitation.
      </Grid>
      <Grid item>
        <Note>
          <strong>Note:</strong> It can take up to 7 business days to get the appointment ID. After
          you get it, you will be taken to UHC ACA's Producer Center:{' '}
          <Link rel='noreferrer' target='_blank' href={'https://platform.sircon.com/#/login'}>
            https://platform.sircon.com/#/login
          </Link>
        </Note>
      </Grid>
      <Grid container item justify='flex-end' spacing={1}>
        <Grid item>
          <Button onClick={() => setConfirmDialog(true)}>Request an Email Invite</Button>
        </Grid>
      </Grid>
      <ConfirmRequestInvitation
        carrierName={Carrier.UnitedHealthCareACA}
        open={confirmDialog}
        onConfirm={() => {
          handleNext()
          setConfirmDialog(false)
        }}
        onClose={() => setConfirmDialog(false)}
      />
    </Grid>
  )
}

export default RequestEmailInvite
