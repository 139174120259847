import React, { useEffect, useRef, useState } from 'react'

import { Collapse } from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

import Box from '../box'

export interface Column<RowType> {
  title?: string
  field: string
  render?: (row: RowType) => React.ReactNode
  align?: 'center' | 'left' | 'right'
  style?: React.CSSProperties
  sortable?: boolean
  comparator?: (rowA: RowType, rowB: RowType) => number
}

export interface SortConfig {
  field: string
  desc?: boolean //default is asc
}

export interface Props<RowType> {
  id?: string
  className?: string
  style?: React.CSSProperties
  title?: string
  columns: Column<RowType>[]
  onHeaderClick?: (column: Column<RowType>, sortConfig?: SortConfig) => void
  onRowClick?: (row: RowType) => void
  rowExpandable?: boolean
  expandContentRenderer?: (row: RowType) => React.ReactNode
  rowKeySelector?: (row: RowType, idx?: number) => string | number
  rowStyleSelector?: (row: RowType) => React.CSSProperties
  searchable?: boolean
  sort?: SortConfig
  data: RowType[]
}

const getSortIcon = (sortConfig?: SortConfig, column?: string) => {
  if (!sortConfig || sortConfig.field !== column) {
    return '/images/table_icons/default.svg'
  }

  return sortConfig.desc ? '/images/table_icons/descend.svg' : '/images/table_icons/ascend.svg'
}

const Main = styled.div`
  > * + * {
    margin-top: 10px;
  }
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    border: 1px solid #cccccc;
    padding: 10px;
    &:focus {
      border: 1px solid #888888;
      outline: none;
    }
  }
`

const Empty = styled.div`
  padding: 1rem 0px;
  text-align: center;
  border: 1px solid #ccc;
  border-top: none;
`

const TableWrapper = styled.div`
  > table {
    border: 1px solid #cccccc;
    width: 100%;
    border-collapse: collapse;
  }
`

const Th = styled.th`
  z-index: 1;
  font-weight: 600;
  background: #eeeeee;

  border: 1px solid #cccccc;
  border-left: none;
  border-right: none;
  padding: 0.5rem 1rem;
  position: sticky;
  top: 0;

  border-top: none;
  &:first-child {
    border-left: none;
  }
  &:last-child {
    border-right: none;
  }

  ${props =>
    props.sortable &&
    `
        cursor: pointer;
    `}
`

const defaultRowKeySelector = (row: any, idx: number) => idx
const defaultStyleSelector = () => void 0

interface RowProps<RowType> {
  expandable?: boolean
  onRowClick?: (row: RowType) => undefined
  columns: Column<RowType>[]
  row: RowType
  style?: React.CSSProperties
  expandContentRenderer?: (row: RowType) => React.ReactNode
}

const Td = styled.td`
  border: 1px solid #cccccc;
  border-left: none;
  border-right: none;
  padding: 0.5rem 1rem;
  vertical-align: top;
  ${props =>
    props['data-expandable'] &&
    `
    border: none;
    border-top: 1px solid #ccc;
  `}
`

const Row = <RowType,>(props: RowProps<RowType>) => {
  const { row, style, expandable, expandContentRenderer, columns, onRowClick } = props

  const expandItemRef = useRef<HTMLTableRowElement | null>(null)
  const [expanded, setExpanded] = useState(false)

  const scrollIntoFocus = () => {
    expandItemRef.current!.scrollIntoView({
      block: 'nearest'
    })
  }

  const handleRowClick = () => {
    if (expandable) {
      const newExpanded = !expanded
      if (newExpanded) {
        setTimeout(() => {
          scrollIntoFocus()
        }, 100)
        setTimeout(() => {
          scrollIntoFocus()
        }, 300)
      }
      setExpanded(newExpanded)
    }
    onRowClick?.(row)
  }

  return (
    <>
      <tr
        style={{
          cursor: expandable ? 'pointer' : void 0,
          ...style
        }}
        onClick={handleRowClick}
      >
        {expandable && (
          <Td style={{ textAlign: 'center' }} data-expandable={expandable}>
            <FontAwesomeIcon
              style={{
                transform: `rotate(${expanded ? 180 : 0}deg)`,
                transition: '.3s'
              }}
              icon={faChevronUp}
            />
          </Td>
        )}
        {columns.map(column => (
          <Td
            key={column.field}
            data-expandable={expandable}
            style={{ ...column.style, textAlign: column.align || 'left' }}
          >
            {column.render ? column.render(row) : row[column.field]}
          </Td>
        ))}
      </tr>
      {expandable && (
        <tr ref={expandItemRef}>
          <td colSpan={columns.length + 1}>
            <Collapse
              in={expanded}
              timeout={200}
              unmountOnExit
              addEndListener={node => {
                scrollIntoFocus()
              }}
            >
              {expandContentRenderer?.(row)}
            </Collapse>
          </td>
        </tr>
      )}
    </>
  )
}

const getDefaultComparator = <RowType,>(sortConfig: SortConfig) => {
  const field = sortConfig.field
  return (rowA: RowType, rowB: RowType) => {
    const valueA = rowA[field]
    const valueB = rowB[field]

    if (valueA === valueB) {
      return 0
    }
    return valueA < valueB ? -1 : 1
  }
}

const Table = <Row extends Record<string, any>>(props: Props<Row>) => {
  const {
    id,
    className,
    style,
    title,
    columns,
    rowKeySelector = defaultRowKeySelector,
    rowStyleSelector = defaultStyleSelector,
    expandContentRenderer,
    onHeaderClick,
    rowExpandable = false,
    searchable = false,
    data: originalData = []
  } = props

  const main = useRef<HTMLDivElement | null>(null)

  const [sort, setSort] = useState(props.sort)
  const [search, setSearch] = useState('')

  useEffect(() => {
    setSort(props.sort)
  }, [props.sort])

  const filterBySearch = (data: Row[], search: string) => {
    return originalData.filter(row => {
      let match = false
      columns.forEach(col => {
        if (row[col.field] == null) {
          return
        }
        match = match || row[col.field].toString().indexOf(search) !== -1
      })
      return match
    })
  }

  let data = filterBySearch(originalData, search)

  if (sort) {
    const sortColumn = columns.find(row => row.field === sort.field)!
    data = data.sort(sortColumn.comparator || getDefaultComparator(sort))

    if (sort.desc) {
      data = data.reverse()
    }
  }

  return (
    <Main ref={main} id={id} className={className} style={style}>
      {(title || searchable) && (
        <Title>
          {title && <div>{title}</div>}
          {searchable && (
            <div>
              <input
                value={search}
                placeholder='Search'
                onChange={e => {
                  setSearch(e.target.value)
                }}
              />
            </div>
          )}
        </Title>
      )}
      <TableWrapper>
        <table>
          <thead>
            <tr>
              {rowExpandable && <Th />}
              {columns.map(column => (
                <Th
                  key={column.field}
                  sortable={column.sortable}
                  style={{ textAlign: column.align || 'left', ...(column.style || {}) }}
                  onClick={() => {
                    if (!column.sortable) {
                      return
                    }

                    const newSortConfig: SortConfig = {
                      field: column.field,
                      desc: sort && sort.field === column.field && !sort.desc
                    }

                    if (onHeaderClick) {
                      onHeaderClick(column, newSortConfig)
                    } else {
                      setSort(newSortConfig)
                    }
                  }}
                >
                  <Box display='flex' alignItems='center'>
                    <span>{column.title}</span>
                    {column.sortable && (
                      <img alt='sort-status-icon' src={getSortIcon(sort, column.field)} />
                    )}
                  </Box>
                </Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map(row => (
              <Row
                key={rowKeySelector(row)}
                style={rowStyleSelector(row)}
                columns={columns}
                row={row}
                expandable={rowExpandable}
                expandContentRenderer={expandContentRenderer}
              />
            ))}
          </tbody>
        </table>
        {data.length === 0 && <Empty>0 rows</Empty>}
      </TableWrapper>
    </Main>
  )
}

export default Table
export { TableWrapper }
