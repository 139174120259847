import gql from 'graphql-tag'

import { SmartflowCarriers } from '../constants'

const APPOINTMENTS_QUERY = gql`
  fragment req on CarrierAppointmentRequestCountableConnection {
    edges {
      node {
        id
        planYear
        status
        carrier {
          name
          loi
          id
        }
      }
    }
  }

  fragment appts on CarrierAppointmentCountableConnection {
    edges {
      node {
        id
        planYear
        status
        statusFullySynced
        metadata
        carrier {
          id
          name
          avaliableState
          loi
          states
          justInTimeStates
        }
        internalAppointmentDetails {
          id
          companyName
          loaName
          fein
          state
          status
          isInternallyDisabled
          disabledReason
          carrierTag {
            name
            loi
          }
        }
        justInTimeStateDetails {
          id
          state
          status
          carrierTag {
            name
            loi
          }
        }
      }
    }
  }

  query (
    $planYear: Float
    $prevPlanYear: Float
    $loi: String
    $sort: CarrierAppointmentRequestSortingInput = { direction: DESC, field: CREATED_AT }
  ) {
    me {
      ${SmartflowCarriers.map(
        carrier => `
        ${carrier}: carrierAppointmentRequests(
          filter: { carrier: "${carrier}", loi: $loi, planYear: $planYear }
          sortBy: $sort
          first: 1
        ) {
          ...req
        }
    `
      )}

      planYearAppointments: carrierAppointments(first: 100, filter: { planYear: $planYear }) {
        ...appts
      }

      prevPlanYearAppointments: carrierAppointments(
        first: 100
        filter: { planYear: $prevPlanYear }
      ) {
        ...appts
      }
    }
  }
`

export default APPOINTMENTS_QUERY
