import React from 'react'

import { Grid } from '@material-ui/core'

import { LOI } from 'store/carriers'
import { Loading as Loader } from 'components/loading'

import Carriers from '../../../carrier_data'

interface Props {
  className?: string
  style?: React.CSSProperties
  carrierName: string
  loi: LOI
}

const Loading = (props: Props) => {
  const { className, style, carrierName, loi } = props

  return (
    <Grid
      className={className}
      style={style}
      container
      direction='column'
      alignItems='center'
      spacing={4}
    >
      <Grid item>
        <img
          style={{ width: '14rem', maxHeight: '7rem' }}
          src={Carriers[loi]?.[carrierName]?.Logo}
        />
      </Grid>
      <Grid item>
        <Loader /> Loading...
      </Grid>
    </Grid>
  )
}

export default Loading
