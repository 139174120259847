import React, { useEffect, useState } from 'react'

import {
  CertificateProviders,
  CertificateWorkflowStatus,
  FfmWorkflowUpdateData
} from 'screens/certification/certification.d'
import { useStoreState } from 'store'

import CertificationCompleted from './certification_completed'
import {
  CertificationFinalExamInfo,
  FfmStageKeys,
  FfmWorkFlowData,
  FfmWorkflowActions,
  FfmWorkflowProps,
  FfmWorkflowStepDetails
} from '../ffm.d'
import CompleteCertification from './complete_certification'
import DataSync from './data_sync'
import GetStarted from './get_started'
import LoginToCms from './login_to_cms'
import { getWorkflowDetails, updateWorkflowDetails } from '../api'
import { mapStageStatus } from '../../utils'

const Workflow = (props: FfmWorkflowProps) => {
  const { onStageUpdate, year } = props
  const CERTIFICATION_COMPLETED = 'FfmCertificationCompleted'
  const userInfo = useStoreState(state => state.userInfo)
  const [finalData, setFinalData] = useState<CertificationFinalExamInfo>()
  const [page, setPage] = useState('NotStarted')
  const [pageData, setPageData] = useState<FfmWorkflowStepDetails>({
    page_data: {},
    page_name: FfmStageKeys.CREATE_LINK_ACCOUNT,
    page_status: CertificateWorkflowStatus.NOT_STARTED
  })

  const getWorkflowUpdates = async () => {
    const response = await getWorkflowDetails({
      year: year,
      userInfoId: userInfo.id,
      certificateProvider: CertificateProviders.ffm,
      authToken: localStorage.getItem('authToken')
    })
    const workflowData: FfmWorkFlowData = await response.json()
    if (workflowData && workflowData.steps) {
      const progressItems = workflowData.steps.map(s => ({
        hidden: false,
        key: s.page_name,
        status: mapStageStatus(s.page_status)
      }))

      const current_progress = workflowData.steps.find(s => s.page_status === 'InProgress')
      setPage(current_progress?.page_name || 'loading')
      switch (workflowData.workflow_status) {
        case CertificateWorkflowStatus.IN_PROGRESS: {
          setPageData(current_progress?.page_data)
          onStageUpdate(progressItems, current_progress?.page_name)
          break
        }
        case CertificateWorkflowStatus.COMPLETED: {
          const finalData = workflowData.steps.find(
            s => s.page_name === FfmStageKeys.SYNC_DATA
          )?.page_data
          onStageUpdate(progressItems, FfmStageKeys.SYNC_DATA)
          setFinalData(finalData)
          setPage(CERTIFICATION_COMPLETED)
          break
        }
        default:
          setPage('error')
      }
    } else {
      setPage('NotStarted')
    }
  }

  useEffect(() => {
    getWorkflowUpdates()
  }, [])

  const get_started_clicked = async () => {
    const response = await updateWorkflowDetails({
      year: year,
      userInfoId: userInfo.id,
      action: FfmWorkflowActions.GET_STARTED,
      certificateProvider: CertificateProviders.ffm,
      authToken: localStorage.getItem('authToken')
    })
    const workflowUpdateData: FfmWorkflowUpdateData = await response.json()
    if (
      workflowUpdateData &&
      workflowUpdateData.workflow_status === CertificateWorkflowStatus.IN_PROGRESS &&
      workflowUpdateData.current_step_page_name === FfmStageKeys.CREATE_LINK_ACCOUNT
    ) {
      getWorkflowUpdates()
    }
  }

  switch (page) {
    case 'NotStarted':
      return <GetStarted onStart={get_started_clicked} year={year} />
    case FfmStageKeys.CREATE_LINK_ACCOUNT:
      return <LoginToCms year={year} getWorkflowUpdates={getWorkflowUpdates} />
    case FfmStageKeys.COMPLETE_CERTIFICATION:
      return <CompleteCertification year={year} getWorkflowUpdates={getWorkflowUpdates} />
    case FfmStageKeys.SYNC_DATA:
      return <DataSync getWorkflowUpdates={getWorkflowUpdates} />
    case CERTIFICATION_COMPLETED:
      return <CertificationCompleted page_data={finalData?.results} />
    default:
      return <h1>FFM - Error loading page</h1>
  }
}

export default Workflow
