import React, { Dispatch, SetStateAction, useState } from 'react'

import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'

import { API_ENDPOINT } from 'utils/environment-variables'
import Button from 'components/button'
import { CertificateProviders } from 'screens/certification/certification.d'
import Warning from 'components/box/warning'
import { useStoreState } from 'store'

import AhipAccountFlow from './ahip_account_flow'
import { AhipWorflowActions, AhipWorkflowStepDetails } from '../../ahip.d'
import NoAhipAccountFlow from './no_ahip_account_flow'
import { updateWorkflowDetails } from '../../api'

interface Props {
  setLinkedAccount: Dispatch<SetStateAction<boolean>>
  year: number
  page_data: AhipWorkflowStepDetails
}

const AhipNotCertified = (props: Props) => {
  const { page_data, setLinkedAccount, year } = props
  const [account, setAccount] = useState('')
  const [allowContinue, setAllowContinue] = useState(false)
  const userInfo = useStoreState(state => state.userInfo)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccount(e.target.value)
    setAllowContinue(false)
  }
  const handleLinkAccount = async () => {
    await updateWorkflowDetails({
      year: year,
      userInfoId: userInfo.id,
      action: AhipWorflowActions.CREATE_LINK_ACCOUNT_CONTINUE,
      certificateProvider: CertificateProviders.ahip,
      authToken: localStorage.getItem('authToken'),
      userData: {
        has_ahip_acc: account === 'yes_account'
      }
    })
    setLinkedAccount(true)
  }

  return (
    <>
      <Grid item>
        <h1 style={{ fontSize: '24px' }}>Create & Link Your AHIP Account</h1>
      </Grid>
      <Grid item>
        We just pulled information from the AHIP database. You do not have an AHIP account
        <br />
        linked to Assurance. In order to track your AHIP progress, you must have an AHIP account
        <br />
        and link it to Assurance.
      </Grid>
      <Grid item>
        <Warning>
          <strong>Status</strong> No AHIP account linked to Assurance.
        </Warning>
      </Grid>
      <Grid item>
        <RadioGroup onChange={handleChange}>
          <FormControlLabel
            value='yes_account'
            control={<Radio />}
            label='I have an AHIP account not linked to Assurance'
          />
          <FormControlLabel
            value='no_account'
            control={<Radio />}
            label='I do not have an AHIP account'
          />
        </RadioGroup>
      </Grid>
      <Grid item container direction='column' alignItems='stretch' spacing={4}>
        {account !== '' &&
          (account === 'yes_account' ? (
            <AhipAccountFlow
              page_data={page_data}
              allowContinue={allowContinue}
              setAllowContinue={setAllowContinue}
            />
          ) : (
            <NoAhipAccountFlow
              page_data={page_data}
              allowContinue={allowContinue}
              setAllowContinue={setAllowContinue}
            />
          ))}
      </Grid>
      <Grid container direction='column' alignItems='flex-end' spacing={4}>
        <Grid style={{ alignItems: 'flex-end', marginBottom: '3%' }} item>
          <Button
            style={{ minWidth: '216px' }}
            onClick={handleLinkAccount}
            disabled={!allowContinue}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default AhipNotCertified
