import React from 'react'

import { Eligibility as BaseEligibility } from '../../../commons/flow/plates/eligibility'
import { EligibilityProps } from '../../../commons/flow/plates/eligibility'
import NotAppointed from '../../../commons/flow/plates/eligibility/not_appointed'
import Terminated from './terminated'

const Eligibility = (props: EligibilityProps) => {
  const { pageName } = props

  switch (pageName) {
    case 'NotAppointedWithCarrier':
      return <NotAppointed {...props} nextLabel='AIQ Eligibility & Training' />
    case 'TerminatedWithoutCause':
      return <Terminated {...props} terminatedReason={'"Termination - Without Cause"'} />
    case 'TerminatedWithCause':
      return (
        <Terminated
          {...props}
          terminatedReason={
            '"Termination - With Cause" and therefore you cannot continure with appointments under Assurance.'
          }
        />
      )
    default:
      return <BaseEligibility {...props} />
  }
}

export default Eligibility
