import React from 'react'

import { Box as BaseBox, BoxProps as BaseProps } from '@material-ui/core'

import styled from 'styled-components'

export interface BoxProps extends BaseProps {
  spacing?: string
}

const StyledBox = styled(BaseBox)`
  ${props =>
    props.spacing != null &&
    `
    > * + * {
      margin-${props.display !== 'flex' || props.flexDirection === 'column' ? 'top' : 'left'}: ${
      props.spacing
    };
    }
  `}
`

const Box = (props: BoxProps) => {
  return <StyledBox {...props} />
}

export default Box
