import gql from 'graphql-tag'

export const ADD_NEW = gql`
  mutation StateAddCreate($input: CarrierStateAddInput!) {
    stateAddCreate(input: $input) {
      carrierStateAdd {
        id
        metadata
        updatedAt
        createdAt
        fileUrl
        carrier {
          id
          name
          loi
        }
        creator {
          id
          email
        }
        __typename
      }
      __typename
    }
  }
`
