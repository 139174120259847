import React, { useState } from 'react'

import { Grid, Link } from '@material-ui/core'

import Button from 'components/button'
import Image from 'components/image'
import Note from 'components/box/note'
import { RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'

import Accordion from '../commons/checklist_accordion'
import CheckboxButton from '../../../components/checkbox_button'
import { PlateProps } from '../commons/plate'
import TimeToComplete from '../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const getIcon = (checked?: boolean) => {
  const props = {
    style: {
      width: '1.25rem',
      height: '1.25rem'
    }
  }
  return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
}

const Certification = (props: Props) => {
  const { plateName, doUpdate, pageData = {} } = props

  const { userInput = {} } = pageData

  const [certificationCompleted, setCertificationCompleted] = useState(
    userInput.certificationCompleted
  )

  const [invitationReceived, setInvitationReceived] = useState(userInput.invitationReceived)

  const handleNext = () => {
    const allComplete = certificationCompleted && invitationReceived
    const data = {
      pageData: {
        userInput: {
          certificationCompleted,
          invitationReceived
        }
      },
      progress: {
        name: plateName,
        status: allComplete ? 'timer' : 'error'
      },
      nextProgress: 'CarrierReview',
      nextPage: {
        name: 'WellCareCarrierReview'
      }
    }
    doUpdate?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Create an Account or Log In</h2>
      </Grid>
      <Grid item>
        <Note>
          <strong>Note:</strong> It can take up to 7 business days for WellCare to send the
          invitation to you once Assurance IQ sends in your request. It’s a little manual - bear
          with us!
        </Note>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <Accordion
          icon={getIcon(invitationReceived)}
          title={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              1. Look for Email Invitation OR try your NPN number in the system
            </span>
          }
        >
          <div>
            Please check your{' '}
            <Link target='_blank' rel='noreferrer' href='https://outlook.office365.com/mail/'>
              <strong>@Assurance.com</strong>
            </Link>{' '}
            email account for:
            <br />
            <br />
            <div>
              <strong>From</strong>: producerservices@wellcare.com
            </div>
            <div>
              <strong>Subject</strong>: Welcome to Centene!
            </div>
          </div>
          <div>
            <strong>
              Often times, agents don't receive an email. At which point, you can skip to the next
              step. If punching in your NPN number works when creating a new account, then it means
              WellCare has processed your invitation (without an official email to you).
            </strong>
          </div>
          <Grid container item justify='flex-end'>
            <CheckboxButton
              checked={invitationReceived || false}
              onChange={value => setInvitationReceived(value)}
              label='I Received My Email. Or, my NPN works.'
            />
          </Grid>
        </Accordion>
      </Grid>
      <Grid item>
        <Accordion
          icon={getIcon(certificationCompleted)}
          title={<span style={{ display: 'flex', alignItems: 'center' }}>2. Certification</span>}
        >
          <div>
            Please create an account on{' '}
            <Link
              rel='noreferrer'
              href='https://centene.cmpsystem.com/ext/ahip/login.php'
              target='_blank'
            >
              WellCare's Learning Management System
            </Link>
            . You will see "Centene" because Centene is WellCare's parent company.
            <Image
              alt='guest-user-dashboard'
              src='/images/carriers/medicare/well_care/appointment/guest_user.jpg'
              style={{ width: '90%', marginTop: '1rem' }}
            />
            <Image
              alt='login-with-id'
              src='/images/carriers/medicare/well_care/appointment/id_login.jpg'
              style={{ width: '90%', marginTop: '1rem' }}
            />
            <Image
              alt='account-create-success'
              src='/images/carriers/medicare/well_care/appointment/account_created.jpg'
              style={{ width: '90%', marginTop: '1rem' }}
            />
          </div>
          <div>
            <div style={{ margin: '0.5rem 0' }}>
              <strong>Complete Certifications Modules</strong>
            </div>
            On your dashboard, you will see your courses. Each course must be completed in the order
            listed below. For example, “2022 Centene Journey to a Successful Season – TeleAgent”
            will unlock after 2022 AHIP is completed.
            <ul style={{ marginTop: '.5rem' }}>
              <li>Complete AHIP Medicare Training</li>
              <li>Complete 2022 Centene ACT Journey to a Successful Season - TeleAgent</li>
              <li>Complete 2022 Centene ACT Know your Product</li>
              <li>Complete 2022 Centene ACT Mastery Exam - TeleAgent</li>
            </ul>
            <Image
              alt='dashboard'
              src='/images/carriers/medicare/well_care/appointment/logged_in_user_dashboard.jpg'
              style={{ width: '90%', marginTop: '1rem' }}
            />
          </div>
          <Grid container item justify='flex-end'>
            <CheckboxButton
              checked={certificationCompleted || false}
              onChange={value => setCertificationCompleted(value)}
              label='I Completed My Certification'
            />
          </Grid>
        </Accordion>
      </Grid>
      <Grid container item justify='flex-end'>
        <Button onClick={handleNext}>Next: Review by WellCare</Button>
      </Grid>
    </Grid>
  )
}

export default Certification
