import gql from 'graphql-tag'

const CONFIRM_INVITATION = gql`
  mutation confirmInvitation($id: ID!, $input: CarrierInvitationFileUpdateInput) {
    invitationFileUpdate(id: $id, input: $input) {
      carrierInvitationFile {
        docUrl
        uploadDate
        confirmedBy
        confirmedByEmail
        confirmedDate
        invitationDetails {
          firstName
          lastName
          email
        }
      }
      errors {
        field
        message
      }
    }
  }
`

export default CONFIRM_INVITATION
