import styled from 'styled-components'

const SuccessBox = styled.div`
  padding: 1rem;
  background: #dff8ed;
  border: 1px solid #b1ebd2;
  color: #006830;
  border-radius: 0.25rem;
`

export default SuccessBox
