import React, { useEffect, useState } from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  makeStyles
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import BaseDropdown from 'components/dropdown'
import Box from 'components/box'
import Button from 'components/button'
import { Carrier, LOI } from 'store/carriers'
import Pagination from 'components/pagination'
import Table, { SortConfig } from 'components/table'
import _ from 'lodash'
import adminCancelCarrierAppointment from 'query/rest/admin_cancel_carrier_appointment'
import adminResendInvitation from 'query/rest/admin_resend_invitation'
import adminRestartEligibility from 'query/rest/admin_restart_eligibility'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'

import AGENT_APPOINTMENT_REQUESTS from '../graphql/query_agent_appointment_requests.gql'
import { AppointmentRequestFilters } from '../index'
import OverrideStageModal from '../override_stage_modal'
import SupportedLoi from '../../../loi_data'
import { getCarrierIconPath } from '../../../carrier_appointments/carrier_data'

import styles from './appointment_requests_table.module.scss'

const Dropdown = styled(BaseDropdown)`
  width: 100%;
`

const IconSizes = {
  small: '1rem',
  medium: '1.5rem',
  large: '4rem'
}

const StatusMap = {
  INPROGRESS: 'In Progress',
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed'
}

const ERRORS = {
  unimplemented: 'This feature is not implemented yet',
  general: 'There was an error making your request'
}

interface OptionLabelProps {
  img?: string
  text: string
}

interface HistoryLog {
  action: string
  stage: string
  updatedAt: string
  detailInfo: any
}

interface HistoryLogDetails {
  eligibilityResult: string
  nextTaskName: string
}

const useStyles = makeStyles(() => ({
  paper: { width: '80%', maxWidth: 'unset' }
}))

const PAGE_LIMIT = 30

interface Props {
  filters: AppointmentRequestFilters
  setInfoNotice: (...args) => void
  setErrorNotice: (...args) => void
  setLoading?: (loading: boolean) => void
}

const getSortBy = (sortConfig?: SortConfig) => {
  if (!sortConfig) {
    return sortConfig
  }

  const TableColumnToSqlField = {
    historyLog: 'CURRENT_TASK',
    createdAt: 'CREATED_AT',
    agentName: 'AGENT',
    owner: 'OWNER',
    carrier: 'CARRIER'
  }

  return {
    field: TableColumnToSqlField[sortConfig.field] || sortConfig.field,
    direction: sortConfig.desc ? 'DESC' : 'ASC'
  }
}

interface OverrideInfo {
  carrier: Carrier
  loi: LOI
}

const invitationEnabled = (carrier: Carrier, loi: LOI) => {
  if (carrier === Carrier.Oscar && loi === LOI.Health) {
    return false
  }
  if (carrier === Carrier.MutualOfOmaha && loi === LOI.Life) {
    return false
  }
  if (carrier === Carrier.LGA && loi === LOI.Life) {
    return false
  }
  if (carrier === Carrier.Prudential && loi === LOI.Life) {
    return false
  }
  return true
}

const AppointmentRequestsTable = ({
  filters,
  setInfoNotice,
  setErrorNotice,
  setLoading
}: Props) => {
  const classes = useStyles()

  const [confirmCancel, setConfirmCancel] = useState<boolean>(false)
  const [confirmResendEmail, setConfirmResendEmail] = useState<boolean>(false)
  const [confirmRestartEligibility, setConfirmRestartEligibility] = useState<boolean>(false)
  const [showHistoryLog, setShowHistoryLog] = useState<any>([])
  const [subjectUser, setSubjectUser] = useState<any>(null)
  const [subjectRequestId, setSubjectRequestId] = useState<any>(null)
  const [totalCount, setTotalCount] = useState(0)
  const [appointmentRequests, setAppointmentRequests] = useState<any>({})
  const [currentPage, setCurrentPage] = useState(1)
  const [sortConfig, setSortConfig] = useState<SortConfig | undefined>({
    field: 'createdAt',
    desc: true
  })

  const [overrideInfo, setOverrideInfo] = useState<OverrideInfo | undefined>()

  const totalPages = Math.ceil(totalCount / PAGE_LIMIT)

  const query = useQuery(AGENT_APPOINTMENT_REQUESTS, {
    variables: {
      limit: PAGE_LIMIT,
      offset: 0,
      filter: filters,
      sortBy: getSortBy(sortConfig)
    },
    skip: true,
    notifyOnNetworkStatusChange: true
  })

  const fetchPage = (page: number) => {
    setLoading?.(true)
    query
      .fetchMore({
        variables: {
          offset: (page - 1) * PAGE_LIMIT,
          limit: PAGE_LIMIT,
          filter: filters,
          sortBy: getSortBy(sortConfig)
        }
      })
      .then(
        response => {
          const { data } = response
          setAppointmentRequests({
            ...appointmentRequests,
            [page]: _.get(data, 'carrierAppointmentRequests.edges')
          })
          setTotalCount(_.get(data, 'carrierAppointmentRequests.totalCount'))
          setLoading?.(false)
        },
        () => void 0
      )
      .catch(err => {
        setLoading?.(false)
        alert('Error occurred')
        console.log(err)
      })
  }

  useEffect(() => {
    // clear data for all pages
    setAppointmentRequests({})
  }, [sortConfig])

  useEffect(() => {
    fetchPage(currentPage)
  }, [currentPage])

  useEffect(() => {
    if (currentPage === 1) {
      fetchPage(1)
    } else {
      setCurrentPage(1)
    }
  }, [
    sortConfig,
    filters.agent,
    filters.planYear,
    filters.stage,
    filters.owners,
    filters.carrier,
    filters.loi
  ])

  const comparator = (rowA: any, rowB: any) => rowA.idx - rowB.idx

  return (
    <div>
      <CancelAppointmentDialog
        requestId={subjectRequestId}
        agent={subjectUser}
        confirmCancel={confirmCancel}
        setConfirmCancel={setConfirmCancel}
        setInfoNotice={setInfoNotice}
        setErrorNotice={setErrorNotice}
        query={query}
      />
      <ShowHistoryLog
        agent={subjectUser}
        showHistoryLog={showHistoryLog}
        setShowHistoryLog={setShowHistoryLog}
        classes={classes}
      />
      <RestartEligibilityCheck
        requestId={subjectRequestId}
        agent={subjectUser}
        confirmRestartEligibility={confirmRestartEligibility}
        setConfirmRestartEligibility={setConfirmRestartEligibility}
        setInfoNotice={setInfoNotice}
        setErrorNotice={setErrorNotice}
        query={query}
      />
      <ResendEmailInvite
        requestId={subjectRequestId}
        agent={subjectUser}
        confirmResendEmail={confirmResendEmail}
        setConfirmResendEmail={setConfirmResendEmail}
        setInfoNotice={setInfoNotice}
        setErrorNotice={setErrorNotice}
        query={query}
      />
      {overrideInfo && (
        <OverrideStageModal
          agent={subjectUser}
          appointmentRequestId={subjectRequestId}
          carrier={overrideInfo.carrier}
          loi={overrideInfo.loi}
          open
          onCancel={() => setOverrideInfo(undefined)}
        />
      )}
      <Table
        style={{ marginTop: '1.5rem' }}
        rowKeySelector={row => row.id}
        sort={sortConfig}
        onHeaderClick={(_, cfg) => setSortConfig(cfg)}
        columns={[
          {
            title: 'Agent Name',
            field: 'agentName',
            style: {
              whiteSpace: 'nowrap'
            },
            sortable: true,
            comparator,
            render: row => <span style={{ whiteSpace: 'nowrap' }}>{row.agentName}</span>
          },
          {
            style: {
              whiteSpace: 'nowrap'
            },
            title: 'Masquerade',
            field: 'assuranceAgentId',
            align: 'center',
            render: row => {
              return (
                row.assuranceAgentId && (
                  <a target='_blank' rel='noreferrer' href={`/?agent_id=${row.assuranceAgentId}`}>
                    <img src={'/images/masquerade.svg'} alt={'masquerade'} />
                  </a>
                )
              )
            }
          },
          {
            title: 'Onboarding Owner',
            style: {
              whiteSpace: 'nowrap'
            },
            field: 'owner',
            sortable: true,
            comparator,
            render: row => <span style={{ whiteSpace: 'nowrap' }}>{row.owner}</span>
          },
          {
            title: 'LOI',
            field: 'loi',
            render: row => {
              const selectedLoi = SupportedLoi.find(l => l.label === row.loi)
              return (
                <Box key={row.loi} display='flex' alignItems='center' spacing={'0.5rem'}>
                  {selectedLoi && <img src={selectedLoi.icon} alt='logo' />}
                  <span>{_.capitalize(selectedLoi?.label || row.loi)}</span>
                </Box>
              )
            }
          },
          {
            title: 'Carrier',
            field: 'carrier',
            sortable: true,
            comparator,
            render: row => {
              return carrierIcon(row.carrier.name as Carrier, row.carrier.loi, 'medium')
            }
          },
          {
            title: 'Plan Year',
            field: 'planYear',
            style: {
              whiteSpace: 'nowrap'
            }
          },
          {
            title: 'Appointment Start',
            field: 'createdAt',
            sortable: true,
            comparator,
            style: {
              whiteSpace: 'nowrap'
            }
          },
          {
            title: 'Appointment Stage',
            field: 'appointmentStage',
            style: {
              whiteSpace: 'nowrap'
            }
          },
          {
            title: 'History Log',
            field: 'historyLog',
            sortable: true,
            comparator
          },
          {
            title: 'Action',
            field: 'action',
            render: row => {
              return (
                !['Cancelled', 'Completed'].includes(row.appointmentStage) && (
                  <Dropdown
                    buttonRender={() => (
                      <IconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true'>
                        <MoreVertIcon htmlColor={'black'} />
                      </IconButton>
                    )}
                    onChange={option => {
                      if (option === 'cancelAppointment') setConfirmCancel(true)
                      if (option === 'resendInvite') setConfirmResendEmail(true)
                      if (option === 'eligibilityCheck') setConfirmRestartEligibility(true)
                      if (option === 'override') {
                        setOverrideInfo({
                          carrier: row.carrier.name as Carrier,
                          loi: row.carrier.loi
                        })
                      }
                      setSubjectUser(row.agent)
                      setSubjectRequestId(row.id)
                    }}
                    labelSelector={option => option.label}
                    keySelector={option => option.value}
                    options={[
                      {
                        label: (
                          <OptionLabel
                            img={'/images/restart.svg'}
                            text={'Restart Eligibility Check'}
                          />
                        ),
                        value: 'eligibilityCheck'
                      },
                      /* hide for carriers like Oscar health that don't have invitation phase */
                      invitationEnabled(row.carrier.name, row.carrier.loi) && {
                        label: <OptionLabel img={'/images/email.svg'} text={'Resend Invite'} />,
                        value: 'resendInvite'
                      },
                      [
                        Carrier.Anthem,
                        Carrier.UnitedHealthCare,
                        Carrier.WellCare,
                        Carrier.Humana,
                        Carrier.MutualOfOmaha,
                        Carrier.LGA,
                        Carrier.Prudential,
                        Carrier.Lumico
                      ].includes(row.carrier.name) && {
                        label: (
                          <div>
                            <OptionLabel text={'Override'} />
                          </div>
                        ),
                        value: 'override'
                      },
                      '-',
                      {
                        label: (
                          <OptionLabel img={'/images/cancel.svg'} text={'Cancel Appointment'} />
                        ),
                        value: 'cancelAppointment'
                      }
                    ].filter(option => option !== false)}
                  />
                )
              )
            }
          }
        ]}
        data={edgesToRows(
          appointmentRequests[currentPage] || [],
          setShowHistoryLog,
          setSubjectUser
        ).map((row, idx) => ({ ...row, idx: sortConfig?.desc ? -idx : idx }))}
      />
      <Pagination
        style={{
          marginTop: '0.25rem'
        }}
        count={totalPages}
        page={currentPage}
        onChange={(event, page) => {
          setCurrentPage(page)
        }}
      />
    </div>
  )
}

function edgesToRows(edges, setShowHistoryLog, setSubjectUser) {
  return _.map(edges, function (edge) {
    const history: HistoryLog | undefined = firstHistoryItem(edge.node.histories)
    return {
      id: edge.node.id,
      assuranceAgentId: edge.node.user.assuranceAgentId,
      owner: edge.node.user.owner,
      carrier: edge.node.carrier,
      agentName: edge.node.user.firstName + ' ' + edge.node.user.lastName,
      agent: edge.node.user,
      planYear: edge.node.planYear,
      loi: edge.node.carrier.loi,
      createdAt: dayjs(edge.node.createdAt).format('MM-DD-YYYY HH:mm'),
      appointmentStage: StatusMap[edge.node.status],
      historyLog: history ? (
        <div className={styles.showMore}>
          {history.stage} - {history.action}.{' '}
          <Button
            variant='text'
            onClick={() => {
              setSubjectUser(edge.node.user)
              setShowHistoryLog(edge.node.histories)
            }}
          >
            <u>Show More</u>
          </Button>
        </div>
      ) : (
        'No History'
      )
    }
  })
}

function showNotice(msg, setNoticeFunction) {
  setNoticeFunction(msg)

  setTimeout(() => {
    setNoticeFunction(null)
  }, 4000)
}

function firstHistoryItem(histories): HistoryLog | undefined {
  return _.head(histories)
}

export function CancelAppointmentDialog({
  requestId,
  agent,
  confirmCancel,
  setConfirmCancel,
  setInfoNotice,
  setErrorNotice,
  query
}) {
  return (
    <Dialog open={confirmCancel}>
      <DialogTitle>
        <strong>Cancel Appointment?</strong>
      </DialogTitle>
      <DialogContent>
        Are you sure you want to cancel the appointment process for {AgentName(agent)}? If the agent
        agent wants to contract with us, they will need to request for an invitation again within 24
        hours, or they will have to go through eligibility check again.
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={() => setConfirmCancel(false)}>
          <u>Cancel</u>
        </Button>
        <Button
          className={styles.cancel}
          onClick={() => {
            adminCancelCarrierAppointment(
              requestId,
              () => {
                showNotice(
                  `You successfully cancelled an appointment for ${AgentName(agent)}`,
                  setInfoNotice
                )
                setConfirmCancel(false)
              },
              () => {
                setErrorNotice(ERRORS.general)
              },
              query
            )
          }}
        >
          Cancel Appointment
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function ShowHistoryLog({ agent, showHistoryLog, setShowHistoryLog, classes }) {
  return (
    <Dialog open={showHistoryLog.length > 0} classes={{ paper: classes.paper }}>
      <DialogTitle>
        <strong>History Log for {AgentName(agent)}</strong>
      </DialogTitle>
      <DialogContent>
        <Table
          sort={{
            field: 'updatedAt',
            desc: true
          }}
          rowKeySelector={row => row.id}
          columns={[
            {
              title: 'Appointment Stage',
              field: 'stage',
              sortable: true
            },
            {
              title: 'Action',
              field: 'action'
            },
            {
              title: 'Details',
              field: 'detailInfo',
              render: row => {
                try {
                  // In case of malformed json we need to catch errors
                  const details: HistoryLogDetails = JSON.parse(JSON.parse(row.detailInfo))

                  if (details && details.nextTaskName && details.eligibilityResult) {
                    return (
                      <>
                        <div>Eligibility: {details.eligibilityResult}</div>
                        <div>Next Task: {details.nextTaskName}</div>
                      </>
                    )
                  }
                } catch {
                  return <></>
                }

                return <></>
              }
            },
            {
              title: 'Date',
              style: {
                whiteSpace: 'nowrap'
              },
              sortable: true,
              field: 'updatedAt',
              render: row => {
                return dayjs(row.updatedAt).format('MM-DD-YYYY HH:mm')
              }
            }
          ]}
          data={showHistoryLog}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={() => setShowHistoryLog([])}>
          <u>Close</u>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function RestartEligibilityCheck({
  requestId,
  agent,
  confirmRestartEligibility,
  setConfirmRestartEligibility,
  setInfoNotice,
  setErrorNotice,
  query
}) {
  return (
    <Dialog open={confirmRestartEligibility}>
      <DialogTitle>
        <strong>Restart Eligibility Check?</strong>
      </DialogTitle>
      <DialogContent>
        Are you sure you want to restart the eligibility check process for {AgentName(agent)}?
        Restarting means the agent will need to go through their eligiblity check again.
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={() => setConfirmRestartEligibility(false)}>
          <u>Cancel</u>
        </Button>
        <Button
          onClick={() => {
            adminRestartEligibility(
              requestId,
              () => {
                showNotice(
                  `You successfully restarted an eligibility check for ${AgentName(agent)}`,
                  setInfoNotice
                )
                setConfirmRestartEligibility(false)
              },
              () => {
                setErrorNotice(ERRORS.general)
              },
              query
            )
          }}
        >
          Restart Eligibility
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function ResendEmailInvite({
  requestId,
  agent,
  confirmResendEmail,
  setConfirmResendEmail,
  setInfoNotice,
  setErrorNotice,
  query
}) {
  return (
    <Dialog open={confirmResendEmail}>
      <DialogTitle>
        <strong>Resend Email Invite?</strong>
      </DialogTitle>
      <DialogContent>
        Are you sure you want to resend an email invite for {AgentName(agent)} ({AgentEmail(agent)}
        )? After resending an invite, their name is added onto the template for next upload into the
        carrier’s system. Please notify the agent.
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={() => setConfirmResendEmail(false)}>
          <u>Cancel</u>
        </Button>
        <Button
          onClick={() => {
            adminResendInvitation(
              requestId,
              () => {
                showNotice(
                  `You successfully resent an invitation to ${AgentName(agent)}`,
                  setInfoNotice
                )
                setConfirmResendEmail(false)
              },
              () => {
                setErrorNotice(ERRORS.general)
              },
              query
            )
          }}
          startIcon={<img src={'/images/email-light.svg'} alt={'email'} />}
        >
          Resend Invite
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function OptionLabel(props: OptionLabelProps) {
  const { img, text } = props

  return (
    <div className={styles.option}>
      {img && <img style={{ width: '1.25rem' }} src={img} alt={text} />}
      <span style={{ marginLeft: !img ? '2rem' : void 0 }}>{text}</span>
    </div>
  )
}

function AgentName(agent) {
  return agent ? `${agent.firstName} ${agent.lastName}` : 'unknown'
}

function AgentEmail(agent) {
  return agent ? `${agent.email}` : 'unknown'
}

function carrierIcon(carrier: Carrier, loi: string, size: string) {
  const height = _.get(IconSizes, size, IconSizes.small)
  return (
    <img src={getCarrierIconPath(carrier, loi as any)} style={{ height: height }} alt={carrier} />
  )
}

export default AppointmentRequestsTable
