import React, { useState } from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import { Carrier } from 'store/carriers'
import Note from 'components/box/note'

import ConfirmRequestInvitation from '../../commons/flow/plates/confirm_request_invitation'
import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const RequestEmailInvite = (props: Props) => {
  const { doNext } = props

  const [confirmDialog, setConfirmDialog] = useState(false)

  const handleNext = () => {
    doNext?.('next')
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Invitation - Request Email Invite</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        You qualify to be appointed with UnitedHealthCare under the Assurance hierarchy. This
        appointment invitation is for the following product(s):
        <ul style={{ marginTop: '.5rem', paddingInlineStart: '1.25rem' }}>
          <li>UnitedHealthCare Medicare Advantage (MA)</li>
          <li>UnitedHealthCare Part D Plan (PDP)</li>
          <li>UnitedHealthCare Dual Special Needs Plan (DSNP)</li>
          <li>UnitedHealthCare Chronic Special Needs (CSNP)</li>
        </ul>
        <br />
        To continue your appointment process, please request an invitation.
      </Grid>
      <Grid item>
        {/*
         *
         * Regular value: 7 business days
         * During AEP: 14 business days during AEP
         *
         */}
        <Note>
          <strong>Note</strong>: It can take up to 14 business days during AEP for UHC to send the
          invitation to you once Assurance IQ sends in your request. It’s a little manual - bear
          with us!
        </Note>
      </Grid>
      <Grid container item justify='flex-end' spacing={1}>
        <Grid item>
          <Button onClick={() => setConfirmDialog(true)}>Request an Email Invite</Button>
        </Grid>
      </Grid>
      <ConfirmRequestInvitation
        carrierName={Carrier.UnitedHealthCare}
        open={confirmDialog}
        onConfirm={() => {
          handleNext()
          setConfirmDialog(false)
        }}
        onClose={() => setConfirmDialog(false)}
      />
    </Grid>
  )
}

export default RequestEmailInvite
