import React from 'react'

import Box from 'components/box'
import Button from 'components/button'
import { CallIcon, MoreDotsIcon } from 'components/icons'
import styled from 'styled-components'

import BaseCard from './card_commons'
import CardButton from './card_button'
import FlippableCard from './flippable_card_with_carrier_tags'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
  > * + * {
    margin-top: 1rem;
  }
`

interface Props {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  logoUrl: string
  appointed: number
  justInTime: number
  carrierTags: string[]
  cardId?: string
  onSupportedProductsClick?: () => void
}

const ViewDetails = (props: Props) => {
  const { onClick, logoUrl, appointed, justInTime, cardId } = props
  const appointedCount = appointed + justInTime

  return (
    <BaseCard cardId={cardId}>
      <CallIcon
        style={{
          position: 'absolute',
          background: 'green',
          borderRadius: '50%',
          padding: '0.25rem',
          right: 0,
          top: 0,
          transform: 'translate(50%, -50%)'
        }}
      />
      <Content>
        <img style={{ maxWidth: '70%', height: '48px' }} alt='logo' src={logoUrl} />
        <Box spacing={'0.4rem'}>{appointedCount > 0 && <div>{appointedCount} Appointed</div>}</Box>
        <Box display='flex' justifyContent='space-around'>
          <Button
            style={{ padding: '0.25rem', minWidth: '0' }}
            variant='text'
            onClick={props.onSupportedProductsClick}
          >
            <MoreDotsIcon />
          </Button>
        </Box>
      </Content>
      <CardButton onClick={onClick}>View Details</CardButton>
    </BaseCard>
  )
}

const FlippableViewDetails = (props: Props) => {
  const buttonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    props.onClick && props.onClick(e)
  }

  return (
    <FlippableCard
      carrierTags={props.carrierTags}
      footer={<CardButton onClick={props.onClick}>View Details</CardButton>}
    >
      <ViewDetails {...props} onClick={buttonClick} />
    </FlippableCard>
  )
}

export default FlippableViewDetails
