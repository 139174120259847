import React from 'react'

import { Divider } from '@material-ui/core'

import { Carrier } from 'store/carriers'
import styled from 'styled-components'

import Carriers from '../carrier_data'
import NotReadyToSell, { ComingSoon, ReadyToSell } from '../commons/status'
import { useCarrierAppointmentStatusForPlanYear } from '../hooks'

export interface CommonChecklistProps {
  carrierName: string
  loi: string
  planYear?: number
  hideHeader?: boolean
}

interface Props extends CommonChecklistProps {
  style?: React.CSSProperties
  heading?: string
  children?: React.ReactNode
  isComingSoon?: boolean
}

const Main = styled.div`
  > * + * {
    margin-top: 1.5rem;
  }
`

const CarrierStatusWrapper = styled.div`
  label {
    font-weight: 600;
  }
  > * + * {
    margin-top: 16px;
  }
`

const CarrierInfo = styled.div`
  display: flex;
  margin-left: 16px;
  > * + * {
    margin-left: 24px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div`
  > * + * {
    margin-top: 12px;
  }
`

const ChecklistSteps = styled.div`
  width: 50vw;
  > * + * {
    margin-top: 16px;
  }
`

const Heading = styled.h2`
  font-size: 24px;
  font-weight: 600;
`

const InstructionsWrapper = (props: Props) => {
  const { hideHeader, carrierName, loi, style, children, isComingSoon } = props

  const carrierStatus = useCarrierAppointmentStatusForPlanYear(
    carrierName,
    loi,
    props.planYear || new Date().getUTCFullYear()
  )

  const logoUrl = Carriers[props.loi]?.[props.carrierName]?.Logo

  const showCarrierStatus =
    loi === 'MEDICARE' ||
    [Carrier.HCSC, Carrier.Anthem, Carrier.Molina].includes(carrierName as Carrier)

  return (
    <Main style={style}>
      {!hideHeader && (
        <CarrierInfo>
          <img width={128} alt='logo' src={logoUrl} />
          {carrierStatus && (
            <>
              <Divider flexItem orientation='vertical' />
              {showCarrierStatus && (
                <CarrierStatusWrapper>
                  <label>Carrier Status</label>
                  {carrierStatus !== 'APPOINTED' && isComingSoon && <ComingSoon />}
                  {carrierStatus !== 'APPOINTED' && !isComingSoon && <NotReadyToSell />}
                  {carrierStatus === 'APPOINTED' && <ReadyToSell />}
                </CarrierStatusWrapper>
              )}
            </>
          )}
        </CarrierInfo>
      )}
      <ContentWrapper>
        <Content>
          {!hideHeader && (
            <div>
              <Heading>{props.heading || 'Carrier Appointment'}</Heading>
            </div>
          )}
          <ChecklistSteps>{children}</ChecklistSteps>
        </Content>
      </ContentWrapper>
    </Main>
  )
}

export default InstructionsWrapper
