import { NavLink, Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom'
import React from 'react'
import { matchPath } from 'react-router'

import { Typography } from '@material-ui/core'

import { ViewMode } from 'store/login_user_info'
import styled from 'styled-components'

import TitleBar from '../../screens/commons/title_bar'

const Nav = styled.nav`
  background: white;
  display: flex;
  flex-direction: column;
`

const Tabs = styled.div`
  padding: 0 0 0 1.5rem;
  white-space: nowrap;
  margin-bottom: -1px;
  z-index: 0;

  a {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    border: none;
    background-color: transparent;
    padding: 0.5rem 0;
    margin-right: 24px;
    font-size: 0.875rem;
    line-height: 1.375rem;

    // for ripple effect
    background-position: center;
    transition: background 750ms;

    @include mobile {
      padding: 0.5rem 0.75rem;
    }

    // ripple effect
    &.active,
    &:hover {
      border-bottom: 2px solid #0473b180;
    }

    // 'active' class is added by NavLink when route matched
    &.active {
      font-weight: 600;
      background-color: transparent;
      border-bottom: 2px solid #0473b1;
    }
  }

  button {
    float: right;
    padding: 0 1rem;
    background: none;
    border: none;
    font: bold 15px 'Open Sans';
    outline: 0;
    cursor: pointer;
    color: #333;

    &:hover {
      color: #0082cb;
    }

    svg {
      vertical-align: middle;
    }
  }
`

interface SubItem {
  link: string
  content: React.ReactElement
}

export interface Item {
  key: string
  label: React.ReactNode
  link: string
  content?: React.ReactElement
  items?: SubItem[]
  preserveView?: boolean
}

interface Props {
  className?: string
  items?: Item[]
  base?: string
  defaultPath?: string
  heading?: boolean
  viewMode?: ViewMode
}

const Content = styled.div`
  background: #fff;
  padding: 24px;
`

const HorizontalLine = styled.div`
  border-top: 1px solid #cccccc;
`

const trimTrailingSlash = (path: string) => path.replace(/\/$/, '')

const join = (base: string, path: string) => {
  const _base = trimTrailingSlash(base)
  const _path = path.replace(/^\//, '')
  return _base + '/' + _path
}

const PathComponent = (props: { children: React.ReactElement }) => {
  const params = useParams()
  return React.cloneElement(props.children, params)
}

const ItemRenderer = (props: { path: string; item: Item }) => {
  const { path, item } = props

  return (
    <Switch>
      <Route exact path={path}>
        {item.content}
      </Route>
      {item.items?.map(subItem => {
        return (
          <Route key={subItem.link} path={join(path, subItem.link)}>
            <PathComponent>{subItem.content}</PathComponent>
          </Route>
        )
      })}
    </Switch>
  )
}

const TabList = (props: Props) => {
  const { className, base = '/', defaultPath, items = [], heading } = props
  const { viewMode } = props

  const location = useLocation()

  const activeItem = items.find(row =>
    matchPath(location.pathname, {
      path: join(base, row.link)
    })
  )

  const routeMatched =
    activeItem &&
    (matchPath(location.pathname, {
      path: join(base, activeItem.link),
      exact: true
    }) != null ||
      activeItem.items?.find(row =>
        matchPath(location.pathname, {
          path: join(join(base, activeItem.link), row.link),
          exact: true
        })
      ) != null)

  const switchRoutes = items.filter(row => row.preserveView !== true)
  const preserveViewRoutes = items.filter(row => row.preserveView === true)

  return (
    <Nav className={className}>
      {heading && (
        <div style={{ padding: '1.5rem 0 0 1.5rem' }}>
          <Typography
            style={{
              fontSize: '1.5rem',
              lineHeight: '1.5rem'
            }}
          >
            {activeItem?.label}
          </Typography>
        </div>
      )}
      <div style={{ margin: '0 16px' }}>
        <Tabs
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0 7px'
          }}
        >
          {items.map(item => (
            <NavLink key={item.key} to={join(base, item.link)}>
              {item.label}
            </NavLink>
          ))}
          <TitleBar viewMode={viewMode} />
        </Tabs>
        <HorizontalLine />
      </div>
      <Content>
        {preserveViewRoutes.map(item => (
          <div
            key={item.key}
            style={{
              display: activeItem === item ? void 0 : 'none'
            }}
          >
            {
              item.content && React.cloneElement(item.content, { active: activeItem === item })
              /* NOTE: no subroutes here as of now*/
            }
          </div>
        ))}
        <Switch>
          {switchRoutes.map(item => {
            const path = join(base, item.link)
            return (
              <Route key={item.key} path={path}>
                <ItemRenderer item={item} path={path} />
              </Route>
            )
          })}
        </Switch>
        {!routeMatched && (
          <Route path='/'>
            <Redirect to={join(base, defaultPath || items[0]?.link || base)} />
          </Route>
        )}
      </Content>
    </Nav>
  )
}

export default TabList
