import React, { useState } from 'react'

import { Grid } from '@material-ui/core'

import { AhipTransitionProps } from '../ahip'

const Transition = (props: AhipTransitionProps) => {
  const { year } = props
  const monthDay = 'June 21'

  return (
    <Grid
      container
      direction='column'
      alignItems='stretch'
      style={{
        margin: '2% 26% 0 26%'
      }}
      spacing={2}
    >
      <Grid item>
        <h1 style={{ fontSize: '24px', paddingBottom: '12px' }}>AHIP Certification for {year}</h1>
      </Grid>
      <Grid item>
        The AHIP Certification for 2023 has closed, or will be closing shortly. Check back on{' '}
        {monthDay} for instructions to start the certification process for 2024. You may be able to
        complete the 2024 certification directly through AHIP before {monthDay}, but be sure to
        check back here for instructions to link your account and transmit your scores to Assurance.
      </Grid>
    </Grid>
  )
}

export default Transition
