import React from 'react'

import { FormControl, Icon, Input, ListItemIcon, Select as MuiSelect } from '@material-ui/core'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/styles'
import { withStyles } from '@material-ui/core/styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles((theme: any) => ({
  formControl: {
    width: '100%',
    minWidth: 120
  }
}))

const StyledMenuItem = withStyles(theme => ({
  root: {
    padding: '10px 20px',
    '&:focus': {
      backgroundColor: '#eaeaea',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {}
    }
  }
}))(MenuItem)

const StyledSelect = withStyles(theme => ({
  root: {
    border: '1px solid rgba(51, 51, 51, 0.16)',
    fontSize: '1rem',
    padding: '7px 16px',
    '&.MuiSelect-outlined': {
      paddingRight: '3rem' // for chevron
    },
    '&:hover': {
      border: '1px solid rgba(51, 51, 51, 0.36)'
    },
    borderRadius: '0.25rem',
    '&:focus': {
      borderRadius: '0.25rem'
    }
  },
  icon: {
    transition: 'transform .3s',
    right: '1rem',
    color: 'black',
    '& svg': {
      top: 'auto'
    }
  },
  selectMenu: {
    display: 'inline-flex'
  }
}))(MuiSelect)

interface Props<OptionType> {
  className?: string
  style?: Record<string, string | number>
  options: OptionType[]
  selected?: any
  keySelector: (option: OptionType) => string | number
  titleSelector?: (option?: OptionType) => React.ReactNode
  labelSelector: (option: OptionType) => React.ReactNode
  logoSelector?: (option: OptionType) => string
  disabledSelector?: (option: OptionType) => boolean | undefined
  onChange?: (option: any) => void
}

const Select = <OptionType,>(props: Props<OptionType>) => {
  const {
    className,
    options,
    style,
    selected,
    labelSelector,
    keySelector,
    disabledSelector,
    titleSelector,
    logoSelector,
    onChange
  } = props

  const classes = useStyles()

  const selectedOption = options.find(option => keySelector(option) === selected)

  return (
    <div className={className} style={style}>
      <FormControl variant='outlined' className={classes.formControl}>
        <StyledSelect
          value={selected}
          onChange={e => {
            onChange?.(e.target.value)
          }}
          input={
            <Input
              value={
                (titleSelector && titleSelector(selectedOption)) ||
                (selectedOption && labelSelector(selectedOption))
              }
            />
          }
          IconComponent={props => {
            return (
              <div className={props.className}>
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            )
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
        >
          {options.map(option => (
            <StyledMenuItem
              disabled={disabledSelector?.(option)}
              value={keySelector(option)}
              key={keySelector(option)}
            >
              {logoSelector && logoSelector(option) !== '' && (
                <ListItemIcon style={{ minWidth: '24px' }}>
                  <Icon
                    style={{
                      minWidth: '24px',
                      fontSize: '1rem',
                      marginRight: '5px',
                      overflow: 'visible'
                    }}
                  >
                    <img alt='logo' src={logoSelector(option)} />
                  </Icon>
                </ListItemIcon>
              )}
              <ListItemText primary={labelSelector(option)} />
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  )
}

export default Select
