import React from 'react'

import { Divider } from '@material-ui/core'

import Stages from 'screens/carrier_appointments/commons/stages'

import { CertificateProviders, ProviderSettings, StageItem } from './certification.d'

interface CertificationStagesProps {
  progressItems: StageItem[]
  currentProgress?: string
  provider: CertificateProviders
  hideDivider?: boolean
}

const CertificationStages = (props: CertificationStagesProps) => {
  const { progressItems, currentProgress, provider, hideDivider } = props
  if (progressItems.length === 0) return <></>
  return (
    <>
      {!hideDivider && <Divider flexItem orientation='vertical' style={{ marginLeft: '2rem' }} />}
      <Stages
        currentItemKey={currentProgress}
        items={progressItems
          .filter(item => !item.hidden)
          .map(item => ({
            label: ProviderSettings[provider].plateMap[item.key],
            status: item.status as any,
            key: item.key
          }))}
      />
    </>
  )
}

export default CertificationStages
