import React from 'react'

import { Carrier, LOI, routeCarrierToCarrierEnum } from 'store/carriers'

import AetnaFlow from '../aetna/flow'
import AmbetterHealthFlow from '../health/ambetter/flow'
import AnthemFlow from '../anthem/flow'
import AnthemHealthFlow from '../health/anthem/flow'
import CignaFlow from '../health/cigna/flow'
import HCSCHealthFlow from '../health/hcsc/flow'
import HumanaFlow from '../humana/flow'
import LGALifeFlow from '../life/lga/flow'
import LumicoFlow from '../life/lumico/flow'
import MolinaHealthFlow from '../health/molina/flow'
import OmahaLifeFlow from '../life/omaha/flow'
import OscarHealthFlow from '../health/oscar/flow'
import ProtectiveLifeFlow from '../life/protective/flow'
import PrudentialFlow from '../life/prudential/flow'
import UnitedHealthCareACAFlow from '../health/uhc_aca/flow'
import UnitedHealthCareFlow from '../united_health_care/flow'
import UnitedHealthOneFlow from '../health/uho/flow'
import WellcareFlow from '../wellcare/flow'

interface Props {
  carrier: string
  loi: string
  planYear?: string
}

const FlowMap = {
  [LOI.Medicare]: {
    [Carrier.Aetna]: AetnaFlow,
    [Carrier.Anthem]: AnthemFlow,
    [Carrier.Humana]: HumanaFlow,
    [Carrier.UnitedHealthCare]: UnitedHealthCareFlow,
    [Carrier.WellCare]: WellcareFlow
  },
  [LOI.Health]: {
    [Carrier.Anthem]: AnthemHealthFlow,
    [Carrier.Ambetter]: AmbetterHealthFlow,
    [Carrier.HCSC]: HCSCHealthFlow,
    [Carrier.Cigna]: CignaFlow,
    [Carrier.UnitedHealthOne]: UnitedHealthOneFlow,
    [Carrier.UnitedHealthCareACA]: UnitedHealthCareACAFlow,
    [Carrier.Oscar]: OscarHealthFlow,
    [Carrier.Molina]: MolinaHealthFlow
  },
  [LOI.Life]: {
    [Carrier.MutualOfOmaha]: OmahaLifeFlow,
    [Carrier.LGA]: LGALifeFlow,
    [Carrier.Prudential]: PrudentialFlow,
    [Carrier.Lumico]: LumicoFlow,
    [Carrier.Protective]: ProtectiveLifeFlow
  }
}

const getSmartFlow = (carrierName: Carrier, loi: LOI, planYear: number) => {
  const Component = FlowMap[loi]?.[carrierName]
  if (!Component) {
    return (
      <div>
        Smartflow unsupported for carrier {carrierName} {loi}
      </div>
    )
  }

  return <Component planYear={planYear} />
}

const SmartFlow = (props: Props) => {
  const carrier = routeCarrierToCarrierEnum(props.carrier)
  const planYear = props.planYear ? +props.planYear : new Date().getUTCFullYear()
  const loi = props.loi.toUpperCase() as LOI
  return getSmartFlow(carrier, loi, planYear)
}

export default SmartFlow
