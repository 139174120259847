import React from 'react'

import _ from 'lodash'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'

import BaseDropdown from '../dropdown'
import Box from '../box'
import CARRIERS_QUERY from './graphql/query_carriers.gql'
import { Carrier } from '../../store/carriers'
import Carriers from '../../screens/carrier_appointments/carrier_data'

interface CarrierOptions {
  label: any
  name: string
  value: string
}

interface OptionLabelProps {
  img: string
  text: string
}

interface CarrierDropdownProps {
  style?: React.CSSProperties
  selected?: string | null
  disabledSelector?: (carrierId: string, carrierName: string) => boolean | undefined
  hiddenSelector?: (carrierId: string, carrierName: string) => boolean | undefined
  showAll?: boolean
  onChange: any
  loi: string
}

const Dropdown = styled(BaseDropdown)`
  button {
    padding: 0.4375rem 1rem;
  }
  width: 100%;
`

const CarrierDropdown = (props: CarrierDropdownProps) => {
  const { disabledSelector, hiddenSelector } = props

  const query = useQuery(CARRIERS_QUERY, {
    variables: { first: 100 }
  })

  const { data } = query

  const carriers = _.get(data || {}, 'carriers.edges')

  let options: CarrierOptions[] = []

  if (props.showAll) {
    options.push({
      label: 'All',
      value: 'all',
      name: 'all'
    })
  }

  const LoiQueryMap = {
    med_advantage: 'MEDICARE',
    health: 'HEALTH',
    life: 'LIFE'
  }

  _.each(carriers, carrier => {
    if (carrier.node.name !== 'Other' && carrier.node.loi === LoiQueryMap[props.loi]) {
      options.push({
        label: (
          <OptionLabel
            img={Carriers[carrier.node.loi]?.[carrier.node.name]?.Logo}
            text={carrier.node.name}
          />
        ),
        value: carrier.node.id,
        name: carrier.node.name
      })
    }
  })

  return (
    <Dropdown
      style={props.style}
      selected={props.selected}
      titleSelector={selected => selected?.label || 'Carrier'}
      disabledSelector={selected =>
        disabledSelector?.(selected.value, options.find(row => row.value === selected.value)!.name)
      }
      onChange={(carrierId: string) =>
        props?.onChange(carrierId, options.find(row => row.value === carrierId)?.name)
      }
      labelSelector={option => option.label}
      keySelector={option => option.value}
      options={options.filter(d => !hiddenSelector?.(d.value, d.name))}
    />
  )
}

function OptionLabel(props: OptionLabelProps) {
  const { img, text } = props

  return (
    <Box display='flex' alignItems='center' spacing='0.5rem'>
      <img src={img} style={{ width: '5rem', maxHeight: '2.5rem' }} alt={text} />
      <span>{text}</span>
    </Box>
  )
}

export default CarrierDropdown
