import React from 'react'

import Table from 'components/table'
import { styleDate } from 'screens/certification/utils'

import { CertificationFinalExamInfo } from '../../ahip.d'

interface Props {
  page_data: CertificationFinalExamInfo
}

const FinalExamTable = (props: Props) => {
  const { page_data } = props

  const columns = [
    {
      title: 'Final Exam Completion Date',
      field: 'completionDate'
    },
    {
      title: 'Score',
      field: 'score'
    }
  ]

  const finalExamData = [
    {
      completionDate: styleDate(page_data?.completion_date) || <>&nbsp;</>,
      score: page_data?.final_score || <>&nbsp;</>
    }
  ]

  return <Table columns={columns} data={finalExamData} />
}

export default FinalExamTable
