import React, { useState } from 'react'

import { Grid, Link } from '@material-ui/core'

import Button from 'components/button'
import { CertificateProviders } from 'screens/certification/certification.d'
import Note from 'components/box/note'
import { updateWorkflowDetails } from 'screens/certification/ffm/api'
import { useStoreState } from 'store'

import { FfmWorkflowActions } from '../../ffm.d'

interface LoginToCmsProps {
  year: number
  getWorkflowUpdates: () => Promise<void>
}

const LoginToCms = (props: LoginToCmsProps) => {
  const { year, getWorkflowUpdates } = props
  const userInfo = useStoreState(state => state.userInfo)
  const [checked, setChecked] = useState(false)
  const handleChange = () => {
    setChecked(!checked)
  }
  const handleLinkAccount = async () => {
    await updateWorkflowDetails({
      year: year,
      userInfoId: userInfo.id,
      action: FfmWorkflowActions.LOGIN_TO_CMS_ACCOUNT_CONTINUE,
      certificateProvider: CertificateProviders.ffm,
      authToken: localStorage.getItem('authToken')
    })
    getWorkflowUpdates()
  }

  return (
    <Grid
      container
      direction='column'
      alignItems='flex-start'
      spacing={4}
      style={{ margin: '0 24% 0 24%' }}
    >
      <Grid item>
        <h1 style={{ fontSize: '24px', paddingBottom: '12px' }}>Log In To Your CMS Account</h1>
      </Grid>
      <Grid item>
        Please go to the{' '}
        <Link href='https://portal.cms.gov/portal' target='_blank' rel='noreferrer'>
          CMS Enterprise Portal
        </Link>{' '}
        and log into your account. If you are unsure whether or not you have an account, please see
        this{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://www.cms.gov/CCIIO/Programs-and-Initiatives/Health-Insurance-Marketplaces/Downloads/Avoid-Duplicate-Portal-Account_QRG_FINAL.pdf'
        >
          reference guide
        </Link>
        . If you do not have an account, please follow the steps below.
      </Grid>
      <Note>
        <Grid container item spacing={2}>
          <Grid item>
            <strong>Note:</strong> It is important that users not create multiple CMS user accounts.
            Having multiple or duplicate accounts can lead to delays, such as not being listed on
            the Agent and Broker FFM Registration Completion List.
          </Grid>
          <Grid item>
            <strong>
              If you previously created a CMS user account, but did not complete FFM registration,
              you can still use your existing account and do not need to create a new one.
            </strong>
          </Grid>
        </Grid>
      </Note>
      <Grid item>
        Step 1: Navigate to the{' '}
        <Link href='https://portal.cms.gov/portal' target='_blank' rel='noreferrer'>
          CMS Enterprise Portal
        </Link>{' '}
        and select <strong>New User Registration.</strong>
      </Grid>
      <Grid item>
        Step 2: Under the Choose Your Application drop-down menu, select the{' '}
        <strong>
          Federally Facilitated Marketplace (FFM)/Request for Marketplace Learning Management System
          Training Access
        </strong>{' '}
        option.
      </Grid>
      <Grid item>
        <img
          style={{ maxWidth: '684px' }}
          src='/images/certification/ffm/login-to-cms-image-1.png'
          alt='login-to-cms-example'
        />
      </Grid>
      <Grid item>
        Step 3: Register your information and create a user ID as prompted to create an account.
      </Grid>
      <Grid item>
        <input type='checkbox' checked={checked} onChange={handleChange} />
        <span> I logged into or created my CMS account</span>
      </Grid>
      <Grid container direction='column' alignItems='flex-end' spacing={4}>
        <Grid style={{ alignItems: 'flex-end', marginBottom: '3%' }} item>
          <Button style={{ minWidth: '216px' }} onClick={handleLinkAccount} disabled={!checked}>
            Continue
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LoginToCms
