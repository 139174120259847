import React from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import { RoundCheckedIcon, TimerStatusIcon, WarningIcon } from 'components/icons'

import { PlateProps } from '../commons/plate'
import TimeToComplete from '../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const getIconFromStatus = (status?: string) => {
  const style = {
    width: '1.25rem',
    marginRight: '0.375rem'
  }
  switch (status) {
    case 'error':
      return <WarningIcon style={style} />
    case 'checked':
      return <RoundCheckedIcon blue style={style} />
    case 'timer':
      return <TimerStatusIcon style={style} />
    default:
      return <TimerStatusIcon style={style} />
  }
}

const CarrierReview = (props: Props) => {
  const { plateName, progressItems, doUpdate } = props
  const certification = progressItems.find(item => item.name === 'Certification')!

  const goto = (nextProgress: string, nextPage: string) => {
    const data = {
      nextProgress,
      nextPage: {
        name: nextPage
      },
      progress: {
        name: plateName,
        status: 'solid'
      },
      pageData: {}
    }
    doUpdate?.('back', data)
  }

  const backLink = (label: string, nextProgress: string, nextPage: string) => {
    return (
      <Button
        variant='text'
        style={{
          marginLeft: '0.2rem',
          padding: 0
        }}
        onClick={() => goto(nextProgress, nextPage)}
      >
        <u>{label}</u>
      </Button>
    )
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Wellcare Carrier Review</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      {certification.status === 'checked' && (
        <>
          <Grid item>
            Well done! Your work here is done for now. Sit back, relax, and we will update your
            progress. <br /> <br />
            Depending on the time of year, Medicare carriers can take anywhere from 1 to 5 days to
            review your files.
          </Grid>
          <Grid item container>
            {getIconFromStatus(certification.status)} Certification
          </Grid>
        </>
      )}
      {certification.status === 'error' && (
        <>
          <Grid item>Oh no, it looks like you have incomplete parts in certification.</Grid>
          <Grid item container>
            {getIconFromStatus(certification.status)} Certification -
            {backLink('Edit Certification Information', 'Certification', 'Certification')}
          </Grid>
        </>
      )}
      {certification.status === 'timer' && (
        <>
          <Grid item>You indicated that you have completed:</Grid>
          <Grid item container>
            {getIconFromStatus(certification.status)} Certification -
            {backLink('Edit Certification Information', 'Certification', 'Certification')}
          </Grid>
          <Grid item>
            Well done!
            <br />
            <br />
            Sit back and relax.
            <br />
            <br />
            The review can take ~7 days as information needs to be updated by WellCare, the National
            Insurance Producer Registry (NIPR), Assurance IQ, and Sunfire Enrollments Platform.
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default CarrierReview
