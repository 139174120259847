import React from 'react'
import { Step } from 'react-joyride'

const getSteps = () => {
  const aetna_button = document.querySelector(
    '#carrier_appointments_Aetna > div > button'
  ) as HTMLElement
  const steps: Step[] = [
    {
      target: '#carrier_appointments',
      title: 'Understanding Carrier Contracting Portal',
      content: (
        <div>
          <p>Welcome to your Carrier Contracting Portal!</p>
          <br />
          <p>
            This is where you can find all of the carriers in which you have appointments with,
            under Assurance IQ.
          </p>
          <br />
          <p>Come back at any time to stay up to date with carriers.</p>
        </div>
      ),
      placement: 'center',
      disableBeacon: true,
      showProgress: true
    },
    {
      target: '#carrier_appointments_Aetna',
      title: '',
      content: (
        <div>
          <p>Aetna Medicare Advantage is one of the carriers Assurance IQ works with.</p>
          <br />
          <p>Every carrier has their own section.</p>
        </div>
      ),
      placement: 'right',
      disableBeacon: true
    },
    {
      target: aetna_button,
      title: '',
      content: (
        <div>
          <p>See what to do next when you click into the respective carrier.</p>
        </div>
      ),
      placement: 'right',
      disableBeacon: true
    },
    {
      target: '#carrier_appointments_plan_year',
      title: '',
      content: (
        <div>
          <p>Each year, carriers may need you to renew your contracting paperwork. </p>
          <br />
          <p>
            We will let you know what that looks like in this specific plan year drop down menu.
          </p>
        </div>
      ),
      placement: 'right',
      disableBeacon: true
    },
    {
      target: '#carrier_appointments_medicare_type',
      title: '',
      content: (
        <div>
          <p>Each carrier sells different types of products.</p>
          <br />
          <p>
            Filter by the type of products to see what is available to be sold on the Assurance
            platform.
          </p>
        </div>
      ),
      placement: 'right',
      disableBeacon: true
    }
  ]

  return steps
}

export { getSteps }
