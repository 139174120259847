import React, { useState } from 'react'

import { Grid, Link } from '@material-ui/core'

import Button from 'components/button'
import CheckboxButton from 'components/checkbox_button'
import Image from 'components/image'
import { RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'

import Accordion from '../commons/checklist_accordion'
import Note from '../../../components/box/note'
import { PlateProps } from '../commons/plate'
import TimeToComplete from '../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    userInput?: {
      certificationEmailReceived?: boolean
      certificationCompleted?: boolean
      validationCompleted?: boolean
    }
  }
}

const getIcon = (checked?: boolean) => {
  const props = {
    style: {
      width: '1.25rem',
      height: '1.25rem'
    }
  }
  return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
}

const Certification = (props: Props) => {
  const { plateName, doUpdate, pageData = {}, doNext } = props

  const { userInput = {} } = pageData

  const [certificationEmailReceived, setEmailReceived] = useState(
    userInput.certificationEmailReceived
  )
  const [certificationCompleted, setCertificationCompleted] = useState(
    userInput.certificationCompleted
  )
  const [validationCompleted, setValidationCompleted] = useState(userInput.validationCompleted)

  const handleNext = () => {
    const allCompleted = certificationEmailReceived && validationCompleted && certificationCompleted

    const data = {
      pageData: {
        userInput: {
          certificationEmailReceived,
          validationCompleted,
          certificationCompleted
        }
      },
      nextProgress: 'CarrierReview',
      progress: {
        name: plateName,
        status: allCompleted ? 'timer' : 'error'
      },
      nextPage: {
        name: 'CarrierReview'
      }
    }
    doUpdate?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Certification</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item container direction='column' spacing={1}>
        <Grid item>
          <Accordion
            icon={getIcon(certificationEmailReceived)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                1. Login or Create a New Anthem Certification Account
              </span>
            }
          >
            <div>
              a. Click the certification link provided here:{' '}
              <Link
                rel={'noreferrer'}
                target={'_blank'}
                href={'https://anthem.cmpsystem.com/ext/ahip/login.php'}
              >
                https://anthem.cmpsystem.com/ext/ahip/login.php
              </Link>
              <br />
              <br />
              b. Logging in or Creating a Profile
              <div>
                <strong>New Agents:</strong> Click the <strong>“First Time Visitors”</strong> button
                to create a new
                <br />
                <strong>Existing agents:</strong> Log in with last year’s username and password.
                There are links to assist you if you have forgotten either of them.
              </div>
              <br />
              c. In the Access Code Field, key in <strong>External-EMO</strong> (with hyphen,
              exactly as shown) and click “Submit”. Agents creating a new profile should proceed to{' '}
              <strong>section D</strong>. <strong>Existing Agents</strong> can skip to{' '}
              <strong>section G</strong>.
              <Image
                alt='enter anthem access code'
                style={{ width: '100%', marginTop: '0.5rem' }}
                src='/images/carriers/medicare/anthem/smartflow/Anthem 14.png'
              />
              <br />
              d. New Agents will be taken to the Registration <strong>“Step 1 of 3”</strong> page
              where the following fields will need to be populated:
              <ol type='i'>
                <li>Last name (enter name as it appears on license)</li>
                <li>Date of Birth (mm/dd/yyyy format)</li>
                <li>Last 4 digits of Social Security Number (SSN)</li>
              </ol>
              <br />
              e. The Registration <strong>“Step 2 of 3”</strong> page will display; complete all
              required fields (marked with a red asterisk) such as name, email, address:
              <ol type='i'>
                <li>Click the “Find my NPN” button to retrieve your National Producer</li>
                <li>Create a password and re-enter it to</li>
                <li>
                  Enter other required fields, such as Email address, Company name, phone number,
                </li>
                <li>Click the Register button.</li>
              </ol>
              <br />
              <br />
              f. The Registration <strong>“Step 3 of 3”</strong> page will display confirming your
              registration and providing you with your username. Be sure to make a note of this.
              Click New agents proceed to Step 8.
              <br />
              <br />
              g. <strong>Existing Agents:</strong> your profile information will be displayed.
              Please review all fields and update if needed. Click <strong>“Update Profile”</strong>
              .
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={certificationEmailReceived || false}
                onChange={value => setEmailReceived(value)}
                label='Mark Completed'
              />
            </Grid>
          </Accordion>
        </Grid>
        <Grid item>
          <Accordion
            icon={getIcon(certificationCompleted)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                2. Complete Certification Courses
              </span>
            }
          >
            <div>
              a. Agents will be taken to the{' '}
              <strong>Anthem Medicare Certification Training Center</strong> (“Training Center”) for
              their AHIP, Compliance and Product Training.
              <br />
              <br />
              b. Review the Terms of Service Agreement presented on the training page.{' '}
              <strong>You must agree to the Terms of Service in order to proceed.</strong>
              <br />
              <br />
              c. Click on the <strong>“Compliance Training”</strong> section. The first course in
              the Compliance training group will be AHIP Medicare Training. Click the AHIP course
              title.
              <br />
              <br />
              d. Click <strong>“Yes”</strong> when asked to confirm you want to leave the Training
              Center to go to the AHIP site.
              <br />
              <br />
              e.{' '}
              <strong>
                Your registration information will carry over from the Training Center to AHIP
              </strong>
              . Complete the AHIP profile registration/verification by entering your password
              information and agreeing to the AHIP site’s terms of service and privacy statement.
              Click <strong>“Register”</strong> (or Update Profile).
              <br />
              <br />
              f. The AHIP site will confirm your username on the next page. Click{' '}
              <strong>“Continue to Purchase”</strong>.
              <br />
              <br />
              g. You will be directed to a purchase page.
              <Note style={{ marginTop: '1rem' }}>
                <strong>Note:</strong> You will be asked by AHIP for upfront payment at Anthem’s
                discounted rate of $125.00.
              </Note>
              <br />
              h. Complete the Billing and Payment information. If the Billing information is the
              same as your Profile information, check the box{' '}
              <strong>“Copy profile information”</strong>.
              <br />
              <br />
              i. Click <strong>“Place Order”</strong>. You will receive an order confirmation. Click{' '}
              <strong>“Go to my courses”</strong> to begin.
              <br />
              <br />
              j. Proceed with the AHIP Medicare Training modules and exam, and then the
              Nondiscrimination, Fraud, Waste & Abuse, and the General Compliance training.
              <br />
              <br />
              k. You can return to the Training Center from the AHIP window by clicking the{' '}
              <strong>“Return to Anthem”</strong> link in the upper right corner of the AHIP
              training page.
              <br />
              <br />
              l. Launch remaining courses by opening each track and clicking on the course title.
              The course will launch right in your training page and slides will advance
              automatically. Use the player controls to pause/resume the course, or return to
              previous slides if needed.
              <br />
              <br />
              m. You must complete each course module before you are able to access and complete its
              assessment.
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={certificationCompleted || false}
                onChange={value => setCertificationCompleted(value)}
                label='I Completed My Certification Courses'
              />
            </Grid>
          </Accordion>
        </Grid>
        <Grid item>
          <Accordion
            icon={getIcon(validationCompleted)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                3. Validate Certifications are Complete
              </span>
            }
          >
            <div>
              A green checkmark will show on the <strong>“My Certifications”</strong> page next to
              each module or assessment when it has been successfully completed.
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={validationCompleted || false}
                onChange={value => setValidationCompleted(value)}
                label='I Completed My Certifications'
              />
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container item justify='flex-end' spacing={1}>
        <Grid item>
          <Button onClick={handleNext}>Next: Anthem Review</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Certification
