import React, { useEffect, useState } from 'react'

import { AhipPageTypes, AhipProps } from './ahip.d'
import Summary from './summary'
import Transition from './transition'
import Workflow from './workflow'

const Ahip = (props: AhipProps) => {
  const { onStageUpdate, year, optionChanges } = props
  const [page, setPage] = useState(props.page)

  useEffect(() => {
    onStageUpdate([])
    setPage(props.page)
  }, [props.page])

  switch (page) {
    case AhipPageTypes.WORKFLOW:
      return <Workflow onStageUpdate={onStageUpdate} year={year} />
    case AhipPageTypes.TRANSITION_PERIOD:
      return <Transition year={year} />
    case AhipPageTypes.CAERTIFICATION_STATUS:
      return <Summary onStageUpdate={onStageUpdate} year={year} optionChanges={optionChanges} />
    default:
      return <h1>AHIP - Error loading page</h1>
  }
}

export default Ahip
