import React from 'react'

import { Carrier, LOI } from 'store/carriers'

import { PlateComponentMap, PlateLabelMap } from './plate_map'
import SmartFlow from '../../commons/flow'

interface Props {
  planYear: number
}

const OscarFlow = (props: Props) => {
  const { planYear } = props

  return (
    <SmartFlow
      planYear={planYear}
      loi={LOI.Health}
      carrierName={Carrier.Oscar}
      plateComponentMap={PlateComponentMap}
      plateLabelMap={PlateLabelMap}
      shouldShowCancelDropdown={_ => true}
    />
  )
}

export default OscarFlow
