import React from 'react'
import Zoom from 'react-medium-image-zoom'

import styled from 'styled-components'

const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  > img {
    width: 100%;
  }
`

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  zoomMargin?: number
}

const Image = (props: Props) => {
  const { zoomMargin = 10, ...rest } = props
  return (
    <Zoom zoomMargin={zoomMargin} wrapStyle={{ display: 'block', width: '100%' }}>
      <Main>
        <img {...rest} />
      </Main>
    </Zoom>
  )
}

export default Image
