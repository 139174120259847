import { LicenseData, LicenseStatus } from 'store/state_license'
import { parseInputDate } from 'utils/utils'

// for drop down choice
export const ALL = '__ALL__'

type Filters = Record<string, any>

export enum ExpiryStatus {
  NotExpired,
  Expired,
  ExpiringSoon
}

export const getExpiryStatus = (license: LicenseData) => {
  if (license.status === LicenseStatus.Inactive) {
    return ExpiryStatus.Expired
  }
  if (license.dateExpire == null) {
    return ExpiryStatus.NotExpired
  }
  const curTime = new Date().getTime()
  const licenseTime = parseInputDate(license.dateExpire).getTime()
  if (curTime > licenseTime) {
    return ExpiryStatus.Expired
  }
  if (curTime + 30 * 86400 * 1000 >= licenseTime) {
    return ExpiryStatus.ExpiringSoon
  }
  return ExpiryStatus.NotExpired
}

export const filterLicenses = (licenses: LicenseData[], filters: Filters) => {
  return Object.entries(filters).reduce((licenses: LicenseData[], entry) => {
    const [field, value] = entry
    if (value === '' || value == null || value === ALL) {
      return licenses
    }

    switch (field) {
      case 'eligibility': {
        const selectedEligibilites: string[] = value

        return licenses.filter(license => {
          return selectedEligibilites.find(key => license[key] === true) != null
        })
      }

      case 'status': {
        if (value === LicenseStatus.Active) {
          return licenses.filter(license => getExpiryStatus(license) !== ExpiryStatus.Expired)
        } else if (value === LicenseStatus.Inactive) {
          return licenses.filter(license => getExpiryStatus(license) === ExpiryStatus.Expired)
        }
        return licenses
      }

      case 'startDate': {
        const filterDate = parseInputDate(value)
        return licenses.filter(license => {
          if (!license.dateExpire) {
            return true
          }
          return filterDate <= parseInputDate(license.dateExpire)
        })
      }

      case 'endDate': {
        const filterDate = parseInputDate(value)
        return licenses.filter(license => {
          if (!license.dateExpire) {
            return true
          }
          return parseInputDate(license.dateExpire) <= filterDate
        })
      }

      case 'states': {
        const states = value as string[]
        return licenses.filter(license => states.includes(license.state))
      }

      default: {
        return licenses.filter(license => license[field] === value)
      }
    }
  }, licenses)
}
