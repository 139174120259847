import React from 'react'

import ApiCompleted from './api_completed'
import ApiError from '../api_error'
import ApiInProgress from './api_in_progress'
import { PlateProps } from '../../../commons/plate'

const Paperwork = (props: PlateProps) => {
  const { pageData } = props
  const { lumicoApiCallStatus } = pageData

  switch (lumicoApiCallStatus) {
    case 'in_progress':
      return <ApiInProgress {...props} />
    case 'completed':
      return <ApiCompleted {...props} />
    case 'error':
      return <ApiError {...props} />
    default:
      return <>Unknown error</>
  }
}

export default Paperwork
