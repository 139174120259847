import React from 'react'

import { SvgIconProps } from '@material-ui/core'

interface IconProps {
  className?: string
  blue?: boolean
  style?: Record<string, string | number | undefined>
}

const RoundCheckedIcon = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      src={props.blue ? '/images/ok_fill_blue.svg' : '/images/ok_fill.svg'}
      alt='round-icon-checked'
    />
  )
}

const RoundUncheckedIcon = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      alt='round-icon-unchecked'
      src={'/images/round_unchecked.svg'}
    />
  )
}

const InvalidIcon = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      alt='icon-invalid'
      src='/images/error.svg'
    />
  )
}

const ResidentIcon = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      alt='icon-resident'
      src='/images/home.svg'
    />
  )
}

const FollowLink = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      alt='icon-follow'
      src='/images/follow_link.svg'
    />
  )
}

const RefreshIcon = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      alt='icon-reset'
      src='/images/refresh.svg'
    />
  )
}

const WarningIcon = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      alt='icon-warning'
      src='/images/warning_fill.svg'
    />
  )
}

const CallIcon = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      alt='icon-call'
      src='/images/icon_call.svg'
    />
  )
}

const InfoIcon = (props: IconProps) => {
  return (
    <img className={props.className} style={props.style} alt='icon-call' src='/images/info.svg' />
  )
}

const DiamondWarningIcon = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      alt='icon-warning'
      src='/images/diamond_warning.svg'
    />
  )
}

const CheckedIcon = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      alt='icon-checked'
      src='/images/checkbox_checked.svg'
    />
  )
}

const UncheckedIcon = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      alt='icon-checked'
      src='/images/checkbox_unchecked.svg'
    />
  )
}

const PinIcon = (props: IconProps) => {
  return (
    <img className={props.className} style={props.style} alt='location-pin' src='/images/pin.svg' />
  )
}

const CalendarClock = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      alt='location-pin'
      src='/images/calendar_clock.svg'
    />
  )
}

const TimerStatusIcon = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      alt='status-timer'
      src='/images/status_timer.svg'
    />
  )
}

const LinkIcon = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      alt='external-link'
      src='/images/link.svg'
    />
  )
}

const AppointmentTerminatedIcon = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      alt='appointment-terminated'
      src='/images/appointment_terminated.svg'
    />
  )
}

const MasqueradeIcon = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      alt='agent-masquerade'
      src='/images/masquerade.svg'
    />
  )
}

const MoreDotsIcon = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      alt='more items'
      src='/images/more-dots.svg'
    />
  )
}

const RestartIcon = (props: IconProps) => {
  return (
    <img className={props.className} style={props.style} alt='restart' src='/images/restart.svg' />
  )
}

export const DoubleChevronIcon = (props: SvgIconProps) => {
  return (
    <svg
      className='MuiSvgIcon-root'
      focusable='false'
      viewBox='0 0 24 24'
      aria-hidden='true'
      {...props}
    >
      <path
        d='M9.29 15.88 13.17 12 9.29 8.12a.9959.9959 0 0 1 0-1.41c.39-.39 1.02-.39 1.41 0l4.59 4.59c.39.39.39 1.02 0 1.41L10.7 17.3c-.39.39-1.02.39-1.41 0-.38-.39-.39-1.03 0-1.42z'
        transform={'translate(-4 0)'}
      />
      <path
        d='M9.29 15.88 13.17 12 9.29 8.12a.9959.9959 0 0 1 0-1.41c.39-.39 1.02-.39 1.41 0l4.59 4.59c.39.39.39 1.02 0 1.41L10.7 17.3c-.39.39-1.02.39-1.41 0-.38-.39-.39-1.03 0-1.42z'
        transform={'translate(4 0)'}
      />
    </svg>
  )
}

export const DownloadIcon = (props: IconProps) => {
  return (
    <img className={props.className} style={props.style} alt='restart' src='/images/download.svg' />
  )
}

export const BinocularsIcon = (props: IconProps) => {
  return (
    <img
      className={props.className}
      style={props.style}
      alt='restart'
      src='/images/binoculars.svg'
    />
  )
}

export const Spinner = (props: IconProps) => {
  return (
    <img className={props.className} style={props.style} alt='restart' src='/images/spinner.svg' />
  )
}

export {
  RoundCheckedIcon,
  RoundUncheckedIcon,
  InvalidIcon,
  ResidentIcon,
  FollowLink,
  RefreshIcon,
  WarningIcon,
  DiamondWarningIcon,
  CallIcon,
  InfoIcon,
  CheckedIcon,
  UncheckedIcon,
  PinIcon,
  CalendarClock,
  TimerStatusIcon,
  LinkIcon,
  AppointmentTerminatedIcon,
  MasqueradeIcon,
  MoreDotsIcon,
  RestartIcon
}
