import React from 'react'

import { Link } from '@material-ui/core'

import Warning from 'components/box/warning'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper from '../commons/instructions_wrapper'

interface Props {
  carrierName: string
  loi: string
}

const Checklist = (props: Props) => {
  return (
    <InstructionsWrapper {...props}>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Complete Appointment Process
          </span>
        }
      >
        <strong>Instructions:</strong>{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360035170032-How-do-I-get-appointed-with-IHC-'
        >
          Assurance Help: How do I get appointed with IHC?
        </Link>
        <br />
        <br />
        1. Go to:{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://contracting.ihcmarketplace.com/landingpage.html#/contract/Filling/guid=MzE3'
        >
          https://contracting.ihcmarketplace.com/landingpage.html#/contract/Filling/guid=MzE3
        </Link>
        <br />
        <br />
        2. Fill out all the required fields as per the instructions.
        <br />
        <br />
        3. If you carry Errors and Omissions Protection, send a copy of your policy to{' '}
        <Link target='_blank' rel='noreferrer' href={'mailto:producercontracting@ihcgroup.com'}>
          producercontracting@ihcgroup.com
        </Link>
        .
        <br />
        <br />
        4. When an application is approved, an email from{' '}
        <Link target='_blank' rel='noreferrer' href={'mailto:services@ihcgroup.com'}>
          services@ihcgroup.com
        </Link>{' '}
        (like the following) will be sent to you.
        <Warning>
          Important: Make sure to set up your account within 48hrs after receiving the "New Account
          Created" email, or you will need to contact IHC.
        </Warning>
      </Accordion>
    </InstructionsWrapper>
  )
}

export default Checklist
