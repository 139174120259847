import React, { ReactElement, useEffect } from 'react'

import { Eligibility as BaseEligibility } from '../../../commons/flow/plates/eligibility'
import { EligibilityProps } from '../../../commons/flow/plates/eligibility'
import NotAppointed from '../../../commons/flow/plates/eligibility/not_appointed'
import withCancellationHandling from '../with_cancellation_handling'

const Eligibility = (props: EligibilityProps) => {
  const { pageName } = props

  switch (pageName) {
    case 'NotAppointedWithCarrier':
      return <NotAppointed {...props} nextLabel='AIQ Eligibility & Training' />

    default:
      return <BaseEligibility {...props} />
  }
}

export default withCancellationHandling(Eligibility)
