import React, { useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'

import Box from 'components/box'
import Button from 'components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import CarrierReview, { CarrierReviewProps } from './carrier_review'
import PreviewCertificationTraining from '../preview_certification_training'

const Review = (props: CarrierReviewProps) => {
  const { plateName, doUpdate, pageData = {} } = props

  const prevStepComplete =
    props.progressItems.find(
      row => row.name === 'CompleteBackground' && row.status === 'checked'
    ) != null

  const { userInput = {} } = pageData

  const preview = userInput.preview || false

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [preview])

  const setPreview = (preview: boolean) => {
    const data = {
      pageData: {
        userInput: {
          preview
        }
      },
      progress: {
        name: plateName,
        status: 'solid'
      },
      nextProgress: 'ReviewByHumana',
      nextPage: {
        name: 'ReviewByHumana'
      }
    }
    doUpdate?.('next', data)
  }

  const handleBack = () => {
    const data = {
      pageData: {
        userInput: {}
      },
      progress: {
        name: props.plateName,
        status: 'solid'
      },
      nextProgress: 'CompleteBackground',
      nextPage: {
        name: 'CompleteBackground'
      }
    }
    props.doUpdate?.('next', data)
  }

  return preview ? (
    <Box spacing='1rem' padding='0 0 1rem 0'>
      <PreviewCertificationTraining />
      <Grid container justify='flex-end'>
        <Button onClick={() => setPreview(false)}>
          <FontAwesomeIcon
            style={{ marginRight: '0.5rem', fontSize: '1.4rem' }}
            icon={faChevronLeft}
          />
          Back to Review by Humana
        </Button>
      </Grid>
    </Box>
  ) : (
    <Box spacing='1rem' padding='0 0 1rem 0'>
      <CarrierReview {...props} />
      <Box display='flex'>
        {!prevStepComplete && (
          <Button onClick={handleBack}>
            <FontAwesomeIcon
              style={{ marginRight: '0.5rem', fontSize: '1.4rem' }}
              icon={faChevronLeft}
            />
            Back to Complete Background
          </Button>
        )}
        <Box flex={1} />
        <Button onClick={() => setPreview(true)}>Preview Certification Process</Button>
      </Box>
    </Box>
  )
}

export default Review
