import React, { useEffect, useState } from 'react'

import { Carrier, LOI } from 'store/carriers'
import { get_yy_mm_dd_hh_mm } from 'utils/utils'
import { useSnackbar } from 'utils/snackbar'
import { useStoreActions, useStoreState } from 'store'
import workflowFetch from 'query/rest/workflow_fetch'

import BaseSmartFlow, { WorkflowStatusApiResponse } from './base'
import { useAppointmentRequest } from '../../graphql/hooks'

const pollAppointmentRequestStatus = async (appointmentRequestId: string) => {
  const data: WorkflowStatusApiResponse = await workflowFetch({
    url: `/${appointmentRequestId}/poll`
  }).then(d => {
    return d.json()
  })

  return data
}

interface Props {
  planYear: number
  carrierName: Carrier
  loi: LOI
  plateComponentMap: Record<string, React.FC<React.ComponentProps<any>>>
  plateLabelMap: Record<string, string>
  shouldShowCancelDropdown: (statusResponse: WorkflowStatusApiResponse) => boolean
}

const MissingFieldMap: { [key: string]: string } = {
  resident_state_license: 'Resident State License',
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  assurance_email: 'Assurance Email Address',
  ssn: 'SSN',
  address: 'Address',
  city: 'City',
  state: 'State',
  zip: 'ZIP',
  phone_number: 'Phone Number',
  dob: 'Date of Birth'
}

const SmartFlow = (props: Props) => {
  const { loi, carrierName, planYear, plateComponentMap, plateLabelMap, shouldShowCancelDropdown } =
    props

  const [statusResponse, setStatusResponse] = useState<WorkflowStatusApiResponse | undefined>()

  const [loading, setLoading] = useState(false)

  const setSnackbar = useSnackbar()

  const carrierInfo = useStoreState(
    state =>
      state.carrierInfo.items.find(carrier => carrier.name === carrierName && carrier.loi === loi)!
  )
  const userInfo = useStoreState(state => state.userInfo)

  const addWorkflowAppointmentStatus = useStoreActions(
    actions => actions.appointmentRequestsWorkflowStatus.load
  )

  const [appointmentRequestId, setAppointmentRequestId] = useState<string | undefined>()

  const addAppointmentRequests = useStoreActions(actions => actions.carrierAppointmentRequests.load)
  const { getAppointmentRequest } = useAppointmentRequest()
  const [loadingAppointmentRequest, setLoadingAppointmentRequest] = useState(true)

  useEffect(() => {
    // fetch the latest appointment request
    setLoadingAppointmentRequest(true)
    getAppointmentRequest(carrierName, loi, planYear).then(request => {
      if (request) {
        setAppointmentRequestId(request.id)
        addAppointmentRequests([request])
      }
      setLoadingAppointmentRequest(false)
    })
  }, [])

  const [lastSynced, setLastSynced] = useState('')

  const retrieveAppointmentStatus = (appointmentRequestId: string) => {
    setLoading(true)
    workflowFetch({
      url: `/${appointmentRequestId}/retrieve`
    })
      .then(d => d.json())
      .then((data: WorkflowStatusApiResponse) => {
        setStatusResponse(data)
        setLoading(false)
      })
      .catch(ex => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!appointmentRequestId) {
      return
    }
    setLoading(true)
    retrieveAppointmentStatus(appointmentRequestId)
  }, [appointmentRequestId])

  useEffect(() => {
    if (!statusResponse) {
      return
    }

    addWorkflowAppointmentStatus({
      ...statusResponse,
      appointmentRequestId: appointmentRequestId!
    })

    setLastSynced(get_yy_mm_dd_hh_mm(new Date()))
  }, [statusResponse])

  useEffect(() => {
    if (!appointmentRequestId) {
      return
    }

    let unmounted = false

    const poller = (appointmentRequestId: string) => {
      if (!localStorage.getItem('authToken')) {
        // user logged out
        return
      }
      pollAppointmentRequestStatus(appointmentRequestId)
        .then(statusResponse => {
          if (unmounted) {
            return
          }
          setStatusResponse(statusResponse)
          setTimeout(() => {
            poller(appointmentRequestId)
          }, 2000)
        })
        .catch(exception => {
          if (unmounted) {
            return
          }
          if (exception.status !== 504) {
            console.warn('Stopping polling due to error', exception)
            return
          }
          setTimeout(() => {
            poller(appointmentRequestId)
          }, 2000)
        })
    }

    poller(appointmentRequestId)

    return () => {
      unmounted = true
    }
  }, [appointmentRequestId])

  const handleStart = () => {
    workflowFetch({
      url: '/start',
      data: {
        userId: userInfo.id,
        carrierId: carrierInfo.id,
        planYear
      }
    })
      .then(d => d.json())
      .then(d => {
        setAppointmentRequestId(d.appointmentRequestId)
      })
      .catch(d => {
        setSnackbar({
          severity: 'error',
          message: 'Could not start flow. Please try again.'
        })
      })
  }

  const handleNext = (
    actionName: string,
    userInput?: Record<string, any>,
    pageData?: Record<string, any>
  ) => {
    const formData = {
      pageName: statusResponse!.pageName,
      currentTask: statusResponse!.currentTask,
      pageData: {
        ...pageData,
        userInput: userInput,
        userButtonAction: 'Next'
      }
    }

    workflowFetch({
      url: `/${appointmentRequestId}/next`,
      data: formData
    })
      .then(d => d.json())
      .then((statusResponse: WorkflowStatusApiResponse) => {
        setStatusResponse(statusResponse)
      })
      .catch(d => {
        if (d.status === 406) {
          d.json().then(err => {
            const missing_fields = err.missing_fields
              .map((f: string) => MissingFieldMap[f])
              .join(', ')
            setSnackbar({
              severity: 'error',
              message:
                `Uh Oh! It looks like you don’t have your ${missing_fields} ` +
                'filled out inside your Assurance Agent Profile. ' +
                'We need that information in order to request your invitation.',
              link: {
                href: 'https://join.assurance.com/agent/',
                text: 'Visit your JOIN profile to update.'
              }
            })
          })
        } else {
          setSnackbar({
            severity: 'error',
            message: 'Could not forward workflow. Please try again.'
          })
        }
      })
  }

  const handleUpdate = (actionName: string, data?: any) => {
    const formData = {
      pageName: statusResponse!.pageName,
      ...data,
      userButtonAction: actionName
    }

    workflowFetch({
      url: `/${appointmentRequestId}/update`,
      data: formData
    })
      .then(d => d.json())
      .then((statusResponse: WorkflowStatusApiResponse) => {
        setStatusResponse(statusResponse)
      })
      .catch(d => {
        setSnackbar({
          severity: 'error',
          message: 'Could not update workflow. Please try again.'
        })
      })
  }

  const cancelCarrierAppointment = () => {
    const formData = {
      cancellationReason: 'forced by user',
      cancelledBy: 'user'
    }
    workflowFetch({
      url: `/${appointmentRequestId}/cancel`,
      data: formData
    })
      .then(d => d.json())
      .then((response: WorkflowStatusApiResponse) => {
        setStatusResponse(response)
      })
      .catch(d => {
        setSnackbar({
          severity: 'error',
          message: 'Could not cancel workflow. Please try again.'
        })
      })
  }

  const restartCarrierAppointment = () => {
    const formData = {
      restartReason: 'user',
      restartedBy: 'user'
    }
    workflowFetch({
      url: `/${appointmentRequestId}/restart`,
      data: formData
    })
      .then(d => d.json())
      .then(response => {
        setStatusResponse(undefined)
        setAppointmentRequestId(response.appointmentRequestId)
        retrieveAppointmentStatus(response.appointmentRequestId)
      })
      .catch(d => {
        setSnackbar({
          severity: 'error',
          message: 'Could not restart workflow. Please try again.'
        })
      })
  }

  const allFetched = !loading && !loadingAppointmentRequest

  const showCancelDropDown = statusResponse ? shouldShowCancelDropdown(statusResponse) : false
  console.log(
    'JOIN-3777 In SmartFlow - statusResponse - shouldShowCancelDropdown - shouldShowCancelDropdown()',
    statusResponse,
    shouldShowCancelDropdown,
    statusResponse && shouldShowCancelDropdown(statusResponse)
  )
  return (
    <BaseSmartFlow
      carrierName={carrierName}
      loi={loi}
      planYear={planYear}
      workflowId={appointmentRequestId}
      workflowStatus={statusResponse}
      loading={!allFetched}
      lastSynced={lastSynced}
      plateComponentMap={plateComponentMap}
      plateLabelMap={plateLabelMap}
      showCancelDropdown={showCancelDropDown}
      handleStart={handleStart}
      handleUpdate={handleUpdate}
      handleNext={handleNext}
      cancelCarrierAppointment={cancelCarrierAppointment}
      restartCarrierAppointment={restartCarrierAppointment}
    />
  )
}

export default SmartFlow
