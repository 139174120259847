import React from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import { LOI } from 'store/carriers'

import Carriers from '../../../carrier_data'

interface Props {
  className?: string
  style?: React.CSSProperties
  carrierName: string
  loi: LOI
  onStart?: () => void
}

const GetStarted = (props: Props) => {
  const { className, style, onStart, carrierName, loi } = props

  return (
    <Grid
      className={className}
      style={style}
      container
      direction='column'
      alignItems='center'
      spacing={4}
    >
      <Grid item>
        <img style={{ width: '14rem', maxHeight: '7rem' }} src={Carriers[loi][carrierName]?.Logo} />
      </Grid>
      <Grid item>
        <span>
          Let our systems determine the best process for you to get going with contracting.
        </span>
      </Grid>
      <Grid item>
        <Button onClick={onStart}>Get Started</Button>
      </Grid>
    </Grid>
  )
}

export default GetStarted
