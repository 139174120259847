import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import { Carrier, CarrierInfo } from 'store/carriers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InternalAppointmentDetail, JustInTimeStateDetails } from 'store/carrier_appointments'
import { Loading } from 'components/loading'
import MultiSelect from 'components/multi_select'
import Select from 'components/select'
import ShowTutorialBox from 'components/show_tutorial_box'
import _ from 'lodash'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { useStoreActions, useStoreState } from 'store'

import APPOINTMENTS_QUERY from './graphql/appointments.gql'
import AetnaMedsuppChecklist from './aetna/medsupp_checklist'
import AmbetterChecklist from './health/ambetter/checklist'
import AnthemHealthChecklist from './anthem/anthem_health_checklist'
import AnthemMedicareChecklist from './anthem/anthem_medicare_checklist'
import { CardInfo, getCardType } from './utils'
import { CardType, CarriersTagsInfo, SmartflowCarriers } from './constants'
import { CarrierAppointmentRequest } from '../../store/carrier_appointment_requests'
import Carriers from './carrier_data'
import CheckInstructionsCard from './commons/cards/check_instructions_card'
import CignaHealthChecklist from './cigna/cigna_health_checklist'
import CignaMedicareChecklist from './cigna/cigna_medicare_checklist'
import ClosedCard from './commons/cards/closed_card'
import ComingSoonCard from './commons/cards/coming_soon_card'
import EligibilityCard from './commons/cards/eligibility_card'
import HcscChecklist from './hcsc/checklist'
import HiiChecklist from './hii/checklist'
import HumanaChecklist from './humana/checklist'
import IhcChecklist from './ihc/checklist'
import LumicoChecklist from './lumico/checklist'
import MolinaChecklist from './molina/checklist'
import MutualOfOmahaChecklist from './mutual_of_omaha/checklist'
import OscarChecklist from './oscar/checklist'
import ProgressCard from './commons/cards/progress_card'
import SupportedLoi from '../loi_data'
import SurebridgeChecklist from './sure_bridge/checklist'
import Tutorial from '../../components/tutorial'
import UhcHealthChecklist from './united_health_care/uhc_health_checklist'
import UhcMedicareChecklist from './united_health_care/uhc_medicare_checklist'
import UnavailableCard from './commons/cards/unavailable_card'
import UnitedHealthACAChecklist from './united_health_care/uhc_health_aca_checklist'
import ViewDetails from './commons/cards/view_details'
import ViewDetailsPage from './commons/pages/view_details'
import WellcareChecklist from './wellcare/checklist'
import { WorkflowStatus } from '../../store/workflow_status'
import { getPlateLabel } from './utils/workflow'
import { getSteps } from './tutorial_steps'
import { useSnackbar } from '../../utils/snackbar'
import workflowFetch from '../../query/rest/workflow_fetch'

const List = styled.div`
  > * {
    margin: 0.75rem;
  }
  margin-left: -0.75rem;
`

const Heading = styled.h2`
  font-weight: 400;
  font-size: 24px;
`

/**
 * Use this hook to get a function for ProgressCard's disabled prop to disable specific cards given
 * the carrier
 */
const disabledCard = (carrierInfo: CarrierInfoForView, conditionCallback: Function) => {
  const TEMP_DISABLED_CARRIERS = {
    LIFE: {},
    MEDICARE: {
      [Carrier.MutualOfOmaha]: CardType.Unavailable,
      [Carrier.AetnaMedSupp]: CardType.Unavailable
    },
    HEALTH: {
      [Carrier.UnitedHealthCare]: CardType.Hidden
    }
  }
  const disabledCardType = TEMP_DISABLED_CARRIERS[carrierInfo.loi][carrierInfo.name]

  if (
    disabledCardType == CardType.Hidden ||
    (!!disabledCardType && conditionCallback(carrierInfo))
  ) {
    return disabledCardType
  }

  return carrierInfo.cardInfo.type
}

/**
 * Deactivated carriers are carriers that should not be visible on the cards screen.
 */
const isDeactivatedCarrier = (loi: string | undefined, carrierInfo: CarrierInfo) => {
  if (!loi) {
    return false
  }

  const DEACTIVATED_CARRIERS = {
    LIFE: [],
    MEDICARE: [Carrier.MutualOfOmaha, Carrier.AetnaMedSupp],
    HEALTH: [],
    'P&C': []
  }

  return DEACTIVATED_CARRIERS[loi].includes(carrierInfo.name.toString())
}

const getInstructionsPage = (carrier: string, loi: string, planYear: number) => {
  const ChecklistMap = {
    MEDICARE: {
      [Carrier.Anthem]: AnthemMedicareChecklist,
      [Carrier.Cigna]: CignaMedicareChecklist,
      [Carrier.Humana]: HumanaChecklist,
      [Carrier.Lumico]: LumicoChecklist,
      [Carrier.MutualOfOmaha]: MutualOfOmahaChecklist,
      [Carrier.SureBridge]: SurebridgeChecklist,
      [Carrier.UnitedHealthCare]: UhcMedicareChecklist,
      [Carrier.WellCare]: WellcareChecklist,
      [Carrier.AetnaMedSupp]: AetnaMedsuppChecklist,
      [Carrier.HCSC]: HcscChecklist
    },
    HEALTH: {
      [Carrier.Ambetter]: AmbetterChecklist,
      [Carrier.Anthem]: AnthemHealthChecklist,
      [Carrier.Cigna]: CignaHealthChecklist,
      [Carrier.Oscar]: OscarChecklist,
      [Carrier.Molina]: MolinaChecklist,
      [Carrier.HCSC]: HcscChecklist,
      [Carrier.UnitedHealthCare]: UhcHealthChecklist,
      [Carrier.UnitedHealthCareACA]: UnitedHealthACAChecklist,
      [Carrier.IHC]: IhcChecklist,
      [Carrier.HII]: HiiChecklist
    }
  }

  const Component = ChecklistMap[loi]?.[carrier]
  return Component ? (
    <Component carrierName={carrier} loi={loi} planYear={planYear} />
  ) : (
    `Checklist doesnot exist for carrier=${carrier} loi=${loi}`
  )
}

type CarrierInfoForView = CarrierInfo & {
  cardInfo: CardInfo
}

enum PageType {
  Eligibility,
  Instructions,
  ViewDetails
}

// sort priority highest at top
const TilesPriorityByLoi = {
  HEALTH: [
    Carrier.UnitedHealthCare,
    Carrier.HII,
    Carrier.IHC,
    Carrier.Anthem,
    Carrier.Ambetter,
    Carrier.Cigna,
    Carrier.Oscar,
    Carrier.HCSC,
    Carrier.Molina
  ]
}

const sortTiles = (carrierInfo: CarrierInfoForView[], loi: string) => {
  const tiles = _.get(TilesPriorityByLoi, loi, [])
  return carrierInfo.sort((a, b) => {
    const aIdx = tiles.indexOf(a.name) + 1 || tiles.length + 1
    const bIdx = tiles.indexOf(b.name) + 1 || tiles.length + 1
    return aIdx - bIdx
  })
}

const getSmartflowLink = (carrierInfo: CarrierInfo, planYear: number) => {
  return (
    '/carrier_appointments' +
    '/' +
    _.snakeCase(carrierInfo.name) +
    '/' +
    carrierInfo.loi.toLowerCase() +
    '/smartflow' +
    '/' +
    planYear
  )
}

const CarrierAppointments = () => {
  const history = useHistory()

  const setSnackbar = useSnackbar()

  const [carrierPage, setCarrierPage] = useState<[string, PageType, string] | null>()

  const carriers = useStoreState(state => state.carrierInfo.items)
  const userLoiInfo = useStoreState(state => state.userInfo.loi).map(loi =>
    SupportedLoi.find(l => l.value === loi)
  )
  const skills = useStoreState(state => state.userInfo.skills)
  const carrierAppointments = useStoreState(state => state.carrierAppointments.items)
  const appointmentRequests = useStoreState(state => state.carrierAppointmentRequests.items)
  const workflowStatuses = useStoreState(state => state.appointmentRequestsWorkflowStatus.items)

  const addCarrierAppointments = useStoreActions(actions => actions.carrierAppointments.load)
  const addWorkflowAppointmentStatus = useStoreActions(
    actions => actions.appointmentRequestsWorkflowStatus.load
  )
  const addAppointmentRequests = useStoreActions(actions => actions.carrierAppointmentRequests.load)

  const [querying, setQuerying] = useState(false)

  const query = useQuery(APPOINTMENTS_QUERY, {
    skip: true
  })

  const [showTutorial, setShowTutorial] = useState<boolean>(false)
  const [selectedLoi, setSelectedLoi] = useState(userLoiInfo[0])

  const afterJune = new Date().getUTCMonth() > 5
  const currentYear = new Date().getUTCFullYear()

  const initialYear =
    afterJune && selectedLoi?.value === 'med_advantage' ? currentYear + 1 : currentYear

  const [planYear, setPlanYear] = useState(initialYear)

  const [carrierOptions, setCarrierOptions] = useState(
    CarriersTagsInfo.filter(tag => tag.loi === selectedLoi?.value)
  )
  const [carrierTypes, setCarrierTypes] = useState(carrierOptions.map(row => row.value))

  // loads carrier appointments as well as workflow statuses / appointment requests
  const loadCarrierAppointments = async () => {
    const result = await query.refetch({
      loi: selectedLoi?.label,
      planYear,
      prevPlanYear: planYear - 1
    })

    const meData = result.data.me

    // add appointments
    const planYearAppointments = meData.planYearAppointments.edges.map(row => row.node)
    const prevPlanYearAppointments = meData.prevPlanYearAppointments.edges.map(row => row.node)

    addCarrierAppointments({
      planYear,
      appointments: planYearAppointments
    })

    addCarrierAppointments({
      planYear: planYear - 1,
      appointments: prevPlanYearAppointments
    })

    const appointmentRequests = SmartflowCarriers.map(
      carrier => meData[carrier]?.edges?.[0]?.node
    ).filter(req => req != null) as CarrierAppointmentRequest[]

    addAppointmentRequests(appointmentRequests)

    const promise = appointmentRequests.map(appointmentRequest =>
      workflowFetch({
        url: `/${appointmentRequest.id}/retrieve`
      })
        .then(d => d.json())
        .then((response: Omit<WorkflowStatus, 'appointmentRequestId'>) => {
          addWorkflowAppointmentStatus({
            ...response,
            appointmentRequestId: appointmentRequest.id
          })
        })
    )

    return Promise.all(promise)
  }

  useEffect(() => {
    setQuerying(true)
    loadCarrierAppointments()
      .then(_ => {
        setQuerying(false)
      })
      .catch(e => {
        console.error(e)
        setQuerying(false)
        setSnackbar({
          message: 'Error loading appointments',
          severity: 'error'
        })
      })
  }, [planYear])

  if (carrierPage) {
    return (
      <div className='carrier_appointments'>
        <Button variant='text' onClick={() => setCarrierPage(null)}>
          <FontAwesomeIcon
            style={{ marginRight: '0.5rem', fontSize: '1.4rem' }}
            icon={faChevronLeft}
          />
          <u>Back to All Carriers</u>
        </Button>
        <div style={{ marginTop: '1rem' }}>
          {carrierPage[1] === PageType.Instructions &&
            getInstructionsPage(carrierPage[0], carrierPage[2], planYear)}
          {carrierPage[1] === PageType.ViewDetails && (
            <ViewDetailsPage
              carrierName={carrierPage[0]}
              loi={carrierPage[2]}
              planYear={planYear}
            />
          )}
        </div>
      </div>
    )
  }

  const carrierInfo: CarrierInfoForView[] = carriers
    .filter(carrier => !isDeactivatedCarrier(selectedLoi?.label, carrier))
    .filter(carrier => carrier.loi === selectedLoi?.label)
    .map(carrier => ({
      ...carrier,
      cardInfo: getCardType(carrier, planYear)
    }))

  const filteredCarrierInfo = carrierInfo.filter(
    info =>
      !info.cardInfo.data?.hidden &&
      (!carrierTypes.length ||
        carrierTypes.find(
          carrierTag => info.carrierTags.find(row => row.name === carrierTag) != null
        ))
  )

  const carrierInfoByPriority = !selectedLoi
    ? filteredCarrierInfo
    : sortTiles(filteredCarrierInfo, selectedLoi.label)

  const handleLoiChange = (loi: string) => {
    const loiValue = SupportedLoi.find(l => l.value === loi)
    setSelectedLoi(loiValue)
    setCarrierOptions(CarriersTagsInfo.filter(tag => tag.loi === loi))
    setCarrierTypes(CarriersTagsInfo.filter(tag => tag.loi === loi).map(row => row.value))
  }

  const years = _.uniq([
    new Date().getUTCFullYear(),
    new Date().getUTCFullYear() + 1,
    ...carrierAppointments.map(info => info.planYear)
  ]).sort()

  return (
    <Grid id='carrier_appointments' container direction='column' spacing={2}>
      <Grid item>
        <ShowTutorialBox onShowTutorial={() => setShowTutorial(true)}>
          Assurance carrier appointments enable you to access the Assurance dialer to receive calls.
          Have the flexibility to choose who you want to get appointed with and when, at your
          convenience.
        </ShowTutorialBox>
      </Grid>
      <Grid item container direction='row' spacing={2}>
        <Grid item id='carrier_appointments_plan_year'>
          <span style={{ fontWeight: 600 }}>Plan Year</span>
          <Select
            style={{ minWidth: '200px', marginTop: '0.25rem' }}
            selected={planYear}
            options={years}
            keySelector={year => year}
            labelSelector={year =>
              year + (year === new Date().getUTCFullYear() ? ' (Current Year)' : '')
            }
            onChange={setPlanYear}
          />
        </Grid>
        <Grid item id='carrier_appointment_loi'>
          <span style={{ fontWeight: 600 }}>Line of Insurance</span>
          <Select
            style={{ minWidth: '200px', marginTop: '0.25rem' }}
            selected={selectedLoi?.value || ''}
            options={userLoiInfo}
            keySelector={loi => loi?.value || ''}
            labelSelector={loi => _.capitalize(loi?.label)}
            logoSelector={loi => loi?.icon || ''}
            onChange={value => handleLoiChange(value)}
          />
        </Grid>
        <Grid item id='carrier_appointments_medicare_type'>
          <span style={{ fontWeight: 600 }}>{_.capitalize(selectedLoi?.label) + ' '}Type</span>
          <MultiSelect
            style={{ minWidth: '200px', marginTop: '0.25rem' }}
            selected={carrierTypes}
            options={carrierOptions}
            keySelector={option => option.value}
            labelSelector={option => option.name}
            onChange={values => setCarrierTypes(values)}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Heading>Assurance Carrier Partners{querying && <Loading />}</Heading>
        <List>
          {carrierInfoByPriority.length < 1 && <p>Coming soon...</p>}
          {carrierInfoByPriority.map(carrierInfo => {
            const { cardInfo } = carrierInfo
            const key = carrierInfo.name + '_' + cardInfo.type
            const cardId = 'carrier_appointments_' + carrierInfo.name

            cardInfo.type = disabledCard(carrierInfo, carrierInfo => {
              const appointments = carrierAppointments.find(
                row =>
                  row.planYear === planYear &&
                  row.carrier.loi === selectedLoi?.label &&
                  row.carrier.name === carrierInfo.name
              )

              const internalAppointments: InternalAppointmentDetail[] = appointments
                ? appointments.internalAppointmentDetails.filter(row => row.status === 'APPOINTED')
                : []

              const justInTimeAppointments: JustInTimeStateDetails[] = appointments
                ? appointments.justInTimeStateDetails.filter(row => row.status === 'ACTIVE')
                : []

              return internalAppointments.length + justInTimeAppointments.length == 0
            })

            const logoUrl = [CardType.Progress, CardType.ViewDetails].includes(cardInfo.type)
              ? Carriers[carrierInfo.loi]?.[carrierInfo.name]?.Logo
              : (Carriers[carrierInfo.loi]?.[carrierInfo.name] || {})?.LogoGrayscale

            const commonProps = {
              key,
              cardId,
              logoUrl,
              carrierTags: carrierInfo.carrierTags.map(row => row.name),
              carrierName: carrierInfo.name
            }

            switch (cardInfo.type) {
              case CardType.Instructions:
                return (
                  <CheckInstructionsCard
                    availableStates={carrierInfo.avaliableState!}
                    onClick={() =>
                      setCarrierPage([carrierInfo.name, PageType.Instructions, carrierInfo.loi])
                    }
                    {...commonProps}
                  />
                )
              case CardType.Progress: {
                const appointmentRequest = appointmentRequests.find(
                  row => row.carrier.name === carrierInfo.name && row.planYear === planYear
                )

                const workflowStatus =
                  appointmentRequest &&
                  workflowStatuses.find(row => row.appointmentRequestId === appointmentRequest.id)

                const visibleProgressItems =
                  workflowStatus?.progressList.filter(row => !row.hidden) || []

                const currentPosition = workflowStatus
                  ? visibleProgressItems.findIndex(
                      row => row.name === workflowStatus.currentProgress
                    )
                  : 0

                const progressPercent =
                  workflowStatus != null
                    ? ((currentPosition + 1) * 100) / Math.max(1, visibleProgressItems.length)
                    : 0

                return (
                  <ProgressCard
                    progressPercent={progressPercent}
                    currentProgress={
                      (workflowStatus &&
                        getPlateLabel(
                          carrierInfo.name,
                          carrierInfo.loi,
                          workflowStatus.currentProgress
                        )) ||
                      'Loading...'
                    }
                    onClick={() => {
                      history.push(getSmartflowLink(carrierInfo, planYear))
                    }}
                    {...commonProps}
                  />
                )
              }
              case CardType.Eligibility: {
                return (
                  <EligibilityCard
                    disabled={cardInfo.data?.disabled}
                    information={cardInfo.data?.information}
                    availableStates={carrierInfo.avaliableState!}
                    onClick={() => {
                      history.push(getSmartflowLink(carrierInfo, planYear))
                    }}
                    {...commonProps}
                  />
                )
              }
              case CardType.Terminated: {
                if (SmartflowCarriers.includes(carrierInfo.name)) {
                  return (
                    <EligibilityCard
                      terminated
                      availableStates={carrierInfo.avaliableState!}
                      onClick={() => {
                        history.push(getSmartflowLink(carrierInfo, planYear))
                      }}
                      {...commonProps}
                    />
                  )
                }

                return (
                  <CheckInstructionsCard
                    terminated
                    availableStates={carrierInfo.avaliableState!}
                    onClick={() =>
                      setCarrierPage([carrierInfo.name, PageType.Instructions, carrierInfo.loi])
                    }
                    {...commonProps}
                  />
                )
              }
              case CardType.ViewDetails: {
                const appointments = carrierAppointments.find(
                  row =>
                    row.planYear === planYear &&
                    row.carrier.loi === selectedLoi?.label &&
                    row.carrier.name === carrierInfo.name
                )

                const internalAppointments: InternalAppointmentDetail[] = appointments
                  ? appointments.internalAppointmentDetails.filter(
                      row => row.status === 'APPOINTED'
                    )
                  : []

                const justInTimeAppointments: JustInTimeStateDetails[] = appointments
                  ? appointments.justInTimeStateDetails.filter(row => row.status === 'ACTIVE')
                  : []

                return (
                  <ViewDetails
                    appointed={_.uniq(internalAppointments.map(row => row.state)).length}
                    justInTime={_.uniq(justInTimeAppointments.map(row => row.state)).length}
                    onClick={() =>
                      setCarrierPage([carrierInfo.name, PageType.ViewDetails, carrierInfo.loi])
                    }
                    {...commonProps}
                  />
                )
              }
              case CardType.ComingSoon:
                return (
                  <ComingSoonCard availableStates={carrierInfo.avaliableState!} {...commonProps} />
                )
              case CardType.Closed:
                return (
                  <ClosedCard
                    planYear={planYear}
                    availableStates={carrierInfo.avaliableState!}
                    {...commonProps}
                  />
                )
              case CardType.ReCertification:
                return (
                  <CheckInstructionsCard
                    label={'Get Recertified'}
                    availableStates={carrierInfo.avaliableState!}
                    onClick={() =>
                      history.push(
                        '/carrier_appointments/' +
                          _.snakeCase(carrierInfo.name) +
                          '/' +
                          carrierInfo.loi.toLowerCase() +
                          '/' +
                          planYear +
                          '/recertification'
                      )
                    }
                    {...commonProps}
                  />
                )
              case CardType.Unavailable:
                return (
                  <UnavailableCard
                    availableStates={carrierInfo.avaliableState!}
                    customTooltip={
                      carrierInfo.name === Carrier.Protective
                        ? 'You are currently not eligible for appointment with Protective. Our system is regularly updated with new information so please check back later if you believe you are eligible for appointment.'
                        : undefined
                    }
                    {...commonProps}
                  />
                )
              default:
                return <></>
            }
          })}
        </List>
      </Grid>
      {showTutorial && (
        <Tutorial tutorialSteps={getSteps()} onFinish={() => setShowTutorial(false)} />
      )}
    </Grid>
  )
}

export default CarrierAppointments
