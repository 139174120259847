import { CarrierStatus } from 'store/carrier_appointments'
import { useStoreState } from 'store'

import { getCarrierAppointmentStatus } from '../utils'

export const useCarrierAppointmentStatusForPlanYear = (
  carrierName: string,
  loi: string,
  planYear: number
): CarrierStatus => {
  const carrierInfo = useStoreState(state =>
    state.carrierAppointments.items.find(
      row =>
        row.carrier.name === carrierName && row.carrier.loi === loi && row.planYear === planYear
    )
  )
  if (!carrierInfo) {
    return CarrierStatus.NotAppointed
  }

  return getCarrierAppointmentStatus(carrierInfo)
}
