import React from 'react'

import { Carrier, LOI } from 'store/carriers'

import { PlateComponentMap, PlateLabelMap } from './plate_map'
import SmartFlow from '../../commons/flow'

interface Props {
  planYear: number
}

const PrudentialFlow = (props: Props) => {
  const { planYear } = props

  return (
    <SmartFlow
      planYear={planYear}
      loi={LOI.Life}
      carrierName={Carrier.Prudential}
      plateComponentMap={PlateComponentMap}
      plateLabelMap={PlateLabelMap}
      shouldShowCancelDropdown={() => true}
    />
  )
}

export default PrudentialFlow
