import AiqEligibilityTraining from './aiq_eligibility_training'
import Appointment from './appointment/appointment'
import CanceledByAgent from '../../commons/flow/plates/canceled_by_agent'
import Cancelled from '../../commons/flow/plates/cancelled'
import CarrierReview from './carrier_review'
import DataSync from '../../commons/flow/plates/data_sync'
import Eligibility from './eligibility'

export const PlateLabelMap = {
  Eligibility: 'Check Eligibility',
  AIQEligibilityAndTraining: 'Eligibility & Training',
  Paperwork: 'Complete Paperwork',
  CarrierReview: 'Review by Prudential',
  DataSync: 'Sync Data',
  CanceledByAgent: 'Canceled by Agent',
  Cancelled: 'Cancelled',
  Exception: 'Exception'
}

export const PlateComponentMap = {
  Eligibility: Eligibility,
  AIQEligibilityAndTraining: AiqEligibilityTraining,
  Paperwork: Appointment,
  CarrierReview,
  DataSync: DataSync,
  CanceledByAgent,
  Cancelled
}
