import AiqEligibilityTraining from './aiq_eligibility_training'
import Appointment from './appointment/appointment'
import CanceledByAgent from '../../commons/flow/plates/canceled_by_agent'
import Cancelled from '../../commons/flow/plates/cancelled'
import CarrierPortalAccess from './carrier_portal_access'
import CarrierReview from './carrier_review'
import DataSync from './data_sync'
import Eligibility from './eligibility'

export const PlateLabelMap = {
  Eligibility: 'Eligibility Check',
  AIQEligibilityAndTraining: 'AIQ Eligibility & Training',
  Paperwork: 'Paperwork',
  CarrierReview: 'Carrier Review',
  DataSync: 'Data Sync',
  CanceledByAgent: 'Canceled by Agent',
  CarrierPortalAccess: 'Carrier Portal Access',
  Cancelled: 'Cancelled',
  Exception: 'Exception'
}

export const PlateComponentMap = {
  Eligibility: Eligibility,
  AIQEligibilityAndTraining: AiqEligibilityTraining,
  Paperwork: Appointment,
  CarrierReview,
  DataSync: DataSync,
  CarrierPortalAccess: CarrierPortalAccess,
  CanceledByAgent,
  Cancelled
}
