import React from 'react'

import AssuranceLoading from 'components/loading'
import { PlateProps } from 'screens/carrier_appointments/commons/plate'

import NotAppointed from './not_appointed'
import PreviouslyAppointed from './previously_appointed'
import TerminatedWithCause from './terminated_with_cause'

interface Props extends PlateProps {
  pageName: 'CheckEligibilitySpinner' | 'NotAppointedWithCarrier' | string
}

const Eligibility = (props: Props) => {
  const { pageName } = props

  switch (pageName) {
    case 'CheckEligibilitySpinner':
      return (
        <div style={{ marginTop: '4rem' }}>
          <AssuranceLoading>Running eligibility check...</AssuranceLoading>
        </div>
      )

    case 'NotAppointedWithCarrier':
      return <NotAppointed {...props} />

    case 'PromptForCarrierStatus':
      return <PreviouslyAppointed {...props} />

    case 'TerminatedWithCause':
      return <TerminatedWithCause {...props} />

    default:
      return <div>Unknown page {pageName}</div>
  }
}

export default Eligibility
