import React from 'react'
import { useHistory } from 'react-router-dom'

import { Grid, makeStyles } from '@material-ui/core'

import Button from 'components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SuccessBox from 'components/box/success_box'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import { PlateProps } from '../commons/plate'
import { withRtsReversalCheck } from '../commons/flow/plates/data_sync/rts_reversal'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const useStyles = makeStyles(theme => ({
  grey: {
    color: theme.palette.grey[500]
  }
}))

const DataSync = withRtsReversalCheck((props: Props) => {
  const classes = useStyles()

  const history = useHistory()

  const status = props.progressItems.find(row => row.name === props.plateName)?.status

  const prevStepComplete =
    props.progressItems.find(
      row => row.name === 'CompleteCertification' && row.status === 'checked'
    ) != null

  const handleContinue = () => {
    history.push('/carrier_appointments')
  }

  const handleBack = () => {
    const data = {
      pageData: {},
      progress: {
        name: props.plateName,
        status
      },
      nextProgress: 'CompleteCertification',
      nextPage: {
        name: 'CompleteCertification'
      }
    }
    props.doUpdate?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Data Sync</h2>
      </Grid>
      {status === 'checked' && (
        <Grid item>
          <SuccessBox>
            <strong>Congratulations!</strong> You’re now appointed by Humana.
          </SuccessBox>
        </Grid>
      )}
      {status === 'checked' && (
        <Grid item container direction='column' alignItems='center'>
          <img src={'/images/data_sync_complete.svg'} />
        </Grid>
      )}
      {status !== 'checked' && (
        <Grid item container direction='column' alignItems='center'>
          <img src={'/images/data_sync_progress.svg'} />
          <span className={classes.grey}>
            Data syncing between Humana and Assurance.
            <br /> <br />
            This can take ~7 days as information needs to be updated by the National Insurance
            Producer Registry (NIPR), Assurance, and Sunfire Enrollments Platform.
            <br />
            <br />
            If this is taking longer than usual, please triple check that you've completed your
            Humana Certifications. This is often the step that many agents miss!
          </span>
        </Grid>
      )}
      {status === 'checked' && (
        <Grid container item justify='flex-end'>
          <Button onClick={handleContinue}>Continue</Button>
        </Grid>
      )}
      {status !== 'checked' && !prevStepComplete && (
        <Grid container item>
          <Button onClick={handleBack}>
            <FontAwesomeIcon
              style={{ marginRight: '0.5rem', fontSize: '1.4rem' }}
              icon={faChevronLeft}
            />
            Back to Certification Training
          </Button>
        </Grid>
      )}
    </Grid>
  )
})

export default DataSync
