import React, { useState } from 'react'

import { Grid, Link } from '@material-ui/core'

import Button from 'components/button'
import CheckboxButton from 'components/checkbox_button'
import Image from 'components/image'
import { RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'

import Accordion from '../commons/checklist_accordion'
import { PlateProps } from '../commons/plate'
import TimeToComplete from '../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    userInput?: {
      certificationEmailReceived?: boolean
      certificationCompleted?: boolean
      healthcareIdCreated?: boolean
      validationCompleted?: boolean
    }
  }
}

const getIcon = (checked?: boolean) => {
  const props = {
    style: {
      width: '1.25rem',
      height: '1.25rem'
    }
  }
  return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
}

const Certification = (props: Props) => {
  const { plateName, doUpdate, pageData = {}, doNext } = props

  const { userInput = {} } = pageData

  const [certificationEmailReceived, setEmailReceived] = useState(
    userInput.certificationEmailReceived
  )
  const [healthcareIdCreated, setHealthcareIdCreated] = useState(userInput.healthcareIdCreated)
  const [certificationCompleted, setCertificationCompleted] = useState(
    userInput.certificationCompleted
  )
  const [validationCompleted, setValidationCompleted] = useState(userInput.validationCompleted)

  const handleNext = () => {
    const allCompleted =
      certificationEmailReceived &&
      healthcareIdCreated &&
      validationCompleted &&
      certificationCompleted

    const data = {
      pageData: {
        userInput: {
          certificationEmailReceived,
          healthcareIdCreated,
          validationCompleted,
          certificationCompleted
        }
      },
      nextProgress: 'CarrierReview',
      progress: {
        name: plateName,
        status: allCompleted ? 'timer' : 'error'
      },
      nextPage: {
        name: 'CarrierReview'
      }
    }
    doUpdate?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Certification</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item container direction='column' spacing={1}>
        <Grid item>
          <Accordion
            icon={getIcon(certificationEmailReceived)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                1. Check for the email to start UHC certifications
              </span>
            }
          >
            <div>
              Please check your Assurance email for an email from UnitedHealthcare. It will show as:
              <br />
              <br />
              From:{' '}
              <Link
                rel='noreferrer'
                target='_blank'
                href='mailto:UHC_MedicareSolutionsContracting@sircon.com'
              >
                <b>UHC_MedicareSolutionsContracting@sircon.com</b>
              </Link>{' '}
              <br />
              Subject: Contracting Status - Certification Access
              <br />
              <br />
              In order to create your One Healthcare ID (Optum ID), click the green “Get Started”
              button.
              <Image
                alt='get healthcare id'
                style={{ width: '100%', marginTop: '0.5rem' }}
                src='/images/carriers/medicare/united_health_care/appointment/certification/UHC Certs 1.jpg'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={certificationEmailReceived || false}
                onChange={value => setEmailReceived(value)}
                label='I Received an Email'
              />
            </Grid>
          </Accordion>
        </Grid>
        <Grid item>
          <Accordion
            icon={getIcon(healthcareIdCreated)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                2. Create a One Healthcare ID (Used to be known as Optimum ID) and Activate Account
              </span>
            }
          >
            <div>
              Follow the directions to set up your One Healthcare ID account, which will be used to
              log into Jarvis, the UHC portal.
              <br />
              <Image
                alt='producer-express-portal click get my password'
                style={{ width: '80%', marginTop: '0.5rem' }}
                src='/images/carriers/medicare/united_health_care/appointment/certification/UHC Certs 2.jpg'
              />
            </div>
            <div>
              An activation code will be sent to your Assurance email account. Click on “Activate my
              One Healthcare ID” and enter your activation code to complete the set up of your One
              Healthcare account
              <br />
              <Image
                alt='retrieve temporary password from email'
                style={{ width: '100%', marginTop: '0.5rem' }}
                src='/images/carriers/medicare/united_health_care/appointment/certification/UHC Certs 3.jpg'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={healthcareIdCreated || false}
                onChange={value => setHealthcareIdCreated(value)}
                label='I Created an ID and Activated My Account'
              />
            </Grid>
          </Accordion>
        </Grid>
        <Grid item>
          <Accordion
            icon={getIcon(certificationCompleted)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                3. Login to Jarvis and Complete Certification
              </span>
            }
          >
            <div>
              Use your One Healthcare ID to login to the Jarvis UHC portal at jarvisuhc.com.
              <br />
              <br />
              <strong>Note:</strong> These credentials are different than your Producer Express
              login.
              <Image
                alt='take-courses'
                style={{ width: '100%', marginTop: '0.5rem' }}
                src='/images/carriers/medicare/united_health_care/appointment/certification/UHC Certs 4.jpg'
              />
            </div>
            <div>
              Click on the “Knowledge Center” menu tab
              <br />
              <br />
              Click on “Training & Certifications”
              <Image
                alt='click on training and certifications on knowledge center menu'
                style={{ width: '100%', marginTop: '0.5rem' }}
                src='/images/carriers/medicare/united_health_care/appointment/certification/UHC Certs 5.jpg'
              />
            </div>
            <div>
              Click on “Launch Learning Lab”
              <Image
                alt='click on launch learning lab'
                style={{ width: '100%', marginTop: '0.5rem' }}
                src='/images/carriers/medicare/united_health_care/appointment/certification/UHC Certs 6.jpg'
              />
            </div>
            <div>
              Click on “UnitedHealthcare Certifications (English)”
              <Image
                alt='click on uhc certifications'
                style={{ width: '100%', marginTop: '0.5rem' }}
                src='/images/carriers/medicare/united_health_care/appointment/certification/UHC Certs 7.jpg'
              />
            </div>
            <div>
              Complete required courses as outlined in your list when you click into the course
              card.
              <Image
                alt='complete required courses'
                style={{ width: '100%', marginTop: '0.5rem', border: '1px solid black' }}
                src='/images/carriers/medicare/united_health_care/appointment/certification/UHC Certs 9.jpg'
              />
            </div>
            <div>
              Once you are done with all your required courses, mark this task as “I Completed My
              Certification Courses” (on this page) and wait for an email confirmation from{' '}
              <strong>UHC_MedicareSolutionsContracting@sircon.com</strong>.
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={certificationCompleted || false}
                onChange={value => setCertificationCompleted(value)}
                label="I've Completed My Certification Courses"
              />
            </Grid>
          </Accordion>
        </Grid>
        <Grid item>
          <Accordion
            icon={getIcon(validationCompleted)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                4. Validate Certifications are Complete
              </span>
            }
          >
            <div>
              A couple of days after you are done with your certifications, you will receive an
              email in your Assurance email account informing you that you are ready to sell with
              UnitedHealthCare.
              <br />
              <br />
              <div>
                <strong>Email from:</strong> UHC_MedicareSolutionsContracting@sircon.com.
                <br />
                <strong>Subject: </strong> Activate Your New Writing Number with UnitedHealthcare
                Medicare Solutions
              </div>
              <Image
                alt='receive confirmation email regarding RTS with UHC'
                style={{ width: '100%', marginTop: '0.5rem' }}
                src='/images/carriers/medicare/united_health_care/appointment/certification/UHC Certs 8.jpg'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={validationCompleted || false}
                onChange={value => setValidationCompleted(value)}
                label='I Received a Confirmation Email'
              />
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container item justify='flex-end' spacing={1}>
        <Grid item>
          <Button onClick={handleNext}>Next: UnitedHealthCare Review</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Certification
