import { PageType, StageItem, WorkFlowData, WorkflowStepDetails } from '../certification.d'

export enum AhipStageKeys {
  CREATE_LINK_ACCOUNT = 'CreateLinkAccount',
  COMPLETE_CERTIFICATION = 'CompleteCertification',
  SYNC_DATA = 'SyncData'
}

export enum AhipPageTypes {
  WORKFLOW = 'workflow',
  TRANSITION_PERIOD = 'transition_period',
  CAERTIFICATION_STATUS = 'certification_status'
}

export const AhipPlateMap = {
  [AhipStageKeys.CREATE_LINK_ACCOUNT]: 'Create & Link Account',
  [AhipStageKeys.COMPLETE_CERTIFICATION]: 'Complete Certification',
  [AhipStageKeys.SYNC_DATA]: 'Sync Data'
}

export enum AhipWorflowActions {
  GET_STARTED = 'GetStarted',
  CREATE_LINK_ACCOUNT_CONTINUE = 'CreateLinkAccountContinue',
  CREATE_LINK_ACCOUNT_COMPLETED = 'CreateLinkAccountCompleted',
  SYNC_DATA = 'SyncData'
}

export interface AhipProps {
  page: PageType
  onStageUpdate: (items: StageItem[], currentProgressKey?: AhipStageKeys) => void
  year: number
  optionChanges: (year: number) => void
}

export interface AhipWorkflowProps {
  onStageUpdate: (items: StageItem[], currentProgressKey?: AhipStageKeys) => void
  year: number
}

export interface AhipSummaryProps {
  onStageUpdate: (items: StageItem[]) => void
  optionChanges: (year: number) => void
  year: number
}

export interface AhipTransitionProps {
  year: number
}

export interface AhipWorkflowStepDetails extends WorkflowStepDetails {
  page_name: AhipStageKeys
  page_data: CompleteCertificationData | any
}

export interface AhipWorkFlowData extends WorkFlowData {
  steps: AhipWorkflowStepDetails[]
}

export interface AhipQuizResult {
  attempt: number
  date: string
  score: number
  passed: boolean
}

export interface CompleteCertificationData {
  modules?: CertificationModulesInfo
  final_exam?: CertificationFinalExamInfo
}

export enum CertificationInfoStatus {
  COMPLETED = 'Completed',
  INCOMPLETE = 'Incomplete'
}

export interface CertificationModulesInfo {
  status: CertificationInfoStatus
  gc_date: string
  nd_date: string
  fwa_date: string
}

export interface CertificationFinalExamInfo {
  status: CertificationInfoStatus
  quiz_data: AhipQuizResult[]
  final_score: number
  completion_date: string
}
