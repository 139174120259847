import React from 'react'

import { ButtonProps, makeStyles } from '@material-ui/core'
import { lighten } from '@material-ui/core/styles'

import Button from 'components/button'

const useStyles = makeStyles(theme => ({
  button: (props: ButtonProps) => ({
    borderRadius: '0 0 0.2rem 0.2rem',
    border: 'none',
    borderTop: '1px solid #ccc',
    '&:hover, &:focus': {
      border: 'none',
      borderTop: '1px solid #ccc'
    },
    '&:focus': {
      background:
        props.variant === 'outlined'
          ? lighten(theme.palette[props.color || 'primary'].light, 0.95)
          : theme.palette[props.color || 'primary'].dark
    },
    '&.Mui-disabled': {
      textDecoration: 'none',
      background: theme.palette.grey[500],
      color: '#fff'
    }
  })
}))

const CardButton = (props: ButtonProps) => {
  const classes = useStyles(props)

  return <Button {...props} className={[classes.button, props.className].join(' ')} />
}

export default CardButton
