import gql from 'graphql-tag'

const ME_ONLY = gql`
  {
    me {
      permissionGroups {
        id
        name
      }
    }
  }
`

export default ME_ONLY
