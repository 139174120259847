import React from 'react'

import { Box, makeStyles } from '@material-ui/core'

import { InfoIcon } from 'components/icons'
import { LightTooltip } from 'components/tooltip'
import styled from 'styled-components'

const useStyles = makeStyles(theme => ({
  common: {
    borderRadius: '1rem',
    fontWeight: 600,
    padding: '0.25rem .5rem',
    width: 'fit-content',
    lineHeight: '1.125rem',
    color: '#fff',
    fontSize: '0.75rem',
    '& > img': {
      width: '1.125rem'
    },
    '& > * + *': {
      marginLeft: '0.25rem'
    }
  },
  notReadyToSell: {
    background: theme.palette.warning.main
  },
  comingSoon: {
    background: theme.palette.warning.light
  },
  readyToSell: {
    background: theme.palette.success.main
  }
}))

const BoxWithTooltip = styled.div`
  display: flex;
`

const comingSoonTooltip = (
  <>
    Assurance IQ is in the process of collecting files from the Medicare Supplement companies within
    this new Carrier Contracting Portal.{<br />}
    {<br />}
    If you have not started your appointment process with the carriers yet, please follow the
    instructions on this page.{<br />}
    {<br />}
    If you have been confirmed appointed by the Assurance IQ team, you will receive the calls
    appropriately.
  </>
)

export const NotReadyToSell = () => {
  const classes = useStyles()
  return (
    <BoxWithTooltip>
      <Box
        display='flex'
        alignItems='center'
        whiteSpace='nowrap'
        className={classes.common + ' ' + classes.notReadyToSell}
      >
        <img src='/images/not_ready_to_sell.svg' alt={'Not Ready-to-sell'} />
        <span>NOT READY-TO-SELL</span>
      </Box>
    </BoxWithTooltip>
  )
}

export const ComingSoon = () => {
  const classes = useStyles()
  return (
    <BoxWithTooltip>
      <Box
        display='flex'
        alignItems='center'
        whiteSpace='nowrap'
        className={classes.common + ' ' + classes.comingSoon}
      >
        <img src='/images/not_ready_to_sell.svg' alt={'Coming Soon'} />
        <span>COMING SOON</span>
      </Box>
      <LightTooltip placement='top-start' title={comingSoonTooltip}>
        <div style={{ marginLeft: '0.25rem' }}>
          <InfoIcon style={{ width: '1.25rem', display: 'block' }} />
        </div>
      </LightTooltip>
    </BoxWithTooltip>
  )
}

export const ReadyToSell = () => {
  const classes = useStyles()
  return (
    <Box
      display='flex'
      whiteSpace='nowrap'
      alignItems='center'
      className={classes.common + ' ' + classes.readyToSell}
    >
      <img src='/images/icon_call.svg' />
      <span>READY-TO-SELL</span>
    </Box>
  )
}

export default NotReadyToSell
