import React from 'react'

import Box from 'components/box'

import BaseCard from './card_commons'
import CardButton from './card_button'
import CarrierTagsView from '../carrier_tags_view'

interface Props {
  logoUrl: string
  carrierTags: string[]
  availableStates: number
  onClick?: () => void
}

const ComingSoonCard = (props: Props) => {
  const { logoUrl, carrierTags, availableStates, onClick } = props

  return (
    <BaseCard style={{ boxShadow: 'none' }}>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        style={{ flex: 1, textAlign: 'center', opacity: '0.5' }}
        spacing={'1rem'}
      >
        <img style={{ maxWidth: '70%', height: '48px' }} alt='logo' src={logoUrl} />
        <div>Available in {availableStates} states</div>
        <CarrierTagsView carrierTags={carrierTags} />
      </Box>
      <CardButton disabled onClick={onClick}>
        Coming Soon
      </CardButton>
    </BaseCard>
  )
}

export default ComingSoonCard
