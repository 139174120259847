import React from 'react'

import { Grid, Link } from '@material-ui/core'

import Button from 'components/button'
import Note from 'components/box/note'
import { PlateProps } from 'screens/carrier_appointments/commons/plate'
import TimeToComplete from 'screens/carrier_appointments/commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const InvitationSent = (props: Props) => {
  const { doNext } = props

  const handleNext = () => {
    doNext?.('next')
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Invitation - Sent</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        Please check your {''}
        <Link target='_blank' rel='noreferrer' href='https://outlook.office365.com/mail/'>
          <strong>@Assurance.com</strong>
        </Link>
        {''} email account for:
        <br />
        <br />
        <div>
          <div>
            <strong>From:</strong> UHC_Contracting@sircon.com
          </div>
          <div>
            <strong>Subject:</strong> Agent name, ASSURANCE IQ LLC invites you to review and sign an
            UnitedHealthcare Individual & Family Exchange Plans contracting request.
          </div>
          <div>
            <strong>Recruiter Name:</strong> ASSURANCE IQ
          </div>
        </div>
        <br />
        Once you receive the invitation, click “Appointment” and complete your paperwork.
      </Grid>
      <Grid item>
        <Note>
          <strong>Note:</strong> It can take up to 7 business days for UHO to initiate the
          appointment process. After you get it, you will be taken to
          <Link target='_blank' rel='noreferrer' href=''>
            UHO's Producer Center.
          </Link>
        </Note>
      </Grid>
      <Grid container item justify='flex-end' spacing={1}>
        <Grid item>
          <Button onClick={handleNext}>Next: Appointment</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InvitationSent
