import React from 'react'

import AlignmentReview from './exception/alignment_review'
import { CarrierReviewProps } from './carrier_review'
import Pending from './exception/pending'
import RequestSubmitted from './exception/request_submitted'
import Review from './review'

const ReviewByHumana = (props: CarrierReviewProps) => {
  const { pageName, pageData } = props

  if (pageName !== 'Exception') {
    return <Review {...props} />
  }

  switch (pageData?.exceptionType) {
    case 'Alignment Review':
      return <AlignmentReview {...props} />
    case 'Pending':
      return <Pending {...props} />
    case 'Request Submitted':
      return <RequestSubmitted {...props} />
    default:
      return <span>Unsupported exception type {pageData?.exceptionType}</span>
  }
}

export default ReviewByHumana
