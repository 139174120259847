import gql from 'graphql-tag'

export const TEMPLATE_DETAIL_QUERY = gql`
  query StateAddsTemplate($id: ID!) {
    stateAddsTemplate(id: $id) {
      updatedAt
      template
    }
  }
`
