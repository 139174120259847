import React from 'react'

import { Grid } from '@material-ui/core'

import Warning from 'components/box/warning'

import { PlateProps } from '../commons/plate'
import TimeToComplete from '../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const RejectedByBrokerServices = (props: Props) => {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Rejected by Broker Services</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Warning>
        <strong>Note:</strong> Case has been rejected after Aetna Medicare Broker Services review.
        Rejection reason will be visible on the case.
      </Warning>
      <Grid item>
        If you are wondering why you were rejected by Aetna, please contact Aetna to find out why
        you were rejected. The Aetna number is 866-714-9301.
        <br />
        <br />
        After you resolve your situation with Aetna, please try again by hitting the 'Needs Help'
        button at the bottom right corner of your screen. We will restart your Eligibility Check to
        enable you to start the carrier appointment process again.
      </Grid>
    </Grid>
  )
}

export default RejectedByBrokerServices
