import React, { Ref, useRef } from 'react'

import styled from 'styled-components'

export interface ChangeEvent {
  name?: string
  value: string
  maskedValue?: string
}

export interface Props {
  className?: string
  style?: Record<string, number | string | undefined>
  label?: React.ReactNode
  name?: string
  placeholder?: string
  value?: string
  type?: 'date' | 'text' | 'number' | 'search'
  formatNumber?: boolean
  mask?: string
  onChange?: (event: ChangeEvent) => void
  inputRef?: (ref: Ref<HTMLInputElement>) => void
}

const Main = styled.div`
  label {
    font-weight: 600;
  }
  input {
    display: block;
    width: 100%;
    padding: 0.4375rem 1rem;
    border: 1px solid rgba(51, 51, 51, 0.16);
    &:focus {
      outline: none;
    }
    box-shadow: inset 0px 2px 2px rgba(51, 51, 51, 0.02), inset 0px 2px 4px rgba(51, 51, 51, 0.06),
      inset 0px 1px 4px rgba(51, 51, 51, 0.06);
  }
`

const getDigitsOnly = (str: string) => {
  return str.replace(/\D/g, '')
}

const Input = (props: Props) => {
  const {
    mask = '',
    name,
    placeholder,
    className,
    style,
    label,
    value = '',
    type,
    formatNumber,
    onChange
  } = props

  const inputRef = useRef<HTMLInputElement | null>(null)

  const formattedNumber = (value: string) => {
    if (value === '') {
      return ''
    }
    if (!formatNumber) {
      return value
    }
    const result: string[] = []
    for (let end = value.length - 1; end >= 0; end -= 3) {
      result.push(value.slice(Math.max(0, end - 2), end + 1))
    }
    return result.reverse().join(',')
  }

  const handleChange = (newValue: string) => {
    const value = type === 'number' ? getDigitsOnly(newValue) : newValue
    const unmasked = value.substr(0, mask.length) === mask ? value.substr(mask.length) : value
    onChange &&
      onChange({
        name,
        value: unmasked,
        maskedValue: value
      })
  }

  const updateCursor = () => {
    const pos = inputRef.current!.selectionStart!
    if (pos < mask.length) {
      inputRef.current!.setSelectionRange(
        mask.length,
        inputRef.current!.selectionEnd || mask.length
      )
    }
  }

  const inputValue = mask + (type === 'number' ? formattedNumber(getDigitsOnly(value)) : value)

  return (
    <Main style={style} className={className}>
      <label>
        {label}
        <input
          placeholder={placeholder}
          style={{
            marginTop: label ? '0.5rem' : 0
          }}
          name={name}
          type={type === 'search' ? 'search' : 'text'}
          ref={ref => {
            inputRef.current = ref
            props.inputRef && props.inputRef(inputRef)
          }}
          value={inputValue}
          onChange={e => handleChange(e.target.value)}
          onFocus={_ => {
            updateCursor()
          }}
          onPaste={_ => {
            updateCursor()
          }}
          onKeyPress={e => {
            const key = e.key
            updateCursor()
            const selectionStart = inputRef.current!.selectionStart!
            const selectionEnd = inputRef.current!.selectionEnd!
            if (type === 'number') {
              if ('0123456789'.indexOf(key) === -1) {
                e.preventDefault()
                return
              }
              if (formatNumber) {
                // replace range with single character, or insert one character
                const newPos =
                  selectionStart === selectionEnd ? selectionEnd + 1 : selectionStart + 1
                const oldValue = inputRef.current!.value
                setTimeout(() => {
                  const newValue = inputRef.current!.value
                  // we need to account for comma insertion
                  const offset = newValue.length - oldValue.length >= 2 ? 1 : 0
                  inputRef.current!.setSelectionRange(newPos + offset, newPos + offset)
                })
              }
            }
          }}
          onKeyDown={e => {
            const key = e.key
            const selectionStart = inputRef.current!.selectionStart!
            const selectionEnd = inputRef.current!.selectionEnd!
            if (key === 'Backspace') {
              if (selectionStart === selectionEnd && selectionStart === mask.length) {
                // doing backspace at mask
                e.preventDefault()
                return
              }
              if (type === 'number' && formatNumber) {
                // delete range of characters
                const newPos = Math.max(
                  selectionStart === selectionEnd ? selectionStart - 1 : selectionStart,
                  mask.length
                )
                setTimeout(() => {
                  inputRef.current!.setSelectionRange(newPos, newPos)
                })
              }
            }
          }}
        />
      </label>
    </Main>
  )
}

export default Input
