import React from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import { RoundCheckedIcon, TimerStatusIcon, WarningIcon } from 'components/icons'

import { PlateProps } from '../commons/plate'
import TimeToComplete from '../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const getIconFromStatus = (status?: string) => {
  const style = {
    width: '1.25rem',
    marginRight: '0.375rem'
  }
  switch (status) {
    case 'error':
      return <WarningIcon style={style} />
    case 'checked':
      return <RoundCheckedIcon blue style={style} />
    case 'timer':
      return <TimerStatusIcon style={style} />
    default:
      return <TimerStatusIcon style={style} />
  }
}

const CarrierReview = (props: Props) => {
  const { plateName, doUpdate, progressItems } = props
  const appointment = progressItems.find(item => item.name === 'Appointment')!
  const certification = progressItems.find(item => item.name === 'Certification')!

  let status: string = ''

  if (appointment.status === 'error' || certification.status === 'error') {
    status = 'error'
  } else if (appointment.status === 'checked' && certification.status === 'checked') {
    status = 'finished'
  } else {
    status = 'timer'
  }

  const goto = (nextProgress: string, nextPage: string) => {
    const data = {
      nextProgress,
      nextPage: {
        name: nextPage
      },
      progress: {
        name: plateName,
        status: 'solid'
      },
      pageData: {}
    }
    doUpdate?.('back', data)
  }

  const backLink = (label: string, nextProgress: string, nextPage: string) => {
    return (
      <Button
        variant='text'
        style={{
          marginLeft: '0.2rem',
          padding: 0
        }}
        onClick={() => goto(nextProgress, nextPage)}
      >
        <u>{label}</u>
      </Button>
    )
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>UnitedHealthCare Carrier Review</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      {status === 'finished' && (
        <>
          <Grid item>
            Well done! Your work here is done for now. Sit back and relax.
            <br />
            <br />
            The review can take ~7 days as information needs to be updated by UHC, the National
            Insurance Producer Registry (NIPR), Assurance IQ, and Sunfire Enrollments Platform.
          </Grid>
          <Grid item container>
            {getIconFromStatus(appointment.status)} Appointment Paperwork
          </Grid>
          <Grid item container>
            {getIconFromStatus(certification.status)} Certification
          </Grid>
        </>
      )}
      {status === 'error' && (
        <>
          <Grid item>
            Oh no, it looks like you have incomplete parts in either of your appointment paperwork
            and certification.
          </Grid>
          <Grid item container>
            {getIconFromStatus(appointment.status)} Appointment Paperwork -
            {backLink('Revisit Start Registration', 'Appointment', 'AppointmentPaperWork')}
          </Grid>
          <Grid item container>
            {getIconFromStatus(certification.status)} Certification -
            {backLink('Revisit Certification', 'Certification', 'Certification')}
          </Grid>
        </>
      )}
      {status === 'timer' && (
        <>
          <Grid item>You marked yourself as completed in both sections.</Grid>
          <Grid item container>
            {getIconFromStatus(appointment.status)} Appointment Paperwork -
            {backLink('Revisit Start Registration', 'Appointment', 'AppointmentPaperWork')}
          </Grid>
          <Grid item container>
            {getIconFromStatus(certification.status)} Certification -
            {backLink('Revisit Certification', 'Certification', 'Certification')}
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default CarrierReview
