import React from 'react'

function CircularProgressBar(props) {
  const { className, percent, style } = props

  const BOX_SZ = 128
  const STROKE_WIDTH = 6.5

  const radius = BOX_SZ / 2 - STROKE_WIDTH / 2
  const circumference = 2 * Math.PI * radius

  const circleStyle = {
    transformOrigin: 'center',
    transform: 'rotate(-90deg)',
    transition: '1s'
  }

  return (
    <svg className={className} width={BOX_SZ} height={BOX_SZ} style={style} fill='none'>
      <circle
        cx={BOX_SZ / 2}
        cy={BOX_SZ / 2}
        r={radius}
        strokeWidth={STROKE_WIDTH}
        stroke='#eee'
        strokeDasharray={circumference}
      />
      <circle
        cx={BOX_SZ / 2}
        cy={BOX_SZ / 2}
        r={radius}
        strokeWidth={STROKE_WIDTH}
        strokeLinecap='round'
        stroke='#0071b7'
        strokeDashoffset={circumference * (1 - percent / 100)}
        strokeDasharray={circumference}
        style={circleStyle}
      />
    </svg>
  )
}

export default CircularProgressBar
