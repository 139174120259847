import React, { useState } from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import CheckboxButton from 'components/checkbox_button'
import Image from 'components/image'
import { RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'

import Accordion from '../commons/checklist_accordion'
import { PlateProps } from '../commons/plate'
import TimeToComplete from '../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    userInput?: {
      checkEmail?: boolean
      loggedIn?: boolean
      completedPaperwork?: boolean
    }
  }
}

const getIcon = (checked?: boolean) => {
  const props = {
    style: {
      width: '1.25rem',
      height: '1.25rem'
    }
  }
  return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
}

const Appointment = (props: Props) => {
  const { plateName, doUpdate, pageData = {} } = props

  const { userInput = {} } = pageData

  const [checkEmail, setCheckEmail] = useState(userInput.checkEmail)
  const [logged, setLogged] = useState(userInput.loggedIn)
  const [completed, setCompleted] = useState(userInput.completedPaperwork)

  const handleNext = () => {
    const allCompleted = checkEmail && logged && completed
    const data = {
      pageData: {
        userInput: {
          checkEmail,
          loggedIn: logged,
          completedPaperwork: completed
        }
      },
      progress: {
        name: plateName,
        status: allCompleted ? 'timer' : 'error'
      },
      nextProgress: 'Certification',
      nextPage: {
        name: 'Certification'
      }
    }
    doUpdate?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Appointment - Paperwork Process</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item container direction='column' spacing={1}>
        <Grid item>
          <Accordion
            icon={getIcon(checkEmail)}
            title={<span style={{ display: 'flex', alignItems: 'center' }}>1. Check Email</span>}
          >
            Please check your Assurance email account for an email from UnitedHealthCare. It will
            show as:
            <br />
            <div>
              <div>
                <strong>From:</strong> UHC_MedicareSolutionsContracting@sircon.com
              </div>
              <div>
                <strong>Subject:</strong> Contracting information from Assurance IQ
              </div>
            </div>
            <br />
            In order to create your Producer Express account, click the green “Get Started” button.
            <br />
            <Image
              style={{ width: '80%' }}
              alt='click-get-started-button'
              src='/images/carriers/medicare/united_health_care/appointment/get_started.jpg'
            />
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={checkEmail || false}
                onChange={value => setCheckEmail(value)}
                label='I Received a Registration Email'
              />
            </Grid>
          </Accordion>
        </Grid>
        <Grid item>
          <Accordion
            icon={getIcon(logged)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                2. Login to Producer Express or Create an Account
              </span>
            }
          >
            <div>
              After clicking “Get Started” you will be brought to the Producer Express portal. Click
              “Get My Password”
              <Image
                style={{ marginTop: '0.5rem' }}
                alt='Click Get My Password'
                src='/images/carriers/medicare/united_health_care/appointment/get_my_password.jpg'
              />
            </div>
            <div>
              A temporary password will be sent to your Assurance email account. Retrieve your
              password and enter it in order to proceed to the appointment paperwork.
              <Image
                style={{ marginTop: '0.5rem' }}
                alt='Enter temprorary password received from email sent'
                src='/images/carriers/medicare/united_health_care/appointment/enter_emailed_temporary_password.jpg'
              />
            </div>
            <div>
              Then, you will be prompted to create a new password. Remember to write it down
              afterwards!
              <Image
                style={{ marginTop: '0.5rem' }}
                alt='create new password'
                src='/images/carriers/medicare/united_health_care/appointment/create_new_password.jpg'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={logged || false}
                onChange={value => setLogged(value)}
                label="I've Logged Into Producer Express"
              />
            </Grid>
          </Accordion>
        </Grid>
        <Grid item>
          <Accordion
            icon={getIcon(completed)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                3. Complete and Submit Paperwork
              </span>
            }
          >
            <div>
              Complete all sections of the application, including:
              <ul>
                <li>Interview Questions</li>
                <li>Signatures</li>
                <li>Send</li>
                <li>Print (optional)</li>
              </ul>
              <Image
                style={{ marginTop: '0.5rem' }}
                alt='complete all remaining sections'
                src='/images/carriers/medicare/united_health_care/appointment/complete_sections.jpg'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={completed || false}
                onChange={value => setCompleted(value)}
                label='I Completed and Submitted All Required Paperwork'
              />
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container item justify='flex-end'>
        <Button onClick={handleNext}>Next: Certification</Button>
      </Grid>
    </Grid>
  )
}

export default Appointment
