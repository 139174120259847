import Appointment from './appointment'
import CanceledByAgent from '../../commons/flow/plates/canceled_by_agent'
import Cancelled from '../../commons/flow/plates/cancelled'
import DataSync from '../../commons/flow/plates/data_sync'
import Eligibility from './eligibility'

export const PlateLabelMap = {
  Eligibility: 'Check Eligibility',
  Appointment: 'Complete Paperwork',
  DataSync: 'Sync Data',
  CanceledByAgent: 'Canceled by Agent',
  Cancelled: 'Cancelled',
  Exception: 'Exception'
}

export const PlateComponentMap = {
  Eligibility: Eligibility,
  Appointment: Appointment,
  DataSync: DataSync,
  CanceledByAgent,
  Cancelled
}
