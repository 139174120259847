import React from 'react'

import { Carrier, LOI } from 'store/carriers'
import SmartFlow from 'screens/carrier_appointments/commons/flow'
import { WorkflowStatusApiResponse } from 'screens/carrier_appointments/commons/flow/base'

import { PlateComponentMap, PlateLabelMap } from './plate_map'

interface Props {
  planYear: number
}

const UhoHealthFlow = (props: Props) => {
  const { planYear } = props

  const shouldShowCancelDropdown = (statusResponse: WorkflowStatusApiResponse) => {
    return (
      statusResponse.currentProgress === 'Eligibility' ||
      (statusResponse.currentProgress === 'Invitation' &&
        !['InvitationSent', 'EmailInviteSent'].includes(statusResponse.pageName))
    )
  }

  return (
    <SmartFlow
      planYear={planYear}
      loi={LOI.Health}
      carrierName={Carrier.UnitedHealthOne}
      plateComponentMap={PlateComponentMap}
      plateLabelMap={PlateLabelMap}
      shouldShowCancelDropdown={shouldShowCancelDropdown}
    />
  )
}

export default UhoHealthFlow
