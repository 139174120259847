import React from 'react'
import { useForm } from 'react-hook-form'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import SuccessBox from 'components/box/success_box'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const NotAppointed = (props: Props) => {
  const { doNext } = props

  const form = useForm<{
    canLogInToPortal: string | null
  }>({
    defaultValues: {
      canLogInToPortal: null
    }
  })

  const { watch, errors, handleSubmit, control } = form
  const formValues = watch()

  const nextInvitation = () => {
    const data = {
      canLogInToPortal: formValues.canLogInToPortal
    }
    doNext?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Eligibility - Not Currently Appointed with Humana</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        We just pulled the information from the National Insurance Producer Registry.
      </Grid>
      <Grid item>
        <SuccessBox>
          <strong>Current Status:</strong> You are currently NOT appointed with Humana which means
          you qualify to continue with Assurance IQ as your new upline.
        </SuccessBox>
      </Grid>
      <Grid container item justify='flex-end'>
        <Button onClick={handleSubmit(nextInvitation)}>Next: Invitation</Button>
      </Grid>
    </Grid>
  )
}

export default NotAppointed
