import React, { useEffect } from 'react'

import AhipStatus from 'screens/certification/ahip/legacy'
import Box from 'components/box'
import { CertificateProviders } from 'screens/certification/certification.d'
import Certification from 'screens/certification'
import { DD_ENV } from 'utils/environment-variables'
import { Loading } from '@assuranceiq/react-components'
import LogRocket from 'logrocket'
import TabList, { Item } from 'components/tab_list'
import { ViewMode } from 'store/login_user_info'
import { useStoreState } from 'store'

import CarrierAppointments from '../../carrier_appointments'
import ErrorsAndOmissions from '../../errors_and_omissions'
import RecertificationChecklist from '../../carrier_appointments/commons/recertification_checklist'
import SmartFlow from '../../carrier_appointments/utils/smart_flow'
import StateLicenses from '../../state_licenses'
import { useAgentLoader } from './hooks'

const W2_CALL_CENTER_VALUE = 'assurance'

const AgentPage = () => {
  const userInfo = useStoreState(state => state.userInfo)
  const isMasquerading = useStoreState(state => state.loginUserInfo.isMasquerading)
  const isW2Agent = userInfo.callCenter?.toLowerCase() === W2_CALL_CENTER_VALUE

  const loading = useAgentLoader()

  useEffect(() => {
    if (loading) {
      return
    }
    const intercomSettings = {
      app_id: (window as any).INTERCOM_APP_ID,
      name: userInfo.firstName + ' ' + userInfo.lastName,
      email: userInfo.email,
      user_hash: userInfo.intercomUserHash,
      createdAt: Math.floor(Date.now() / 1000),
      hide_default_launcher: false
    }

    ;(window as any).Intercom('boot', intercomSettings)
    ;(window as any).logrocket = LogRocket
    LogRocket.init('nccnr2/appointment-manager')
    LogRocket.identify(userInfo.assuranceAgentId, {
      name: userInfo.firstName + ' ' + userInfo.lastName,
      email: userInfo.email,
      subscriptionType: 'pro'
    })
    return () => {
      ;(LogRocket as any).uninstall()
    }
  }, [loading])

  let tabItems: Item[] = [
    {
      key: 'carrier_appointments',
      label: 'Carrier Contracting',
      link: '/carrier_appointments',
      content: <CarrierAppointments />,
      items: [
        {
          link: '/:carrier/:loi/:planYear/recertification',
          content: <RecertificationChecklist {...({} as any)} />
        },
        {
          link: '/:carrier/:loi/smartflow/:planYear',
          content: <SmartFlow {...({} as any)} />
        },
        {
          link: '/:carrier/:loi/smartflow',
          content: <SmartFlow {...({} as any)} />
        }
      ]
    },
    {
      key: 'state_licenses',
      label: 'State Licenses',
      link: '/state_licenses',
      content: <StateLicenses />
    }
  ]

  if (!isW2Agent) {
    tabItems.push({
      key: 'errors_and_omissions',
      label: (
        <Box display='flex' alignItems='center' spacing='0.5rem' component='span'>
          <span>Errors & Omissions</span>
        </Box>
      ),
      link: '/errors_and_omissions',
      content: <ErrorsAndOmissions />
    })
  }

  const use_legacy = false // DD_ENV === 'production'
  if (use_legacy) {
    if (userInfo.loi.includes('med_advantage')) {
      tabItems.push({
        key: 'ahip',
        label: (
          <Box display='flex' alignItems='center' spacing='0.5rem' component='span'>
            <span>AHIP</span>
          </Box>
        ),
        link: '/ahip',
        content: <AhipStatus />
      })
    }
  } else {
    if (userInfo.loi.includes('med_advantage')) {
      const ahip = CertificateProviders.ahip
      tabItems.push({
        key: ahip,
        label: (
          <Box display='flex' alignItems='center' spacing='0.5rem' component='span'>
            <span>{ahip}</span>
          </Box>
        ),
        link: '/ahip',
        content: <Certification provider={ahip} />
      })
    }

    if (userInfo.loi.includes('health')) {
      const ffm = CertificateProviders.ffm
      tabItems.push({
        key: ffm,
        label: (
          <Box display='flex' alignItems='center' spacing='0.5rem' component='span'>
            <span>{ffm}</span>
          </Box>
        ),
        link: '/ffm',
        content: <Certification provider={ffm} />
      })
    }
  }
  return (
    <div>
      {loading ? (
        <Loading fullscreen fullscreenTitle={'Pulling data...'} />
      ) : (
        <TabList base='/' viewMode={ViewMode.Agent} items={tabItems} />
      )}
    </div>
  )
}

export default AgentPage
