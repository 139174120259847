import React, { useEffect, useState } from 'react'

import { AppointmentTerminatedIcon, RoundCheckedIcon } from 'components/icons'
import Box from 'components/box'
import { InternalAppointmentDetail } from 'store/carrier_appointments'
import { LightTooltip } from 'components/tooltip'
import Table, { Column } from 'components/table'
import { useStoreState } from 'store'

import { AppointmentsTooltip } from './tooltips'
import Header from './header'

enum AlignTypes {
  left = 'left',
  center = 'center',
  right = 'right'
}
interface Props {
  carrierName: string
  loi: string
  planYear?: number
}

interface AppointedStates {
  id: string
  companyName: string
  isDisabled?: boolean
  disabledReason?: string
  state: string
  status: string
  loaName: string
  appointmentType: string
  MA: boolean
  PDP: boolean
  DSNP: boolean
  CSNP: boolean
}

const ViewDetails = (props: Props) => {
  const { carrierName, loi } = props

  const currentYear = new Date().getFullYear()
  const planYear = props.planYear || currentYear

  const appointmentsForPlanYear = useStoreState(state =>
    state.carrierAppointments.items.find(
      row =>
        row.planYear === planYear && row.carrier.name === carrierName && row.carrier.loi === loi
    )
  )

  const licenses: InternalAppointmentDetail[] =
    appointmentsForPlanYear?.internalAppointmentDetails || []

  const appointedLicenses = licenses.filter(row => row.status === 'APPOINTED')

  const justInTimeAppointments = appointmentsForPlanYear
    ? appointmentsForPlanYear.justInTimeStateDetails.filter(row => row.status === 'ACTIVE')
    : []

  const niprAppointments: AppointedStates[] = appointedLicenses.map(app => ({
    id: app.id,
    isDisabled: app.isInternallyDisabled,
    disabledReason: app.disabledReason,
    companyName: app.companyName,
    state: app.state,
    status: app.status,
    loaName: app.loaName,
    appointmentType: 'NIPR Record',
    MA: app.carrierTag.some(tag => tag.name === 'Medicare Advantage'),
    PDP: app.carrierTag.some(tag => tag.name === 'Plan D'),
    DSNP: app.carrierTag.some(tag => tag.name === 'Dual Special Needs Plan'),
    CSNP: app.carrierTag.some(tag => tag.name === 'Chronic Special Needs Plan')
  }))

  const jitAppointments: AppointedStates[] = justInTimeAppointments.map(jit => ({
    id: jit.id,
    companyName: appointmentsForPlanYear?.carrier.name || '',
    state: jit.state,
    status: jit.status,
    loaName: '-',
    appointmentType: 'Just In Time',
    MA: jit.carrierTag.some(tag => tag.name === 'Medicare Advantage'),
    PDP: jit.carrierTag.some(tag => tag.name === 'Plan D'),
    DSNP: jit.carrierTag.some(tag => tag.name === 'Dual Special Needs Plan'),
    CSNP: jit.carrierTag.some(tag => tag.name === 'Chronic Special Needs Plan')
  }))

  const appointedStates: AppointedStates[] = [...niprAppointments, ...jitAppointments]

  let columnsItems: Column<AppointedStates>[] = [
    {
      title: 'State',
      field: 'state',
      sortable: true,
      align: AlignTypes.left,
      render: row => (
        <Box display='flex' alignItems='center' justifyContent='space-between' spacing='0.5rem'>
          <span>{row.state}</span>{' '}
          {row.isDisabled && (
            <LightTooltip
              placement='top-start'
              title={
                <div>
                  <div style={{ fontWeight: 600 }}>Appointment Terminated</div>
                  <div style={{ marginTop: '1rem' }}>
                    <strong>{row.disabledReason}</strong>
                  </div>
                </div>
              }
            >
              <div style={{ display: 'flex' }}>
                <AppointmentTerminatedIcon />
              </div>
            </LightTooltip>
          )}
        </Box>
      )
    },
    {
      title: 'Company Name',
      field: 'companyName',
      sortable: true,
      align: AlignTypes.left,
      render: row => <span>{row.companyName}</span>
    }
  ]

  if (loi === 'MEDICARE') {
    columnsItems.push({
      title: 'Line of Authority',
      field: 'loaName',
      sortable: false,
      align: AlignTypes.center,
      render: row => <span>{row.loaName}</span>
    })
  }

  columnsItems.push({
    title: 'Appointment Type',
    field: 'appointmentType',
    sortable: true,
    align: AlignTypes.left,
    render: row => <span>{row.appointmentType}</span>
  })

  if (loi === 'MEDICARE') {
    columnsItems.push(
      {
        title: 'Medicare Advantage',
        field: 'MA',
        sortable: false,
        align: AlignTypes.center,
        render: row =>
          row.MA && (
            <Box display='flex' alignItems='center' spacing='1rem' flexDirection='column'>
              <RoundCheckedIcon />
            </Box>
          )
      },
      {
        title: 'Prescription Drugs Plan (PDP)',
        field: 'PDP',
        sortable: false,
        align: AlignTypes.center,
        render: row =>
          row.PDP && (
            <Box display='flex' alignItems='center' spacing='1rem' flexDirection='column'>
              <RoundCheckedIcon />
            </Box>
          )
      },
      {
        title: 'Chronic Special Needs Plan (CSNP)',
        field: 'CSNP',
        sortable: false,
        align: AlignTypes.center,
        render: row =>
          row.CSNP && (
            <Box display='flex' alignItems='center' spacing='1rem' flexDirection='column'>
              <RoundCheckedIcon />
            </Box>
          )
      },
      {
        title: 'Dual Special Needs Plan (DSNP)',
        field: 'DSNP',
        sortable: false,
        align: AlignTypes.center,
        render: row =>
          row.DSNP && (
            <Box display='flex' alignItems='center' spacing='1rem' flexDirection='column'>
              <RoundCheckedIcon />
            </Box>
          )
      }
    )
  }

  return (
    <div>
      <Header carrierName={carrierName} loi={loi} currentYear={currentYear} planYear={planYear} />
      {appointedStates.length > 0 && (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '1.5rem',
              marginTop: '1.5rem'
            }}
          >
            Appointed States
            <AppointmentsTooltip />
          </div>
          <Table
            sort={{
              field: 'state'
            }}
            rowStyleSelector={row => ({
              color: row.isDisabled ? 'grey' : void 0
            })}
            style={{ marginTop: '1rem' }}
            rowKeySelector={row => row.id}
            columns={columnsItems}
            data={appointedStates}
          />
        </>
      )}
    </div>
  )
}

export default ViewDetails
