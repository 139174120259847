import React, { useState } from 'react'

import Box from 'components/box'
import Button from 'components/button'
import { Loading } from 'components/loading'
import { RoundCheckedIcon } from 'components/icons'
import { useSnackbar } from 'utils/snackbar'

import HelloSignRenderer from './hello_sign_renderer'
import customFetch from '../../../../query/rest/custom_fetch'

interface Props {
  signatureRequestId: string
  onSuccess: () => void
  completedHelloSign: boolean
}

const HelloSignLauncher = (props: Props) => {
  const { signatureRequestId, onSuccess, completedHelloSign } = props

  const setSnackbar = useSnackbar()

  const [signingUrl, setSigningUrl] = useState<string>('')

  const [showSignature, setShowSignature] = useState(false)

  const fetchSigningUrl = () => {
    customFetch({ url: `/api/hellosign/${signatureRequestId}/signing_url` })
      .then(d => d.json())
      .then(d => setSigningUrl(d.signing_url))
      .catch(async e => {
        let signed = false
        try {
          const resp = await e?.json()
          signed = resp?.errorMessage?.includes('409 error: This request has already been signed')
        } catch (e) {
          console.error(e)
        }
        if (signed) {
          onSuccess()
        } else {
          setSnackbar({ message: 'Error generating signing url', severity: 'error' })
        }
        setShowSignature(false)
      })
  }

  const loadSignature = () => {
    setShowSignature(true)
    fetchSigningUrl()
  }

  if (completedHelloSign) {
    return (
      <Box color='success.main' display='flex' alignItems='center'>
        <RoundCheckedIcon />
        &nbsp;You have completed appointment paperwork.
      </Box>
    )
  }

  return (
    <div>
      <Button onClick={loadSignature}>Launch HelloSign</Button>
      {showSignature && !signingUrl && (
        <Box display='inline-flex' ml={1}>
          <Loading /> Generating signature form
        </Box>
      )}
      {showSignature && signingUrl && (
        <HelloSignRenderer
          signatureRequestId={signatureRequestId}
          signingUrl={signingUrl}
          onSuccess={onSuccess}
          onClose={() => {
            setShowSignature(false)
            setSigningUrl('')
          }}
        />
      )}
    </div>
  )
}

export default HelloSignLauncher
