import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Link, Typography } from '@material-ui/core'

import Box from 'components/box'
import Button from 'components/button'
import CarrierDropdown from 'components/carrier_dropdown'
import { ChangeEvent } from 'components/input'
import { Loading } from 'components/loading'
import Pagination from 'components/pagination'
import { S3_BUCKET_URL } from 'utils/environment-variables'
import Select from 'components/select'
import Table, { SortConfig } from 'components/table'
import _ from 'lodash'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { useMutation, useQuery } from '@apollo/client'

import PresignedUrlLink from '../../../components/presigned_url_link/presigned_url_link'
import STATUS_FILE_MUTATION from './graphql/mutation_upload_status_file.gql'
import STATUS_FILE_QUERY from './graphql/query_status_files.gql'
import SupportedLoi from '../../loi_data'
import { getCarrierIconPath } from '../../carrier_appointments/carrier_data'

import styles from './status_file_upload.module.scss'

const ErrorNotice = styled.div`
  position: fixed;
  z-index: 10;
  left: 50%;
  margin-left: -255px;
  width: 510px;
  text-align: center;
  padding: 16px;
  background: #f8d7da;
  border: 1px solid #eaabb1;
  color: #930103;
  border-radius: 4px;
`

const InfoNotice = styled.div`
  position: fixed;
  z-index: 10;
  left: 50%;
  margin-left: -255px;
  width: 510px;
  text-align: center;
  padding: 16px;
  background: #dff8ed;
  border: 1px solid #b1ebd2;
  color: #006830;
  border-radius: 4px;
`

const PAGE_LIMIT = 30

const getSortBy = (sortConfig?: SortConfig) => {
  if (!sortConfig) {
    return sortConfig
  }

  const TableColumnToSqlField = {
    uploader: 'EMAIL',
    uploadDate: 'UPLOAD_DATE',
    carrier: 'CARRIER'
  }

  return {
    field: TableColumnToSqlField[sortConfig.field],
    direction: sortConfig.desc ? 'DESC' : 'ASC'
  }
}

const StatusFileUpload = () => {
  const [errorNotice, setErrorNotice] = useState<string | null>(null)
  const [infoNotice, setInfoNotice] = useState<string | null>(null)
  const [totalCount, setTotalCount] = useState(0)
  const [statusFiles, setStatusFiles] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedLoi, setSelectedLoi] = useState('med_advantage')

  const totalPages = Math.ceil(totalCount / PAGE_LIMIT)

  const [sortConfig, setSortConfig] = useState<SortConfig | undefined>({
    field: 'uploadDate',
    desc: true
  })

  const query = useQuery(STATUS_FILE_QUERY, {
    variables: {
      first: PAGE_LIMIT,
      offset: 0,
      sortBy: getSortBy(sortConfig)
    },
    onCompleted: data => {
      setStatusFiles(_.get(data, 'statusFiles.edges'))
      setTotalCount(_.get(data, 'statusFiles.totalCount'))
    }
  })

  const { loading } = query

  const [statusFileCreate, { loading: updating, data: fileUploadData }] = useMutation(
    STATUS_FILE_MUTATION,
    {
      onCompleted: data => {
        const errors = data.statusFileCreate.adminToolErrors
        const success = !errors || errors.length === 0
        const file = data.statusFileCreate.carrierStatusFile
        if (success) {
          showNotice(
            `File ${file.statusFile} uploaded successfully for ${file.carrier.name}`,
            setInfoNotice
          )
        } else {
          showNotice('Something went wrong with upload', setErrorNotice)
        }
      },
      onError: error => {
        showNotice(`Something went wrong with upload: ${error}`, setErrorNotice)
      }
    }
  )

  const fileInput = useRef<HTMLInputElement | null>(null)

  const { setValue, handleSubmit, watch, register, trigger } = useForm<Record<string, any>>({
    defaultValues: {
      fileSelected: false,
      statusFile: '',
      carrier: null
    }
  })

  const fetchPage = (page: number) => {
    query
      .fetchMore({
        variables: {
          offset: (page - 1) * PAGE_LIMIT,
          limit: PAGE_LIMIT
        }
      })
      .then(
        response => {
          const { data } = response
          setStatusFiles(_.get(data, 'statusFiles.edges'))
          setTotalCount(_.get(data, 'statusFiles.totalCount'))
        },
        () => void 0
      )
      .catch(err => {
        alert('Error occurred')
        console.log(err)
      })
  }

  useEffect(() => {
    fetchPage(currentPage)
  }, [currentPage])

  useEffect(() => {
    const FORM_FIELDS = {
      statusFile: { required: true },
      fileSelected: { required: false },
      carrier: { required: true }
    }
    for (const [field, rule] of Object.entries(FORM_FIELDS)) {
      register(field, rule)
    }
  }, [register])

  const formValues = watch()

  const submit = formValues => {
    const input = {
      carrier: formValues.carrier,
      statusFile: fileInput.current!.files![0]
    }

    statusFileCreate({
      variables: {
        input
      }
    }).then(_ => {
      query.refetch().then(_ => {})
    })
  }

  const handleDropdownChange = (event: ChangeEvent) => {
    const field = 'carrier'
    const value = event
    setValue(field as any, value)
    trigger(field)
  }

  const handleLoiChange = (loi: string) => {
    setSelectedLoi(loi)
    setValue('carrier', null)
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files![0]) {
      setValue('statusFile', e.target.files![0].name)
      setValue('fileSelected', true)
    }
  }

  return (
    <div className={styles.root}>
      {errorNotice && <ErrorNotice>{errorNotice}</ErrorNotice>}
      {infoNotice && <InfoNotice>{infoNotice}</InfoNotice>}
      <div className={styles.header}>
        <Typography variant='h6' component='h2'>
          <strong>Status File Upload</strong>
        </Typography>
      </div>
      <form>
        <div className={styles.uploadStep}>
          <div className={styles.step}>1. Upload Status File</div>
          <input
            ref={fileInput}
            type='file'
            accept='.xlsx'
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <Button
            color='secondary'
            onClick={() => {
              fileInput.current!.click()
            }}
          >
            Select File
          </Button>
          <span style={{ marginLeft: '10px', fontWeight: 600 }}>{formValues.statusFile}</span>
        </div>
        <div className={styles.uploadStep}>
          <div className={styles.step}>2. Line of Insurance</div>
          <Select
            style={{ minWidth: '200px', marginTop: '0.25rem' }}
            selected={selectedLoi}
            options={SupportedLoi}
            keySelector={loi => loi.value}
            labelSelector={loi => _.capitalize(loi.label)}
            logoSelector={loi => loi.icon}
            onChange={handleLoiChange}
          />
        </div>
        <div className={styles.uploadStep}>
          <div className={styles.step}>3. Select a Carrier</div>
          <CarrierDropdown
            selected={formValues.carrier ? formValues.carrier : 'carrier'}
            onChange={handleDropdownChange}
            loi={selectedLoi}
          />
        </div>
        <div className={styles.uploadStep}>
          <div className={styles.step}>&nbsp;</div>
          <Button
            disabled={!formValues.fileSelected || !formValues.carrier}
            onClick={handleSubmit(submit)}
          >
            Submit
          </Button>
        </div>
      </form>
      {loading && (
        <>
          <br />
          <Loading />
        </>
      )}
      <Table
        sort={sortConfig}
        style={{ marginTop: '2rem' }}
        rowKeySelector={row => row.id}
        onHeaderClick={(_, cfg) => {
          setSortConfig(cfg)
        }}
        columns={[
          {
            title: 'Upload Date',
            field: 'uploadDate',
            sortable: true,
            comparator: (rowA, rowB) =>
              new Date(rowA.uploadDate).getTime() - new Date(rowB.uploadDate).getTime()
          },
          {
            title: 'File Name',
            field: 'fileName',
            render: row => {
              const bucket_name = S3_BUCKET_URL.replace('https://', '').split('.')[0]
              return <PresignedUrlLink url={bucket_name + '/' + row.filePath} />
            }
          },
          {
            title: 'LOI',
            field: 'loi',
            render: row => {
              const selectedLoi = SupportedLoi.find(l => l.label === row.loi)
              return (
                <Box key={row.loi} display='flex' alignItems='center' spacing={'0.5rem'}>
                  {selectedLoi && <img src={selectedLoi.icon} alt='logo' />}
                  <span>{_.capitalize(selectedLoi?.label || row.loi)}</span>
                </Box>
              )
            }
          },
          {
            title: 'Carrier',
            field: 'carrier',
            sortable: true,
            render: row => (
              <img
                src={getCarrierIconPath(row.carrier, row.loi)}
                style={{ height: '1.5rem' }}
                alt={`${row.carrier} logo`}
              />
            )
          },
          {
            title: 'Uploader',
            field: 'uploader',
            sortable: true
          }
        ]}
        data={edgesToRows(statusFiles)}
      />
      <Pagination
        style={{
          marginTop: '0.25rem'
        }}
        count={totalPages}
        page={currentPage}
        onChange={(event, page) => {
          setCurrentPage(page)
        }}
      />
    </div>
  )
}

function edgesToRows(edges) {
  return _.map(edges, function (edge) {
    const uploader: string = edge.node.uploader ? `${edge.node.uploader.email}` : 'unknown'
    return {
      id: edge.node.id,
      uploadDate: dayjs(edge.node.createdAt).format('MM-DD-YYYY HH:mm'),
      filePath: edge.node.statusFile,
      // eslint-disable-next-line no-useless-escape
      fileName: edge.node.statusFile.replace(/^.*[\\\/]/, ''),
      carrier: edge.node.carrier.name,
      loi: edge.node.carrier.loi,
      uploader: uploader
    }
  })
}

function showNotice(msg, setNoticeFunction) {
  setNoticeFunction(msg)

  setTimeout(() => {
    setNoticeFunction(null)
  }, 6000)
}

export default StatusFileUpload
