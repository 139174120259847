import React from 'react'

import { Link } from '@material-ui/core'

import Accordion from '../../commons/checklist_accordion'
import InstructionsWrapper from '../../commons/instructions_wrapper'

interface Props {
  carrierName: string
  loi: string
}

const Checklist = (props: Props) => {
  return (
    <InstructionsWrapper {...props}>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Request for an Appointment Invitation
          </span>
        }
      >
        Let us know you want an invitation to get started with appointment by filling out the{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360050014612-How-Do-I-Get-Appointed-With-Ambetter-to-Offer-ACA-Plans-'
        >
          Ambetter Invitation Request Form
        </Link>
        .
        <br />
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            2. Complete Appointment Process
          </span>
        }
      >
        <strong>Instructions:</strong>
        <br />
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360050014612-How-Do-I-Get-Appointed-With-Ambetter-to-Offer-ACA-Plans-'
        >
          Assurance Help: How do I get appointed with Ambetter?
        </Link>
        <br />
        <br />
        When Ambetter is ready to onboard you for their plans you will get a welcome email just like
        screenshot below: (You only have 5 days to complete it so make sure you start process ASAP)
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>3. Check Appointment Status</span>
        }
      >
        Your Affordable Care Act (ACA) appointment status is in one place.{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://join.assurance.com/agent/aca_appointments'
        >
          Check my ACA appointment statuses
        </Link>
        .
      </Accordion>
    </InstructionsWrapper>
  )
}

export default Checklist
