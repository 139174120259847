import React, { useEffect, useState } from 'react'

import { Grid, Link } from '@material-ui/core'

import Button from 'components/button'
import { CertificateProviders } from 'screens/certification/certification.d'
import Note from 'components/box/note'
import { useStoreState } from 'store'

import { FfmWorkflowActions } from '../../ffm.d'
import { updateWorkflowDetails } from '../../api'

interface CompleteCertificationProps {
  year: number
  getWorkflowUpdates: () => Promise<void>
}

const CompleteCertification = (props: CompleteCertificationProps) => {
  const { year, getWorkflowUpdates } = props
  const [checked, setChecked] = useState(false)
  const userInfo = useStoreState(state => state.userInfo)

  const handleCompleteCertification = async () => {
    await updateWorkflowDetails({
      year: year,
      userInfoId: userInfo.id,
      action: FfmWorkflowActions.SYNC_DATA,
      certificateProvider: CertificateProviders.ffm,
      authToken: localStorage.getItem('authToken')
    })
    getWorkflowUpdates()
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Grid
      container
      direction='column'
      alignItems='flex-start'
      spacing={2}
      style={{ margin: '0 26% 0 26%' }}
    >
      <Grid item>
        <h1 style={{ fontSize: '24px', paddingBottom: '12px' }}>Certification</h1>
      </Grid>
      <Grid item>
        Step 1: Log in to the{' '}
        <Link href='https://portal.cms.gov/portal' target='_blank' rel='noreferrer'>
          CMS Enterprise Portal.
        </Link>
      </Grid>
      <Grid item>
        Step 2: From the <strong>My Portal</strong> page, click <strong>Add Application</strong>.
      </Grid>
      <Grid item>
        Step 3: Select{' '}
        <strong>Federally Facilitated Marketplace (FFM)/Request for MLMS Training Access.</strong>
      </Grid>
      <Grid item>
        Step 4: Select the <strong>Agent Broker Training Access</strong> role.
      </Grid>
      <Grid item>
        Step 5: Complete identity proofing. You will be prompted to log out when this is complete.
      </Grid>
      <Grid item>
        Step 6: Click on the <strong>Marketplace Training - Agent Broker</strong> tile to display
        all available links and select the <strong>MLMS Training</strong> link to complete your MLMS
        Profile as prompted. Be sure to select the <strong>Agent Broker</strong> role.
      </Grid>
      <Grid item>
        Step 7: Click on the <strong>Marketplace Training - Agent Broker</strong> tile to display
        all available links and select the <strong>MLMS Training</strong> link to complete training.
      </Grid>
      <Grid item>Step 8: Execute the Applicable Agreement(s) on the MLMS.</Grid>
      <Grid item>Step 9: Confirm registration status and print certificate.</Grid>
      <Note>
        For detailed instructions on completing the certification, please see the{' '}
        <strong>
          <Link
            href='https://help.assurance.com/hc/en-us/articles/8694904290964-How-to-get-FFM-certified-for-2023-for-Returning-Agents-'
            target='_blank'
            rel='noreferrer'
          >
            step-by-step guide.
          </Link>
        </strong>
      </Note>
      <Grid item>
        When you have completed your certification, come back to this page and check the box below,
        then click <strong>Sync Data.</strong>
      </Grid>
      <Grid item>
        <input type='checkbox' checked={checked} onChange={() => setChecked(!checked)} />
        <span> I completed my FFM Certification</span>
      </Grid>
      <Grid container direction='column' alignItems='flex-end' spacing={4}>
        <Grid style={{ alignItems: 'flex-end', marginBottom: '3%' }} item>
          <Button
            onClick={handleCompleteCertification}
            disabled={!checked}
            startIcon={
              <>
                <img
                  style={{ width: '24px' }}
                  alt='link-icon'
                  src='/images/certification/ffm/link.png'
                />
              </>
            }
          >
            Sync Data
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CompleteCertification
