import React from 'react'

import { Box, Button, Grid, Link } from '@material-ui/core'

import Image from 'components/image'
import InfoBox from 'components/box/info_box'
import Note from 'components/box/note'
import SuccessBox from 'components/box/success_box'
import { carrierFullName } from 'store/carriers'
import { get_yy_mm_dd_hh_mm } from 'utils/utils'

import { PlateProps } from '../../../commons/plate'
import TimeToComplete from '../../../commons/time_to_complete'

const TrainingIncomplte = (props: PlateProps) => {
  const { carrierName, pageData, dateStarted, timeToComplete, dateLastSynced } = props

  const lastSynced = dateLastSynced ? new Date(dateLastSynced) : undefined
  const syncDate = lastSynced ? get_yy_mm_dd_hh_mm(lastSynced) : 'Unknown'
  const carrier = carrierFullName(carrierName)
  const appointed_states = pageData.appointed_states ? pageData.appointed_states : 'Unknown'

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Mandatory Assurance Training</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={dateStarted} timeToComplete={timeToComplete} />
      </Grid>
      <Grid item>
        {pageData.background_check_status === 'complete' ? (
          <SuccessBox>
            <strong>Congratulations!</strong> You're now appointed in the following states with{' '}
            {carrier}: {appointed_states}.
          </SuccessBox>
        ) : (
          <InfoBox>
            <strong>
              Status of Review by {carrier} : {pageData.background_check_status}
            </strong>
            <br />
            Your background check is in review by the {carrier} Team.{' '}
            <em>Last Updated on {syncDate}</em>
            <br />
            <br />
            It takes about 14 days for the carrier to review your background and paperwork. If it
            has been longer than that, please reach out to to the Assurance contracting team at{' '}
            <Link target='_blank' href={'mailto:lifecarrierappointments@assurance.com'}>
              lifecarrierappointments@assurance.com
            </Link>{' '}
            and they will inquire on your behalf.
          </InfoBox>
        )}
      </Grid>
      <Grid item>
        In order to sell {carrier} products on the platform, you need to complete the mandatory
        product training on Assurance University that was assigned to you during your onboarding
        process. This training is designed to help you gain a deeper understanding of {carrier}{' '}
        products as you prepare to help shoppers on the platform.
        <br />
        <br />
        <strong>
          You will not be able to sell {carrier} products until the Assurance Training is complete.
        </strong>{' '}
        Please ensure that you complete the training and get ready-to-sell. You are almost there!
      </Grid>
      <Grid item style={{ paddingLeft: '2rem' }}>
        1. Log in to Axonify, Assurance's Learning Management System, by clicking the button below.
        <Box mt={2}>
          <Button
            variant='contained'
            href='https://aiq.axonify.com/training'
            target='_blank'
            style={{ backgroundColor: '#FFF', width: '25rem' }}
          >
            Axonify Training Link
          </Button>
        </Box>
        <br />
        2. You should have been assigned the following modules:{' '}
        <strong>
          Lumico Life Protect Now Term (PNT), Lumico Replacement Life Insurance Policies
        </strong>{' '}
        on your Training Path. Click on the path and complete all the modules. Once you complete the
        training, this step will be checked automatically and you will be able to move on to the
        next step.
        <Image
          style={{ marginTop: '1rem' }}
          alt='example appointment email'
          src='/images/carriers/life/lumico/smartflow/axonify.png'
        />
        <br />
        If you do not see the training path assigned to you and this step is not marked completed,
        please reach out to the contracting team at
        <Link target='_blank' href={'mailto:lifecarrierappointments@assurance.com'}>
          lifecarrierappointments@assurance.com
        </Link>{' '}
        to further assist you.
      </Grid>
      <Grid item>
        <Note>
          Note: It may take up to one hour for systems to sync your completion status once you
          complete it. Please give it sometime to refresh before you reach out to the contracting
          team.
        </Note>
      </Grid>
    </Grid>
  )
}

export default TrainingIncomplte
