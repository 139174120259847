import React, { useState } from 'react'

import { Divider, Link, Typography } from '@material-ui/core'

import Box from 'components/box'
import Button from 'components/button'
import DateComponent from 'components/date'
import {
  InfoIcon,
  InvalidIcon,
  RefreshIcon,
  ResidentIcon,
  RoundCheckedIcon
} from 'components/icons'
import { LicenseData, LicenseStatus } from 'store/state_license'
import { LightTooltip } from 'components/tooltip'
import MultiSelect from 'components/multi_select'
import Select from 'components/select'
import ShowTutorialBox from 'components/show_tutorial_box'
import Table from 'components/table'
import Tutorial from 'components/tutorial'
import US_STATES from 'utils/us_states'
import { get_yy_mm_dd_hh_mm } from 'utils/utils'
import styled from 'styled-components'
import { useStoreState } from 'store'

import { ALL, ExpiryStatus, filterLicenses, getExpiryStatus } from './utils'
import Chart from './chart'
import GeneralLicensingInfo from './general_licensing_info'
import { getSteps } from './tutorial_steps'

const Filters = styled.div`
  display: flex;
  > * + * {
    margin-left: 24px;
  }
`

const Filter = styled.div`
  > * + * {
    margin-top: 8px;
  }
  > *:first-child {
    font-weight: 600;
    display: flex;
  }
`

const RefreshButton = styled(Button)`
  padding: 0.2rem;
`

const LicenseTable = styled(Table)`
  td:first-child {
    width: 50px;
  }
`

const SubTable = styled(Table)`
  th {
    background: none;
  }

  table {
    border: none;
  }
`

const SubTableWrapper = styled.div`
  h3 {
    font-size: 24px;
    font-weight: 400;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 4rem;
  }
  box-shadow: inset 0px 2px 2px rgba(51, 51, 51, 0.16), inset 0px 2px 4px rgba(51, 51, 51, 0.16),
    inset 0px 1px 8px rgba(51, 51, 51, 0.16);
`

enum Eligibility {
  Medicare = 'medicareEligibility',
  Life = 'lifeEligibility',
  Variable = 'variableEligibility',
  Casualty = 'casualtyEligibility',
  Property = 'propertyEligibility',
  Health = 'healthEligibility'
}

const DefaultFilters = {
  eligibility: Object.values(Eligibility),
  states: Object.keys(US_STATES)
}

const StateLicenses = () => {
  const [filters, setFilters] = useState<Record<string, any>>(DefaultFilters)
  const userInfo = useStoreState(state => state.userInfo)

  const [lastUpdated] = useState<string>(get_yy_mm_dd_hh_mm(new Date()))

  const stateLicenseModel = useStoreState(state => state.stateLicenses)
  const [showTutorial, setShowTutorial] = useState<boolean>(false)

  const updateFilter = (key: string, value: any) => {
    setFilters({
      ...filters,
      [key]: value
    })
  }

  const filteredLicenseData = filterLicenses(stateLicenseModel.items, filters)

  return (
    <Box display='flex' flexDirection='column' spacing='1.5rem' id='state_licenses'>
      <Box spacing='1rem'>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h2' style={{ fontSize: '1.5rem', fontWeight: 400 }}>
            State Licenses
          </Typography>
          <Box component='span' color='#666'>
            Last Updated: {lastUpdated}
          </Box>
        </Box>
        <ShowTutorialBox onShowTutorial={() => setShowTutorial(true)}>
          The information below is pulled based on the National Insurance Producer Registry. Use
          information on this page to manage your own business by seeing your licensure coverage
          across the United States of America. Helpful Assurance Helpdesk article:{' '}
          <Link
            href='https://help.assurance.com/hc/en-us/articles/360044077932-What-are-the-Best-States-to-Get-Licensed-In-'
            target='_blank'
            rel='noreferrer'
          >
            <b>What are the Best States to Get Licensed In?</b>
          </Link>
        </ShowTutorialBox>
      </Box>
      <Chart
        id='state_licenses_chart'
        style={{
          height: '26vw',
          overflow: 'hidden'
        }}
        data={filteredLicenseData}
        tooltipProps={{
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          npn: userInfo.npn
        }}
        stateSelector={row => {
          return row.state
        }}
        colorSelector={row => {
          const expiryStatus = getExpiryStatus(row)
          if (expiryStatus === ExpiryStatus.Expired) {
            return 'red'
          }
          if (row.isResidentState) {
            return '#582C83'
          }
          if (row.status === LicenseStatus.Active) {
            return 'green'
          }
          return 'grey'
        }}
        tooltipRender={tooltipProps => {
          const residentLicense = stateLicenseModel.items.find(
            row => row.status === LicenseStatus.Active && row.isResidentState
          )
          return (
            <GeneralLicensingInfo
              agentFirstName={tooltipProps.firstName}
              agentLastName={tooltipProps.lastName}
              npn={tooltipProps.npn}
              license={residentLicense || ({} as LicenseData)}
            />
          )
        }}
      />
      <Divider />
      <Filters id='state_licenses_filter'>
        {/*
          <Filter>
            <div>Active State Licenses</div>
            <div style={{ fontSize: '1.2rem' }}>
              {stateLicenseModel.activeLicenses}/{stateLicenseModel.totalLicenses}
            </div>
          </Filter>
          */}
        <Filter>
          <div>
            Insurance Type
            <LightTooltip
              placement='top-start'
              title='Your Lines of Authority (LOA) determines which type of insurance you can sell on the Assurance platform.'
            >
              <div style={{ marginLeft: '0.25rem' }}>
                <InfoIcon style={{ width: '1.25rem', display: 'block' }} />
              </div>
            </LightTooltip>
          </div>
          <MultiSelect
            selected={filters.eligibility}
            onChange={selected => updateFilter('eligibility', selected)}
            labelSelector={option => option.label}
            titleSelector={selected => selected?.map(row => row.label).join(', ')}
            keySelector={option => option.value}
            options={[
              { label: 'Casualty', value: Eligibility.Casualty },
              { label: 'Health', value: Eligibility.Health },
              { label: 'Life', value: Eligibility.Life },
              { label: 'Medicare', value: Eligibility.Medicare },
              { label: 'Property', value: Eligibility.Property },
              { label: 'Variable', value: Eligibility.Variable }
            ]}
          />
        </Filter>
        <Filter>
          <div>Filter by State or Territory</div>
          <MultiSelect
            style={{ minWidth: '200px', textAlign: 'left' }}
            selected={filters.states}
            titleSelector={states => (
              <span
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  display: 'inline-block'
                }}
              >
                {states?.length}/{Object.keys(US_STATES).length} selected
              </span>
            )}
            options={Object.keys(US_STATES)
              .sort((a, b) => a.localeCompare(b))
              .map(state => ({ label: state, value: state }))}
            keySelector={option => option.value}
            labelSelector={option => option.label}
            onChange={states => {
              updateFilter('states', states)
            }}
          />
        </Filter>
        <Filter>
          <div>License Status</div>
          <Select
            selected={filters.status || ALL}
            onChange={option => updateFilter('status', option)}
            labelSelector={option => option.label}
            keySelector={option => option.value}
            options={[
              { label: 'All', value: ALL },
              { label: 'Active', value: LicenseStatus.Active },
              { label: 'Inactive', value: LicenseStatus.Inactive }
            ]}
          />
        </Filter>
        <Filter>
          <div>Filter by Expiry Date</div>
          <Box display='flex' alignItems='center' spacing='0.625rem'>
            <DateComponent
              value={filters.startDate || ''}
              onChange={date => updateFilter('startDate', date)}
            />
            <span>-</span>
            <DateComponent
              value={filters.endDate || ''}
              onChange={date => updateFilter('endDate', date)}
            />
          </Box>
        </Filter>
        <Filter>
          <div>&nbsp;</div>
          <RefreshButton
            style={{ marginTop: '-0.0625px' }}
            variant='text'
            onClick={() => {
              setFilters(DefaultFilters)
            }}
          >
            <RefreshIcon style={{ marginRight: '0.375rem' }} />
            <u>Reset Filters</u>
          </RefreshButton>
        </Filter>
      </Filters>
      <LicenseTable
        id='state_licenses_table'
        title='State licenses'
        rowKeySelector={row => row.id}
        rowExpandable
        sort={{
          field: 'state'
        }}
        expandContentRenderer={row => (
          <SubTableWrapper>
            <h3>{US_STATES[row.state]} Line Of Authority</h3>
            <SubTable
              rowKeySelector={row => row.id}
              columns={[
                { title: '', field: 'blank' },
                { title: 'Line of Authority', field: 'loaName', style: { width: '33.33%' } },
                /*
                {
                  title: 'Status',
                  field: 'status',
                  style: { width: '33.33%' },
                  render: row => (row.status === LicenseStatus.Active ? 'Active' : 'Inactive')
                },
                */
                { title: 'Code', field: 'loaCode' }
              ]}
              data={row.loas}
            />
          </SubTableWrapper>
        )}
        columns={[
          {
            title: 'State',
            field: 'state',
            sortable: true,
            render: row => {
              return (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {row.isResidentState && (
                    <>
                      <ResidentIcon />
                      &nbsp;
                    </>
                  )}
                  {US_STATES[row.state]} ({row.state})
                </span>
              )
            }
          },
          /*
          {
            title: 'Status',
            field: 'status',
            sortable: true,
            render: row => {
              switch (row.status) {
                case LicenseStatus.Active:
                  return 'Active'
                case LicenseStatus.Inactive:
                  return 'Inactive'
                default:
                  return row.status
              }
            }
          },
          */
          { title: 'License Number', field: 'number', sortable: true },
          { title: 'Date issued', field: 'dateIssued', sortable: true },
          {
            title: 'Expiry Date',
            field: 'dateExpire',
            sortable: true,
            render: row => {
              let icon: React.ReactNode
              if (row.status === LicenseStatus.Active) {
                const expiryStatus = getExpiryStatus(row)
                if (expiryStatus === ExpiryStatus.Expired) {
                  icon = <InvalidIcon />
                } else {
                  icon = <RoundCheckedIcon />
                }
              } else if (row.status === LicenseStatus.Inactive) {
                icon = <InvalidIcon />
              }
              return (
                <Box display='flex' alignItems='center' spacing={'0.25rem'}>
                  {icon}
                  <span>{row.dateExpire || 'Not Applicable'}</span>
                </Box>
              )
            }
          },
          {
            title: 'License Class Name',
            field: 'className',
            sortable: true
          },
          {
            title: 'License Class Code',
            field: 'classCode',
            sortable: true
          }
        ]}
        data={filteredLicenseData}
      />
      {showTutorial && (
        <Tutorial tutorialSteps={getSteps()} onFinish={() => setShowTutorial(false)} />
      )}
    </Box>
  )
}

export default StateLicenses
