import React from 'react'

import { Eligibility as BaseEligibility } from '../../../commons/flow/plates/eligibility'
import { EligibilityProps } from '../../../commons/flow/plates/eligibility'
import NotAppointed from '../../../commons/flow/plates/eligibility/not_appointed'
import OutOfFlow from '../../../commons/flow/plates/eligibility/out_of_flow'
import PreviouslyAppointed from '../../../commons/flow/plates/eligibility/previously_appointed'

const Eligibility = (props: EligibilityProps) => {
  const { pageName } = props

  switch (pageName) {
    case 'NotAppointedWithCarrier':
      return <NotAppointed {...props} nextLabel='Complete Paperwork' />

    case 'OutOfSystemWorkFlowInProgress':
      return (
        <OutOfFlow
          {...props}
          instructionsLink={
            'https://help.assurance.com/hc/en-us/articles/360047045852-How-Do-I-Get-Appointed-With-Oscar-to-Offer-ACA-Plans-'
          }
        />
      )

    case 'PromptForCarrierStatus':
      return <PreviouslyAppointed {...props} phoneNumber={'1-855-672-2788'} />

    default:
      return <BaseEligibility {...props} />
  }
}

export default Eligibility
