import React, { useState } from 'react'

import { Grid, Link } from '@material-ui/core'

import Button from 'components/button'
import CheckboxButton from 'components/checkbox_button'
import Image from 'components/image'
import Note from 'components/box/note'
import { RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'

import Accordion from '../commons/checklist_accordion'
import { PlateProps } from '../commons/plate'
import TimeToComplete from '../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    userInput?: {
      aetnaCertification?: boolean
      courseCertification?: boolean
      validationCompleted?: boolean
    }
  }
}

const getIcon = (checked?: boolean) => {
  const props = {
    style: {
      width: '1.25rem',
      height: '1.25rem'
    }
  }
  return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
}

const Certification = (props: Props) => {
  const { plateName, doUpdate, pageData = {}, doNext } = props

  const { userInput = {} } = pageData

  const [aetnaCertification, setAetnaCertification] = useState(userInput.aetnaCertification)
  const [courseCertification, setCourseCertification] = useState(userInput.courseCertification)
  const [validationCompleted, setValidationCompleted] = useState(userInput.validationCompleted)

  const handleBack = () => {
    const allCompleted = aetnaCertification && courseCertification && validationCompleted

    const data = {
      nextProgress: 'Appointment',
      nextPage: {
        name: 'AppointmentPaperWork'
      },
      progress: {
        //required
        name: plateName,
        status: allCompleted ? 'timer' : 'error'
      },
      pageData: {
        userInput: {
          aetnaCertification,
          courseCertification,
          validationCompleted
        }
      }
    }
    doUpdate?.('back', data)
  }

  const handleNext = () => {
    const allCompleted = aetnaCertification && courseCertification && validationCompleted
    const data = {
      pageData: {
        userInput: {
          aetnaCertification,
          courseCertification,
          validationCompleted
        }
      },
      nextProgress: 'AetnaCarrierReview',
      progress: {
        name: plateName,
        status: allCompleted ? 'timer' : 'error'
      },
      nextPage: {
        name: 'AetnaCarrierReview'
      }
    }
    doUpdate?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Note>
          Note: You can start the certification process before your Appointment step is complete.
          Don't wait. Keep going!
        </Note>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Certification</h2>
      </Grid>
      <Grid item container direction='column' spacing={1}>
        <Grid item>
          <Accordion
            icon={getIcon(aetnaCertification)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                1. Login or Create a New Aetna Certification Account
              </span>
            }
          >
            Login or create a new account on the Aetna Certification website:
            <br />
            <Link
              target='_blank'
              rel='noreferrer'
              href='https://aetna.cmpsystem.com/ext/ahip/login.php'
            >
              https://aetna.cmpsystem.com/ext/ahip/login.php
            </Link>
            <br />
            <Image
              alt='certification-portal-login'
              style={{ width: '100%' }}
              src='/images/carriers/medicare/aetna/certification/1. Certification Portal Log in.jpg'
            />
            <br />
            <br />
            Leave the fields blank. Go straight to 'Continue Producer Registration'.
            <Image
              alt='register-for-new-account'
              style={{ width: '100%' }}
              src='/images/carriers/medicare/aetna/certification/2. Register for new account.jpg'
            />
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={aetnaCertification || false}
                onChange={value => setAetnaCertification(value)}
                label='Mark Completed'
              />
            </Grid>
          </Accordion>
        </Grid>
        <Grid item>
          <Accordion
            icon={getIcon(courseCertification)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                2. Complete Certification Courses
              </span>
            }
          >
            In the same portal, complete the Aetna Annual Certification & Other Required Aetna
            Medicare Training. If you experience difficulty with the online training, contact
            Aetna's team at 1-866-714-9301.
            <br />
            <Link
              target='_blank'
              rel='noreferrer'
              href='https://aetna.cmpsystem.com/ext/ahip/login.php'
            >
              Launch Aetna's Learning System Portal.
            </Link>
            <br />
            <div>
              <strong>Courses to complete:</strong>
              <ul style={{ paddingInlineStart: '1.25rem' }}>
                <li>AHIP Medicare Training</li>
                <li>Individual Core Medicare Requirements</li>
                <li>Individual Core Certification - Mastery Test</li>
                <li>Aetna Individual Medicare Attestations</li>
                <li>Aetna Individual MA/MAPD</li>
                <li>Individual Medicare Producer Certification Survey</li>
              </ul>
            </div>
            <div>
              Once you are done with all your required courses,{' '}
              <strong>mark this task with “I Completed My Certification Courses” below</strong>.
            </div>
            <br />
            <Image
              alt='take-courses'
              style={{ width: '100%' }}
              src='/images/carriers/medicare/aetna/certification/3. Take the courses.jpg'
            />
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={courseCertification || false}
                onChange={value => setCourseCertification(value)}
                label='I Completed My Certification Courses'
              />
            </Grid>
          </Accordion>
        </Grid>
        <Grid item>
          <Accordion
            icon={getIcon(validationCompleted)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                3. Validate Certifications are Complete
              </span>
            }
          >
            Once you are done, head into the 'Transcript' section of the portal. Download a PDF of
            your certification.
            <br />
            <br />
            <Image
              alt='get-transcript'
              style={{ width: '100%' }}
              src='/images/carriers/medicare/aetna/certification/4. Get Your Transcript.jpg'
            />
            <br />
            <Image
              alt='pdf-of-transcript'
              style={{ width: '100%' }}
              src='/images/carriers/medicare/aetna/certification/PDF of the Transcript.jpg'
            />
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={validationCompleted || false}
                onChange={value => setValidationCompleted(value)}
                label='I Downloaded My Certificate'
              />
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container item justify='flex-end' spacing={1}>
        <Grid item>
          <Button color='secondary' onClick={handleBack}>
            Back: Appointment
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={handleNext}>Next: Aetna Carrier Review</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Certification
