import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Grid } from '@material-ui/core'

import Button from 'components/button'

import LumicoAgentInfoGetApi from './lumico-agent-info-get-api'

const AdminUtils = () => {
  return (
    <Switch>
      <Route path='/admin/utils/lumico-agent-info-get-api'>
        <LumicoAgentInfoGetApi />
      </Route>
      <Route path='/admin/utils'>
        <div style={{ padding: 20 }}>
          <Button href='/admin/utils/lumico-agent-info-get-api'>Lumico Agent-info Get API</Button>
        </div>
      </Route>
    </Switch>
  )
}

export default AdminUtils
