import React from 'react'

import { Grid, Link } from '@material-ui/core'

import Button from 'components/button'
import Image from 'components/image'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const InvitationSent = (props: Props) => {
  const { doNext } = props

  const handleNext = () => {
    doNext?.('next')
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Invitation Sent</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        Invitation has been sent to your email. Please check your {''}
        <Link target='_blank' rel='noreferrer' href='https://outlook.office365.com/mail/'>
          <strong>@Assurance.com</strong>
        </Link>
        {''} email account for:
        <br />
        <br />
        <div>
          <div>
            <strong>From:</strong> DoNotRespond@humana.com
          </div>
          <div>
            <strong>Subject:</strong> Your Attention is Required - Humana Background Authorization
          </div>
          <div>
            <strong>Recruiter Name:</strong> ASSURANCE IQ
          </div>
        </div>
        <br />
        Once you receive the invitation, come back to this page and see what to do next.
      </Grid>
      <Grid container item>
        <Image
          alt='reference-image'
          src='/images/carriers/medicare/humana/appointment/Humana Invitation Example.jpg'
          style={{ width: '90%' }}
        />
      </Grid>
      <Grid container item justify='flex-end' spacing={1}>
        <Grid item>
          <Button onClick={handleNext}>Next: Complete Background Check</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InvitationSent
