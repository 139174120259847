import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import Header from 'components/header'
import { Loading } from '@assuranceiq/react-components'
import { PermissionGroup, hasAdminPermissionGroup } from 'store/base_user_info'
import { ViewMode, getViewModeFromSession } from 'store/login_user_info'
import { isIframeView } from 'utils/utils'
import { useQuery } from '@apollo/client'
import { useStoreActions, useStoreState } from 'store'

import Admin from '../admin'
import AgentPage from './agent_page/agent_page'
import { DISABLE_MASQUERADE_HEADER } from '../../commons/constants'
import ME_ONLY from './graphql/me_only.gql'

import styles from './styles.module.scss'

const Main = () => {
  const location = useLocation()

  const loginUser = useStoreState(state => state.loginUserInfo)
  const setViewMode = useStoreActions(state => state.loginUserInfo.setViewMode)
  const clearMasquerade = useStoreActions(state => state.loginUserInfo.clearMasquerade)

  const updateUserInfo = useStoreActions(actions => actions.userInfo.update)
  const updateLoginUserInfo = useStoreActions(actions => actions.loginUserInfo.update)

  const userQuery = useQuery(ME_ONLY, {
    fetchPolicy: 'standby',
    context: {
      headers: {
        [DISABLE_MASQUERADE_HEADER]: true
      }
    }
  })

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    userQuery
      .refetch()
      .then(response => {
        const data = response.data.me
        setLoading(false)
        updateLoginUserInfo({
          permissionGroups: data.permissionGroups as PermissionGroup[]
        })

        const adminUser = hasAdminPermissionGroup(data.permissionGroups)

        if (!loginUser.viewModeInitialized) {
          const viewMode = adminUser
            ? getViewModeFromSession() === ViewMode.Agent || loginUser.isMasquerading
              ? ViewMode.Agent
              : ViewMode.Admin
            : ViewMode.Agent

          setViewMode(viewMode)
        }

        if (loginUser.isMasquerading && !adminUser) {
          clearMasquerade()
          window.location.href = '/'
        }
      })
      .catch(e => {
        console.error(e)
      })
  }, [])

  useEffect(() => {
    if (loading) {
      return
    }

    if (!loginUser.isMasquerading) {
      updateUserInfo({
        profilePictureLink: loginUser.profilePictureLink
      })
    }
  }, [loginUser, loading])

  if (loginUser.viewModeInitialized) {
    if (
      loginUser.isAdmin &&
      loginUser.viewMode === ViewMode.Admin &&
      location.pathname.substr(0, 6) !== '/admin'
    ) {
      return <Redirect to='/admin' />
    }
  }

  const showMainPage = loginUser.viewModeInitialized

  return (
    <div className={styles.root}>
      {!isIframeView && <Header />}
      {loading || !showMainPage ? (
        <Loading fullscreen fullscreenTitle={'Pulling data...'} />
      ) : (
        <main>
          <Switch>
            <Route path='/admin'>
              <Admin />
            </Route>
            <Route path='/'>
              <AgentPage />
            </Route>
          </Switch>
        </main>
      )}
    </div>
  )
}

export default Main
