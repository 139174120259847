import React, { useEffect, useState } from 'react'

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

import Button from 'components/button'
import { Carrier, LOI } from 'store/carriers'
import Select from 'components/select'
import { UserInfo } from 'store/user_info'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { useSnackbar } from 'utils/snackbar'
import workflowFetch from 'query/rest/workflow_fetch'

const APPOINTMENT_REQUEST_HISTORY_QUERY = gql`
  query AppointmentRequestHistoryQuery($id: ID!) {
    carrierAppointmentRequest(id: $id) {
      id
      histories {
        id
        stage
      }
    }
  }
`
interface Props {
  carrier: Carrier
  loi: LOI
  appointmentRequestId: string
  agent: UserInfo
  open: boolean
  onCancel: () => void
}

const getStageOptionsForCarrier = (carrier: Carrier, loi: LOI, currentStage?: string) => {
  if (loi === LOI.Life) {
    switch (carrier) {
      case Carrier.MutualOfOmaha: {
        return [
          {
            label: 'Paperwork',
            stage: 'Paperwork'
          }
        ]
      }
      case Carrier.LGA: {
        return [
          {
            label: 'Paperwork',
            stage: 'Paperwork'
          }
        ]
      }
      case Carrier.Prudential: {
        return [
          {
            label: 'Paperwork',
            stage: 'Paperwork'
          }
        ]
      }
      case Carrier.Lumico: {
        const options = [
          {
            label: 'Paperwork',
            stage: 'Paperwork'
          }
        ]
        // TODO: Consider keeping the stage names in consts somewhere
        if (currentStage === 'CarrierReviewAndAIQTraining') {
          options.push({
            label: 'Data Sync',
            stage: 'DataSync'
          })
        }
        return options
      }
    }
  }

  if (loi !== LOI.Medicare) {
    return []
  }
  switch (carrier) {
    case Carrier.Anthem: {
      return [
        {
          label: 'Complete Certification',
          stage: 'Certification'
        },
        {
          label: 'Request Invitation',
          stage: 'Invitation'
        }
      ]
    }
    case Carrier.WellCare: {
      return [
        {
          label: 'Complete Certification',
          stage: 'Certification'
        },
        {
          label: 'Request Invitation',
          stage: 'Invitation'
        }
      ]
    }
    case Carrier.Humana: {
      return [
        {
          label: 'Complete Certification',
          stage: 'CompleteCertification'
        },
        {
          label: 'Request Invitation',
          stage: 'Invitation'
        }
      ]
    }
    case Carrier.UnitedHealthCare: {
      return [
        {
          label: 'Complete Certification',
          stage: 'Certification'
        },
        {
          label: 'Request Invitation',
          stage: 'Invitation'
        }
      ]
    }
    default:
      break
  }
  return []
}
function useAppointmentCurrentStage(appointmentRequestId: string) {
  const [currentStage, setCurrentStage] = useState<string | undefined>()

  const query = useQuery(APPOINTMENT_REQUEST_HISTORY_QUERY, {
    skip: false,
    notifyOnNetworkStatusChange: true,
    variables: {
      id: appointmentRequestId
    }
  })

  // TODO: Debounce this
  useEffect(() => {
    if (!currentStage) {
      query
        .fetchMore({})
        .then(response => {
          const { data } = response
          const lastHistory = data.carrierAppointmentRequest.histories[0]
          setCurrentStage(lastHistory.stage)
        })
        .catch(err => {
          console.error(err)
        })
    }
  }, [currentStage, query])

  return currentStage
}
const OverrideStageModal = (props: Props) => {
  const { carrier, appointmentRequestId, agent, loi, onCancel, open } = props

  const setSnackbar = useSnackbar()

  const [stage, setStage] = useState<string | undefined>()

  const [error, setError] = useState(false)

  const currentStage = useAppointmentCurrentStage(appointmentRequestId)

  const handleOverride = async () => {
    if (!stage) {
      setError(true)
      return
    }
    try {
      await workflowFetch({
        url: '/' + appointmentRequestId + '/override',
        data: {
          stageName: stage
        }
      })
      setSnackbar({
        message: 'Override successful for ' + agent.email,
        severity: 'success'
      })
      onCancel()
    } catch (e) {
      console.error(e)
      setSnackbar({
        message: 'Override to ' + stage + ' unsuccessful for ' + agent.email + ' for ' + carrier,
        severity: 'error'
      })
    }
  }

  return (
    <Dialog maxWidth='xl' open={open}>
      <DialogTitle>
        <strong>
          Override Appointment Stage for <em>{agent.email}</em>
        </strong>
      </DialogTitle>
      <DialogContent>
        <span style={{ color: error ? 'red' : void 0 }}>Please select the stage for {carrier}</span>
        <Select
          style={{ marginTop: '0.5rem' }}
          selected={stage || ''}
          onChange={stage => {
            setError(false)
            setStage(stage)
          }}
          keySelector={option => option.stage}
          labelSelector={option => option.label}
          options={getStageOptionsForCarrier(carrier, loi, currentStage)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={() => onCancel()}>
          <u>Cancel</u>
        </Button>
        <Button
          onClick={() => {
            handleOverride()
          }}
        >
          Override
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OverrideStageModal
