import React, { useState } from 'react'

import {
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  makeStyles
} from '@material-ui/core'

import Note from 'components/box/note'

import Button from '../../../../components/button'
import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

enum CarrierStatus {
  ReleaseLetter = 'releaseLetter',
  UnknownUpline = 'unknownUpline',
  NoReleaseLetter = 'noReleaseLetter'
}

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    carrierStatus: CarrierStatus
  }
}

const useStyles = makeStyles(theme => ({
  button: () => ({
    width: 'auto'
  })
}))

const PreviouslyAppointed = (props: Props) => {
  const { pageData, doNext } = props

  const [choice, setChoice] = useState<CarrierStatus | undefined>(pageData?.carrierStatus)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChoice(e.target.value as CarrierStatus)
  }

  const handleNext = () => {
    const data = {
      carrierStatus: choice
    }
    doNext?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>
          Eligibility - Previously Appointed with UnitedHealthCare
        </h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        We just pulled the information from the National Insurance Producer Registry (NIPR).
      </Grid>
      <Grid item>
        <Note>
          <strong>Current Status:</strong> You are appointed with UnitedHealthCare under an upline
          that is not Assurance IQ.
        </Note>
      </Grid>
      <Grid item>
        <strong>A release letter is required to proceed.</strong>
        <RadioGroup value={choice || ''} onChange={handleChange}>
          <FormControlLabel
            value={CarrierStatus.ReleaseLetter}
            control={<Radio color='primary' />}
            label='I have a Release letter from my previous agency'
          />
          <FormControlLabel
            value={CarrierStatus.NoReleaseLetter}
            control={<Radio color='primary' />}
            label='I do not have a Release letter from my previous agency'
          />
          <FormControlLabel
            value={CarrierStatus.UnknownUpline}
            control={<Radio color='primary' />}
            label='I don’t know who my upline is right now'
          />
        </RadioGroup>
      </Grid>
      <Divider style={{ margin: '1rem 0' }} />
      {choice === CarrierStatus.ReleaseLetter && (
        <>
          <Grid item>
            Great! In order to get appointed with UnitedHealthcare with Assurance, you will need to
            provide this release letter, so keep it accessible as you navigate through the rest of
            the process.
          </Grid>
          <Grid container item justify='flex-end'>
            <Button onClick={handleNext}>Next: Invitation</Button>
          </Grid>
        </>
      )}
      {choice === CarrierStatus.NoReleaseLetter && (
        <Grid item>
          You will need to obtain a Transfer Release Form from your upline agency to continue with
          appointments. The release letter must be on FMO letterhead and signed by the principal.
          <br />
          <br />
          When you obtain this, please click <strong>Cancel Appointment</strong> in the top right
          corner of this screen. You can start this <strong>Eligibility Check</strong> again. When
          you can proceed, you will be prompted to request an invitation from Assurance IQ.
        </Grid>
      )}
      {choice === CarrierStatus.UnknownUpline && (
        <Grid item>
          If you are unsure who your upline is, please call UnitedHealthcare (UHC) at
          1-888-381-8581. You will need to receive a release letter from your prior upline before
          proceeding.
          <br />
          <br />
          To check if your NIPR UHC appointment has been terminated, please by clicking{' '}
          <strong>Cancel Appointment</strong> in the top right corner of this screen. You can start
          this <strong>Eligibility Check</strong> again. When you can proceed, you will be prompted
          to request an invitation from Assurance IQ.
        </Grid>
      )}
    </Grid>
  )
}

export default PreviouslyAppointed
