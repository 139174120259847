import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import { CssBaseline } from '@material-ui/core'
import { StylesProvider, ThemeProvider } from '@material-ui/styles'

import { ApolloClient, ApolloProvider, InMemoryCache, from } from '@apollo/client'
import { DataDogRUMProvider } from '@assuranceiq/react-components'
import { Loading } from '@assuranceiq/react-components'
import { PostIframeURL } from 'components/PostIframeURL'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'

import {
  DD_APPLICATION_ID,
  DD_CLIENT_TOKEN,
  DD_ENV,
  DD_SAMPLE_RATE,
  DD_SERVICE,
  GRAPHQL_ENDPOINT
} from './utils/environment-variables'
import { DISABLE_MASQUERADE_HEADER, MASQUERADE_HEADER } from './commons/constants'
import SnackbarProvider from './utils/snackbar'
import theme from './styles/theme'
import useLogout from './utils/hooks/logout'
import { useStoreActions, useStoreState } from './store'

import './styles/global.scss'

export interface DefaultLayoutProps {
  children: React.ReactNode
}

export const App = function (props: DefaultLayoutProps) {
  const { children } = props

  const { isLoading, isAuthenticated, loginWithRedirect, getIdTokenClaims } = useAuth0()

  const logout = useLogout()

  const [idToken, setIdToken] = useState<string | null>(null)

  const urlParams = new URLSearchParams(window.location.search)

  const updateLoginUserInfo = useStoreActions(actions => actions.loginUserInfo.update)

  const loginUser = useStoreState(actions => actions.loginUserInfo)
  const setMasquerade = useStoreActions(actions => actions.loginUserInfo.setMasquerade)
  const clearMasquerade = useStoreActions(actions => actions.loginUserInfo.clearMasquerade)

  let masqueradeId = urlParams.get('agent_id') || loginUser.masqueradeId

  if (masqueradeId === '-1') {
    clearMasquerade()
    masqueradeId = null
  } else if (masqueradeId) {
    setMasquerade(masqueradeId)
  }

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (!isAuthenticated) {
      loginWithRedirect().then(() => {
        return
      })
    }
    const getUserMetadata = async () => {
      try {
        const idToken = await getIdTokenClaims()
        if (idToken) {
          setIdToken(idToken.__raw)
          localStorage.setItem('authToken', idToken.__raw)
          updateLoginUserInfo({
            loaded: true,
            email: idToken['email'],
            assuranceAgentId: idToken['sub'].split('|').slice(-1)[0],
            profilePictureLink: idToken['picture'],
            roles: idToken['https://assurance.com/roles']
          })
        }
      } catch (e) {
        console.error(e)
      }
    }
    getUserMetadata().then(() => {})
  }, [isLoading, isAuthenticated])

  if (isLoading || !isAuthenticated || !idToken || !loginUser.loaded) {
    return <Loading fullscreen={true} />
  }

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      )
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
    }

    const tokenExpired =
      graphQLErrors?.find(
        error =>
          error.message.toLowerCase() === 'signature has expired' ||
          error.extensions?.exception.code === 'JSONWebTokenExpired'
      ) != null

    if (tokenExpired) {
      logout({ returnTo: window.location.origin })
    }
  })

  const authLink = setContext((gql, { headers }) => {
    const { [DISABLE_MASQUERADE_HEADER]: disableMasquerade, ...customHeaders } = headers || {}

    if (!disableMasquerade && masqueradeId) {
      customHeaders[MASQUERADE_HEADER] = masqueradeId
    }

    return {
      headers: {
        ...customHeaders,
        authorization: `JWT ${idToken}`
      }
    }
  })

  const client = new ApolloClient({
    uri: GRAPHQL_ENDPOINT,
    link: from([errorLink, authLink, createUploadLink({ uri: GRAPHQL_ENDPOINT })]),
    cache: new InMemoryCache() as any,
    connectToDevTools: false
  })

  return (
    <DataDogRUMProvider
      applicationId={DD_APPLICATION_ID}
      clientToken={DD_CLIENT_TOKEN}
      service={DD_SERVICE}
      env={DD_ENV}
      sampleRate={DD_SAMPLE_RATE}
      trackInteractions={true}
    >
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <StylesProvider injectFirst>
            <SnackbarProvider>
              <Router hashType='noslash'>
                <PostIframeURL />
                {children}
              </Router>
            </SnackbarProvider>
          </StylesProvider>
        </ThemeProvider>
      </ApolloProvider>
    </DataDogRUMProvider>
  )
}
