import React, { useRef, useState } from 'react'

import styled from 'styled-components'

import BaseCard from './card_commons'
import CardCarrierTagsBackView from './card_carrier_tags_back_view'

const Face = styled.div`
  backface-visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

interface Props {
  children: React.ReactElement
  carrierTags: string[]
  footer?: React.ReactNode
}

const FlippableCard = (props: Props) => {
  const { footer, children, carrierTags } = props

  const [flipView, setFlipView] = useState(false)

  const ref = useRef<HTMLDivElement | null>(null)

  const updateRotation = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = ref.current!.getBoundingClientRect()
    const dx = (e.clientX - rect.left - rect.width / 2) / rect.width
    const dy = (e.clientY - rect.top - rect.height / 2) / rect.height
    const aspectRatio = rect.height / rect.width
    const scale = 10
    const newHoverTransform = [-dy * scale * aspectRatio, dx * scale]
    ref.current!.style.transform = `rotateX(${newHoverTransform[0]}deg) rotateY(${
      newHoverTransform[1] + (flipView ? 180 : 0)
    }deg)`
  }

  return (
    <div
      style={{
        width: '212px',
        height: '220px',
        display: 'inline-block',
        perspective: '50rem',
        position: 'relative'
      }}
    >
      <div
        ref={ref}
        style={{
          height: '100%',
          transformStyle: 'preserve-3d',
          transform: `rotateX(0deg) rotateY(${flipView ? 180 : 0}deg)`,
          transition: 'transform 0.5s'
        }}
        onMouseMove={updateRotation}
        onMouseEnter={updateRotation}
        onMouseLeave={e => {
          ref.current!.style.transform = `rotateX(0deg) rotateY(${flipView ? 180 : 0}deg)`
        }}
      >
        <Face
          onClick={e => {
            setFlipView(true)
          }}
          style={{
            transform: 'translateZ(0px)'
          }}
        >
          {React.cloneElement(children, { onSupportedProductsClick: () => setFlipView(true) })}
        </Face>
        <Face
          style={{
            transform: 'rotateY(180deg)'
          }}
        >
          <BaseCard>
            <CardCarrierTagsBackView
              footer={footer}
              carrierTags={carrierTags}
              onClose={() => setFlipView(false)}
            />
          </BaseCard>
        </Face>
      </div>
    </div>
  )
}

export default FlippableCard
