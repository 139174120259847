import React from 'react'

import { Grid } from '@material-ui/core'

import Warning from 'components/box/warning'

import { PlateProps } from '../../../commons/plate'
import TimeToComplete from '../../../commons/time_to_complete'

interface Props extends PlateProps {}

const AlignmentReview = (props: Props) => {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Alignment Review</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <Warning>
          <strong>Note:</strong> Humana is confirming if you are eligible to be contracted under
          Assurance IQ.
        </Warning>
      </Grid>
      <Grid item>
        No Action needed.
        <br />
        <br />
        If you've been stuck on this for over 10 days, please give Humana a call to inquire on your
        status. The Humana number is 1-800-309-3163.
        <br />
        <br />
        You can also contact us by using the ‘Need Help’ button on the bottom right corner of your
        screen to speak with an Onboarding Specialist.
      </Grid>
    </Grid>
  )
}

export default AlignmentReview
