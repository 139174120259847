import React from 'react'

import Accordion from 'components/accordion'
import ChecklistAccordion from 'screens/carrier_appointments/commons/checklist_accordion'
import { RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'
import Table from 'components/table'

import { CertificationInfoStatus, CompleteCertificationData } from '../../ahip.d'

const CompleteModulesAccordion = (props: CompleteCertificationData) => {
  const { modules } = props
  const getIcon = () => {
    const checked = modules?.status === CertificationInfoStatus.COMPLETED.valueOf()
    const props = {
      style: {
        width: '1.25rem',
        height: '1.25rem'
      }
    }
    return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
  }
  return (
    <ChecklistAccordion icon={getIcon()} title={<span>2. Complete learning modules</span>}>
      <Table
        columns={[
          {
            title: 'Module',
            field: 'module'
          },
          {
            title: 'Completion Date',
            field: 'completionDate'
          }
        ]}
        data={[
          {
            module: 'Medicare Fraud, Waste and Abuse',
            completionDate: modules?.fwa_date || <>Not Available</>
          },
          {
            module: 'General Compliance',
            completionDate: modules?.gc_date || <>Not Available</>
          },
          {
            module: 'Non-Discrimination',
            completionDate: modules?.nd_date || <>Not Available</>
          }
        ]}
      />
    </ChecklistAccordion>
  )
}

export default CompleteModulesAccordion
