import React from 'react'

import { Grid } from '@material-ui/core'

import Note from 'components/box/note'

import OscarChecklist from '../../oscar/checklist'
import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const Appointment = (props: Props) => {
  const { carrierName, loi } = props

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Appointment - Paperwork</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <OscarChecklist carrierName={carrierName} loi={loi} hideHeader />
      </Grid>
      <Grid item>
        <Note>
          <strong>Note:</strong> Once you submit your application for review, it can take 3-5
          business days for Oscar to update us with your appointment status. Please allow us this
          time to receive the confirmation and update your profile.
        </Note>
      </Grid>
    </Grid>
  )
}

export default Appointment
