import React from 'react'
import { Step } from 'react-joyride'

const getSteps = () => {
  const steps: Step[] = [
    {
      target: '#state_licenses_licensing_info',
      title: 'Understanding State Licensure Portal',
      content: (
        <div>
          <p>Welcome to your state licensure page.</p>
          <br />
          <p>
            We took the information from NIPR and displaying this to you in one central place so you
            can stay up-to-date with your state licenses.
          </p>
        </div>
      ),
      placement: 'center',
      disableBeacon: true,
      showProgress: true
    },
    {
      target: '#state_licenses_chart',
      title: '',
      content: (
        <div>
          <p>Hovering over the map will show you which state it is.</p>
          <br />
          <p>The different colors represent what that state status is.</p>
        </div>
      ),
      placement: 'top',
      disableBeacon: true
    },
    {
      target: '#state_licenses_licensing_info',
      title: '',
      content:
        'Know your resident license right off the bat when you go through carrier contracting.',
      placement: 'left',
      disableBeacon: true
    },
    {
      target: '#state_licenses_filter',
      title: '',
      content: 'Filter for what licenses you want to see.',
      placement: 'top',
      disableBeacon: true
    },
    {
      target: '#state_licenses_table',
      title: '',
      content: (
        <div>
          <p>All of your states are listed in the table.</p>
          <br />
          <p>Click on the drop down to see the state’s line of authority you have.</p>
          <br />
          <p>
            All of your state information is important for us to check your eligibility to go
            through carrier contracting.
          </p>
        </div>
      ),
      placement: 'top',
      disableBeacon: true
    }
  ]

  return steps
}

export { getSteps }
