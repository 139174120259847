import gql from 'graphql-tag'

const STATUS_FILE_MUTATION = gql`
  mutation ($input: CarrierStatusFileInput!) {
    statusFileCreate(input: $input) {
      carrierStatusFile {
        id
        createdAt
        updatedAt
        privateMetadata
        metadata
        statusFile
        carrier {
          id
          name
          avaliableState
          loi
          states
        }
        uploader {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`

export default STATUS_FILE_MUTATION
