import React from 'react'

import { Grid, Table } from '@material-ui/core'

import Button from 'components/button'
import Note from 'components/box/note'
import SuccessBox from 'components/box/success_box'

import AttemptsTable from './attempts'
import { CertificationFinalExamInfo } from '../../ahip.d'
import FinalExamTable from './final_exam'

interface Props {
  year: number
  page_data: CertificationFinalExamInfo
  isSummary?: boolean
}

const SyncData = (props: Props) => {
  const { year, isSummary, page_data } = props
  return (
    <Grid
      container
      direction='column'
      alignItems='stretch'
      style={{
        margin: '2% 26% 0 26%'
      }}
      spacing={3}
    >
      <Grid item style={{ width: '100%' }}>
        <SuccessBox>
          <strong>Good job!</strong> Your AHIP for {year} is complete!
        </SuccessBox>
      </Grid>
      <Grid item>
        <FinalExamTable page_data={page_data} />
      </Grid>
      <Grid item>
        <AttemptsTable page_data={page_data} />
      </Grid>
    </Grid>
  )
}

export default SyncData
