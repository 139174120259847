import React from 'react'

import { Grid } from '@material-ui/core'
import Link from '@material-ui/core/Link'

import Note from 'components/box/note'
import { useStoreState } from 'store'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const WrongLineOfAuthority = (props: Props) => {
  const residentState = useStoreState(state => state.stateLicenses.residentLicense)
  const requiredLineOfAuthority = 'LOA2'

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Ineligible due to Wrong Line of Authority</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        We just pulled the information from the National Insurance Producer Registry.
      </Grid>
      <Grid item>
        <Note>
          <strong>Current Status:</strong> Your Resident License is in {residentState?.state} and
          this state requires you to have a specific Line of Authority which you don't have yet.
          Please refer to the article on{' '}
          <Link
            rel='noreferrer'
            target='_blank'
            href={'https://help.assurance.com/hc/en-us/articles/4403298408468'}
          >
            What Lines of Authority (LOA) do I need to have in my states in order to sell Medicare?
          </Link>
        </Note>
      </Grid>
      <Grid item>
        Please head to the state’s website to purchase the appropriate Line of Authority. Once you
        do, allow a few days for NIPR to update.
        <br />
        <br />
        Then, try to <strong>Check Eligibility</strong> again by clicking on the{' '}
        <strong>Cancel Appointment</strong> at the top right hand corner of this screen.
      </Grid>
    </Grid>
  )
}

export default WrongLineOfAuthority
