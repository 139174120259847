import React from 'react'
import { Redirect } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'

import { Box } from '@material-ui/core'

import Message from 'components/message/Message'
import { Model } from 'store'
import TabList, { Item } from 'components/tab_list'
import { ViewMode } from 'store/login_user_info'
import { useStoreState } from 'easy-peasy'

import AdminUtils from './utils'
import AgentAppointmentRequests from './agent_appointment_requests'
import AgentDirectory from './agent_directory'
import AppointmentFileUpload from './appointment_file_upload'
import InvitationConfirmation from './invitation_confirmation'
import Reporting from './reporting'
import StateAdds from './state_adds'
import StatusFileUpload from './status_file_upload'

const Admin = () => {
  let tabItems: Item[] = [
    {
      key: 'agent_directory',
      label: 'Agent Directory',
      link: '/agent_directory',
      content: <AgentDirectory />
    },
    {
      key: 'agent_appointments',
      label: 'Agent Appointments',
      link: '/agent_appointments',
      content: <AgentAppointmentRequests />
    },
    {
      key: 'status_file_upload',
      label: 'Status File Upload',
      link: '/status_file_upload',
      content: <StatusFileUpload />
    },
    {
      key: 'appointment_file_upload',
      label: 'Appointment File Upload',
      link: '/appointment_file_upload',
      content: <AppointmentFileUpload />
    }
  ]

  const loginUser = useStoreState<Model>(state => state.loginUserInfo)

  const isAdmin = loginUser.isAdmin

  if (
    loginUser.hasPermissionGroup('ContractingSpecialist') ||
    loginUser.hasPermissionGroup('Superuser')
  ) {
    tabItems.push({
      key: 'invitation_confirmation',
      label: 'Invitation Confirmation',
      link: '/invitation_confirmation',
      content: <InvitationConfirmation />
    })
  }

  tabItems.push({
    key: 'reporting',
    label: 'Reporting',
    link: '/reporting',
    preserveView: true,
    content: <Reporting />
  })

  const enableStateAdd = true
  if (
    enableStateAdd &&
    (loginUser.hasPermissionGroup('ContractingSpecialist') ||
      loginUser.hasPermissionGroup('Superuser'))
  ) {
    tabItems.push({
      key: 'state_adds',
      label: 'State Adds',
      link: '/state_adds',
      content: <StateAdds />
    })
  }

  if (loginUser.isMasquerading) {
    return <Redirect url={'/'} />
  }

  if (!isAdmin) {
    return (
      <Box padding={3}>
        <Message type='warning'>
          Restricted page. You don't have necessary permissions to view the page.
        </Message>
      </Box>
    )
  }

  return (
    <div>
      <Switch>
        <Route path='/admin/utils'>
          <AdminUtils />
        </Route>
        <Route path='/admin'>
          <TabList
            base='/admin/'
            viewMode={ViewMode.Admin}
            defaultPath={tabItems[0].link}
            items={tabItems}
          />
        </Route>
      </Switch>
    </div>
  )
}

export default Admin
