import { Action, action } from 'easy-peasy'

export interface ErrorsAndOmissionsApiData {
  id: number | string
  carrierName: string
  certificate: string
  accountId: string
  policyNumber: number
  coverageAmountInCent: number
  perOccrAmountInCent: number
  startDate: string
  endDate: string
  year: number
}

export interface ErrorsAndOmissionsModel {
  items: ErrorsAndOmissionsApiData[]
  load: Action<ErrorsAndOmissionsModel, ErrorsAndOmissionsApiData[]>
  update: Action<ErrorsAndOmissionsModel, ErrorsAndOmissionsApiData>
  create: Action<ErrorsAndOmissionsModel, ErrorsAndOmissionsApiData>
}

export const errorsAndOmissionsModel: ErrorsAndOmissionsModel = {
  items: [] as ErrorsAndOmissionsApiData[],
  load: action((state, payload) => {
    state.items = payload
  }),
  update: action((state, payload) => {
    state.items = state.items.map(row => (row.id === payload.id ? payload : row))
  }),
  create: action((state, payload) => {
    state.items = [...state.items, payload]
  })
}
