import React from 'react'

import { Grid, GridProps, Link } from '@material-ui/core'

import { LinkIcon } from 'components/icons'

export const getAppointMoreStatesLabel = (carrierName: string, loi: string): React.ReactNode => {
  switch (carrierName) {
    case 'Humana':
    case 'WellCare':
      return <span>No Action Needed. Assurance Updates Weekly.</span>
    case 'Aetna':
      return <span>No Action. State Adds are done automatically by Aetna’s Team</span>
    case 'UHC':
      return (
        <span>
          Request for another Appointment Invitation from Assurance IQ{' '}
          <Link
            target='_blank'
            rel='noreferrer'
            href='https://help.assurance.com/hc/en-us/articles/360050850811-How-do-I-add-non-resident-states-for-Medicare-Advantage-Carriers-'
          >
            <LinkIcon style={{ width: '1rem' }} />
          </Link>
        </span>
      )
    case 'Anthem':
      return (
        <span>
          Request for another Appointment Invitation from Assurance IQ{' '}
          <Link
            target='_blank'
            rel='noreferrer'
            href={
              loi === 'MEDICARE'
                ? 'https://assuranceiq.formstack.com/forms/medicare_anthem_request_appointment_id'
                : 'https://assuranceiq.formstack.com/forms/health_anthem__new_state_appointment_ids'
            }
          >
            <LinkIcon style={{ width: '1rem' }} />
          </Link>
        </span>
      )

    default:
      return <span>Request for another Appointment Invitation from Assurance IQ</span>
  }
}

export const LabeledNode = (props: { label: React.ReactNode } & GridProps) => {
  const { label, children, ...rest } = props

  return (
    <Grid {...rest}>
      <strong>{label}</strong>
      <div style={{ marginTop: '0.5rem' }}>{children}</div>
    </Grid>
  )
}
