import React, { ReactElement, useEffect, useState } from 'react'

import { PlateProps } from '../../commons/plate'
import RequestDenied from './request_denied'

/*
Business wants to see the termination message defined in the RequestDenied component if
an agent shows up as terminated in RTS, regardless of the stage the agent is in.

This component wraps a stage component, and checks if request is denied. If it is, then
draws the RequestDenied screen. Otherwise, it draws the wrapped component.
*/
const withCancellationHandling = WrappedComponent => {
  return function ComponentWithCancellation({ cancelled, ...props }) {
    const { pageData } = props
    const requestDenied = pageData?.requestDenied
    const [returnedComponent, setReturnedComponent] = useState<ReactElement | null>(null)

    useEffect(() => {
      if (requestDenied) {
        setReturnedComponent(<RequestDenied {...(props as PlateProps)} />)
      } else {
        setReturnedComponent(<WrappedComponent {...props} />)
      }
    }, [pageData])

    return returnedComponent
  }
}

export default withCancellationHandling
