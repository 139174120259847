import React, { useEffect, useRef, useState } from 'react'

import { CloseRounded } from '@material-ui/icons'
import { Dialog, DialogContent, DialogTitle, Icon, IconButton } from '@material-ui/core'

import Box from 'components/box'
import { DD_ENV, HELLOSIGN_CLIENT_ID } from 'utils/environment-variables'
import HelloSign from 'hellosign-embedded'

interface Props {
  signatureRequestId: string
  signingUrl: string
  onSuccess: () => void
  onClose: () => void
}

const HelloSignRenderer = (props: Props) => {
  const { signatureRequestId, signingUrl, onSuccess, onClose } = props

  const [containerSetup, setContainerSetup] = useState(false)

  const ref = useRef<HTMLDivElement | null>(null)

  const signatureHandlerSetup = (container: HTMLElement, signingUrl: string) => {
    const helloSign = new HelloSign({
      clientId: HELLOSIGN_CLIENT_ID,
      allowCancel: true,
      skipDomainVerification: DD_ENV !== 'production',
      container: container
    })

    const handleOpen = (...args) => {
      console.log('Hellosign opened', args)
    }

    const signatureId = new URL(signingUrl).searchParams.get('signature_id')

    let signatureComplete = false
    let finishTriggered = false

    const handleSign = (data: { signatureId: string }) => {
      console.log('Hellosign signed', data, signatureRequestId)
      if (signatureId === data.signatureId) {
        // We don't want to trigger immediate success, because hellosign has another success page
        // that has close button
        signatureComplete = true
      }
    }

    const checkAndTriggerSignSuccess = () => {
      if (signatureComplete && !finishTriggered) {
        finishTriggered = true
        onSuccess()
      }
    }

    const handleClose = (...args) => {
      console.log('Hellosign closed', args)

      checkAndTriggerSignSuccess()
      onClose()
    }

    const subscribe = () => {
      console.log('creating subscriptions')
      helloSign.on('open', handleOpen)
      helloSign.on('sign', handleSign)
      helloSign.on('close', handleClose)
    }

    const unsubscribe = () => {
      console.log('canceling subscriptions')
      checkAndTriggerSignSuccess()
      helloSign.off('open', handleOpen)
      helloSign.off('sign', handleSign)
      helloSign.off('close', handleClose)
    }

    subscribe()

    helloSign.open(signingUrl)

    return () => {
      unsubscribe()
    }
  }

  useEffect(() => {
    if (!containerSetup) return
    return signatureHandlerSetup(ref.current!, signingUrl)
  }, [containerSetup])

  return (
    <Dialog open fullScreen fullWidth PaperProps={{ style: { minHeight: '90vh' } }} maxWidth='md'>
      <DialogTitle>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <span>Fill up the form below</span>
          <IconButton color='inherit' onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'stretch' }}>
        <div
          style={{ flexBasis: '100%' }}
          ref={node => {
            if (!node) {
              return
            }
            ref.current = node
            setContainerSetup(true)
          }}
        />
      </DialogContent>
    </Dialog>
  )
}

export default HelloSignRenderer
