import React, { useState } from 'react'

import { Grid, Link } from '@material-ui/core'

import Button from 'components/button'
import CheckboxButton from 'components/checkbox_button'
import Image from 'components/image'
import InfoBox from 'components/box/info_box'
import { WarningIcon } from 'components/icons'
import { carrierFullName } from 'store/carriers'

import { PlateProps } from '../../../commons/plate'
import TimeToComplete from '../../../commons/time_to_complete'

const redTextStyle = { color: '#ff4237' }
const warningIconStyle = {
  width: '1.25rem',
  marginRight: '0.375rem'
}

const BackgroundCheck = (props: PlateProps) => {
  const { carrierName, doNext, pageData = {} } = props
  const { userInput = {} } = pageData
  const [backgroundCheckcompleted, setBackgroundCheckcompleted] = useState(
    userInput.backgroundCheckcompleted
  )
  const [nextClicked, setNextClicked] = useState(false)

  const carrier = carrierFullName(carrierName)

  const handleNext = () => {
    setNextClicked(true)
    if (backgroundCheckcompleted) doNext?.('next')
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Background Check</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <InfoBox>
          {carrier} initiates a background check via Checkr within two business days after you have
          submitted your paperwork. Please continue checking your assurance email inbox. If you
          haven’t received an email to start background check within 5 days of submitting your
          paperwork, please email the contracting team at lifecarrierappointments@assurance.com
        </InfoBox>
      </Grid>
      <Grid item>
        Please check your Assurance email account for an email from Checkr on behalf of {carrier}.
        <br />
        <br />
        From:{' '}
        <Link target='_blank' href={'mailto:support@checkr.com'}>
          support@checkr.com
        </Link>
        <br />
        Subject: <strong>Lumico has asked Checkr to run your background check</strong>
        <br />
        <br />
        This is a request to run your background check on behalf of {carrier}. Click on{' '}
        <strong>"Start Background Check"</strong> and answer all the questions accurately.
        <br />
        <br />
        See below for an example of this email. If you do not see this email in your inbox, please
        check your spam/junk folder as well.
        <br />
        <br />
        Note: Please complete the background check as soon as you receive the email. The{' '}
        <strong>link expires in 30 days</strong> and delaying this step will delay the appointment
        process.
        <Image
          style={{ width: '70%', marginTop: '0.75rem' }}
          alt='example appointment email'
          src='/images/carriers/life/lumico/smartflow/background_check.png'
        />
        <br />
        {nextClicked && !backgroundCheckcompleted && (
          <Grid item container justifyContent='flex-end'>
            <Grid item>
              <WarningIcon style={warningIconStyle} />
            </Grid>
            <Grid item style={redTextStyle}>
              You must complete this action item
            </Grid>
          </Grid>
        )}
        <Grid container item justifyContent='flex-end'>
          <CheckboxButton
            checked={backgroundCheckcompleted || false}
            onChange={value => setBackgroundCheckcompleted(value)}
            label="I've Completed My Background Check"
          />
        </Grid>
        <br />
      </Grid>
      <Grid container item justifyContent='flex-end' alignItems='center' spacing={3}>
        {nextClicked && !backgroundCheckcompleted && (
          <Grid item>
            <Grid container>
              <Grid item>
                <WarningIcon style={warningIconStyle} />
              </Grid>
              <Grid item style={redTextStyle}>
                Action Items Incomplete
              </Grid>
            </Grid>
          </Grid>
        )}
        <Button onClick={handleNext}>Next: Carrier Review</Button>
      </Grid>
    </Grid>
  )
}

export default BackgroundCheck
