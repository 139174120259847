import React from 'react'

import { ASSURANCE_URL } from 'utils/environment-variables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { useAuth0 } from '@auth0/auth0-react'
import { useStoreState } from 'store'

import Box from '../box'
import Dropdown from '../dropdown'
import useLogout from '../../utils/hooks/logout'

const Main = styled.div`
  background: #ffffff;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 9px 0 #dedede;
  margin-bottom: 10px;
  padding: 24px 24px 34px 24px;
  > * + * {
    margin-left: 24px;
  }
`

const Menu = styled.div`
  display: flex;
  font-size: 16px;
  > * + * {
    margin-left: 16px;
  }
`

const MenuItem = styled.a`
  cursor: pointer;
  color: #777777;
  font-size: 14px;
  &:visited {
    color: #777777;
  }
  &:hover {
    font-weight: 500;
  }
  text-decoration: none;
`

const Gap = styled.div`
  flex: 1;
`

const CustomButton = styled(Dropdown)`
  cursor: pointer;
  background: none;
`

interface HeaderItem {
  label: string
  link: string
}

const Header = () => {
  const { isAuthenticated } = useAuth0()

  const logout = useLogout()

  const userInfo = useStoreState(state => state.userInfo)
  const loginUserInfo = useStoreState(state => state.loginUserInfo)
  const effectiveUser = loginUserInfo.isMasquerading ? userInfo : loginUserInfo

  let items: HeaderItem[] = [
    { label: 'Dashboard', link: `${ASSURANCE_URL}/agent` },
    { label: 'Lookup Lead', link: `${ASSURANCE_URL}/agent/lookup_lead` },
    {
      label: 'To-Do Lists',
      link: `${ASSURANCE_URL}/agent/customer_care/to_dos?filter_option=sent_to_carrier`
    },
    {
      label: 'Licensure',
      link: '/'
    },
    {
      label: 'Commissions',
      link: `https://commission.assurance.com/agents/${effectiveUser.assuranceAgentId}/policies/submitted`
    },
    { label: 'My Team', link: `${ASSURANCE_URL}/agent/members` },
    { label: 'Calls', link: `${ASSURANCE_URL}/agent/calls` },
    { label: 'Training Leads', link: `${ASSURANCE_URL}/agent/training_leads` }
  ]

  const profileUrl = `${ASSURANCE_URL}/agent/my_profile`

  let menuOptions = [
    { label: 'View profile', key: 'profile' },
    { label: 'Logout', key: 'logout' }
  ]

  if (loginUserInfo.isMasquerading) {
    menuOptions.push({ label: 'End Masquerade', key: 'masquerade' })
  }

  return (
    <Main>
      <a style={{ display: 'flex' }} href='/'>
        <img width={43.56} src='/images/assurance-logo.svg' alt='Assurance logo' />
      </a>
      <Menu>
        {items.map(item => (
          <MenuItem key={item.label} href={item.link}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
      <Gap />
      <Box display='flex' alignItems='center' spacing='0.5rem'>
        <Box display='flex' alignItems='center'>
          {loginUserInfo.email !== effectiveUser.email && (
            <span>
              <strong>{loginUserInfo.email} → &nbsp;</strong>
            </span>
          )}
          <span>{`${effectiveUser.email}`}</span>
        </Box>
        {isAuthenticated && (
          <CustomButton
            options={menuOptions}
            keySelector={opt => opt.key}
            labelSelector={option => option.label}
            menuAlign='right'
            titleSelector={_ =>
              userInfo.profilePictureLink ? (
                <img
                  style={{
                    width: '1.8rem',
                    borderRadius: '50%'
                  }}
                  src={userInfo.profilePictureLink}
                />
              ) : (
                <FontAwesomeIcon
                  style={{
                    fontSize: '1.8rem'
                  }}
                  icon={faUserCircle}
                />
              )
            }
            onChange={key => {
              if (key === 'logout') {
                logout({ returnTo: window.location.origin })
              } else if (key === 'profile') {
                window.location.href = profileUrl
              } else if (key === 'masquerade') {
                window.location.href = '/?agent_id=-1'
              }
            }}
          />
        )}
      </Box>
    </Main>
  )
}

export default Header
