import React, { useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'

import Box from 'components/box'
import Button from 'components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import PreviewCertificationTraining from '../preview_certification_training'
import ReviewBackgroundComp from './review_background'
import { ReviewBackgroundProps } from './review_background'

const ReviewBackground = (props: ReviewBackgroundProps) => {
  const [preview, setPreview] = useState(false)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [preview])

  return preview ? (
    <Box spacing='1rem' padding='0 0 1rem 0'>
      <PreviewCertificationTraining />
      <Grid container justify='flex-end'>
        <Button onClick={() => setPreview(false)}>
          <FontAwesomeIcon
            style={{ marginRight: '0.5rem', fontSize: '1.4rem' }}
            icon={faChevronLeft}
          />
          Back to Review of Background Check by Humana
        </Button>
      </Grid>
    </Box>
  ) : (
    <Box spacing='1rem' padding='0 0 1rem 0'>
      <ReviewBackgroundComp {...props} />
      <Grid container justify='flex-end'>
        <Button onClick={() => setPreview(true)}>Preview Certification Process</Button>
      </Grid>
    </Box>
  )
}

export default ReviewBackground
