import React, { useState } from 'react'

import { Grid } from '@material-ui/core'

import { InvalidIcon, RoundCheckedIcon } from 'components/icons'
import SuccessBox from 'components/box/success_box'
import Table from 'components/table'
import { formatDateForFfm } from 'screens/certification/utils'
import styled from 'styled-components'

import { CertificationFinalExamResults } from '../../ffm.d'

const Legend = styled.div`
  display: flex;
  align-items: center;
`

interface CertificationCompletedProps {
  page_data?: CertificationFinalExamResults[]
}

const CertificationCompleted = (props: CertificationCompletedProps) => {
  const { page_data } = props
  const [year, setYear] = useState<number>(2022)
  const columns = [
    {
      title: 'Applicable Plan Year',
      field: 'applicablePlanYear'
    },
    {
      title: 'Individual Marketplace Registration Completion Date',
      field: 'completionDate'
    },
    {
      title: 'Individual Marketplace Registration End Date',
      field: 'endDate'
    },
    {
      title: 'NPN Valid (Current Plan Year Only)',
      field: 'npnValid'
    }
  ]

  const data = page_data?.map(d => {
    let npnValidOutput = <></>
    if (d.npn_valid_current_plan_year_only === 'Yes') {
      npnValidOutput = (
        <Legend>
          <RoundCheckedIcon /> &nbsp;Yes
        </Legend>
      )
    } else if (d.npn_valid_current_plan_year_only === 'No') {
      npnValidOutput = <Legend>&nbsp;No</Legend>
    } else {
      npnValidOutput = <Legend>&nbsp;{d.npn_valid_current_plan_year_only}</Legend>
    }

    return {
      applicablePlanYear: d.applicable_plan_year || <></>,
      completionDate: formatDateForFfm(d.individual_registration_completion_date) || <></>,
      endDate: formatDateForFfm(d.individual_marketplace_end_date) || <></>,
      npnValid: npnValidOutput
    }
  })

  return (
    <Grid
      container
      direction='column'
      alignItems='stretch'
      spacing={4}
      style={{ margin: '0 1% 0 1%' }}
    >
      <Grid item>
        <SuccessBox>
          <strong>Good job!</strong> Your FFM for {year} - {year + 1} is complete!
        </SuccessBox>
      </Grid>
      <Grid item>
        <Table columns={columns} data={data || []} />
      </Grid>
    </Grid>
  )
}

export default CertificationCompleted
