import React, { useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'

import { useStoreState } from 'store'

import { AhipSummaryProps, CertificationFinalExamInfo, CertificationInfoStatus } from '../ahip.d'
import AttemptsTable from '../workflow/sync_data/attempts'
import { CertificateProviders } from '../../certification.d'
import ExpirationBanner from './expiration_banner'
import FinalExamTable from '../workflow/sync_data/final_exam'
import { getCertificationSummary } from '../api'

const Summary = (props: AhipSummaryProps) => {
  const { year, optionChanges } = props
  const userInfo = useStoreState(state => state.userInfo)
  const [pageData, setPageData] = useState<CertificationFinalExamInfo>({
    final_score: 0,
    completion_date: '',
    quiz_data: [],
    status: {} as CertificationInfoStatus
  })
  const getSummaryDetails = async () => {
    const response = await getCertificationSummary({
      year: year,
      userInfoId: userInfo.id,
      certificateProvider: CertificateProviders.ahip,
      authToken: localStorage.getItem('authToken')
    })
    const summaryDetails: CertificationFinalExamInfo = await response.json()
    setPageData(summaryDetails)
  }

  useEffect(() => {
    getSummaryDetails()
  }, [])

  return (
    <Grid container direction='column' alignItems='flex-start' spacing={4}>
      <Grid item>
        <ExpirationBanner year={year} optionChanges={optionChanges} />
      </Grid>
      <Grid item>
        <FinalExamTable page_data={pageData} />
      </Grid>
      <Grid item>
        <AttemptsTable page_data={pageData} />
      </Grid>
    </Grid>
  )
}

export default Summary
