import React, { useEffect, useRef, useState } from 'react'

import { Link } from '@material-ui/core'

import Box from 'components/box'
import Button from 'components/button'
import { Loading } from 'components/loading'
import Select from 'components/select'
import SuccessBox from 'components/box/success_box'
import { loader } from 'graphql.macro'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'

import Note from '../../../../components/box/note'
import { useStoreActions, useStoreState } from '../../../../store'

const CREATE = loader('./graphql/create_ahip_status.gql')
const UPDATE = loader('./graphql/update_ahip_status.gql')

const Content = styled.div`
  > * + * {
    margin-left: 24px;
  }
  display: flex;
  align-items: flex-start;
`

const ForYear = styled.div`
  margin-bottom: 8px;
  display: flex;
  > * + * {
    margin-left: 24px;
  }
`

const Message = styled.span`
  color: #883d07;
  font-size: 12px;
  ${props =>
    props.type === 'success' &&
    `
    color: green;
  `}
  ${props =>
    props.type === 'error' &&
    `
    color: #dd2d30;
  `}
`

const ItemTitle = styled.div`
  display: flex;
  font-size: 24px;
  margin-top: 24px;
  > * + * {
    margin-left: 4px;
  }
`

const ItemBody = styled.div`
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
`

const Main = styled.div`
  > * + * {
    margin-top: 24px;
  }
`

const InnerItem = styled.div`
  padding: 16px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`
const MessageBox = styled.div`
  display: flex;
  height: 114px;
  margin: 0px 16px;
  > * {
    line-height: 1.8;
  }
`

const Form = styled.div`
  margin-top: 10px;
  @media (max-width: 599px) {
    display: flex;
    flex-direction: column;
    > * + * {
      margin-top: 10px !important;
    }
  }
`

const AhipStatus = () => {
  const convertToEst = (date: Date) => {
    const estOffset = 4 * 60
    return new Date(date.getTime() - estOffset * 60000)
  }

  const currentYear = new Date().getUTCFullYear()

  const years = [currentYear - 1, currentYear]
  const targetDateEst = convertToEst(new Date(Date.UTC(currentYear, 0o5, 18)))
  const currentDateEst = convertToEst(new Date())
  const activeYear = currentDateEst < targetDateEst ? currentYear - 1 : currentYear
  const [selectedYear, setSelectedYear] = useState(activeYear)

  const handleYearChange = (year: number) => {
    setSelectedYear(year)
  }

  const storeCreateAhipStatus = useStoreActions(actions => actions.ahipStatus.create)
  const storeUpdateAhipStatus = useStoreActions(actions => actions.ahipStatus.update)

  const ahipStatus = useStoreState(state => state.ahipStatus.items)
  const currentYearConfirmed = ahipStatus.find(row => row.year === selectedYear) != null
  const prevYearConfirmed = ahipStatus.find(row => row.year === activeYear - 1) != null
  const nextYearConfirmed = ahipStatus.find(row => row.year === activeYear) != null

  const [submitMessage, setSubmitMessage] = useState<{
    success: boolean
    message: string
  } | null>(null)

  const addSubmitMessage = (message: { success: boolean; message: string } | null) => {
    setSubmitMessage(message)
  }

  const [createAhipStatus, { loading: creating }] = useMutation(CREATE, {
    onCompleted: data => {
      const errors = data.ahipStatusCreate.ahipStatusErrors
      const success = errors.length === 0
      if (success) {
        storeCreateAhipStatus(data.ahipStatusCreate.ahipStatus)
      }
      addSubmitMessage({
        success,
        message: success ? 'Creation successful.' : errors[0].message
      })
    },
    onError: error => {
      console.error(error)
      addSubmitMessage({
        success: false,
        message: error.message
      })
    }
  })

  const [updateAhipStatus, { loading: updating }] = useMutation(UPDATE, {
    onCompleted: data => {
      const errors = data.ahipStatusUpdate.ahipStatusErrors
      const success = errors.length === 0
      if (success) {
        storeUpdateAhipStatus(data.ahipStatusUpdate.ahipStatus)
      }
      addSubmitMessage({
        success,
        message: success ? 'Update successful.' : errors[0].message
      })
    },
    onError: error => {
      console.error(error)
      addSubmitMessage({
        success: false,
        message: error.message
      })
    }
  })

  useEffect(() => {
    handleYearChange(selectedYear)
  }, [ahipStatus])

  const submit = formValues => {
    addSubmitMessage(null)
    const input = {
      year: selectedYear,
      isConfirmed: true
    }

    const existingAhipStatus = ahipStatus.find(info => info.year === selectedYear)
    if (existingAhipStatus == null) {
      createAhipStatus({
        variables: {
          input
        }
      })
    } else {
      updateAhipStatus({
        variables: {
          input,
          id: existingAhipStatus.id
        }
      })
    }
  }

  return (
    <Main id='ahip_body'>
      <Content>
        <div style={{ flexBasis: '100%' }}>
          <ForYear>
            <div id='errors_and_omissions_status'>
              <span style={{ fontWeight: 600 }}>AHIP Certification Year</span>
              <Select
                style={{ minWidth: '200px', marginTop: '8px', display: 'block' }}
                selected={selectedYear}
                options={years.map(year => ({ year: year }))}
                keySelector={option => option.year}
                labelSelector={option =>
                  option.year +
                  '–' +
                  (option.year + 1) +
                  (option.year === activeYear ? ' (Current)' : '')
                }
                titleSelector={option => {
                  if (option) {
                    return (
                      option.year +
                      '–' +
                      (option.year + 1) +
                      (option.year === activeYear ? ' (Current)' : '')
                    )
                  }
                  return '–'
                }}
                onChange={handleYearChange}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {(updating || creating) && (
                <Box marginLeft='1rem'>
                  <Loading />
                </Box>
              )}
            </div>
          </ForYear>
          {selectedYear === activeYear - 1 &&
            (prevYearConfirmed ? (
              <SuccessBox>
                Good Job! You confirmed that you have completed your AHIP certification training.
              </SuccessBox>
            ) : (
              <Note>
                <strong>ATTENTION</strong>: The AHIP {selectedYear - 1}-{selectedYear} training will
                close on 6/17 @ 11:59 PM ET. The {selectedYear}-{selectedYear + 1} training will
                open on 6/21 @ 12 AM ET. As a reminder, you must pay for, and complete, the AHIP
                certification each year. If you are getting certified for the first time, we
                recommend that you wait and only take the {selectedYear}-{selectedYear + 1} exam on
                6/21 since the {selectedYear - 1}-{selectedYear} exam will expire soon. Come back
                when you’re ready!
              </Note>
            ))}
          {selectedYear === activeYear &&
            (nextYearConfirmed ? (
              <SuccessBox>
                Good Job! You confirmed that you have completed your AHIP certification training.
              </SuccessBox>
            ) : (
              <Note>
                <strong>ATTENTION</strong>: The AHIP {selectedYear - 1}-{selectedYear} training
                closed on 6/17 @ 11:59 PM ET. The {selectedYear}–{selectedYear + 1} training opened
                on 6/21 @ 12 AM ET. As a reminder, you must pay for, and complete, the AHIP
                certification each year.
              </Note>
            ))}

          <Form>
            <div>
              In order to sell Medicare Advantage plans, Assurance's carriers mandate that you
              complete the <strong>current</strong> year’s AHIP Certification. Use this section of
              your dashboard to stay up-to-date on your AHIP progress and training opportunities.
            </div>
            <div>
              <ItemTitle>1. Create Account or Login to a Current Account</ItemTitle>
              <ItemBody>
                <div>
                  Use our AHIP link below when logging into or creating your AHIP account to receive
                  a discount for the {selectedYear + '–' + (selectedYear + 1)} certification.
                </div>
                <br />
                <InnerItem>
                  <img
                    width='140'
                    alt='ahip-logo'
                    style={{ marginTop: '20px' }}
                    src='/images/ahip_logo.svg'
                  />
                  <MessageBox>
                    <div>
                      Make sure to take advantage of Assurance’s discount and use our site license
                      links.
                      <br />
                      <strong>On Demand Agents</strong>:{' '}
                      <a
                        href='https://www.ahipmedicaretraining.com/clients/assurance'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Use this link to sign up with a discount
                      </a>{' '}
                      (On Demand Only)
                      <br />
                      <strong>Call Center Agents</strong>: Contact your Supervisor for the discount
                      link.
                      <br />
                      <strong>W2 Hub Employees</strong>: Contact your Sales Manger for the discount
                      link.
                    </div>
                  </MessageBox>
                </InnerItem>
              </ItemBody>
            </div>
            <hr />
            <div>
              <ItemTitle>2. Take AHIP Certification</ItemTitle>
              <ItemBody>
                <form target='_blank' method='get' action='/docs/AHIP 2021 User Guide.pdf'>
                  After logging in to the AHIP portal, complete your training and certification
                  using this{' '}
                  <Link type='submit' component='button'>
                    guide
                  </Link>
                  . Return to this page once you’ve passed your exam.
                </form>
              </ItemBody>
            </div>
            <hr />
            <div>
              <ItemTitle>3. Confirm</ItemTitle>
              <ItemBody>
                I confirm that I have completed the {selectedYear + '–' + (selectedYear + 1)} AHIP
                certification training.
                <Button
                  style={{ marginLeft: '10px', height: '40px', width: '121px' }}
                  disabled={currentYearConfirmed}
                  onClick={submit}
                >
                  {currentYearConfirmed ? 'Confirmed' : 'Confirm'}
                </Button>
              </ItemBody>
            </div>
          </Form>
          {submitMessage && (
            <Message
              type={submitMessage.success ? 'success' : 'error'}
              style={{ display: 'block', marginTop: '20px', fontSize: '1.2rem' }}
            >
              {submitMessage.message}
            </Message>
          )}
        </div>
      </Content>
    </Main>
  )
}

export default AhipStatus
