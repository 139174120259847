import React from 'react'

import { Link } from '@material-ui/core'

import Note from 'components/box/note'
import Warning from 'components/box/warning'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper from '../commons/instructions_wrapper'

interface Props {
  carrierName: string
  loi: string
}

const AetnaMedsuppChecklist = (props: Props) => {
  return (
    <InstructionsWrapper carrierName={props.carrierName} loi={props.loi}>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Request for an Appointment Invitation
          </span>
        }
      >
        Let us know you want an invitation to get started with appointment by filling out the{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://assuranceiq.formstack.com/forms/aetna_med_supplement'
        >
          Aetna Medicare Supplement Invitation Request Form
        </Link>
        .
        <Warning>
          <strong>Caution:</strong> If you initiate your appointment with Aetna Medicare Supplement
          by following the above link, you've indicated your interest in working with Aetna Medicare
          Supplement directly.
        </Warning>
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            2. Complete Appointment Process
          </span>
        }
      >
        <strong>Full Instructions:</strong>
        <br />
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360057881891-How-do-I-get-Appointed-with-Aetna-Med-Supp-'
        >
          Assurance Help: How do I get appointed with Aetna Medicare Supplement?
        </Link>
        <br />
        <br />
        After requesting an invite, it can take up to 3 days to receive an invite from Aetna
        Medicare Supplement. If you meet Aetna’s requirements, you will receive a welcome email to
        your Assurance email address. In that email there will be a link that will guide you to the
        Aetna Medicare Supplement portal to complete your appointment. The link above provides you
        with a step-by-step process to getting appointed.
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>3. Wait for Data to Sync</span>
        }
      >
        If you are experiencing difficulties with the Medicare onboarding experience on the
        Assurance Platform, please reach out to our team through the 'Need Help' button in the
        bottom right corner of your screen.
        <br />
        <Note>
          If you have questions on form completion, please contact{' '}
          <strong>Aetna Medicare Supplement Licensing and Credentialing</strong> at{' '}
          <strong>1-866-272-6630</strong>.
        </Note>
      </Accordion>
    </InstructionsWrapper>
  )
}

export default AetnaMedsuppChecklist
