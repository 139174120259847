import React, { useState } from 'react'

import { Grid } from '@material-ui/core'
import Link from '@material-ui/core/Link'

import Box from 'components/box'
import Button from 'components/button'
import CheckboxButton from 'components/checkbox_button'
import Image from 'components/image'
import Message from 'components/message/Message'
import { RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'

import Accordion from '../../commons/checklist_accordion'
import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    userInput?: {
      checkEmail?: boolean
      loggedIn?: boolean
      completedPaperwork?: boolean
    }
  }
}

const getIcon = (checked?: boolean) => {
  const props = {
    style: {
      width: '1.25rem',
      height: '1.25rem'
    }
  }
  return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
}

const Appointment = (props: Props) => {
  const { plateName, doUpdate, pageData = {} } = props

  const { userInput = {} } = pageData

  const [checkEmail, setCheckEmail] = useState(userInput.checkEmail)
  const [logged, setLogged] = useState(userInput.loggedIn)
  const [completed, setCompleted] = useState(userInput.completedPaperwork)
  const [showError, setShowError] = useState(false)

  const handleNext = () => {
    const allCompleted = checkEmail && logged && completed
    if (!allCompleted) {
      setShowError(true)
      return
    }
    const data = {
      pageData: {
        userInput: {
          checkEmail,
          loggedIn: logged,
          completedPaperwork: completed
        }
      },
      progress: {
        name: plateName,
        status: allCompleted ? 'checked' : 'error'
      },
      nextProgress: 'CarrierReview',
      nextPage: {
        name: 'CarrierReview'
      }
    }
    doUpdate?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Appointment - Paperwork</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item container direction='column' spacing={1}>
        {showError && !checkEmail && (
          <Grid item>
            <Message type='warning'>You must complete this action item</Message>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(checkEmail)}
            title={<span style={{ display: 'flex', alignItems: 'center' }}>1. Check Email</span>}
          >
            Please check your Assurance email account for an email from Cigna. It will show as:
            <br />
            <div>
              <div>
                <strong>From:</strong>{' '}
                <Link target='_blank' rel='noreferrer' href='mailto:producerexpress@sircon.com'>
                  producerexpress@sircon.com
                </Link>
              </div>
              <div>
                <strong>Subject:</strong> Cigna Producer Onboarding Invitation
              </div>
              <div>
                <strong>Recruiter Name:</strong> ASSURANCE IQ
              </div>
            </div>
            <div>
              a. We have initiated your subagent appointment through our agency. You should have
              received an email from{' '}
              <Link target={'_blank'} rel={'noreferrer'} href={'mailto:producerexpress@sircon.com'}>
                producerexpress@sircon.com
              </Link>{' '}
              with the subject line <strong>“Cigna Producer Onboarding Invitation”</strong> in your
              email inbox.
            </div>
            <div>
              Example of email below. If you do not receive an email in your inbox, please check
              your spam/junk folder as well.
              <br />
              <Image
                style={{ width: '100%', marginTop: '0.5rem' }}
                alt='cigna producer onboarding invitation'
                src='/images/carriers/health/cigna/smartflow/check_email.png'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={checkEmail || false}
                onChange={value => setCheckEmail(value)}
                label='I Received a Registration Email'
              />
            </Grid>
          </Accordion>
        </Grid>
        {showError && !logged && (
          <Grid item style={{ marginTop: '1rem' }}>
            <Message type='warning'>You must complete this action item</Message>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(logged)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                2. Log Into Onboarding Portal
              </span>
            }
          >
            <div>
              a. Once you click on the link in the email, you will be redirected to{' '}
              <Link target='_blank' rel={'noreferrer'} href='https://px.sircon.com/'>
                Cigna Onboarding Portal
              </Link>
              .
              <br />
              <br />
              Please use your @assurance.com email id to login. In case you do not remember your
              password, it’s ok, just hit ‘Forget Password?’ and follow the easy steps. Make sure
              the ‘Domain’ is always ‘Cigna’.
              <Image
                style={{ width: '90%', marginTop: '0.5rem' }}
                alt='getting started'
                src='/images/carriers/health/cigna/smartflow/getting_started.png'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={logged || false}
                onChange={value => setLogged(value)}
                label="I've Logged Into Onboarding Portal"
              />
            </Grid>
          </Accordion>
        </Grid>
        {showError && !completed && (
          <Grid item style={{ marginTop: '1rem' }}>
            <Message type='warning'>You must complete this action item</Message>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(completed)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                3. Complete and Submit Paperwork
              </span>
            }
          >
            <div>
              b. Once logged into the Onboarding Portal, you can start the appointment application.
              <br />
              <br />
              Follow and complete each tab at the top of the page. Each section must be complete
              before submitting your request.
              <br />
              <br />
              i. First section is “Interview Questions” and requires some general information. Start
              off by reviewing the User Agreement. Scroll through all of the agreements and click
              continue.
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='agreements'
                src='/images/carriers/health/cigna/smartflow/agreement.png'
              />
            </div>
            <div>
              c. On the next page, you should see Assurance IQ come up as your Agency Name. If this
              is not there please reach out to <u>healthlicensing@assurance.com</u>. Then, please
              select NO as you are not affiliated with any other agency, and continue
              <br />
              <br />
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='affiliayed with other agency or not'
                src='/images/carriers/health/cigna/smartflow/interview_qsn.png'
              />
            </div>
            <div>
              d. The next few screens require you to fill in your general information. Note- Under{' '}
              <strong>Business address,</strong>, please be sure to input Assurance’s corporate
              address - <strong>920 5th Ave, Seattle, WA 98104</strong>. Everything else is your
              personal information.
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='general information'
                src='/images/carriers/health/cigna/smartflow/general_information.png'
              />
            </div>
            <div>
              e. After this, review all the states that you will be able to sell Cigna products in.
              Click Continue to move onto the next step.
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='review request states'
                src='/images/carriers/health/cigna/smartflow/review_appointment_request.png'
              />
            </div>
            <div>
              f. Next, you will be asked to choose your county. Please be sure to select the correct
              residential county that you live in.
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='choose county'
                src='/images/carriers/health/cigna/smartflow/county.png'
              />
            </div>
            <div>
              g.Next, complete all the background questions. Click ‘Continue’ after answering all
              the questions.
              <Image
                style={{ marginTop: '1rem', border: '1px solid black' }}
                alt='background qsns'
                src='/images/carriers/health/cigna/smartflow/background_qsn.png'
              />
            </div>
            <div>
              h. You will be directed to a section where you will need to sign off on state mandated
              documents. Please ensure that you click on the "Signature Required" link to complete
              all of the forms, or you will not be able to submit. Once completed, click on “I
              Agree”.
              <Image
                style={{ marginTop: '1rem', border: '1px solid black' }}
                alt='review documents'
                src='/images/carriers/health/cigna/smartflow/review_docs.png'
              />
            </div>
            <div>
              i. Lastly, you will be shown a summary of all of the documents that you have signed.
              Please review and make sure all the documents have been completed or signed. Once you
              are ready, click "Send Now" to submit application to the carrier, and done!
              <Image
                style={{ marginTop: '1rem', border: '1px solid black' }}
                alt='send request'
                src='/images/carriers/health/cigna/smartflow/send_request.png'
              />
            </div>
            <div>
              h. [Optional] You can save and print all the documents that you had signed and
              submitted to the carrier. This is <strong>NOT</strong> required and is completely
              optional. Click “End” to exit the application screen.
              <Image
                style={{ marginTop: '1rem', border: '1px solid black' }}
                alt='fill out forms'
                src='/images/carriers/health/cigna/smartflow/save_documents.png'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={completed || false}
                onChange={value => setCompleted(value)}
                label='I Completed and Submitted All Required Paperwork'
              />
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container item justify='flex-end'>
        <Box width='100%' display='flex' alignItems='center' justifyContent='flex-end'>
          {showError && ![checkEmail, logged, completed].every(d => d) && (
            <Box mr='1rem'>
              <Message type={'warning'}>Action items incomplete</Message>
            </Box>
          )}
          <Button onClick={handleNext}>Next: Carrier Review</Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Appointment
