import React from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import Warning from 'components/box/warning'

import { PlateProps } from '../../../commons/plate'
import TimeToComplete from '../../../commons/time_to_complete'
import { carrierFullName } from '../../../../../store/carriers'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  terminatedReason?: string
}

const Terminated = (props: Props) => {
  const { carrierName, terminatedReason } = props

  const fullCarrierName = carrierFullName(carrierName)

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>
          Eligibility - Previously Appointed with {fullCarrierName}
        </h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        According to the National Insurance Producer Registry (NIPR), you were appointed with{' '}
        {fullCarrierName} in the past.
      </Grid>
      <Grid item>
        <Warning>
          <strong>Current Status:</strong> {fullCarrierName} has deemed you {terminatedReason}.
        </Warning>
      </Grid>
      <Grid item>We are unable to progress you further.</Grid>
      <Grid item>
        Please reach out to the {fullCarrierName} contracting team at{' '}
        <strong>brokerage.appoint@prudential.com</strong> for more details
      </Grid>
    </Grid>
  )
}

export default Terminated
