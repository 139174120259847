import React from 'react'

import { AppointmentTerminatedIcon } from 'components/icons'
import { LightTooltip } from 'components/tooltip'

interface Props {
  carrier: string
}

const TerminatedTooltip = (props: Props) => {
  return (
    <LightTooltip
      placement='top-start'
      title={
        <div>
          <div style={{ fontWeight: 600 }}>Appointment Terminated</div>
          <div style={{ marginTop: '1rem' }}>
            You were previously appointed with Assurance. Your appointment with {props.carrier} has
            been terminated.
          </div>
        </div>
      }
    >
      <div style={{ marginLeft: '0.25rem' }}>
        <AppointmentTerminatedIcon />
      </div>
    </LightTooltip>
  )
}

export default TerminatedTooltip
