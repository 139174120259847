import React from 'react'

import { CheckCircle } from '@material-ui/icons'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'

import { RoundCheckedIcon, TimerStatusIcon, WarningIcon } from 'components/icons'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const AmbetterLink = (
  <Link
    target='_blank'
    rel='noreferrer'
    href='https://help.assurance.com/hc/en-us/articles/360048891952-How-Do-I-Get-Appointed-With-Ambetter-to-Offer-ACA-Plans-'
  >
    Click to view instructions on How to get appointed with Ambetter to offer ACA plans.
  </Link>
)

const getIconFromStatus = (status?: string) => {
  const style = {
    width: '1.25rem',
    marginRight: '0.375rem'
  }
  switch (status) {
    case 'error':
      return <WarningIcon style={style} />
    case 'checked':
      return <RoundCheckedIcon blue style={style} />
    case 'timer':
      return <TimerStatusIcon style={style} />
    default:
      return <TimerStatusIcon style={style} />
  }
}

const CarrierReview = (props: Props) => {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Ambetter Carrier Review</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>Well done!</Grid>
      <Grid item>
        Your work here is done for now. Sit back, relax, and we will update your progress.
      </Grid>
      <Grid item>
        Note: if this is taking longer than expected, make sure you review the instructions again
        and check if you’ve completed all the components to your appointment paperwork.
      </Grid>
      <Grid item>
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360050014612-How-Do-I-Get-Appointed-With-Ambetter-to-Offer-ACA-Plans-'
        >
          Click to view instructions on How to get appointed with Ambetter to offer ACA plans.
        </Link>
      </Grid>
    </Grid>
  )
}

export default CarrierReview
