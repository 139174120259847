import React from 'react'

import { Link } from '@material-ui/core'

import Warning from 'components/box/warning'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper from '../commons/instructions_wrapper'

interface Props {
  carrierName: string
  loi: string
}

const Checklist = (props: Props) => {
  return (
    <InstructionsWrapper {...props}>
      <Warning>
        Please make sure you are entering in “Assurance IQ, LLC” under any spot listed with agency
        name. Failure to do so will delay in processing of your application.
        <br />
        <br />
        Please be sure to download the Certificate of Liability located at the bottom of the help
        desk article in the link in step one. Ensure this and all other carriers are to be completed
        by the deadline discussed by your recruiter.
        <br />
        <br />
        While going through it, please be sure to use your Assurance email as the contact email and
        utilize our Assurance Toll-Free number 1-844-358-4538 for your contact phone number.
      </Warning>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Complete Contracting Process
          </span>
        }
      >
        <strong>Instructions:</strong>
        <br />
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360047503992-How-do-I-get-appointed-with-Health-Insurance-Innovations-HII-'
        >
          Assurance Help: How do I get appointed with Health Insurance Innovations (HII)?
        </Link>
        <br />
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            2. Complete Appointment Process
          </span>
        }
      >
        To begin appointment process, please click on the gray box by the words SKIP for the
        following carriers as we do not offer products for them.
        <ul>
          <li>American Financial Security Life Insurance Company</li>
          <li>Lifeshield</li>
          <li>Amaglamated</li>
        </ul>
        <Warning>
          Be sure to only skip those 3 Carriers. If you skip others you will not get approved to
          sell the products and will have to reschedule your training. In the event this happens,
          please email your Onboarding Manager for a new link.
        </Warning>
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            3. Complete Error and Omission Declaration
          </span>
        }
      >
        You must now add on the E&O PDF that is provided at the bottom of the instruction's article.
        You will need to download it first. Then upload to the contracting portion by following
        these instructions.
      </Accordion>
    </InstructionsWrapper>
  )
}

export default Checklist
