import CanceledByAgent from './canceled_by_agent'
import Cancelled from './cancelled'
import CarrierReview from './carrier_review'
import Certification from './certification'
import DataSync from './data_sync'
import Eligibility from './eligibility'
import Invitation from './invitation'

export const PlateLabelMap = {
  Eligibility: 'Check Eligibility',
  Invitation: 'Request Invite',
  Appointment: 'Complete Paperwork',
  Certification: 'Complete Certification',
  CarrierReview: 'Review by Carrier',
  OnboardComplete: 'Onboard Complete',
  DataSync: 'Sync Data',
  InComplete: 'Incomplete',
  ApprovalRequested: 'Approval Requested',
  CanceledByAgent: 'Canceled by Agent',
  Cancelled: 'Cancelled',
  NoticeOfIntent: 'Notice of Intent',
  RejectedByBrokerServices: 'Rejected By Broker Services',
  Exception: 'Exception'
}

export const PlateComponentMap = {
  Eligibility: Eligibility,
  Invitation: Invitation,
  Certification: Certification,
  DataSync: DataSync,
  CarrierReview: CarrierReview,
  CanceledByAgent,
  Cancelled
}
