import React from 'react'

import AssuranceLoading from 'components/loading'

import NotAppointed from './not_appointed'
import { PlateProps } from '../../commons/plate'
import PreviouslyAppointed from './previously_appointed'
import TerminatedWithCause from './terminated_with_cause'
import WrongLineOfAuthority from './wrong_line_of_authority'

interface Props extends PlateProps {
  pageName:
    | 'CheckEligibilitySpinner'
    | 'NotAppointedWithCarrier'
    | 'InEligibleLineOfAuthority'
    | 'PromptForCarrierStatus'
    | 'TerminatedWithCause'
    | string
}

const Eligibility = (props: Props) => {
  const { pageName } = props

  switch (pageName) {
    case 'CheckEligibilitySpinner':
      return (
        <div style={{ marginTop: '4rem' }}>
          <AssuranceLoading>Running eligibility check...</AssuranceLoading>
        </div>
      )

    case 'NotAppointedWithCarrier':
      return <NotAppointed {...props} />

    case 'PromptForCarrierStatus':
      return <PreviouslyAppointed {...props} />

    case 'TerminatedWithCause':
      return <TerminatedWithCause {...props} />

    case 'InEligibleLineOfAuthority':
      return <WrongLineOfAuthority {...props} />

    default:
      return <div>Unknown page {pageName}</div>
  }
}

export default Eligibility
