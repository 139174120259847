import React, { useState } from 'react'

import { Grid, Link, Typography } from '@material-ui/core'

import Box from 'components/box'
import Button from 'components/button'
import InfoBox from 'components/box/info_box'
import Message from 'components/message/Message'
import { RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'

import Accordion from '../../../commons/checklist_accordion'
import { PlateProps } from '../../../commons/plate'
import TimeToComplete from '../../../commons/time_to_complete'
import { carrierFullName } from '../../../../../store/carriers'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    training_requirement?: boolean
    eligibility_check?: boolean
  }
}

const getIcon = (checked?: boolean) =>
  checked ? <RoundCheckedIcon blue /> : <RoundUncheckedIcon />

const AiqEligibilityTraining = (props: Props) => {
  const { carrierName, doNext, pageData } = props

  const trainingCompleted = pageData?.training_requirement
  const eligibilityCheckCompleted = pageData?.eligibility_check

  const [showError, setShowError] = useState(false)

  const handleNext = () => {
    if (!trainingCompleted) {
      setShowError(true)
      return
    }
    doNext?.('next', {}, pageData)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Assurance IQ Eligibility</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <InfoBox>
          <strong>Note:</strong> To start the appointment paperwork, you need to have the AU Term
          skill in JOIN and complete the mandatory training. Please complete the necessary
          requirements and these sections should update automatically.
        </InfoBox>
      </Grid>
      <Grid item>
        <Accordion
          icon={getIcon(eligibilityCheckCompleted)}
          title={
            <span style={{ display: 'flex', alignItems: 'center' }}>1. Eligibility Check</span>
          }
        >
          {eligibilityCheckCompleted ? (
            <div>You are eligible to be appointed with {carrierFullName(carrierName)}.</div>
          ) : (
            <div>
              You do not currently have the AU Term skill. If you do not have the AU Term skill and
              wish to obtain it, please talk to your Sales Manager about what you can do in order to
              obtain this skill.
            </div>
          )}
        </Accordion>
      </Grid>
      {showError && !trainingCompleted && (
        <Grid item>
          <Message type='warning'>You must complete this action item</Message>
        </Grid>
      )}
      <Grid item>
        <Accordion
          icon={getIcon(trainingCompleted)}
          title={<span style={{ display: 'flex', alignItems: 'center' }}>2. Training</span>}
        >
          {trainingCompleted ? (
            <div>
              You are eligible to be appointed with {carrierFullName(carrierName)}. Please proceed
              and complete your training.
            </div>
          ) : (
            <>
              <div>
                Log in to Axonify, Assurance's Learning Management System, by clicking the button
                below.
              </div>
              <Button color='secondary' target='_blank' href='https://aiq.axonify.com/training/'>
                Axonify Training Link
              </Button>
            </>
          )}
        </Accordion>
      </Grid>
      <Grid container item justifyContent='flex-end'>
        <Box width='100%' display='flex' alignItems='center' justifyContent='flex-end'>
          <Button disabled={!trainingCompleted && !eligibilityCheckCompleted} onClick={handleNext}>
            Next: Paperwork
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default AiqEligibilityTraining
