import React from 'react'

import { Grid, Link } from '@material-ui/core'

import Button from 'components/button'
import Image from 'components/image'

import Note from '../../../../components/box/note'
import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const InvitationSent = (props: Props) => {
  const { doNext } = props

  const handleNext = () => {
    doNext?.('next')
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Invitation Sent</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <Note>
          <strong>Note:</strong> It can take up to 48 hours to get the appointment ID. After you get
          it, you will be taken to Anthem's Producer Toolbox:
          https://brokerportal.anthem.com/apps/ptb/login
        </Note>
      </Grid>
      <Grid item>
        Assurance IQ's licensing team has requested for an invitation on your behalf. Please check
        your {''}
        <Link target='_blank' rel='noreferrer' href='https://outlook.office365.com/mail/'>
          <strong>@Assurance.com</strong>
        </Link>
        {''} email account for:
        <br />
        <br />
        <div>
          <strong>From:</strong> software.support@anthem.com
        </div>
        <div>
          <strong>Subject:</strong> Your new Appointment
        </div>
        <br />
        Once you receive the invitation, come back to this page and see what to do next.
      </Grid>
      <Grid container item>
        <Image
          alt='invitation email'
          src='/images/carriers/medicare/anthem/smartflow/anthem1.png'
          style={{ width: '80%' }}
        />
      </Grid>
      <Grid container item justify='flex-end' spacing={1}>
        <Grid item>
          <Button onClick={handleNext}>Next: Appointment</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InvitationSent
