import React from 'react'

import { Grid, makeStyles } from '@material-ui/core'

import Button from 'components/button'
import SuccessBox from 'components/box/success_box'
import { carrierFullName } from 'store/carriers'

import { PlateProps } from '../../../commons/plate'
import { withRtsReversalCheck } from '../../../commons/flow/plates/data_sync/rts_reversal'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const useStyles = makeStyles(theme => ({
  grey: {
    color: theme.palette.grey[500]
  }
}))

const DataSync = withRtsReversalCheck((props: Props) => {
  const { plateName, carrierName, doUpdate } = props

  const classes = useStyles()

  const status = 'checked'

  const handleNext = () => {
    const data = {
      pageData: {},
      progress: {
        name: plateName,
        status: 'checked'
      },
      nextProgress: 'CarrierPortalAccess',
      nextPage: {
        name: 'CarrierPortalAccess'
      }
    }
    doUpdate?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Data Sync</h2>
      </Grid>
      {status === 'checked' && (
        <Grid item>
          <SuccessBox>
            <strong>Congratulations!</strong> You’re now appointed by {carrierFullName(carrierName)}
            .
          </SuccessBox>
        </Grid>
      )}
      {status === 'checked' && (
        <Grid item container direction='column' alignItems='center'>
          <img src={'/images/data_sync_complete.svg'} />
        </Grid>
      )}
      {status !== 'checked' && (
        <Grid item container direction='column' alignItems='center'>
          <img src={'/images/data_sync_progress.svg'} />
          <span className={classes.grey}>
            Data syncing between {carrierFullName(carrierName)} and Assurance.
          </span>
        </Grid>
      )}
      {status === 'checked' && (
        <Grid container item justify='flex-end'>
          <Button onClick={handleNext}>Next: Carrier Portal Access</Button>
        </Grid>
      )}
    </Grid>
  )
})

export default DataSync
