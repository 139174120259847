import React, { useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'

import {
  CertificateProviders,
  CertificationProps,
  ProviderSettings,
  StageItem,
  StageItemStatus,
  YearOptionItem
} from './certification.d'
import CertificationStages from './certification_stages'
import { FfmPlateMap, FfmStageKeys } from './ffm/ffm.d'
import YearDropdown from './year_dropdown'
import { getYearOptions } from './ahip/api'

const Certification = (props: CertificationProps) => {
  const { provider } = props
  const [yearOptions, setYearOptions] = useState<YearOptionItem[]>([])
  const [selectedOption, setSelectedOption] = useState<YearOptionItem>()
  const [progressItems, setProgressItems] = useState<StageItem[]>([])
  const [currentProgress, setCurrentProgress] = useState<string>()

  const updateYearOptions = async () => {
    const response = await getYearOptions({
      certificateProvider: provider,
      authToken: localStorage.getItem('authToken')
    })
    const yearOptionData: YearOptionItem[] = await response.json()
    if (yearOptionData) {
      setYearOptions(yearOptionData)
      yearOptionData.length > 0 && setSelectedOption(yearOptionData[0])
    }
  }

  useEffect(() => {
    updateYearOptions()
  }, [])

  const optionChanges = (selectedYear: number) => {
    const selectedItem = yearOptions.find(y => y.year === selectedYear)
    setSelectedOption(selectedItem)
  }
  const updateStages = (stageItems: StageItem[], currentProgressKey?: string) => {
    setProgressItems(stageItems)
    setCurrentProgress(currentProgressKey)
  }

  const getComponent = () => {
    const Comp = ProviderSettings[provider].component

    return (
      (!!selectedOption && (
        <Comp
          page={selectedOption.page}
          onStageUpdate={updateStages}
          year={selectedOption.year}
          optionChanges={optionChanges}
        />
      )) || <h2>Loading...</h2>
    )
  }

  return (
    <Grid container direction='column'>
      <Grid item container>
        <Grid item container direction='row' style={{ minHeight: '70px' }}>
          {provider !== CertificateProviders.ffm && (
            <Grid item>
              <h2>{provider} Certification Year</h2>
              <YearDropdown
                selectedOption={selectedOption}
                yearOptions={yearOptions}
                optionChanges={optionChanges}
              />
            </Grid>
          )}
          <CertificationStages
            hideDivider={provider === CertificateProviders.ffm}
            provider={provider}
            progressItems={progressItems}
            currentProgress={currentProgress}
          />
        </Grid>
      </Grid>
      <Grid item container>
        {getComponent()}
      </Grid>
    </Grid>
  )
}

export default Certification
