import { Action, action } from 'easy-peasy'
import _ from 'lodash'

export enum Carrier {
  Aetna = 'Aetna',
  AetnaMedSupp = 'Aetna Medicare Supplement',
  Ambetter = 'Ambetter',
  Anthem = 'Anthem',
  Cigna = 'Cigna',
  HCSC = 'HCSC',
  HII = 'HII',
  Humana = 'Humana',
  IHC = 'IHC',
  LGA = 'LGA',
  Lumico = 'Lumico',
  Molina = 'Molina',
  MutualOfOmaha = 'Omaha',
  Oscar = 'Oscar',
  Prudential = 'Prudential',
  Protective = 'Protective',
  SureBridge = 'SureBridge',
  UnitedHealthOne = 'UHO',
  UnitedHealthCare = 'UHC',
  UnitedHealthCareACA = 'UHC_ACA',
  WellCare = 'WellCare'
}

// converts carrier from the hash path to carrier enum
export const routeCarrierToCarrierEnum = (carrier: string): Carrier => {
  switch (carrier.toLowerCase()) {
    case 'uho':
      return Carrier.UnitedHealthOne
    case 'uhc':
      return Carrier.UnitedHealthCare
    case 'uhc_aca':
      return Carrier.UnitedHealthCareACA
    case 'hcsc':
      return Carrier.HCSC
    case 'omaha':
      return Carrier.MutualOfOmaha
    case 'aetna_medicare_supplement':
      return Carrier.AetnaMedSupp
    case 'lga':
      return Carrier.LGA
  }
  return Carrier[_.startCase(carrier).split(' ').join('')]
}

export const carrierFullName = (carrier: Carrier) => {
  return (
    {
      [Carrier.LGA]: 'Legal and General',
      [Carrier.MutualOfOmaha]: 'Mutual of Omaha',
      [Carrier.UnitedHealthOne]: 'United Health One'
    }[carrier] || `${carrier}`
  )
}

export enum LOI {
  Medicare = 'MEDICARE',
  Health = 'HEALTH',
  Life = 'LIFE'
}

interface CarrierTag {
  id: string
  name: string
  loi: LOI
}

export interface CarrierInfo {
  id: string
  name: Carrier
  loi: LOI
  avaliableState?: number
  states: string[]
  justInTimeStates: string[]
  carrierTags: CarrierTag[]
}

export interface CarrierInfoModel {
  items: CarrierInfo[]
  load: Action<CarrierInfoModel, CarrierInfo[]>
}

export const carrierInfoModel: CarrierInfoModel = {
  items: [] as CarrierInfo[],
  load: action((state, payload) => {
    state.items = payload
  })
}
