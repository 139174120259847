import React, { useRef, useState } from 'react'

import { DeleteForeverRounded } from '@material-ui/icons'
import {
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'
import PublishIcon from '@material-ui/icons/Publish'

import Box from 'components/box'
import Button from 'components/button'
import CheckboxButton from 'components/checkbox_button'
import Image from 'components/image'
import Message from 'components/message/Message'
import { RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'
import { get_s3_url } from 'utils/utils'
import { useSnackbar } from 'utils/snackbar'

import Accordion from '../../../commons/checklist_accordion'
import HelloSignLauncher from '../../commons/hello_sign_launcher'
import { PlateProps } from '../../../commons/plate'
import PresignedUrlLink from '../../../../../components/presigned_url_link/presigned_url_link'
import TimeToComplete from '../../../commons/time_to_complete'
import withCancellationHandling from '../with_cancellation_handling'
import workflowFetch from '../../../../../query/rest/workflow_fetch'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData: {
    files?: string[]
    signature_request_id: string
    userInput?: {
      completedHelloSign?: boolean
      completedSupplementalInformation?: boolean
      backgroundAnsweredYes?: boolean
    }
  }
}

const getIcon = (checked?: boolean) => {
  const props = {
    style: {
      width: '1.25rem',
      height: '1.25rem'
    }
  }
  return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
}

const Appointment = (props: Props) => {
  const { plateName, workflowId, doNext, doUpdate, pageData } = props

  const fileInput = useRef<HTMLInputElement | null>(null)

  const { userInput = {}, files = [] } = pageData

  const [completedHelloSign, setCompletedHelloSign] = useState(userInput.completedHelloSign)
  const [backgroundAnsweredYes, setBackgroundAnsweredYes] = useState(
    userInput.backgroundAnsweredYes
  )
  const [completedSupplementalInformation, setCompletedSupplementalInformation] = useState(
    userInput.completedSupplementalInformation
  )
  const [showError, setShowError] = useState(false)

  const allCompleted = !!completedSupplementalInformation && !!completedHelloSign

  const [currentFiles, setCurrentFiles] = useState<string[]>(files)

  const [file, setFile] = useState<File | undefined>()

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files![0]) {
      setFile(e.target.files![0])
    }
  }

  const setSnackbar = useSnackbar()

  const submitFile = () => {
    const formData = new FormData()

    if (file) {
      formData.append('file', file)
    }
    for (const file of currentFiles) {
      formData.append('currentFiles[]', file)
    }

    formData.append(
      'pageData',
      JSON.stringify({
        ...pageData,
        userInput: {
          ...userInput,
          backgroundAnsweredYes: true
        }
      })
    )

    workflowFetch({
      url: `/${workflowId}/upload`,
      data: formData
    })
      .then(d => d.json())
      .then(data => {
        setCurrentFiles(data.files)
        setSnackbar({
          message: 'Successfully updated.',
          severity: 'success'
        })
      })
      .catch(ex => {
        setSnackbar({
          message: 'Error uploading file',
          severity: 'error'
        })
      })
  }

  const handleNext = () => {
    if (!allCompleted) {
      setShowError(true)
      return
    }
    const userInput = {
      backgroundAnsweredYes,
      completedSupplementalInformation,
      completedHelloSign
    }
    doNext?.('next', userInput, pageData)
  }

  const handleSignatureFinish = () => {
    const data = {
      pageData: {
        ...pageData,
        userInput: {
          ...pageData?.userInput,
          completedHelloSign: true
        }
      },
      progress: {
        name: plateName,
        status: 'solid'
      },
      nextProgress: 'Paperwork',
      nextPage: {
        name: 'Paperwork'
      }
    }
    doUpdate?.('HelloSignSubmit', data)
    setCompletedHelloSign(true)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Appointment - Paperwork</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item container direction='column' spacing={1}>
        {showError && !completedHelloSign && (
          <Grid item>
            <Message type='warning'>You must complete this action item</Message>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(completedHelloSign)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                1. Complete and Submit Paperwork on HelloSign
              </span>
            }
          >
            Please launch HelloSign using the button below and complete the appointment form. Some
            fields will be pre-filled for you. Enter all the required details correctly. If you have
            any questions, please reach out to the contracting team.
            <br />
            <HelloSignLauncher
              signatureRequestId={pageData.signature_request_id}
              onSuccess={handleSignatureFinish}
              completedHelloSign={completedHelloSign || false}
            />
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={completedHelloSign || false}
                onChange={() => {
                  if (!completedHelloSign) {
                    setSnackbar({
                      message:
                        'Please complete your paperwork on HelloSign to mark this step as complete.',
                      severity: 'info'
                    })
                  }
                }}
                label='I’ve Completed My Paperwork on HelloSign'
              />
            </Grid>
          </Accordion>
        </Grid>
        {showError && !completedSupplementalInformation && (
          <Grid item>
            <Message type='warning'>You must complete this action item</Message>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(completedSupplementalInformation)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                2. Supplemental Information
              </span>
            }
          >
            <Image
              style={{ width: '100%', marginTop: '0.5rem' }}
              alt='supplemental information'
              src='/images/carriers/life/lga/smartflow/supplemental_information.png'
            />
            <br />
            <strong>Did you answer “yes” to any of the background questions on HelloSign?</strong>
            <RadioGroup
              value={backgroundAnsweredYes ? 'yes' : 'no'}
              onChange={e => setBackgroundAnsweredYes(e.target.value === 'yes')}
            >
              <FormControlLabel value='yes' control={<Radio />} label='Yes' />
              <FormControlLabel value='no' control={<Radio />} label='No' />
            </RadioGroup>
            {backgroundAnsweredYes && (
              <div>
                <Typography gutterBottom>
                  Please provide supporting documents. Make sure you attach{' '}
                  <strong>all necessary documents</strong> to ensure that your appointment request
                  is processed without any delays.
                </Typography>
                <input
                  ref={fileInput}
                  type='file'
                  accept='.jpg,.png,.pdf,.jpeg,.doc,.docx'
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                <div>
                  <Button
                    color='secondary'
                    onClick={() => {
                      fileInput.current!.click()
                    }}
                  >
                    Select File
                  </Button>{' '}
                  <br />
                  {file && (
                    <Box mt={1}>
                      <strong>{file.name}</strong>
                    </Box>
                  )}
                  <Box mt={1}>
                    <ol>
                      {currentFiles.map(fileUrl => (
                        <li key={fileUrl}>
                          <PresignedUrlLink url={fileUrl} />
                          <IconButton
                            onClick={() => {
                              setCurrentFiles(currentFiles.filter(file => file !== fileUrl))
                            }}
                          >
                            <DeleteForeverRounded color='primary' />
                          </IconButton>
                        </li>
                      ))}
                    </ol>
                  </Box>
                </div>
                <Box mt={2} display='flex' justifyContent='flex-end'>
                  <Button startIcon={<PublishIcon />} color='secondary' onClick={submitFile}>
                    Submit
                  </Button>
                </Box>
              </div>
            )}
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={completedSupplementalInformation || false}
                onChange={value => setCompletedSupplementalInformation(value)}
                label='I’ve Completed The Necessary Supporting Documents'
              />
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container item justify='flex-end'>
        <Box width='100%' display='flex' alignItems='center' justifyContent='flex-end'>
          {showError && !allCompleted && (
            <Box mr='1rem'>
              <Message type={'warning'}>Action items incomplete</Message>
            </Box>
          )}
          <Button onClick={handleNext}>Next: Carrier Review</Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default withCancellationHandling(Appointment)
