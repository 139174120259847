import React from 'react'

import { Grid, Link } from '@material-ui/core'

import Button from 'components/button'
import Note from 'components/box/note'
import Warning from 'components/box/warning'

import { PlateProps } from '../../../commons/plate'
import TimeToComplete from '../../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  invitationSent?: boolean
}

const EmailInviteSent = (props: Props) => {
  const { doNext, invitationSent } = props

  const handleNext = () => {
    doNext?.('next')
  }

  const tooLongInPage =
    !invitationSent &&
    props.dateStarted &&
    new Date().getTime() - new Date(props.dateStarted).getTime() > 3 * 86400 * 1000

  const title = invitationSent ? 'Invitation Sent' : 'Invitation - Confirm Email Invite'

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>{title}</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      {tooLongInPage ? (
        <Grid item>
          <Warning>
            It looks like you didn’t receive an invitation in the past 72 hours. Please request
            assistance from the Assurance team.
          </Warning>
        </Grid>
      ) : (
        <Grid item>
          <Note>
            <strong>Note:</strong> It can take up to 7 business days to get the appointment ID.
            After you get it, you will be taken to Molina's{' '}
            <Link rel='noreferrer' target='_blank' href={'https://platform.sircon.com/#/login'}>
              Producer Portal
            </Link>
            :
          </Note>
        </Grid>
      )}
      <Grid item>
        Assurance IQ's licensing team has requested for an invitation on your behalf. Please check
        your{' '}
        <Link target='_blank' rel='noreferrer' href='https://outlook.office365.com/mail/'>
          <strong>@Assurance.com</strong>
        </Link>{' '}
        email account for:
        <br />
        <br />
        <div>
          <strong>From:</strong>{' '}
          <Link target={'_blank'} rel={'noreferrer'} href={'mailto:molinahealthcare@vertafore.com'}>
            molinahealthcare@vertafore.com
          </Link>
        </div>
        <div>
          <strong>Subject:</strong> Onboarding - Agent email address
        </div>
        <br />
        Once you receive the invitation, come back to this page and see what to do next.
      </Grid>
      {invitationSent && (
        <Grid container item justify='flex-end' spacing={1}>
          <Grid item>
            <Button onClick={handleNext}>Next: Appointment</Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default EmailInviteSent
