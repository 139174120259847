import React from 'react'

import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'

import Button from 'components/button'
import Warning from 'components/box/warning'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const handleNext = () => {
  //TODO (hernan) implement restart paperwork
}

const CarrierReview = (props: Props) => {
  const { progressItems, pageData } = props
  const appointment = progressItems.find(item => item.name === 'Paperwork')!

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>{props.carrierName} Carrier Review</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      {appointment.status === 'checked' && (
        <>
          <Grid item>Well done! Sit back, relax, and we will update your progress.</Grid>
          <Grid item>
            Sometimes {props.carrierName} might require additional information from you. Please
            monitor your inbox closely and reply to the carrier requests promptly.
          </Grid>
          <Grid item>
            Note: This process could take up to 14 days. If it has been longer than that, please
            reach out to the Assurance contracting team at{' '}
            <Link
              target='_blank'
              rel='noreferrer'
              href={'mailto:lifecarrierappointments@assurance.com'}
            >
              lifecarrierappointments@assurance.com
            </Link>{' '}
            and they will inquire on your behalf.
          </Grid>
        </>
      )}
      {appointment.status === 'error' &&
        pageData?.restarted(
          <>
            <Grid item>
              <Warning>
                <strong>Current Status:</strong> Paperwork needs to be restarted.
              </Warning>
            </Grid>
            <Grid item>
              Oh no! It looks like the paperwork you had previously submitted has expired. This
              usually happens when a carrier requires additional information from either you or the
              agency and they haven't heard back. Please restart the paperwork step and reach out to
              the contracting team for more information.
            </Grid>
            <Grid container item justify='flex-end'>
              <Button onClick={handleNext}>Restart paperwork</Button>
            </Grid>
          </>
        )}
      {appointment.status === 'error' && (
        <>
          <Grid item>
            <Warning>
              <strong>Current Status:</strong> {pageData.carrierName} denied appointment.
            </Warning>
          </Grid>
          <Grid item>
            {pageData.carrierName} has denied your request for appointment. Please reach out to the
            carrier licensing team at{' '}
            <Link target='_blank' rel='noreferrer' href={'mailto:agentlicensing@bannerlife.com'}>
              agentlicensing@bannerlife.com
            </Link>{' '}
            requesting additional information.
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default CarrierReview
