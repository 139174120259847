import React, { useState } from 'react'

import { Divider, FormControlLabel, Grid, Radio, RadioGroup, makeStyles } from '@material-ui/core'

import Button from 'components/button'
import Note from 'components/box/note'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

enum CarrierStatus {
  ReleaseLetter = 'releaseLetter',
  UnknownUpline = 'unknownUpline',
  NoReleaseLetter = 'noReleaseLetter'
}

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    carrierStatus: CarrierStatus
  }
}

const useStyles = makeStyles(theme => ({
  button: () => ({
    width: 'auto'
  })
}))

const PreviouslyAppointed = (props: Props) => {
  const { pageData, doNext } = props

  const classes = useStyles()

  const [choice, setChoice] = useState<CarrierStatus | undefined>(pageData?.carrierStatus)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChoice(e.target.value as CarrierStatus)
  }

  const handleNext = () => {
    const data = {
      carrierStatus: choice
    }
    doNext?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Eligibility - Previously Appointed with Aetna</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        We just pulled the information from the National Insurance Producer Registry (NIPR).
      </Grid>
      <Grid item>
        <Note>
          <strong>Current Status:</strong> You are appointed with Aetna under an upline that is not
          Assurance IQ. Continuing with Assurance IQ as your new upline will not impact your
          commissions or renewals earned to-date with your prior upline.
        </Note>
      </Grid>
      <Grid item>
        <strong>A release letter is required to proceed.</strong>
        <RadioGroup value={choice || ''} onChange={handleChange}>
          <FormControlLabel
            value={CarrierStatus.ReleaseLetter}
            control={<Radio color='primary' />}
            label='I have a Transfer Release Form from my previous agency'
          />
          <FormControlLabel
            value={CarrierStatus.NoReleaseLetter}
            control={<Radio color='primary' />}
            label='I do not have a Transfer Release Form from my previous agency'
          />
          <FormControlLabel
            value={CarrierStatus.UnknownUpline}
            control={<Radio color='primary' />}
            label='I don’t know who my upline is right now'
          />
        </RadioGroup>
      </Grid>
      <Divider style={{ margin: '1.5rem 0' }} />
      {choice === CarrierStatus.ReleaseLetter && (
        <Grid item>
          <strong>
            Please try to log in to your <u>Aetna Producer World account</u>
          </strong>
          .
          <br />
          <br />
          <form
            target='_blank'
            method='get'
            action={'https://www.aetna.com/producer_public/login.fcc'}
          >
            <Button type='submit' color='secondary' className={classes.button}>
              Launch Aetna Producer World
            </Button>
          </form>
          <br />
          <br />
          If you can’t login in to the Aetna Producer World, please call the Aetna Medicare Broker
          Services Department at <strong>1-866-714-9301</strong> from 8:00 AM to 8:00 PM EST Monday
          through Friday to reset your email and/or password.
        </Grid>
      )}
      {choice === CarrierStatus.NoReleaseLetter && (
        <Grid item>
          You will need to obtain a Transfer Release Form from your upline agency to continue with
          appointments.
          <br />
          <br />
          <form
            target='_blank'
            method='get'
            action='/docs/Notice of Intent-Transfer Release Form.pdf'
          >
            <Button type='submit' color='secondary' className={classes.button}>
              Download Aetna’s Transfer Release Form
            </Button>
          </form>
          <br />
          <br />
          Try again later.
        </Grid>
      )}
      {choice === CarrierStatus.UnknownUpline && (
        <Grid item>
          If you are unsure of who your current upline is, please contact Aetna’s Medicare Broker
          Services Department at <strong>1-866-714-9301</strong> from 8:00 AM to 8:00 PM EST Monday
          through Friday to inquire.
          <br />
          <br />
          Try again later.
        </Grid>
      )}
      {choice === CarrierStatus.ReleaseLetter && (
        <Grid container item justify='flex-end'>
          <Button onClick={handleNext}>Next: Invitation</Button>
        </Grid>
      )}
    </Grid>
  )
}

export default PreviouslyAppointed
