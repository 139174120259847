import React from 'react'

import { Grid } from '@material-ui/core'

import Warning from 'components/box/warning'

import { PlateProps } from '../../../plate'

export const withRtsReversalCheck = (Component: React.FC<PlateProps>) => (props: PlateProps) => {
  const revertedMessage = props.pageData?.message

  if (revertedMessage) {
    return (
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <h2 style={{ fontSize: '1.5rem' }}>Data Sync</h2>
        </Grid>
        <Grid item>
          <Warning>{revertedMessage}</Warning>
        </Grid>
      </Grid>
    )
  }

  return <Component {...props} />
}

export default withRtsReversalCheck
