import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  makeStyles
} from '@material-ui/core'

import Button from 'components/button'
import { Carrier, LOI, carrierFullName } from 'store/carriers'
import Dropdown from 'components/dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WorkflowStatus } from 'store/workflow_status'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import Carriers from '../../carrier_data'
import GetStarted from './plates/get_started'
import Loading from './plates/loading'
import Stages from '../../commons/stages'

const useStyles = makeStyles(theme => ({
  plateWrapper: {
    '& > *': {
      maxWidth: '50%',
      margin: 'auto'
    },
    '& .MuiButton-root': {
      borderRadius: '2rem',
      paddingLeft: '2rem',
      paddingRight: '2rem'
    }
  },
  redButton: {
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.dark
    }
  }
}))

export type WorkflowStatusApiResponse = Omit<WorkflowStatus, 'appointmentRequestId'>

interface Props {
  carrierName: Carrier
  loi?: LOI
  planYear?: number
  workflowId?: number | string
  workflowStatus?: WorkflowStatusApiResponse
  lastSynced: string
  plateComponentMap: Record<string, React.FC<React.ComponentProps<any>>>
  plateLabelMap: Record<string, string>
  showCancelDropdown?: boolean
  loading?: boolean
  handleStart: () => void
  handleUpdate: (actionName: string, data?: Record<string, any>) => void
  handleNext: (
    actionName: string,
    userInput?: Record<string, any>,
    pageData?: Record<string, any>
  ) => void
  cancelCarrierAppointment: () => void
  restartCarrierAppointment: () => void
}

const BaseSmartFlow = (props: Props) => {
  const {
    workflowId,
    workflowStatus,
    carrierName,
    planYear = new Date().getUTCFullYear(),
    loading,
    lastSynced,
    plateComponentMap,
    plateLabelMap,
    showCancelDropdown,
    handleNext,
    handleStart,
    handleUpdate,
    cancelCarrierAppointment,
    restartCarrierAppointment
  } = props

  const loi = props.loi ? props.loi : LOI.Medicare

  const history = useHistory()

  const classes = useStyles()

  const currentProgress = workflowStatus?.currentProgress

  const progressItems = workflowStatus?.progressList || []

  const [cancelDialog, setShowCancelDialog] = useState(false)

  const cancelled = !loading && workflowStatus?.requestStatus === 'Cancelled'

  const plate = cancelled
    ? workflowStatus?.cancelledBy === 'user'
      ? 'CanceledByAgent'
      : 'Cancelled'
    : currentProgress

  const Component =
    plate && (plateComponentMap[plate] || (() => <div>Unsupported page {currentProgress}</div>))

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [Component])

  // JOIN-4129: Force "BackgroundCheck" task to be hidden so it no longer shows up for old workflows
  // TODO: Remove this once all workflows have been migrated to the version without BackgroundCheck or it is no longer
  //  needed
  if (carrierName === Carrier.Lumico) {
    workflowStatus?.progressList
      .filter(progressItem => progressItem.name === 'BackgroundCheck')
      .forEach(progressItem => {
        progressItem.hidden = true
      })
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div>
            <Button variant='text' onClick={() => history.push('/carrier_appointments')}>
              <FontAwesomeIcon
                style={{ marginRight: '0.5rem', fontSize: '1.4rem' }}
                icon={faChevronLeft}
              />
              <u>Back to All Carriers</u>
            </Button>
          </div>
          {!cancelled && currentProgress && (
            <Box display='flex' alignItems='center'>
              <div style={{ color: '#666', marginRight: '0.5rem' }}>Last Synced: {lastSynced}</div>
              {showCancelDropdown && (
                <Dropdown
                  onChange={key => {
                    if (key === 'cancel') {
                      setShowCancelDialog(true)
                    }
                  }}
                  labelSelector={option => option.label}
                  keySelector={option => option.value}
                  titleSelector={_ => 'Cancel Appointment'}
                  options={[{ label: 'Cancel', value: 'cancel' }]}
                />
              )}
            </Box>
          )}
        </div>
      </Grid>
      {loading && (
        <Grid item>
          <Loading carrierName={carrierName} loi={loi} />
        </Grid>
      )}
      <Dialog onClose={() => setShowCancelDialog(false)} open={cancelDialog}>
        <DialogTitle>
          <strong>Cancel Carrier Appointment?</strong>
        </DialogTitle>
        <DialogContent dividers>
          <List>
            <strong>
              Are you sure you want to cancel your carrier appointment process with{' '}
              {carrierFullName(carrierName)}?
            </strong>
            <br />
            <br />
            Canceling your appointment process with {carrierFullName(carrierName)} will discard all
            of the progress you’ve made. And in the future if you wish to be appointed by{' '}
            {carrierFullName(carrierName)}, you’ll need to redo the entire appointment process
            again.
          </List>
        </DialogContent>
        <DialogActions>
          <Button variant='text' onClick={() => setShowCancelDialog(false)}>
            <u>Go Back, I Want to Get Appointed</u>
          </Button>
          <Button
            className={classes.redButton}
            onClick={() => {
              cancelCarrierAppointment()
              setShowCancelDialog(false)
            }}
          >
            Cancel Carrier Appointment
          </Button>
        </DialogActions>
      </Dialog>
      {!loading && (
        <>
          {Component && (
            <Grid
              container
              item
              direction='row'
              alignItems='center'
              style={{
                flexWrap: window.innerWidth < 1200 ? 'wrap' : 'nowrap'
              }}
            >
              <img
                alt='logo'
                style={{ maxWidth: '8.5rem' }}
                src={Carriers[loi][carrierName]?.Logo}
              />
              <Divider flexItem orientation='vertical' style={{ marginLeft: '2rem' }} />
              <Stages
                currentItemKey={currentProgress}
                items={progressItems
                  .filter(item => !item.hidden)
                  .map(item => ({
                    label: plateLabelMap[item.name],
                    status: item.status as any,
                    key: item.name
                  }))}
              />
            </Grid>
          )}
          <Grid item className={classes.plateWrapper}>
            {Component ? (
              <Component
                carrierName={carrierName}
                planYear={planYear}
                loi={loi}
                workflowId={workflowId}
                plateName={workflowStatus?.currentProgress || ''}
                pageName={workflowStatus?.pageName || ''}
                currentTask={workflowStatus?.currentTask}
                pageData={workflowStatus?.pageData || {}}
                progressItems={progressItems}
                dateStarted={progressItems.find(row => row.name === currentProgress)?.dateStarted}
                timeToComplete={
                  progressItems.find(row => row.name === currentProgress)?.timeToComplete
                }
                dateLastSynced={
                  progressItems.find(row => row.name === currentProgress)?.dateLastSynced
                }
                cancellationDate={workflowStatus?.cancellationDate}
                cancellationReason={workflowStatus?.cancellationReason}
                cancelledBy={workflowStatus?.cancelledBy}
                doNext={handleNext}
                doUpdate={handleUpdate}
                doRestart={restartCarrierAppointment}
              />
            ) : (
              <GetStarted carrierName={carrierName} loi={loi} onStart={handleStart} />
            )}
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default BaseSmartFlow
