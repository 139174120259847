import React from 'react'

import { Link } from '@material-ui/core'

import Note from 'components/box/note'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper from '../commons/instructions_wrapper'

interface Props {
  carrierName: string
  loi: string
}

const CignaMedicareChecklist = (props: Props) => {
  return (
    <InstructionsWrapper {...props}>
      <Note>
        We prepare files to send to Cigna every Wednesday morning at 10:00AM EST. Each week, only
        one bulk file is sent to Cigna to initiate new onboarding requests as well as new state
        adds.
      </Note>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Request for an Appointment
          </span>
        }
      >
        {/*<p>*/}
        {/*  To indicate your interest in getting started with appointments, you need to request for an*/}
        {/*  invitation.*/}
        {/*</p>*/}
        {/*<Note>*/}
        {/*  Please check this&nbsp;*/}
        {/*  <Link*/}
        {/*    target='_blank'*/}
        {/*    rel='noreferrer'*/}
        {/*    href='https://myassurance-my.sharepoint.com/:x:/g/personal/wbollu_assurance_com/Ea2afeKsZRpDodLl-KRVVMkBFI_1hiQYWbtbboXCE5eg6w?e=eTaW6p'*/}
        {/*  >*/}
        {/*    Assurance Excel Spreadsheet*/}
        {/*  </Link>*/}
        {/*  &nbsp;(Note: you must be signed in to view) to see if you have been sent for processing*/}
        {/*  and when. If you are on this list, you do not need to send another request. If you do, it*/}
        {/*  will complicate your file and result in massive delays on Cigna’s front. If something*/}
        {/*  looks or sounds off, please contact us at&nbsp;*/}
        {/*  <Link target='_blank' rel='noreferrer' href='mailto:MedicareLicensing@assurance.com'>*/}
        {/*    MedicareLicensing@assurance.com*/}
        {/*  </Link>*/}
        {/*  .*/}
        {/*</Note>*/}
        <p>Prepare the following information for the invitation form:</p>
        <ol>
          <li>Full Name as it appears in NIPR</li>
          <li>Assurance Email Address (mandatory accuracy)</li>
          <li>NPN Number</li>
        </ol>
        <p>
          Please ensure you are using your Assurance email address to fill out the form. Using the
          wrong email addresses will error out and will further delay your appointment process for
          Cigna.
        </p>
        <p>
          <Link
            target='_blank'
            rel='noreferrer'
            href='https://assuranceiq.formstack.com/forms/cigna_medadvantage'
          >
            Click to request for a Cigna appointment invitation
          </Link>
          .
        </p>
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            2. Register &amp; Complete Certifications
          </span>
        }
      >
        <strong>Instructions:</strong>
        <p>
          <Link
            target='_blank'
            rel='noreferrer'
            href='https://help.assurance.com/en/articles/8208809-how-do-i-get-appointed-with-cigna'
          >
            Assurance Help: How do I get appointed with Cigna for Medicare Advantage?
          </Link>
        </p>
        <p>
          Once Cigna processes the bulk appointment file, you will receive an email inside your
          Assurance inbox. From here, follow the instructions on how to get going.
        </p>
        <ol>
          <li>Complete Registrations in order to create an account.</li>
          <li>Complete Certification process.</li>
        </ol>
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>3. Wait for Data to Sync</span>
        }
      >
        <p>
          It can take several days for Cigna to complete their review of your profile and for us to
          get the data inside our systems. We receive files from Cigna once a week to confirm RTS
          statuses.
        </p>
        <p>The faster you do the above processes, the faster the sync will be.</p>
        <p>
          Should you have any issues, please notify us at&nbsp;
          <Link target='_blank' rel='noreferrer' href='mailto:join-medicare@assurance.com'>
            join-medicare@assurance.com
          </Link>
          .
        </p>
      </Accordion>
    </InstructionsWrapper>
  )
}

export default CignaMedicareChecklist
