import React, { useState } from 'react'

import { Grid, Link, Typography } from '@material-ui/core'

import Box from 'components/box'
import Button from 'components/button'
import Image from 'components/image'
import { Loading } from 'components/loading'
import Message from 'components/message/Message'
import Note from 'components/box/note'
import { RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'

import Accordion from '../../../commons/checklist_accordion'
import { PlateProps } from '../../../commons/plate'
import TimeToComplete from '../../../commons/time_to_complete'
import { carrierFullName } from '../../../../../store/carriers'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    completed_work_orders?: number
    agent_type?: 'ondemand' | 'w2' | string
    training_requirement?: boolean
  }
}

const getIcon = (checked?: boolean) =>
  checked ? <RoundCheckedIcon blue /> : <RoundUncheckedIcon />

const AiqEligibilityTraining = (props: Props) => {
  const { carrierName, doNext, pageData } = props

  const agentType = pageData?.agent_type

  const agentTypeLoaded = agentType != null

  const workOrderRequirements =
    agentTypeLoaded &&
    (agentType !== 'ondemand' ||
      (pageData?.completed_work_orders != null && pageData?.completed_work_orders >= 2))

  const training = !!pageData?.training_requirement

  const [showError, setShowError] = useState(false)

  const allCompleted = workOrderRequirements && training

  const handleNext = () => {
    if (!allCompleted) {
      setShowError(true)
      return
    }
    doNext?.('next', {}, pageData)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Assurance IQ Eligibility & Training</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <Note>
          <strong>Note:</strong> To start the appointment paperwork, you need to meet the minimum
          requirement for work orders and complete the mandatory training. Please complete the
          necessary requirements and these sections should update automatically.
        </Note>
      </Grid>
      {showError && !workOrderRequirements && (
        <Grid item>
          <Message type='warning'>You must complete this action item</Message>
        </Grid>
      )}
      <Grid item>
        <Accordion
          icon={getIcon(workOrderRequirements)}
          title={
            <Box spacing='0.5rem' display='flex' alignItems='center'>
              <span>1. Work Order Requirements</span>
              {!agentTypeLoaded ? (
                <Loading />
              ) : (
                agentType !== 'ondemand' && <span>- Not Required</span>
              )}
            </Box>
          }
        >
          {!agentTypeLoaded ? (
            'We are fetching data for this checklist step. Please wait.'
          ) : workOrderRequirements ? (
            <div>You meet the minimum work order requirements. Great job!</div>
          ) : (
            <div>
              You do not meet the minimum work hour requirements. Please{' '}
              <strong>complete at least 2 work orders</strong> and check back.
            </div>
          )}
        </Accordion>
      </Grid>
      {showError && !training && (
        <Grid item>
          <Message type='warning'>You must complete this action item</Message>
        </Grid>
      )}
      <Grid item>
        <Accordion
          icon={getIcon(training)}
          title={<span style={{ display: 'flex', alignItems: 'center' }}>2. Training</span>}
        >
          <div>
            <Box mb={2} component={Typography}>
              1. Log into Axonify by clicking on the button below.
            </Box>
            <Button color='secondary' target='_blank' href='https://aiq.axonify.com/training/'>
              Axonify Training Link
            </Button>
            <Image
              style={{ width: '100%', marginTop: '1rem' }}
              alt='axonify login'
              src='/images/carriers/life/prudential/smartflow/axonify_login.png'
            />
            <br />
            <p>
              2. You should have been assigned a Training Path of {carrierFullName(carrierName)}.
              Click on the path and complete all the modules. Once you complete the training, this
              step will be checked automatically and you will be able to move on to the next step.
            </p>
            <Image
              style={{ width: '100%', marginTop: '0.5rem' }}
              alt='moo training path'
              src='/images/carriers/life/prudential/smartflow/training_path.png'
            />
            <Box mt={1} mb={1}>
              If you do not see the training path assigned to you and this step is not marked
              completed, please reach out to the contracting team at{' '}
              <Link target='_blank' href={'mailto:lifecarrierappointments@assurance.com'}>
                lifecarrierappointments@assurance.com
              </Link>{' '}
              to further assist you.
            </Box>
            <Note>
              Note: It may take up to one hour for systems to sync your completion status once you
              complete it. Please give it sometime to refresh before you reach out to the
              contracting team.
            </Note>
          </div>
        </Accordion>
      </Grid>
      <Grid container item justify='flex-end'>
        <Box width='100%' display='flex' alignItems='center' justifyContent='flex-end'>
          {showError && !allCompleted && (
            <Box mr='1rem'>
              <Message type={'warning'}>Action items incomplete</Message>
            </Box>
          )}
          <Button disabled={!agentTypeLoaded} onClick={handleNext}>
            Next: Paperwork
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default AiqEligibilityTraining
