import gql from 'graphql-tag'

const CARRIER_RTS_FILE = gql`
  query carrierRtsFile($first: Int!, $offset: Int!, $sortBy: CarrierRtsFileSortingInput) {
    carrierRtsFile(first: $first, offset: $offset, sortBy: $sortBy) {
      totalCount
      edges {
        node {
          id
          fileUrl
          uploader {
            id
            email
            firstName
            lastName
          }
          carrier {
            id
            name
            avaliableState
            loi
            states
          }
          metadata
          updatedAt
          createdAt
        }
      }
    }
  }
`

export default CARRIER_RTS_FILE
