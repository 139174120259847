import React from 'react'
import { useHistory } from 'react-router-dom'

import { Grid, makeStyles } from '@material-ui/core'

import Button from 'components/button'
import SuccessBox from 'components/box/success_box'
import { carrierFullName } from 'store/carriers'
import withCancellationHandling from 'screens/carrier_appointments/life/lga/with_cancellation_handling'

import { PlateProps } from '../../../plate'
import { withRtsReversalCheck } from './rts_reversal'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const useStyles = makeStyles(theme => ({
  grey: {
    color: theme.palette.grey[500]
  }
}))

const DataSync = withRtsReversalCheck((props: Props) => {
  const { carrierName } = props

  const classes = useStyles()

  const history = useHistory()

  const status = props.progressItems.find(row => row.name === props.plateName)?.status

  const handleContinue = () => {
    history.push('/carrier_appointments')
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Data Sync</h2>
      </Grid>
      {status === 'checked' && (
        <Grid item>
          <SuccessBox>
            <strong>Congratulations!</strong> You’re now appointed by {carrierFullName(carrierName)}
            .
          </SuccessBox>
        </Grid>
      )}
      {status === 'checked' && (
        <Grid item container direction='column' alignItems='center'>
          <img src={'/images/data_sync_complete.svg'} />
        </Grid>
      )}
      {status !== 'checked' && (
        <Grid item container direction='column' alignItems='center'>
          <img src={'/images/data_sync_progress.svg'} />
          <span className={classes.grey}>
            Data syncing between {carrierFullName(carrierName)} and Assurance.
          </span>
        </Grid>
      )}
      {status === 'checked' && (
        <Grid container item justify='flex-end'>
          <Button onClick={handleContinue}>Continue</Button>
        </Grid>
      )}
    </Grid>
  )
})

export default withCancellationHandling(DataSync)
