import React, { Dispatch, SetStateAction, useState } from 'react'

import { Grid } from '@material-ui/core'

import AhipCertified from './ahip_certified'
import AhipNotCertified from './ahip_not_certified'
import { AhipWorkflowStepDetails } from '../../ahip.d'

interface Props {
  year: number
  page_data: AhipWorkflowStepDetails
  getWorkflowUpdates: () => Promise<void>
}

const CreateAndLinkAccount = (props: Props) => {
  const { page_data, year, getWorkflowUpdates } = props
  const [linkedAccount, setLinkedAccount] = useState(false)

  // TODO: draw AhipCertified if has_acc_data is present in response
  return (
    <Grid
      container
      direction='column'
      alignItems='stretch'
      style={{
        margin: '2% 26% 0 26%'
      }}
      spacing={2}
    >
      {linkedAccount ? (
        <AhipCertified year={year} getWorkflowUpdates={getWorkflowUpdates} />
      ) : (
        <AhipNotCertified year={year} page_data={page_data} setLinkedAccount={setLinkedAccount} />
      )}
    </Grid>
  )
}

export default CreateAndLinkAccount
