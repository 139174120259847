import React from 'react'

import { Grid, Link } from '@material-ui/core'

import Note from 'components/box/note'

interface TransitionProps {
  year: number
}

const Transition = (props: TransitionProps) => {
  const { year } = props

  return (
    <Grid
      container
      direction='column'
      spacing={2}
      style={{ margin: '30px 24% 0 24%', maxWidth: '684px' }}
    >
      <Grid item>
        <h1 style={{ fontSize: '24px', paddingBottom: '12px' }}>FFM Certification for {year}</h1>
        <span>
          Your {year - 1} FFM certification allows you to enroll clients in ACA plans effective{' '}
          {year - 1} only, and expires on 10/31. To be eligible to sell ACA plans effective {year},
          you must complete the FFM Plan Year {year} certification when released.
        </span>
      </Grid>
      <Grid item>
        Please check back here on September 1 for instructions to start the certification process.
      </Grid>
      <Grid item>
        <Note>
          <strong>Note:</strong> the FFM Plan Year {year} certification may open before September 1;
          you can check the{' '}
          <Link target='_blank' rel='noreferrer' href='https://portal.cms.gov/portal/'>
            CMS Enterprise Portal
          </Link>{' '}
          for more information in the interim.
        </Note>
      </Grid>
    </Grid>
  )
}

export default Transition
