import { CarrierAppointmentRequest } from 'store/carrier_appointment_requests'
import { LOI } from 'store/carriers'
import { useQuery } from '@apollo/client'

import APPOINTMENT_REQUESTS from './appointment_requests.gql'

export const useAppointmentRequest = () => {
  const { refetch } = useQuery(APPOINTMENT_REQUESTS, {
    fetchPolicy: 'standby'
  })

  const getAppointmentRequest = (carrier: string, loi: LOI, planYear: number) => {
    const promise = refetch({
      carrier,
      loi,
      planYear
    })
    return promise.then(response => {
      if (response.data == null) {
        return undefined
      }
      const appointmentRequest: CarrierAppointmentRequest | undefined =
        response.data.me.carrierAppointmentRequests.edges[0]?.node

      return appointmentRequest
    })
  }

  return { getAppointmentRequest }
}
