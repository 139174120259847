import { Controller, useForm } from 'react-hook-form'
import React from 'react'

import { Divider, FormControlLabel, Grid, Link, Radio, RadioGroup } from '@material-ui/core'

import Button from 'components/button'
import Message from 'components/message/Message'
import Note from 'components/box/note'
import { getCarrierInfo } from 'store'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const NotAppointed = (props: Props) => {
  const { doNext } = props

  const form = useForm<{
    canLogInToPortal: string | null
  }>({
    defaultValues: {
      canLogInToPortal: null
    }
  })

  const { watch, errors, handleSubmit, control } = form
  const formValues = watch()

  // const aetna = getCarrierInfo('Aetna')

  const nextInvitation = () => {
    const data = {
      canLogInToPortal: formValues.canLogInToPortal
    }
    doNext?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Eligibility - Not Currently Appointed with Aetna</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        We just pulled the information from the National Insurance Producer Registry.
      </Grid>
      <Grid item>
        <Note>
          <strong>Current Status:</strong> You are currently NOT appointed with Aetna which means
          you qualify to continue with Assurance IQ as your new upline.
        </Note>
      </Grid>
      {/**<Grid item>
        You qualify to be appointed for the following{' '}
        <strong>{aetna?.states.length || 0} states in which Aetna operates in:</strong>
        <br />
        <br />
        {aetna?.states.join(', ') || '0 states available'}
      </Grid>**/}
      <Grid item>
        <Divider style={{ margin: '1rem 0' }} />
      </Grid>
      <Grid item>
        <strong>
          Do you have an{' '}
          <Link target='_blank' href='https://www.aetna.com/producer/Login.do'>
            Aetna Producer World
          </Link>{' '}
          portal account?
        </strong>
      </Grid>
      <Grid item>
        If you are not sure or cannot log in, please call <strong>Aetna at 1-866-714-9301</strong>{' '}
        to inquire. Their team can also help you update your email and/or reset your password. You
        should not continue until one of the following options is true for you.
      </Grid>
      <Grid item>
        <Controller
          name='canLogInToPortal'
          rules={{ required: 'This field is required.' }}
          control={control}
          as={
            <RadioGroup>
              <FormControlLabel
                value='yes'
                control={<Radio color='primary' />}
                label='Yes, I do have an Aetna Producer World portal account. I can successfully log in.'
              />
              <FormControlLabel
                value='no'
                control={<Radio color='primary' />}
                label='No, I do not have an Aetna Producer World portal account.'
              />
            </RadioGroup>
          }
        />
      </Grid>
      {errors.canLogInToPortal && <Message type='warning'>Please select an option</Message>}
      {formValues.canLogInToPortal && (
        <Grid container item justify='flex-end'>
          <Button onClick={handleSubmit(nextInvitation)}>Next: Invitation</Button>
        </Grid>
      )}
    </Grid>
  )
}

export default NotAppointed
