import { LogoutOptions, useAuth0 } from '@auth0/auth0-react'

const useLogout = () => {
  const { logout } = useAuth0()

  return (options?: LogoutOptions) => {
    console.log('Logging out')
    localStorage.removeItem('authToken')
    sessionStorage.removeItem('masqueradeId')
    ;(window as any).Intercom('shutdown')
    logout(options)
  }
}

export default useLogout
