import React from 'react'

import Note from 'components/box/note'
import styled from 'styled-components'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper from '../commons/instructions_wrapper'
import { NotReadyToSell } from '../commons/status'
import Select from '../../../components/select'
import { useCarrierAppointmentStatusForPlanYear } from '../hooks'

interface Props {
  carrierName: string
  loi: string
}

const CarrierStatusWrapper = styled.div`
  label {
    color: #071f2b;
    font-weight: 600;
  }
  > * + * {
    margin-top: 16px;
  }
`

const Checklist = (props: Props) => {
  const carrierStatus = useCarrierAppointmentStatusForPlanYear(
    props.carrierName,
    props.loi,
    new Date().getFullYear()
  )

  return (
    <InstructionsWrapper {...props}>
      <Note>
        <strong>October 22, 2020</strong>: We will no longer be reimbursing agents who accidentally
        went direct with Anthem. If you initiate your own appointment with Anthem and fill out
        paperwork, you've indicated your interest in working with Anthem directly. With that, we
        will not be reimbursing you for your state appointment fees paid to Anthem.
        <br />
        <br />
        We will review each file if your receipt states October 21, 2020 OR PRIOR. Please read
        paperwork carefully and do NOT initiate your own appointment with Anthem. Only fill out
        appointment IDs initiated by the Assurance team. These IDs are cyarboi, wbollu,
        clouis-assurance, jfleuriet, mbickett. Always triple check with your site manager or the
        Assurance team to make sure.
      </Note>
      <p>
        In order to continue to sell Anthem products on the Assurance platform, agents must get
        appointed with Anthem. The step-by-step process using the Anthem Producer Toolbox to get
        appointed is shown below.
        <br />
        <br />
        If you have questions on form completion, please contact the Anthem Licensing and
        Credentialing at 877-304-6470.
        <br />
        <br />
        If you are experiencing technical issues, please contact the Anthem Help Desk at
        888-268-4361.
      </p>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Request for an Appointment Invitation
          </span>
        }
      >
        Let us know you want an invitation to get started with appointment by filling out the{' '}
        <a
          target='_blank'
          rel='noreferrer'
          href='https://assuranceiq.formstack.com/forms/humana_appointment_requests'
        >
          Humana Invitation Request Form
        </a>
        .
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            2. Complete Your Background Check and get a SAN
          </span>
        }
      >
        <strong>Instructions:</strong>
        <br />
        <a target='_blank' rel='noreferrer' href='#'>
          Assurance Help: How do I get appointed with Humana? Part 1: Complete Your Background Check
          and get a SAN
        </a>
        <br />
        <br />
        The first part of any Medicare Advantage carrier appointment is to REQUEST AN INVITATION.
        You need to indicate your interest in wanting an appointment email from us before we can
        proceed.
        <br />
        <br />
        Click here:{' '}
        <a href='https://assuranceiq.formstack.com/forms/humana_appointment_requests'>
          https://assuranceiq.formstack.com/forms/humana_appointment_requests
        </a>
        <br />
        <br />
        If you meet Humana's requirements, you will receive a welcome email from Humnana to complete
        a background check. It can take up to 10 days for a full background check. This background
        check link is valid for 30 days.
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            3. Register with Vantage, Humana’s Agent Portal, and Complete Online Education
          </span>
        }
      >
        <strong>Instructions:</strong>
        <br />
        <a target='_blank' rel='noreferrer' href='#'>
          Assurance Help: How do I get appointed with Humana? Part 2: Register with Vantage,
          Humana’s Agent Portal, and Complete Online Education
        </a>
        <br />
        <br />
        Once your background check is completed, you will receive another email from Humana with a
        SAN number to register on{' '}
        <a href='#' target='_blank' rel='noreferrer'>
          Humana's Agent Portal
        </a>
        .
        <br />
        <br />
        Go to{' '}
        <a
          target='_blank'
          rel='noreferrer'
          href='https://slservices.humana.com/AgentRegistration/StepOnePage.aspx'
        >
          https://slservices.humana.com/AgentRegistration/StepOnePage.aspx
        </a>{' '}
        and click Get Started
      </Accordion>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            4. Indicate Appointment Status
          </span>
        }
      >
        <label>
          <strong>Self-Indicated Statuses</strong>
        </label>
        <Select
          selected='Unappointed'
          style={{ width: '200px' }}
          options={['Appointed', 'Unappointed']}
          keySelector={option => option}
          labelSelector={option => option}
        />
        <CarrierStatusWrapper>
          <label>Carrier Status</label>
          {carrierStatus !== 'APPOINTED' && <NotReadyToSell />}
        </CarrierStatusWrapper>
      </Accordion>
      <Note>
        If you are experiencing difficulties with the Medicare onboarding experience on the
        Assurance Platform, please check out our{' '}
        <a
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360049621952-Humana-FAQ-v-2020-'
        >
          Humana FAQs
        </a>{' '}
        or let us know at <strong>join-medicare@assurance.com</strong>.
        <br />
        <br />
        If you have questions on form completion, please contact the{' '}
        <strong>Humana Licensing and Credentialing</strong> at <strong>877-304-6470</strong>.
        <br />
        <br />
        If you are experiencing technical issues, please contact the{' '}
        <a target='_blank' rel='noreferrer' href='#'>
          <strong>Humana Help Desk</strong>
        </a>{' '}
        at <strong>xxx-xxx-xxxx</strong>.
      </Note>
    </InstructionsWrapper>
  )
}

export default Checklist
