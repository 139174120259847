import React from 'react'

import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'

import { Carrier, LOI } from 'store/carriers'

import { HelpDesk } from '../../utils/extras'
import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'
import { getIconForProgressStatus } from '../../commons/stages/item'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const CarrierReview = (props: Props) => {
  const { progressItems } = props
  const appointment = progressItems.find(item => item.name === 'Appointment')!

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Molina Carrier Review</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        Well done!
        <br />
        <br />
        Your work here is done for now. Sit back, relax, and we will update your progress.
        <br />
        <br />
        Note: if this is taking longer than expected, make sure you review the instructions again
        and check if you’ve completed all the components to your appointment paperwork.
        <br />
        <br />
        <Link
          target='_blank'
          rel='noreferrer'
          href={HelpDesk[LOI.Health][Carrier.Molina].instructionsLink}
        >
          Click to view instructions on How to get appointed with Molina to offer ACA plans.
        </Link>
      </Grid>
      <Grid item container>
        {getIconForProgressStatus(appointment.status)} Appointment Paperwork
      </Grid>
    </Grid>
  )
}

export default CarrierReview
