import React from 'react'

import Box from 'components/box'
import Button from 'components/button'
import { RestartIcon } from 'components/icons'

import CarrierTags from '../../carrier_tags'
import Hoverable from '../../hoverable'

interface Props {
  footer?: React.ReactNode
  onClose: () => void
  carrierTags: string[]
}

const getCarrierTagLabel = (carrierTag: string) => CarrierTags[carrierTag]?.Label || ''

const CardCarrierTagsBackView = (props: Props) => {
  const { carrierTags, onClose } = props

  return (
    <Box display={'flex'} flexDirection={'column'} height={'100%'} onClick={onClose}>
      <Button
        style={{ marginLeft: 'auto', padding: '0.5rem 0.75rem', minWidth: 0 }}
        variant='text'
        onClick={onClose}
      >
        <RestartIcon />
      </Button>
      <div style={{ flex: 1, overflow: 'auto', padding: '0 1rem 1rem 1rem' }}>
        {carrierTags
          .sort((a, b) => getCarrierTagLabel(a).length - getCarrierTagLabel(b).length)
          .map(carrierTag => (
            <div
              key={carrierTag}
              style={{
                display: 'flex',
                justifyContent: 'space-around'
              }}
            >
              <Hoverable
                style={{ margin: '0.25rem' }}
                open
                iconSrc={CarrierTags[carrierTag]?.Icon}
                label={CarrierTags[carrierTag]?.Label}
              />
            </div>
          ))}
      </div>
      {props.footer}
    </Box>
  )
}

export default CardCarrierTagsBackView
