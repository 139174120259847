import React from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import TimeToComplete from 'screens/carrier_appointments/commons/time_to_complete'
import Warning from 'components/box/warning'
import { carrierFullName } from 'store/carriers'

import { PlateLabelMap } from './plate_map'
import { PlateProps } from '../../commons/plate'

const ApiError = (props: PlateProps) => {
  const { carrierName, doRestart, plateName } = props

  const carrier = carrierFullName(carrierName)

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>{PlateLabelMap[plateName]}</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <Warning>Sorry, we encountered an error while communicating with {carrier}!</Warning>
      </Grid>
      <Grid container item justifyContent='flex-end' spacing={1}>
        <Grid item>
          <Button onClick={doRestart}>Restart Eligibility Check</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ApiError
