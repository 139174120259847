import { Tooltip, withStyles } from '@material-ui/core'

export const LightTooltip = withStyles(theme => ({
  tooltip: {
    border: '1px solid #ccc',
    padding: '1rem',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    minWidth: '20vw',
    boxShadow: theme.shadows[3],
    fontSize: '0.8rem'
  }
}))(Tooltip)
