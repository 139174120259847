import React from 'react'

import { Grid } from '@material-ui/core'

import { API_ENDPOINT } from 'utils/environment-variables'
import Button from 'components/button'
import SuccessBox from 'components/box/success_box'
import { useStoreState } from 'store'

import { AhipWorflowActions } from '../../ahip.d'
import { CertificateProviders } from '../../../certification.d'
import { updateWorkflowDetails } from '../../api'

interface Props {
  getWorkflowUpdates: () => Promise<void>
  year: number
}

const AhipCertified = (props: Props) => {
  const { year, getWorkflowUpdates } = props
  const userInfo = useStoreState(state => state.userInfo)

  const handleContinue = async () => {
    await updateWorkflowDetails({
      year: year,
      userInfoId: userInfo.id,
      action: AhipWorflowActions.CREATE_LINK_ACCOUNT_COMPLETED,
      certificateProvider: CertificateProviders.ahip,
      authToken: localStorage.getItem('authToken')
    })
    getWorkflowUpdates()
  }

  return (
    <>
      <Grid item>
        <h1 style={{ fontSize: '24px' }}>Create & Link Your AHIP Account</h1>
      </Grid>
      <Grid item>
        <SuccessBox>
          <strong>Good job!</strong> You've successfully linked your AHIP account to Assurance
        </SuccessBox>
      </Grid>
      <Grid item>
        <p>
          Please click <strong>Continue</strong> to proceed.
        </p>
      </Grid>
      <Grid container direction='column' alignItems='flex-end' spacing={4}>
        <Button style={{ minWidth: '216px', marginTop: '40px' }} onClick={handleContinue}>
          Continue
        </Button>
      </Grid>
    </>
  )
}

export default AhipCertified
