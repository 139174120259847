import styled from 'styled-components'

const InfoBox = styled.div`
  padding: 1rem;
  color: #007cc2;
  background: #f0faff;
  border: 1px solid #0082cb;
  border-radius: 4px;
`

export default InfoBox
