import gql from 'graphql-tag'

const FIELDS_CHOICES_QUERY = gql`
  query {
    agentOwners
    callCenters
  }
`

export default FIELDS_CHOICES_QUERY
