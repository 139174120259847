import React from 'react'

import styled from 'styled-components'

const DateField = styled.div`
  display: flex;
  align-items: stretch;
  background: white;

  padding: 0.375rem 1rem;

  &:focus {
    outline: 1px solid black;
  }

  input {
    text-transform: uppercase;
    background: transparent;
    flex: 1;
    padding-right: 0px;
    &:focus {
      outline: none;
    }
  }

  border: 1px solid rgba(51, 51, 51, 0.16);
  border-radius: 4px;

  > * {
    box-shadow: none;
    border-radius: 4px;
    border: none;
  }
`

interface Props {
  className?: string
  label?: React.ReactNode
  style?: Record<string, string | number>
  value?: string
  onChange?: (newDate: string) => void
}

const Label = styled.label`
  margin-bottom: 8px;
  font-weight: 600;
  display: block;
`

const Main = styled.div``

const DateComponent = (props: Props) => {
  const { className, value, style, label, onChange = () => void 0 } = props

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  return (
    <Main className={className} style={style}>
      {label && <Label>{label}</Label>}
      <DateField>
        <input type='date' value={value} onChange={handleChange} />
      </DateField>
    </Main>
  )
}

export default DateComponent
export { DateField }
