import React from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import SuccessBox from 'components/box/success_box'
import { carrierFullName } from 'store/carriers'

import { PlateProps } from '../../../plate'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  nextLabel: string
}

const NotAppointed = (props: Props) => {
  const { nextLabel, carrierName, doNext } = props

  const handleNext = () => {
    const data = {}
    doNext?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>
          Eligibility - Not Currently Appointed with {carrierFullName(carrierName)}
        </h2>
      </Grid>
      <Grid item></Grid>
      <Grid item>
        We just pulled the information from the National Insurance Producer Registry.
      </Grid>
      <Grid item>
        <SuccessBox>
          <strong>Current Status:</strong> You are currently NOT appointed with{' '}
          {carrierFullName(carrierName)} which means you qualify to continue with Assurance IQ as
          your new upline.
        </SuccessBox>
      </Grid>
      <Grid container item justify='flex-end'>
        <Button onClick={handleNext}>Next: {nextLabel}</Button>
      </Grid>
    </Grid>
  )
}

export default NotAppointed
