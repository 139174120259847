import React, { useState } from 'react'

import { Grid } from '@material-ui/core'
import Link from '@material-ui/core/Link'

import Box from 'components/box'
import Button from 'components/button'
import CheckboxButton from 'components/checkbox_button'
import Image from 'components/image'
import Message from 'components/message/Message'
import { RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'

import Accordion from '../../commons/checklist_accordion'
import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    userInput?: {
      checkEmail?: boolean
      loggedIn?: boolean
      completedPaperwork?: boolean
    }
  }
}

const getIcon = (checked?: boolean) => {
  const props = {
    style: {
      width: '1.25rem',
      height: '1.25rem'
    }
  }
  return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
}

const Appointment = (props: Props) => {
  const { plateName, doUpdate, pageData = {} } = props

  const { userInput = {} } = pageData

  const [checkEmail, setCheckEmail] = useState(userInput.checkEmail)
  const [logged, setLogged] = useState(userInput.loggedIn)
  const [completed, setCompleted] = useState(userInput.completedPaperwork)
  const [showError, setShowError] = useState(false)

  const handleNext = () => {
    const allCompleted = checkEmail && logged && completed
    if (!allCompleted) {
      setShowError(true)
      return
    }
    const data = {
      pageData: {
        userInput: {
          checkEmail,
          loggedIn: logged,
          completedPaperwork: completed
        }
      },
      progress: {
        name: plateName,
        status: allCompleted ? 'checked' : 'error'
      },
      nextProgress: 'CarrierReview',
      nextPage: {
        name: 'CarrierReview'
      }
    }
    doUpdate?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Appointment - Paperwork</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item container direction='column' spacing={1}>
        {showError && !checkEmail && (
          <Grid item>
            <Message type='warning'>You must complete this action item</Message>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(checkEmail)}
            title={<span style={{ display: 'flex', alignItems: 'center' }}>1. Check Email</span>}
          >
            Please check your Assurance email account for an email from Molina. It will show as:
            <br />
            <div>
              <div>
                <strong>From:</strong>{' '}
                <Link
                  target='_blank'
                  rel='noreferrer'
                  href='mailto:dont-reply-molinahealthcare@vertafore.com'
                >
                  dont-reply-molinahealthcare@vertafore.com
                </Link>
              </div>
              <div>
                <strong>Subject:</strong> Onboarding - Assurance agent email
              </div>
              <div>
                <strong>Recruiter Name:</strong> ASSURANCE IQ
              </div>
            </div>
            <div>
              a. We have initiated your subagent appointment through our agency. You should have
              received an email from{' '}
              <Link
                target={'_blank'}
                rel={'noreferrer'}
                href={'mailto:molinahealthcare@vertafore.com'}
              >
                molinahealthcare@vertafore.com
              </Link>
              . Click on <strong>“Click here to start onboarding”</strong>.
            </div>
            <div>
              Example of email below. If you do not receive an email in your inbox, please check
              your spam/junk folder as well.
              <br />
              <Image
                style={{ width: '100%', marginTop: '0.5rem' }}
                alt='onboarding invitation'
                src='/images/carriers/health/molina/smartflow/check_email.png'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={checkEmail || false}
                onChange={value => setCheckEmail(value)}
                label='I Received a Registration Email'
              />
            </Grid>
          </Accordion>
        </Grid>
        {showError && !logged && (
          <Grid item style={{ marginTop: '1rem' }}>
            <Message type='warning'>You must complete this action item</Message>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(logged)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                2. Log Into Producer Portal
              </span>
            }
          >
            <div>
              b. Once you click on the link in the email, you will be redirected to Molina's{' '}
              <Link target='_blank' rel={'noreferrer'} href='http://www.molinamarketplace.com/'>
                Producer Portal
              </Link>
              .
              <br />
              <br />
              i. This page lays out the different documents required during the application process.
              <Image
                style={{ width: '90%', marginTop: '0.5rem' }}
                alt='getting started'
                src='/images/carriers/health/molina/smartflow/get_started.png'
              />
              <br />
              ii. Next up is the Terms and Conditions Disclosure - read through and scroll all the
              way through to the bottom of the page, click on I agree box and click next
              <Image
                style={{ width: '90%', marginTop: '0.5rem' }}
                alt='terms and conditions disclosure'
                src='/images/carriers/health/molina/smartflow/terms_and_conditions_disclosure.png'
              />
              <br />
              iii. Read through Terms and Conditions Background Report. You can download the Agency
              contract form and Programming Agreement for your convenience of a later read. Click
              the “I Agree” bottom and hit “Next”.
              <Image
                style={{ width: '90%', marginTop: '0.5rem' }}
                alt='Read through Terms and Conditions Background Report'
                src='/images/carriers/health/molina/smartflow/terms_and_conditions_background_report.png'
              />
              <br />
              iv. Review the PDB Disclaimer Message, check <strong>“I agree”</strong> and click Next
              <Image
                style={{ width: '90%', marginTop: '0.5rem', border: '1px solid black' }}
                alt='review PDB disclaimer message'
                src='/images/carriers/health/molina/smartflow/review_pdb_disclaimer.png'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={logged || false}
                onChange={value => setLogged(value)}
                label="I've Logged Into Producer Portal"
              />
            </Grid>
          </Accordion>
        </Grid>
        {showError && !completed && (
          <Grid item style={{ marginTop: '1rem' }}>
            <Message type='warning'>You must complete this action item</Message>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(completed)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                3. Complete and Submit Paperwork
              </span>
            }
          >
            <div>
              c. Once logged into the Producer Portal, you can start the appointment application.
              Please follow and complete each step accurately.
              <br />
              <br />
              i. Start with the Primary Information page. This page should already display your SSN
              number, Last Name and NPN number. Verify that the information is correct and hit{' '}
              <strong>“Next”</strong>.
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='fill up primary information'
                src='/images/carriers/health/molina/smartflow/primary_information.png'
              />
            </div>
            <div>
              d. The next page is the Agent Contact Information Page. Some information will be auto
              populated, please ensure that the information is correct and complete the required
              sections. Note: Under Business Type, click on Individual/Sole Proprietor. Scroll down
              and verify your primary resident address and enter your Contact Information, Business
              email address should always be your Assurance email address. Once complete, click{' '}
              <strong>“Next”</strong>
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='demographic info part 1'
                src='/images/carriers/health/molina/smartflow/demographic_info.png'
              />
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='demographic info part 2'
                src='/images/carriers/health/molina/smartflow/demographic_info_2.png'
              />
            </div>
            <div>
              e. Keep your E&O Policy information handy as you will need to provide the policy
              details in this step. Make sure to upload the copy of the E&O policy. Hit “Save” once
              you enter all the fields. Your E&O policy information will be saved and the page will
              be updated. Please verify and hit <strong>“Next”</strong>.
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='errors and omissions'
                src='/images/carriers/health/molina/smartflow/errors_and_omissions.png'
              />
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='errors and omissions verify'
                src='/images/carriers/health/molina/smartflow/e_and_o_verify.png'
              />
            </div>
            <div>
              f. This page you verify all the states that you can get appointed in to sell Molina.
              These states are pre-selected for you based on your active state licenses. No action
              is needed here, please review and click “Next”.
              <br />
              <br />
              Note: Please note that the states available are: FL, ID, IL, MI, MS, OH, SC, TX, UT,
              WI
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='review states'
                src='/images/carriers/health/molina/smartflow/review_states.png'
              />
            </div>
            <div>
              g. Next page will pull your FFM Certification. If you need to add a new FFM
              certification, you can do that here too. Indicate the Type of Certification as FFM
              Individual, plan year as 2022 and the date you completed the certification. Next,
              upload your FFM certification and hit <strong>“Save”</strong>. Once saved, verify the
              Certification information on the page and hit <strong>“Next”</strong>
              <Image
                style={{ marginTop: '1rem', width: '100%' }}
                alt='ffm certification'
                src='/images/carriers/health/molina/smartflow/ffm_certification.png'
              />
              <Image
                style={{ marginTop: '1rem', width: '100%' }}
                alt='ffm certification files upload'
                src='/images/carriers/health/molina/smartflow/certification_files_upload.png'
              />
            </div>
            <div>
              h. The next page is the Appointment page, click on the box next to Molina Healthcare.
              Verify that all your states are listed and hit <strong>“Next”</strong>.
              <Image
                style={{ marginTop: '1rem', width: '100%' }}
                alt='review appointments'
                src='/images/carriers/health/molina/smartflow/review_appointments.png'
              />
            </div>
            <div>
              i. Next up are background questions! Please answer each question to the best of your
              ability. Any incorrect answers can delay or cancel your appointment request. Once
              completed, hit <strong>“Next”</strong>.
              <br />
              <br />
              Note: For any YES answers, please upload all corresponding documentation. If you have
              a letter of explanation, please upload that as well.
              <Image
                style={{ marginTop: '1rem', border: '1px solid black' }}
                alt='background questions'
                src='/images/carriers/health/molina/smartflow/background_questions.png'
              />
              <br />
              j. Review the Fair Credit Disclosure page, scroll down and click the box{' '}
              <strong>“I agree”</strong>. Then, hit <strong>“Next”</strong>
              <Image
                style={{ marginTop: '1rem', border: '1px solid black' }}
                alt='fair credit disclosure'
                src='/images/carriers/health/molina/smartflow/fair_credit_disclosure.png'
              />
              <br />
              k. You are almost at the end! All that’s left now is sigining a few documents. Start
              with checking the box that you agree to the electronic records and signatures and hit
              “Continue”
              <Image
                style={{ marginTop: '1rem', border: '1px solid black' }}
                alt='agree before signing disclosure'
                src='/images/carriers/health/molina/smartflow/agree_before_signature.png'
              />
              <br />
              <Box ml='1rem'>
                i. Hit <strong>“start”</strong> and sign all the documents that follow. Once done,
                hit <strong>“Finish”</strong>.
                <Image
                  style={{ marginTop: '1rem', border: '1px solid black' }}
                  alt='start signature'
                  src='/images/carriers/health/molina/smartflow/start_signature.png'
                />
                <Image
                  style={{ marginTop: '1rem', border: '1px solid black' }}
                  alt='sign where required'
                  src='/images/carriers/health/molina/smartflow/signature.png'
                />
                <Image
                  style={{ marginTop: '1rem', border: '1px solid black' }}
                  alt='finish signature'
                  src='/images/carriers/health/molina/smartflow/finish_signature.png'
                />
                <br />
                ii. Click <strong>“I agree”</strong> on the PDB Disclaimer message and hit{' '}
                <strong>“Next”</strong>.
                <Image
                  style={{ marginTop: '1rem', border: '1px solid black' }}
                  alt='agree disclaimer'
                  src='/images/carriers/health/molina/smartflow/agree_disclaimer.png'
                />
              </Box>
            </div>
            <div>
              l. Lastly, fill in your name and signature date into the Authentication page. Check
              the box that gives your consent to process the application electronically.
              <Image
                style={{ marginTop: '1rem', border: '1px solid black' }}
                alt='authentication'
                src='/images/carriers/health/molina/smartflow/authentication.png'
              />
              <br />
              m. That’s it, hit “Next” and submit your application! You should receive an email from
              Molina confirming your submission.
              <Image
                style={{ marginTop: '1rem', border: '1px solid black' }}
                alt='onboarding finished email from Molina'
                src='/images/carriers/health/molina/smartflow/finish_onboarding_email.png'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={completed || false}
                onChange={value => setCompleted(value)}
                label='I Completed and Submitted All Required Paperwork'
              />
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container item justify='flex-end'>
        <Box width='100%' display='flex' alignItems='center' justifyContent='flex-end'>
          {showError && ![checkEmail, logged, completed].every(d => d) && (
            <Box mr='1rem'>
              <Message type={'warning'}>Action items incomplete</Message>
            </Box>
          )}
          <Button onClick={handleNext}>Next: Carrier Review</Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Appointment
