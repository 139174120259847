import React from 'react'

import { Divider, Grid, Link } from '@material-ui/core'

import Button from 'components/button'
import Image from 'components/image'

import { PlateProps } from '../commons/plate'
import TimeToComplete from '../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const Activate = (props: Props) => {
  const { plateName, doUpdate } = props

  const handleNext = () => {
    const data = {
      pageData: {
        userInput: {}
      },
      progress: {
        name: plateName,
        status: 'checked'
      },
      nextProgress: 'CompleteCertification',
      nextPage: {
        name: 'CompleteCertification'
      }
    }
    doUpdate?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Humana Activated</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        Well done! You’re Humana Activated. You may now proceed to complete your online education
        and certification.
      </Grid>
      <Grid item>
        <Divider style={{ margin: '0.5rem 0' }} />
      </Grid>
      <Grid item>
        You should have received an email in your{' '}
        <Link target='_blank' rel='noreferrer' href='https://outlook.office365.com/mail/'>
          <strong>{'{@Assurance.com}'}</strong>
        </Link>{' '}
        inbox that contains your Humana SAN Number needed to complete your certification training.
        <br />
        <br />
        <strong>From:</strong> DoNotRespond@humana.com
        <br />
        <strong>Subject:</strong> Welcome to Humana - Next Steps
      </Grid>
      <Grid item>
        <Image
          alt='reference-image'
          src='/images/carriers/medicare/humana/appointment/Review by Humana.jpg'
          style={{ width: '90%' }}
        />
      </Grid>
      <Grid container item justify='flex-end'>
        <Button onClick={handleNext}>Next: Certification</Button>
      </Grid>
    </Grid>
  )
}

export default Activate
