import React from 'react'

import Box from 'components/box'
import Button from 'components/button'
import { InfoIcon, MoreDotsIcon } from 'components/icons'
import { LightTooltip } from 'components/tooltip'

import BaseCard from './card_commons'
import FlippableCard from './flippable_card_with_carrier_tags'

interface Props {
  logoUrl: string
  carrierTags: string[]
  availableStates: number
  cardId?: string
  customTooltip?: string
  onSupportedProductsClick?: () => void
}

const UnavailableCard = (props: Props) => {
  const { logoUrl, availableStates, cardId, customTooltip } = props
  const defaultTooltipText =
    'Our system is updating data. Information will be available shortly. Please check back later.'

  return (
    <BaseCard style={{ boxShadow: 'none' }} cardId={cardId}>
      <div
        style={{
          position: 'absolute',
          borderRadius: '50%',
          padding: '0.25rem',
          right: 0,
          top: 0,
          transform: 'translate(50%, -50%)'
        }}
      >
        <LightTooltip
          placement='top-start'
          title={<span>{customTooltip || defaultTooltipText}</span>}
        >
          <div style={{ marginLeft: '0.25rem' }}>
            <InfoIcon style={{ width: '1.625rem', display: 'block' }} />
          </div>
        </LightTooltip>
      </div>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        style={{ flex: 1, textAlign: 'center' }}
        padding='0.5rem 0'
        spacing={'1rem'}
      >
        <img style={{ maxWidth: '70%', height: '48px' }} alt='logo' src={logoUrl} />
        <div>Available in {availableStates} states</div>
        <Box display='flex' justifyContent='space-around'>
          <Button
            style={{ padding: '0.25rem', minWidth: '0' }}
            variant='text'
            onClick={props.onSupportedProductsClick}
          >
            <MoreDotsIcon />
          </Button>
        </Box>
      </Box>
      <Box
        style={{
          borderRadius: '0 0 0.2rem 0.2rem',
          borderTop: '1px solid #ccc',
          padding: '0.5rem',
          textAlign: 'center'
        }}
      >
        Unavailable
      </Box>
    </BaseCard>
  )
}

const FlippableUnavailableCard = (props: Props) => {
  return (
    <FlippableCard carrierTags={props.carrierTags}>
      <UnavailableCard {...props} />
    </FlippableCard>
  )
}

export default FlippableUnavailableCard
