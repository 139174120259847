import React, { useState } from 'react'

import { Grid } from '@material-ui/core'
import Link from '@material-ui/core/Link'

import Box from 'components/box'
import Button from 'components/button'
import CheckboxButton from 'components/checkbox_button'
import Image from 'components/image'
import Message from 'components/message/Message'
import Note from 'components/box/note'
import { RoundCheckedIcon, RoundUncheckedIcon } from 'components/icons'
import styled from 'styled-components'

import Accordion from '../../commons/checklist_accordion'
import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    userInput?: {
      checkEmail?: boolean
      loggedIn?: boolean
      completedPaperwork?: boolean
    }
  }
}

const getIcon = (checked?: boolean) => {
  const props = {
    style: {
      width: '1.25rem',
      height: '1.25rem'
    }
  }
  return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
}

const Appointment = (props: Props) => {
  const { plateName, doUpdate, pageData = {} } = props

  const { userInput = {} } = pageData

  const [checkEmail, setCheckEmail] = useState(userInput.checkEmail)
  const [logged, setLogged] = useState(userInput.loggedIn)
  const [completed, setCompleted] = useState(userInput.completedPaperwork)
  const [showError, setShowError] = useState(false)

  const handleNext = () => {
    const allCompleted = checkEmail && logged && completed
    if (!allCompleted) {
      setShowError(true)
      return
    }
    const data = {
      pageData: {
        userInput: {
          checkEmail,
          loggedIn: logged,
          completedPaperwork: completed
        }
      },
      progress: {
        name: plateName,
        status: allCompleted ? 'checked' : 'error'
      },
      nextProgress: 'CarrierReview',
      nextPage: {
        name: 'CarrierReview'
      }
    }
    doUpdate?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Appointment - Paperwork</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item container direction='column' spacing={1}>
        {showError && !checkEmail && (
          <Grid item>
            <Message type='warning'>You must complete this action item</Message>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(checkEmail)}
            title={<span style={{ display: 'flex', alignItems: 'center' }}>1. Check Email</span>}
          >
            Please check your Assurance email account for an email from HCSC. It will show as:
            <br />
            <div>
              <div>
                <strong>From:</strong>{' '}
                <Link
                  target='_blank'
                  rel='noreferrer'
                  href='mailto:producer_service_center@psc.hcsc.health'
                >
                  Producer Service Center {'<'}producer_service_center@psc.hcsc.health{'>'}
                </Link>
              </div>
              <div>
                <strong>Subject:</strong> Your Onboarding number - Onboarding Invitation
              </div>
              <div>
                <strong>Recruiter Name:</strong> ASSURANCE IQ
              </div>
            </div>
            <div>
              a. We have initiated your subagent appointment through our agency. You should have
              received an email from{' '}
              <Link
                target={'_blank'}
                rel={'noreferrer'}
                href={'mailto:producer_service_center@psc.hcsc.health'}
              >
                producer_service_center@psc.hcsc.health
              </Link>{' '}
              with the subject line <strong>“Onboarding Invitation”</strong> in your email inbox.
            </div>
            <div>
              Example of email below. If you do not receive an email in your inbox, please check
              your spam/junk folder as well.
              <br />
              <Image
                style={{ width: '100%', marginTop: '0.5rem' }}
                alt='producer service center onboarding invitation'
                src='/images/carriers/health/hcsc/smartflow/psc_invitation.png'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={checkEmail || false}
                onChange={value => setCheckEmail(value)}
                label='I Received a Registration Email'
              />
            </Grid>
          </Accordion>
        </Grid>
        {showError && !logged && (
          <Grid item style={{ marginTop: '1rem' }}>
            <Message type='warning'>You must complete this action item</Message>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(logged)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                2. Log Into Producer Service Center
              </span>
            }
          >
            <div>
              a. Once you click on the link in the email, you will be redirected to{' '}
              <Link
                target='_blank'
                rel={'noreferrer'}
                href='https://social.webcomserver.com/wpm/mt/hcsc/projects/ob/ob-43479'
              >
                Producer Service Center
              </Link>
              .
              <br />
              <br />
              Please use your @assurance.com email id to login. In case you do not remember your
              password, it’s ok, just hit ‘Forget Password?’ and follow the easy steps. Make sure
              the ‘Domain’ is always ‘HCSC’.
              <Image
                style={{ width: '50%', marginTop: '0.5rem' }}
                alt='login or register'
                src='/images/carriers/health/hcsc/smartflow/login_to_psc.png'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={logged || false}
                onChange={value => setLogged(value)}
                label="I've Logged Into Producer Service Center"
              />
            </Grid>
          </Accordion>
        </Grid>
        {showError && !completed && (
          <Grid item style={{ marginTop: '1rem' }}>
            <Message type='warning'>You must complete this action item</Message>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(completed)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                3. Complete and Submit Paperwork
              </span>
            }
          >
            <div>
              b. Once logged into the Producer Service Center, you can start the appointment
              application.
              <br />
              <br />
              Follow and complete each tab at the top of the page. Each section must be complete
              before submitting your request.
              <br />
              <br />
              i. First tab is your general information. Please complete all the required fields.
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='fill up general information'
                src='/images/carriers/health/hcsc/smartflow/general_tab.png'
              />
            </div>
            <div>
              c. The “Licenses” tab displays all your active licenses pulled from NIPR. Select all
              states you wish to do business in.
              <br />
              <br />
              Reminder: Remember HCSC plans are in the Blue Cross and Blue Shield states of{' '}
              <strong>IL, MT, NM, OK, and TX</strong>. You must hold an{' '}
              <strong>active license in at least one of these states</strong> to get appointed.
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='your active licenses'
                src='/images/carriers/health/hcsc/smartflow/license_tab.png'
              />
            </div>
            <div>
              d. Review your active appointments on the “Appointments” tab. This is only{' '}
              <strong>informational</strong> and no action is needed.
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='your active appointments'
                src='/images/carriers/health/hcsc/smartflow/appointments_tab.png'
              />
            </div>
            <div>
              e. Please fill the “Background Questions” carefully. Make sure you answer each
              question truthfully. If any question is answered yes, you will need to provide a
              detailed summary of facts for each yes answer.
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='fill background questions'
                src='/images/carriers/health/hcsc/smartflow/background_qsn_tab.png'
              />
            </div>
            <div>
              f. Lastly, fill the required information about your E&O insurance in the “E&O” tab.
              Please upload your E&O certificate on this page.
              <Image
                style={{ marginTop: '1rem', width: '100%', border: '1px solid black' }}
                alt='fill E&O insurance'
                src='/images/carriers/health/hcsc/smartflow/error_and_omission_tab.png'
              />
            </div>
            <div>
              g. Make sure you have completed the forms on all the tabs. Once finished, hit{' '}
              <strong>Submit</strong>. This will submit your application to HCSC!
              <Note style={{ marginTop: '1rem' }}>
                <strong>Important:</strong> Important: The appointment application process consist
                of five tabs. All five tabs must be populated or/and reviewed. Once done, you MUST
                click on submit in order for the appointment application to be submitted to HCSC.
              </Note>
              <Image
                style={{ marginTop: '1rem', border: '1px solid black' }}
                alt='fill out forms'
                src='/images/carriers/health/hcsc/smartflow/e_and_o_submit.png'
              />
              <br />
              Note: You can log back in at anytime with your credentials to see the status of your
              application.
              <Image
                style={{ marginTop: '1rem', border: '1px solid black' }}
                alt='status dashboard'
                src='/images/carriers/health/hcsc/smartflow/status_dashboard.png'
              />
            </div>
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={completed || false}
                onChange={value => setCompleted(value)}
                label='I Completed and Submitted All Required Paperwork'
              />
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container item justify='flex-end'>
        <Box width='100%' display='flex' alignItems='center' justifyContent='flex-end'>
          {showError && ![checkEmail, logged, completed].every(d => d) && (
            <Box mr='1rem'>
              <Message type={'warning'}>Action items incomplete</Message>
            </Box>
          )}
          <Button onClick={handleNext}>Next: Carrier Review</Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Appointment
