import React, { useEffect, useState } from 'react'

import Switch, { SwitchProps } from '@material-ui/core/Switch'
import { styled } from '@material-ui/styles'

import Box from 'components/box'
import { ViewMode } from 'store/login_user_info'
import { useStoreActions, useStoreState } from 'store'

interface Props {
  viewMode?: ViewMode
}

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 20,
  margin: '0 7px',
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: -1,
    padding: 0,
    transform: 'translateX(5px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(25px)',
      '& + .MuiSwitch-track': {
        backgroundColor: '#007CC2'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    width: 20,
    height: 20
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2
  }
}))

const TitleBar = (props: Props) => {
  const { viewMode } = props

  const loginUser = useStoreState(state => state.loginUserInfo)
  const setViewMode = useStoreActions(state => state.loginUserInfo.setViewMode)

  useEffect(() => {
    if (loginUser.viewMode !== viewMode) {
      window.location.href = loginUser.viewMode === ViewMode.Agent ? '/' : '/admin'
    }
  }, [loginUser.viewMode])

  const handleModeChange = (newMode, agentViewCheck) => {
    setViewMode(agentViewCheck ? ViewMode.Agent : ViewMode.Admin)
  }

  return (
    <Box
      flex='1'
      display='flex'
      flexDirection='row-reverse'
      alignItems='center'
      style={{
        background: 'white',
        fontSize: '14px'
      }}
    >
      {loginUser.isAdmin && (
        <div>
          <span>Admin View</span>
          <MaterialUISwitch
            checked={loginUser.viewMode === ViewMode.Agent}
            onChange={handleModeChange}
            color={'primary'}
          />
          <span>Agent View</span>
        </div>
      )}
    </Box>
  )
}

export default TitleBar
