import React from 'react'

import { Divider, Grid, makeStyles } from '@material-ui/core'

import Box from 'components/box'
import { Carrier, LOI } from 'store/carriers'
import InfoBox from 'components/box/info_box'
import { InternalAppointmentDetail } from 'store/carrier_appointments'
import _, { uniq } from 'lodash'
import { useStoreState } from 'store'

import { AppointmentsTooltip } from './tooltips'
import { CarrierInfo } from '../../../../../store/carriers'
import CarrierTags from '../../../carrier_tags'
import Carriers from '../../../carrier_data'
import Hoverable from '../../../hoverable'
import { LabeledNode } from '../util'
import NotReadyToSell, { ReadyToSell } from '../../status'
import { useCarrierAppointmentStatusForPlanYear } from '../../../hooks'

const useStyles = makeStyles(theme => ({
  tags: {
    maxWidth: '25vw',
    '& > * > *': {
      margin: '0.125rem'
    }
  }
}))

// Carriers that have the LOI Life type hidden from the details section
const HIDDEN_TYPE_CARRIERS = [Carrier.MutualOfOmaha, Carrier.LGA]

interface HeaderProps {
  carrierName: string
  loi: string
  planYear: number
  currentYear: number
}

const CARRIER_AGENT_NUMBER_KEY_MAPPING = {
  [Carrier.LGA]: 'lga_agent_number',
  [Carrier.Prudential]: 'pru_agent_number',
  [Carrier.Lumico]: 'lumico_life_agent_number',
  [Carrier.MutualOfOmaha]: 'moo_life_producer_number'
}

const Header = (props: HeaderProps) => {
  const { currentYear, carrierName, planYear, loi } = props

  const logoUrl = Carriers[loi]?.[carrierName]?.Logo

  const carrierInfo = useStoreState(
    state => state.carrierInfo.items.find(row => row.name === carrierName && row.loi === loi)!
  )

  const userInfo = useStoreState(state => state.userInfo)
  const carrierNumberKey = CARRIER_AGENT_NUMBER_KEY_MAPPING[carrierName]
  const carrierNumber = userInfo.carrierData[carrierNumberKey] || 'N/A'

  const appointmentsForPlanYear = useStoreState(state =>
    state.carrierAppointments.items.find(
      row => row.carrier.name === carrierName && row.carrier.loi === loi && planYear === planYear
    )
  )

  const carrierStatus = useCarrierAppointmentStatusForPlanYear(carrierName, loi, planYear)

  const licenses: InternalAppointmentDetail[] =
    appointmentsForPlanYear?.internalAppointmentDetails || []

  const appointedLicenses = licenses.filter(row => row.status === 'APPOINTED')

  const availableStatesForCarrier = useStoreState(state => {
    const carrier =
      state.carrierAppointments.items.find(
        row => row.carrier.name === carrierName && row.carrier.loi === loi && planYear === planYear
      )?.carrier ?? ({} as CarrierInfo)
    const states = carrier?.states ?? []
    const justInTimeStates = carrier?.justInTimeStates ?? []
    return _.uniq([...states, ...justInTimeStates]).sort()
  })

  const appointedStates = _.uniq(appointedLicenses.map(row => row.state)).sort()

  const justInTimeAppointments = appointmentsForPlanYear
    ? appointmentsForPlanYear.justInTimeStateDetails.filter(row => row.status === 'ACTIVE')
    : []

  const appointedStatesCount = appointedStates.length + justInTimeAppointments.length

  const classes = useStyles()

  const hideType = loi === LOI.Life && HIDDEN_TYPE_CARRIERS.includes(Carrier[carrierName])
  const hideAppointMoreStates = loi === LOI.Life && carrierName === Carrier.MutualOfOmaha

  return (
    <Box spacing={'1.5rem'}>
      <Grid container direction='row' spacing={3} wrap='nowrap'>
        <Grid item>
          <Box display='flex' flexDirection={'column'} style={{ alignItems: 'center' }}>
            <img src={logoUrl} />
            <div style={{ marginTop: '1rem' }}>
              {carrierStatus === 'APPOINTED' && <ReadyToSell />}
              {carrierStatus !== 'APPOINTED' && <NotReadyToSell />}
            </div>
          </Box>
        </Grid>
        <Grid item>
          <Divider orientation='vertical' />
        </Grid>
        <LabeledNode item label='Producer Number'>
          {carrierNumber}
        </LabeledNode>
        <Grid item>
          <Divider orientation='vertical' />
        </Grid>
        {!hideType && (
          <>
            <LabeledNode className={classes.tags} item label={_.capitalize(loi) + ' Type'}>
              {carrierInfo.carrierTags
                .map(row => row.name)
                .map(carrierTag => (
                  <div
                    key={carrierTag}
                    style={{
                      display: 'inline-block'
                    }}
                  >
                    <Hoverable
                      open
                      iconSrc={(CarrierTags[carrierTag] || {}).Icon}
                      label={(CarrierTags[carrierTag] || {}).Label}
                    />
                  </div>
                ))}
            </LabeledNode>
            <Grid item>
              <Divider orientation='vertical' />
            </Grid>
          </>
        )}
        <LabeledNode
          item
          label={
            <Box display='flex' alignItems='center' whiteSpace='nowrap'>
              Appointed
              <AppointmentsTooltip />
            </Box>
          }
        >
          <div style={{ fontSize: '1.5rem' }}>{appointedStatesCount}</div>
        </LabeledNode>
        <Grid item>
          <Divider orientation='vertical' />
        </Grid>
        <LabeledNode item label='Plan Year'>
          {planYear}
          {planYear === currentYear && ' (current year)'}
        </LabeledNode>
      </Grid>
      <InfoBox
        style={{ display: 'flex', alignItems: 'flex-start', color: 'rgba(5, 31, 44, 0.96)' }}
      >
        <img
          style={{ marginRight: '0.875rem', marginTop: '0.25rem' }}
          src='/images/info_navy.svg'
        />
        <span>
          Assurance Operates in the Following States or Territories for &nbsp;
          <strong>{carrierName}</strong> - {availableStatesForCarrier.join(', ')}. For getting
          appointed in more states, please navigate to the&nbsp;
          <a
            href='https://help.assurance.com/hc/en-us/articles/11605153457684'
            rel='noreferrer'
            target='_blank'
          >
            {'Helpdesk Article to learn more.'}
          </a>
        </span>
      </InfoBox>
    </Box>
  )
}

export default Header
