import React from 'react'

import { Grid, makeStyles } from '@material-ui/core'

import { carrierFullName } from 'store/carriers'

import { PlateProps } from '../../../plate'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  instructionsLink: string
}

const useStyles = makeStyles(theme => ({
  grey: {
    color: theme.palette.grey[500]
  }
}))

const OutOfFlow = (props: Props) => {
  const classes = useStyles()
  const { carrierName, instructionsLink } = props

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>
          Eligibility - It looks like you already started the Appointment process!
        </h2>
      </Grid>
      <Grid item container direction='column' alignItems='center'>
        <img src={'/images/out_of_flow.svg'} />
        <span className={classes.grey}>
          Please follow the steps to complete your appointment paperwork and certifications process.
          <br />
          <br />
          <a href={instructionsLink} target='_blank' rel='noreferrer'>
            See Instructions for How to Get Appointed with {carrierFullName(carrierName)}.
          </a>
          <br />
          <br />
          We will let you know when you have become Ready to Sell.
          <br />
          <br />
          Please reach out to us via the Needs Help button in the bottom right corner of your screen
          should you need any assistance.
        </span>
      </Grid>
    </Grid>
  )
}

export default OutOfFlow
