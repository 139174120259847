import { Action, Computed, action, computed } from 'easy-peasy'

import { BaseUserInfo, BaseUserInfoActionModel, Role, commonUserActions } from './base_user_info'

export const VIEW_MODE_SESSION_KEY = 'VIEW_MODE'
export const MASQUERADE_SESSION_KEY = 'masqueradeId'

const AdminRoles = ['admin', 'developer']

export enum ViewMode {
  Agent = 'AGENT',
  Admin = 'ADMIN'
}

export interface LoginUserInfo extends BaseUserInfo {
  loaded: boolean
  roles: Role[]
  viewMode: ViewMode
  viewModeInitialized: boolean
}

export interface LoginUserInfoModel extends LoginUserInfo, BaseUserInfoActionModel {
  update: Action<LoginUserInfoModel, Partial<LoginUserInfo>>
  setViewMode: Action<LoginUserInfoModel, ViewMode>
  isMasquerading: Computed<LoginUserInfoModel, boolean>
  masqueradeId: Computed<LoginUserInfoModel, string | null>
  setMasquerade: Action<LoginUserInfoModel, string>
  clearMasquerade: Action<LoginUserInfoModel>
}

const hasAdminRole = (roles: Role[]) => AdminRoles.find(role => roles.includes(role)) != null

const saveViewModeInSession = (viewMode: ViewMode) => {
  sessionStorage.setItem(VIEW_MODE_SESSION_KEY, viewMode)
}

export const getViewModeFromSession = () =>
  sessionStorage.getItem(VIEW_MODE_SESSION_KEY) as ViewMode

export const loginUserInfoModel: LoginUserInfoModel = {
  loaded: false,
  id: '',
  npn: '',
  loi: [],
  assuranceAgentId: '',
  firstName: '',
  lastName: '',
  email: '',
  roles: [],
  permissionGroups: [],
  ...(commonUserActions() as any),
  // default view mode is agent unless overriden directly
  viewMode: (sessionStorage.getItem(VIEW_MODE_SESSION_KEY) as ViewMode) || ViewMode.Agent,
  viewModeInitialized: false,
  isMasquerading: computed(() => sessionStorage.getItem(MASQUERADE_SESSION_KEY) != null),
  masqueradeId: computed(() => sessionStorage.getItem(MASQUERADE_SESSION_KEY)),
  clearMasquerade: action(state => {
    sessionStorage.removeItem(MASQUERADE_SESSION_KEY)
    state.viewMode = state.isAdmin ? ViewMode.Admin : ViewMode.Agent
  }),
  setMasquerade: action((state, masqueradeId) => {
    sessionStorage.setItem(MASQUERADE_SESSION_KEY, masqueradeId)
    state.viewMode = ViewMode.Agent
    saveViewModeInSession(ViewMode.Agent)
  }),
  setViewMode: action((state, viewMode) => {
    state.viewMode = viewMode
    state.viewModeInitialized = true
    saveViewModeInSession(viewMode)
    // if we go from Agent View to Admin view, we need to make square masquerading is removed
    // to avoid back and forth
    if (viewMode === ViewMode.Admin) {
      sessionStorage.removeItem(MASQUERADE_SESSION_KEY)
    }
  })
}
