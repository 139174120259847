import gql from 'graphql-tag'

const INVITATIONS_QUERY = gql`
  query invitations($offset: Int, $limit: Int!, $loi: String!, $carrier: String!) {
    invitationFiles(
      loi: $loi
      carrier: $carrier
      first: $limit
      offset: $offset
      sortBy: { field: UPLOAD_DATE, direction: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          docUrl
          uploadDate
          confirmedBy
          confirmedByEmail
          confirmedDate
          invitationDetails {
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`

export default INVITATIONS_QUERY
