import React from 'react'

import { Divider, Grid, Link } from '@material-ui/core'

import Box from 'components/box'
import Image from 'components/image'
import { RoundCheckedIcon } from 'components/icons'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

export interface CarrierReviewProps extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const CarrierReview = (props: CarrierReviewProps) => {
  const backgroundCheckStatus = props.progressItems.find(row => row.name === 'BackgroundCheck')

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Review by Humana</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      {backgroundCheckStatus?.status === 'checked' && (
        <>
          <Grid item>
            You marked yourself as completed on:
            <Box display='flex' marginTop='0.75rem' alignItems='center'>
              <RoundCheckedIcon
                blue
                style={{
                  width: '1.25rem',
                  marginRight: '0.375rem'
                }}
              />
              Start Background Check
            </Box>
            <br />
            Well done! Your work here is done for now. Sit back, relax, and we will update your
            progress as soon as we receive it from Humana.
          </Grid>
          <Grid item>
            <Divider style={{ margin: '0.5rem 0' }} />
          </Grid>
        </>
      )}
      <Grid item>
        When you get approved, you’ll receive an email in your{' '}
        <Link target='_blank' rel='noreferrer' href='https://outlook.office365.com/mail/'>
          <strong>{'{@Assurance.com}'}</strong>
        </Link>{' '}
        inbox that contains your Humana SAN Number needed to complete your certification training.
        <br />
        <br />
        Once you receive this, come back to this page to see what to do next.
        <br />
        <br />
        <strong>From:</strong> DoNotRespond@humana.com
        <br />
        <strong>Subject:</strong> Welcome to Humana - Next Steps
      </Grid>
      <Grid item>
        <Image
          alt='reference-image'
          src='/images/carriers/medicare/humana/appointment/Review by Humana.jpg'
          style={{ width: '90%' }}
        />
      </Grid>
    </Grid>
  )
}

export default CarrierReview
