import React from 'react'

import { Grid } from '@material-ui/core'

import Note from 'components/box/note'

import { PlateProps } from '../commons/plate'
import TimeToComplete from '../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const OnboardComplete = (props: Props) => {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Onboard Complete</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <Note>
          <strong>Note:</strong> Your Aetna case has successfully been completed. At this stage, it
          takes at least 3 business days for you to show up in Aetna's reporting to us.
        </Note>
      </Grid>
      <Grid item>
        No action required from you. If it has been stuck in this stage for over 10 days, please
        contact Aetna to find out what is happening. The Aetna number is{' '}
        <strong>866-714-9301</strong>.
      </Grid>
    </Grid>
  )
}

export default OnboardComplete
