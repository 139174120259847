import React from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import Warning from 'components/box/warning'
import { get_yy_mm_dd_hh_mm } from 'utils/utils'

import { PlateProps } from '../commons/plate'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const CanceledByAgent = (props: Props) => {
  const { cancellationDate, doRestart } = props

  const date = cancellationDate ? get_yy_mm_dd_hh_mm(new Date(cancellationDate)) : '-'

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Carrier Appointment Canceled by Agent</h2>
      </Grid>
      <Grid item>
        <Warning>
          <strong>Note:</strong> The invitation we sent you was canceled by you.
        </Warning>
      </Grid>
      <Grid item>
        It has been noted in our system that on <strong>{date}</strong> you canceled your
        appointment process with WellCare.
      </Grid>
      <Grid container item justify='flex-end' spacing={1}>
        <Grid item>
          <Button onClick={doRestart}>Restart Eligibility Check</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CanceledByAgent
