import React from 'react'

import { Link } from '@material-ui/core'

import Note from 'components/box/note'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper from '../commons/instructions_wrapper'

interface Props {
  carrierName: string
  loi: string
}

const Checklist = (props: Props) => {
  return (
    <InstructionsWrapper {...props} isComingSoon={true}>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Request for an Appointment Invitation
          </span>
        }
      >
        Let us know you want to get started with carrier contracting using one of the below options.
        <br />
        <ul>
          <li>
            For Assurance W2 Full-Time Agent (i.e. you are part of a hub): Please fill out the{' '}
            <Link
              target='_blank'
              rel='noreferrer'
              href='https://assuranceiq.formstack.com/forms/bpodecember2020appointmentinfo'
            >
              Hub Invitation Request Form
            </Link>
            . Fill it one time for all Medicare Advantage Carriers.
          </li>

          <li>
            For Assurance 1099 On-Demand Agent (i.e. work on your own terms): Please fill out the{' '}
            <Link
              target='_blank'
              rel='noreferrer'
              href='https://assuranceiq.formstack.com/forms/wellcare_appointment_requests'
            >
              WellCare Invitation Request Form
            </Link>
            .
          </li>
        </ul>
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>2. Complete Certifications</span>
        }
      >
        <strong>Instructions:</strong>
        <br />
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360034341432-How-do-I-get-appointed-with-WellCare-'
        >
          Assurance Help: How do I get appointed with WellCare?
        </Link>
        <br />
        <br />
        If you meet WellCare requirements, your information will be sent to WellCare for processing
        which can take up to 5 business days. Occassionally, go to{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://centene.cmpsystem.com/ext/ahip/login.php'
        >
          Centene Learning Center{' '}
        </Link>
        to see if your NPN has been processed. If yes, please proceed to complete your required
        training.
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>3. Wait for data to sync</span>
        }
      >
        If you are experiencing difficulties with the Medicare onboarding experience on the
        Assurance Platform, please check out our{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360050156991-WellCare-FAQ-v-2020-'
        >
          WellCare FAQs
        </Link>{' '}
        Alternatively, you can reach out to our team through the 'Need Help' button in the bottom
        right corner of your screen.
        <Note>
          If you have questions on paperwork completion, please contact the{' '}
          <strong>WellCare licensing team</strong> at <strong>1-866-822-1339</strong>.
        </Note>
      </Accordion>
    </InstructionsWrapper>
  )
}

export default Checklist
