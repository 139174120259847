import React from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import styled from 'styled-components'

import { AhipPageTypes } from '../ahip.d'

interface Props {
  year: number
  optionChanges: (year: number) => void
}

const Note = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-content: center;
  padding: 1rem;
  background: #fff7e0;
  border: 1px solid #ffeab2;
  border-radius: 0.25rem;
  color: #883d07;
`

const ExpirationBanner = (props: Props) => {
  const { year, optionChanges } = props
  const handleClick = () => {
    optionChanges(year + 1)
  }
  return (
    <Note>
      <Grid item>
        <strong>{year + 1} is coming!</strong> The current year's AHIP certification will expire on
        06/16/2023. Please log into your AHIP account and complete your {year + 1} AHIP
        certification when it becomes available on 06/21/2023. As a reminder, you must complete the
        AHIP certification each year. If you are a new agent, we recommend you wait until the new
        year's exam is available to register.
      </Grid>
      <Button
        style={{
          backgroundColor: '#883D07',
          minWidth: '160px',
          height: '40px',
          margin: '10px 0 20px 10px'
        }}
        onClick={handleClick}
      >
        Begin AHIP {year + 1}
      </Button>
    </Note>
  )
}

export default ExpirationBanner
