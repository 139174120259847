import React from 'react'

import Appointment from './appointment'
import ApprovalRequested from './approval_requested'
import BackgroundCheck from './background_check'
import BusinessReview from './business_review'
import CanceledByAgent from './canceled_by_agent'
import Cancelled from './cancelled'
import CarrierReview from './carrier_review'
import Certification from './certification'
import DataSync from './data_sync'
import Eligibility from './eligibility'
import Exception from './exception'
import Incomplete from './incomplete'
import Invitation from './invitation'
import NoticeOfIntent from './notice_of_intent'
import OnboardComplete from './onboard_complete'
import RejectedByBrokerServices from './rejected_by_broker_services'
import ReviewApplication from './review_application'

export const PlateLabelMap: Record<string, string> = {
  Eligibility: 'Check Eligibility',
  Invitation: 'Request Invite',
  Appointment: 'Complete Paperwork',
  Certification: 'Complete Certification',
  AetnaCarrierReview: 'Review by Carrier',
  BackgroundCheck: 'Check Background',
  BusinessReview: 'Review by Business',
  ReviewApplication: 'Review Application',
  OnboardComplete: 'Onboard Complete',
  DataSync: 'Sync Data',
  InComplete: 'Incomplete',
  ApprovalRequested: 'Approval Requested',
  CanceledByAgent: 'Canceled by Agent',
  Cancelled: 'Cancelled',
  NoticeOfIntent: 'Notice of Intent',
  RejectedByBrokerServices: 'Rejected By Broker Services',
  Exception: 'Exception'
}

export const PlateComponentMap: Record<string, React.FunctionComponent<any>> = {
  Eligibility,
  Invitation,
  Appointment,
  Certification,
  BackgroundCheck,
  AetnaCarrierReview: CarrierReview,
  BusinessReview,
  ReviewApplication,
  OnboardComplete,
  DataSync,
  InComplete: Incomplete,
  ApprovalRequested,
  CanceledByAgent,
  Cancelled,
  NoticeOfIntent,
  RejectedByBrokerServices,
  Exception
}
