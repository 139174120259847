import React from 'react'

import ApiError from '../api_error'
import { PlateProps } from '../../../commons/plate'
import RequestDenied from './request_denied'
import RequestTerminated from './request_terminated'
import TrainingComplted from './training_completed'
import TrainingIncomplte from './training_incomplete'

const CarrierReviewAndAIQTraining = (props: PlateProps) => {
  const { pageData } = props

  return pageData.lumicoGetStatus === 'error' ? (
    <ApiError {...props} />
  ) : pageData.background_check_status === 'failed' ? (
    <RequestDenied {...props} />
  ) : pageData.background_check_status === 'terminated' ? (
    <RequestTerminated {...props} />
  ) : pageData.training_completed ? (
    <TrainingComplted {...props} />
  ) : (
    <TrainingIncomplte {...props} />
  )
}

export default CarrierReviewAndAIQTraining
