import React from 'react'

import AssuranceLoading from '../../../../../../components/loading'
import { HelpDesk } from '../../../../utils/extras'
import OutOfFlow from './out_of_flow'
import { PlateProps } from '../../../plate'
import PreviouslyAppointed from './previously_appointed'
import TerminatedWithCause from './terminated_with_cause'

export interface EligibilityProps extends PlateProps {
  pageName:
    | 'CheckEligibilitySpinner'
    | 'NotAppointedWithCarrier'
    | 'WrongLineOfAuthority'
    | 'PromptForCarrierStatus'
    | 'TerminatedWithCause'
    | 'OutOfSystemWorkFlowInProgress'
    | string
}

export const Eligibility = (props: EligibilityProps) => {
  const { pageName } = props
  switch (pageName) {
    case 'CheckEligibilitySpinner':
      return (
        <div style={{ marginTop: '4rem' }}>
          <AssuranceLoading>Running eligibility check...</AssuranceLoading>
        </div>
      )

    case 'PromptForCarrierStatus':
      return (
        <PreviouslyAppointed
          {...props}
          phoneNumber={HelpDesk[props.loi]?.[props.carrierName]?.phoneNumber}
        />
      )

    case 'OutOfSystemWorkFlowInProgress':
      return (
        <OutOfFlow
          {...props}
          instructionsLink={HelpDesk[props.loi]?.[props.carrierName]?.instructionsLink}
        />
      )

    case 'TerminatedWithCause':
      return <TerminatedWithCause {...props} />

    default:
      return <div>Unknown page {pageName}</div>
  }
}

export default Eligibility
