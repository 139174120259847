import { Action, Computed, action, computed } from 'easy-peasy'

import { LoginUserInfo } from './login_user_info'

export interface LineOfAuthority {
  id: string | number
  loaName: string
  status: string
  loaCode: string
}

export enum LicenseStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export interface LicenseData {
  id: number
  state: string
  status: LicenseStatus
  isResidentState?: boolean
  number: string
  className: string
  classCode: string
  loas: LineOfAuthority[]
  dateIssued: string
  dateExpire: string
}

export interface StateLicensesModel {
  items: LicenseData[]
  activeLicenses: number
  totalLicenses: number
  load: Action<
    StateLicensesModel,
    {
      activeLicenses: number
      totalLicenses: number
      licenses: LicenseData[]
    }
  >
  residentLicense: Computed<StateLicensesModel, LicenseData | undefined>
}

export const stateLicensesModel: StateLicensesModel = {
  items: [] as LicenseData[],
  activeLicenses: 0,
  totalLicenses: 0,
  load: action((state, payload) => {
    state.items = payload.licenses
    state.activeLicenses = payload.activeLicenses
    state.totalLicenses = payload.totalLicenses
  }),
  residentLicense: computed(state =>
    state.items.find(row => row.isResidentState && row.status === LicenseStatus.Active)
  )
}
