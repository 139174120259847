import React from 'react'
import { Step } from 'react-joyride'

const getSteps = () => {
  const steps: Step[] = [
    {
      target: '#errors_and_omissions_body',
      title: 'Understanding Errors and Omissions Page',
      content: (
        <div>
          <p>Welcome to your Errors and Omissions page.</p>
          <br />
          <p>
            All agents are required to have Errors and Omissions insurance to sell on our platform.
            This tab will store all of your Errors and Omissions insurance information.
          </p>
        </div>
      ),
      placement: 'center',
      disableBeacon: true,
      showProgress: true
    },
    {
      target: '#errors_and_omissions_form',
      title: '',
      content: (
        <div>
          <p>Use your Errors and Omissions Certificate to fill out this section.</p>
          <br />
          <p>All fields with an asterisk are required.</p>
        </div>
      ),
      placement: 'right',
      disableBeacon: true
    },
    {
      target: '#errors_and_omissions_status',
      title: '',
      content:
        'Use the drop down on the top right hand corner to select a specific year in which to input your information. We’ll allow you to store up to three years for your reference.',
      placement: 'right',
      disableBeacon: true
    },
    {
      target: '#errors_and_omissions_submit',
      title: '',
      content: (
        <div>
          <p>
            When all required information has been inputted, you will upload your certificate, check
            off that all information is correct, and then click submit.
          </p>
          <br />
          <p>
            If all information is not inputted where required, the system will prompt you to take
            action so that you can complete the section.
          </p>
        </div>
      ),
      placement: 'bottom',
      disableBeacon: true
    },
    {
      target: '#errors_and_omissions_message',
      title: '',
      content:
        'When all information has been inputted correctly and submitted, this tab will appear as “Complete”',
      placement: 'top',
      disableBeacon: true
    },
    {
      target: '#errors_and_omissions_partner',
      title: '',
      content:
        'Don’t have Errors and Omissions insurance? No worries! You can visit our partner, NAPA, and sign up.',
      placement: 'left',
      disableBeacon: true
    }
  ]

  return steps
}

export { getSteps }
