import { API_ENDPOINT } from 'utils/environment-variables'
import { CertificateProviders } from 'screens/certification/certification.d'

import { AhipWorflowActions } from '../ahip.d'

export interface GetCertificationSummaryRequest {
  userInfoId: string
  certificateProvider: CertificateProviders
  authToken: string | null
  year: number
}

export interface UpdateWorkflowDetailsRequest {
  userInfoId: string
  certificateProvider: CertificateProviders
  authToken: string | null
  year: number
  action: AhipWorflowActions
  userData?: {}
}

export interface GetWorkflowDetailsRequest {
  userInfoId: string
  certificateProvider: CertificateProviders
  authToken: string | null
  year: number
}

export interface GetYearOptionsRequest {
  certificateProvider: CertificateProviders
  authToken: string | null
}

interface BodyData {
  [key: string]: any
}

const updateWorkflowDetails = async (params: UpdateWorkflowDetailsRequest) => {
  let body: BodyData = {
    year: params.year,
    action: params.action
  }
  if (params.userData) {
    body.user_data = params.userData
  }

  const response = await fetch(
    `${API_ENDPOINT}/api/certification/${params.userInfoId}/${params.certificateProvider}/workflow`,
    {
      method: 'post',
      headers: {
        Authorization: `${params.authToken}`
      },
      body: JSON.stringify(body)
    }
  )

  return response
}

const getWorkflowDetails = async (params: GetWorkflowDetailsRequest) => {
  const response = await fetch(
    `${API_ENDPOINT}/api/certification/${params.userInfoId}/${params.certificateProvider}/${params.year}/workflow`,
    {
      method: 'get',
      headers: {
        Authorization: `${params.authToken}`
      }
    }
  )

  return response
}

const getYearOptions = async (params: GetYearOptionsRequest) => {
  const response = await fetch(
    `${API_ENDPOINT}/api/certification/${params.certificateProvider}/options`,
    {
      method: 'get',
      headers: {
        Authorization: `${params.authToken}`
      }
    }
  )

  return response
}

const getCertificationSummary = async (params: GetCertificationSummaryRequest) => {
  const response = await fetch(
    `${API_ENDPOINT}/api/certification/${params.userInfoId}/${params.certificateProvider}/${params.year}/summary`,
    {
      method: 'get',
      headers: {
        Authorization: `${params.authToken}`
      }
    }
  )

  return response
}

export { updateWorkflowDetails, getWorkflowDetails, getYearOptions, getCertificationSummary }
