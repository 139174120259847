import React from 'react'

import Grid from '@material-ui/core/Grid'
import { Link } from '@material-ui/core'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'
import { carrierFullName } from '../../../../store/carriers'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    requestDenied?: boolean
    [key: string]: any
  }
}

const RequestDenied = (props: Props) => {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Carrier Review - Appointment Request Denied</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        {carrierFullName(props.carrierName)} has denied your request for appointment. Please reach
        out to the carrier licensing team at{' '}
        <Link target='_blank' href='mailto:agentlicensing@bannerlife.com'>
          agentlicensing@bannerlife.com
        </Link>{' '}
        requested for additional information.
      </Grid>
    </Grid>
  )
}

export default RequestDenied
