import workflowFetch from './workflow_fetch'

const adminResendInvitation = (carrierRequestId: string, success: any, error: any, query: any) => {
  workflowFetch({
    url: `/${carrierRequestId}/invitation/resend`,
    withAuth: true,
    data: {
      resendReason: 'adminResent',
      resendBy: 'admin'
    }
  })
    .then(d => d.json())
    .then(data => {
      success()
      if (query) query.refetch().then(_ => {})
    })
    .catch(() => {
      if (error) error()
    })
}

export default adminResendInvitation
