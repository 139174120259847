import React from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import Warning from 'components/box/warning'

import { PlateProps } from '../../../commons/plate'
import TimeToComplete from '../../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const TerminatedWithCause = (props: Props) => {
  const { doNext } = props

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Eligibility - Previously Appointed with UHO</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        According to the National Insurance Producer Registry (NIPR), you were appointed with UHO in
        the past.
      </Grid>
      <Grid item>
        <Warning>
          <strong>Current Status:</strong> UHO has deemed you “Termination - With Cause” and
          therefore, you cannot continue with appointments under Assurance.
        </Warning>
      </Grid>
      <Grid item>If you believe this termination is an error, please contact UHO to appeal.</Grid>
      <Grid container item justify='flex-end'>
        <form
          target='_blank'
          method='get'
          action='https://assuranceiq.formstack.com/forms/appointment_manager__request_assistance_from_assurance'
        >
          <Button type='submit'>Request Assistance from Assurance</Button>
        </form>
      </Grid>
    </Grid>
  )
}

export default TerminatedWithCause
