import gql from 'graphql-tag'

const CARRIERS_QUERY = gql`
  query carriers($first: Int!) {
    carriers(first: $first) {
      edges {
        node {
          id
          name
          avaliableState
          loi
          states
        }
      }
    }
  }
`

export default CARRIERS_QUERY
