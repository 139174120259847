import React from 'react'

import { Link } from '@material-ui/core'

import Note from 'components/box/note'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper from '../commons/instructions_wrapper'

interface Props {
  carrierName: string
  loi: string
}

const Checklist = (props: Props) => {
  return (
    <InstructionsWrapper {...props}>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Request for an Appointment Invitation
          </span>
        }
      >
        Let us know you want to get started with carrier contracting using one of the below options.
        <br />
        <ul>
          <li>
            For Assurance W2 Full-Time Agent (you are part of a hub): Please fill out the{' '}
            <Link
              target='_blank'
              rel='noreferrer'
              href='https://assuranceiq.formstack.com/forms/bpodecember2020appointmentinfo'
            >
              Hub Invitation Request Form
            </Link>
            . Fill it one time for all Medicare Advantage Carriers.
          </li>
          <br />
          <li>
            For Assurance 1099 On-Demand Agent (work on your own terms): Please fill out the{' '}
            <Link
              target='_blank'
              rel='noreferrer'
              href='https://assuranceiq.formstack.com/forms/humana_appointment_requests'
            >
              Humana Invitation Request Form
            </Link>
            .
          </li>
        </ul>
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            2. Complete Your Background Check and get a SAN Number
          </span>
        }
      >
        <strong>Instructions:</strong>
        <br />
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360044124271#h_01F2SPRDXXED392NMS0ZH2K4XN'
        >
          Assurance Help: How do I get appointed with Humana? Part 1: Complete Your Background Check
          and get a SAN
        </Link>
        <br />
        <br />
        If you meet Humana's requirements, you will receive a welcome email from Humana to complete
        a background check. It can take up to 10 days for a full background check. This email
        invitation for your background check link is valid for 30 days.
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>3. Complete Certifications</span>
        }
      >
        <strong>Instructions:</strong>
        <br />
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360044124271#h_01F2SPRKEM7CPGQPJFRQR2BVK2'
        >
          Assurance Help: How do I get appointed with Humana? Part 2: Register with Vantage,
          Humana’s Agent Portal, and Complete Online Education
        </Link>
        <br />
        <br />
        Once your background check is completed, you will receive another email from Humana with a
        SAN number to register on{' '}
        <Link href='https://www.humana.com/' target='_blank' rel='noreferrer'>
          Humana's Agent Portal
        </Link>
        .
        <br />
        <br />
        Tip: Go to the{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://slservices.humana.com/AgentRegistration/StepOnePage.aspx'
        >
          Registration Page
        </Link>{' '}
        and click 'Get Started'.
      </Accordion>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>4. Wait for data to sync</span>
        }
      >
        If you are experiencing difficulties with the Medicare onboarding experience on the
        Assurance Platform, please check out our{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360049621952-Humana-FAQ-v-2020-'
        >
          Humana FAQs
        </Link>{' '}
        Alternatively, you can reach out to our team through the 'Need Help' button in the bottom
        right corner of your screen.
        <Note>
          If you have questions on form completion, please contact the{' '}
          <strong>Humana Agent Support</strong> at <strong>1-800-309-3163</strong> or email the{' '}
          <Link target='_blank' rel='noreferrer' href='mailto:AgentSupport@Humana.com'>
            <strong>Humana Help Desk</strong>
          </Link>{' '}
          at <strong>AgentSupport@Humana.com</strong>.
        </Note>
      </Accordion>
    </InstructionsWrapper>
  )
}

export default Checklist
