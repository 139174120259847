import React, { useEffect, useState } from 'react'

import { FfmPageTypes, FfmProps } from './ffm.d'
import Transition from './transition'
import Workflow from './workflow'

const Ffm = (props: FfmProps) => {
  const { onStageUpdate, year } = props
  const [page, setPage] = useState(props.page)

  useEffect(() => {
    onStageUpdate([])
    setPage(props.page)
  }, [props.page])

  switch (page) {
    case FfmPageTypes.WORKFLOW:
      return <Workflow onStageUpdate={onStageUpdate} year={year} />
    case FfmPageTypes.TRANSITION:
      return <Transition year={year} />
    default:
      return <h1>FFM - Error loading page</h1>
  }
}

export default Ffm
