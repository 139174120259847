import React from 'react'

import { Grid } from '@material-ui/core'

interface Props {
  ahipLink: string
}

const AhipPromoBanner = (props: Props) => {
  const { ahipLink } = props
  return (
    <>
      <Grid item>
        <img
          style={{ width: '139.2px' }}
          alt='ahip-logo'
          src='/images/certification/ahip/ahip_logo.png'
        />
      </Grid>
      <Grid item>
        Assurance has partnered with AHIP to provide a 25% discount
        <br />
        ($50) when you use this link.
        <br />
        <br />
        <a href={ahipLink} target='_blank' rel='noreferrer'>
          Sign up for AHIP with a discount
        </a>
      </Grid>
    </>
  )
}

export default AhipPromoBanner
