import React from 'react'

import BackgroundInformationRequestExpired from './background_information_request_expired'
import BaseException from './base'
import { PlateProps } from '../../commons/plate'
import ReleaseRequired from './release_required'

const RequestError = (props: PlateProps) => (
  <BaseException
    {...props}
    title={'Request Error'}
    message={'There was an error when we tried to submit you for the Humana onboarding process.'}
  />
)

const RequestExpired = (props: PlateProps) => (
  <BaseException
    {...props}
    title={'Request Expired'}
    message={'Unfortunately, your appointment request for Humana has expired.'}
  />
)

const Declined = (props: PlateProps) => (
  <BaseException
    {...props}
    title={'Declined'}
    message={'Unfortunately, Humana has declined your request'}
  />
)

const ClosedOut = (props: PlateProps) => (
  <BaseException {...props} title={'Closed Out'} message={'Humana has closed your application.'} />
)

const CancelledRequest = (props: PlateProps) => (
  <BaseException
    {...props}
    title={'Cancelled Request'}
    message={'There was an error when we tried to submit you for the Humana onboarding process.'}
  />
)

const TerminationScheduled = (props: PlateProps) => (
  <BaseException
    {...props}
    title={'Termination Scheduled'}
    message={
      'You are being terminated from the Assurance hierarchy either by Humana or by Assurance IQ.'
    }
  />
)

const PendingTermination = (props: PlateProps) => (
  <BaseException
    {...props}
    title={'Pending Termination'}
    message={'You are being terminated from the Assurance hierarchy.'}
  />
)

const Exception = (props: PlateProps) => {
  const { pageData } = props

  switch (pageData?.exceptionType) {
    case 'Request Error':
      return <RequestError {...props} />

    case 'Request Expired':
      return <RequestExpired {...props} />

    case 'Declined':
      return <Declined {...props} />

    case 'Closed Out':
      return <ClosedOut {...props} />

    case 'Cancelled Request':
      return <CancelledRequest {...props} />

    case 'Termination Scheduled':
      return <TerminationScheduled {...props} />

    case 'Pending Termination':
      return <PendingTermination {...props} />

    case 'Release Required':
      return <ReleaseRequired {...props} />

    case 'Background Information Request Expired':
      return <BackgroundInformationRequestExpired {...props} />

    default:
      return <div>Unknown page {pageData?.exceptionType}</div>
  }
}

export default Exception
