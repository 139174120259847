import React from 'react'

import { Grid, Link } from '@material-ui/core'

import Warning from 'components/box/warning'
import { carrierFullName } from 'store/carriers'
import { get_yy_mm_dd_hh_mm } from 'utils/utils'

import { PlateProps } from '../../../commons/plate'
import { join_life_email } from '../common'

const RequestDenied = (props: PlateProps) => {
  const { carrierName, dateLastSynced } = props

  const carrier = carrierFullName(carrierName)
  const lastSynced = dateLastSynced ? new Date(dateLastSynced) : undefined
  const syncDate = lastSynced ? get_yy_mm_dd_hh_mm(lastSynced) : 'Unknown'

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Appointment Request Denied by {carrier} </h2>
      </Grid>
      <Grid item style={{ color: '#666' }}>
        Updated On: {syncDate}
      </Grid>
      <Grid item>
        <Warning>Status of Background Check - Denied By The Carrier</Warning>
      </Grid>
      <Grid item>
        It appears that the carrier has denied your request for appointment based on the background
        check or the information you had provided in the appointment form. Unfortunately, you cannot
        proceed with the appointment process. Please email{' '}
        <Link target='_blank' href={'mailto:' + join_life_email}>
          {join_life_email}
        </Link>{' '}
        for more details.
      </Grid>
    </Grid>
  )
}

export default RequestDenied
