import React, { useEffect, useState } from 'react'

import { ButtonProps, Link } from '@material-ui/core'

import { get_file } from '../../utils/utils'

const PresignedUrlLink = props => {
  const [presignedUrl, setPresignedUrl] = useState<string>('')

  useEffect(() => {
    get_file(props.url).then(d => {
      setPresignedUrl(d)
    })
  }, [props])

  return (
    <Link download href={presignedUrl}>
      {props.url.split('/').slice(-1)[0]}
    </Link>
  )
}

export default PresignedUrlLink
