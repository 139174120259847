/**
 * Load environment variables in.
 */
export const API_ENDPOINT: string = process.env.REACT_APP_API_ENDPOINT as string
export const GRAPHQL_ENDPOINT: string = process.env.REACT_APP_GRAPHQL_ENDPOINT as string

export const AUTH0_DOMAIN: string = process.env.REACT_APP_AUTH0_DOMAIN as string
export const AUTH0_CLIENT_ID: string = process.env.REACT_APP_AUTH0_CLIENT_ID as string
export const AUTH0_AUDIENCE: string = process.env.REACT_APP_AUTH0_AUDIENCE as string
export const AUTH0_RESPONSE_TYPE: string = process.env.REACT_APP_AUTH0_RESPONSE_TYPE as string
export const AUTH0_SCOPE: string = process.env.REACT_APP_AUTH0_SCOPE as string

export const DD_APPLICATION_ID: string = process.env.REACT_APP_DD_APPLICATION_ID as string
export const DD_CLIENT_TOKEN: string = process.env.REACT_APP_DD_CLIENT_TOKEN as string
export const DD_ENV: string = process.env.REACT_APP_DD_ENV as string
export const DD_SAMPLE_RATE: number = parseInt(process.env.REACT_APP_DD_SAMPLE_RATE || '0', 10)
export const DD_SERVICE: string = process.env.REACT_APP_DD_SERVICE as string
export const S3_BUCKET_URL: string = process.env.REACT_APP_S3_BUCKET_URL as string
export const DATA_ENGINEERING_THIRD_PARTY_DATA_S3_BUCKET_URL: string = process.env
  .REACT_APP_DATA_ENGINEERING_THIRD_PARTY_DATA_S3_BUCKET_URL as string
export const ASSURANCE_URL: string = process.env.REACT_APP_ASSURANCE_URL as string
export const HELLOSIGN_CLIENT_ID: string = process.env.REACT_APP_HELLOSIGN_CLIENT_ID as string
