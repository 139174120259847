import gql from 'graphql-tag'

export const QUERY = gql`
  query stateAdds(
    $first: Int!
    $offset: Int!
    $sortBy: CarrierStateAddSortingInput
    $filters: CarrierStateAddFilterInput
  ) {
    stateAdds(first: $first, offset: $offset, sortBy: $sortBy, filter: $filters) {
      totalCount
      edges {
        node {
          id
          metadata
          updatedAt
          createdAt
          fileUrl
          carrier {
            id
            name
            loi
          }
          creator {
            id
            email
            firstName
            lastName
          }
        }
      }
    }
  }
`
