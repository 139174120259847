import React from 'react'

import { Grid } from '@material-ui/core'

import Warning from 'components/box/warning'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  title: string
  message: string
}

const BaseException = (props: Props) => {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>{props.title}</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} />
      </Grid>
      <Grid item>
        <Warning>
          <strong>Note:</strong> {props.message}
        </Warning>
      </Grid>
      <Grid item>
        You can contact us by using the ‘Need Help’ button on the bottom right corner of your screen
        to speak with an Onboarding Specialist. Our team needs to review your profile should you
        want to proceed further.
      </Grid>
    </Grid>
  )
}

export default BaseException
