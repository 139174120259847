import React, { useEffect, useState } from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core'

import Box from 'components/box'
import Button from 'components/button'
import CarrierDropdown from 'components/carrier_dropdown'
import { Loading } from 'components/loading'
import { Model } from 'store'
import { PageNumberToInvitations } from 'store/admin'
import Pagination from 'components/pagination'
import Select from 'components/select'
import Table from 'components/table'
import _ from 'lodash'
import { get_s3_url } from 'utils/utils'
import { useMutation, useQuery } from '@apollo/client'
import { useStoreActions, useStoreState } from 'easy-peasy'

import CONFIRM_INVITATION from './graphql/confirm_invitation.gql'
import INVITATIONS_QUERY from './graphql/invitations.gql'
import PresignedUrlLink from '../../../components/presigned_url_link/presigned_url_link'
import SupportedLoi from '../../loi_data'

const useStyles = makeStyles(theme => ({
  paper: { minWidth: '50vw' },
  blue: {
    color: theme.palette.primary.main
  }
}))

const InvitationConfirmation = () => {
  const classes = useStyles()

  const invitationPageMap = useStoreState<Model, PageNumberToInvitations>(
    state => state.invitations.pageNumberToInvitations
  )

  const addInvitationPage = useStoreActions<Model>(actions => actions.invitations.load)
  const updateInvitationInPage = useStoreActions<Model>(
    actions => actions.invitations.updateInvitation
  )

  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)

  const [loi, setLoi] = useState<string>('all')
  const [carrier, setCarrier] = useState<{
    name: string
    id: string
  }>({ name: 'all', id: 'all' })

  const handleCarrierChange = (id: string, name: string) => {
    setCarrier({
      name,
      id
    })
  }

  const handleLoiChange = (loi: string) => {
    setLoi(loi)
    setCarrier({ name: 'all', id: 'all' })
  }

  const PAGE_LIMIT = 30

  const invitations = invitationPageMap[currentPage]

  const totalPages = Math.ceil(totalCount / PAGE_LIMIT)

  const query = useQuery(INVITATIONS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      loi,
      carrier: carrier.name,
      offset: 0,
      limit: PAGE_LIMIT
    }
  })

  const [confirmInvitation, { loading: confirmLoading }] = useMutation(CONFIRM_INVITATION)

  const fetchPage = (page: number) => {
    query
      .fetchMore({
        variables: {
          loi,
          carrier: carrier.name,
          offset: (page - 1) * PAGE_LIMIT,
          limit: PAGE_LIMIT
        }
      })
      .then(
        response => {
          const { invitationFiles } = response.data
          const invitations = invitationFiles.edges.map(row => row.node)
          const totalCount = invitationFiles.totalCount
          setTotalCount(totalCount)
          addInvitationPage({ pageNumber: currentPage, invitations })
        },
        () => void 0
      )
      .catch(err => {
        alert('Error occurred')
        console.log(err)
      })
  }

  useEffect(() => {
    fetchPage(currentPage)
  }, [currentPage, loi, carrier])

  const [confirmDialogInvitationId, setConfirmationDialogId] = useState<string | null>()

  const loadConfirmationDialog = (invitationId: string) => {
    setConfirmationDialogId(invitationId)
  }

  const handleConfirmInvitation = () => {
    confirmInvitation({
      variables: {
        id: confirmDialogInvitationId,
        input: {}
      }
    }).then(d => {
      const errors = d.data.invitationFileUpdate.errors
      if (errors.length > 0) {
        alert(errors[0].message)
        return
      }
      const newInvitation = {
        id: confirmDialogInvitationId,
        ...d.data.invitationFileUpdate.carrierInvitationFile
      }
      updateInvitationInPage({ pageNumber: currentPage, invitation: newInvitation })
      setConfirmationDialogId(null)
    })
  }

  return (
    <div>
      <Typography variant='h6' component='h2'>
        <strong>Invitation Confirmation {query.loading && <Loading />}</strong>
      </Typography>
      <Box marginTop='1rem' spacing='1.5rem' display='flex' alignItems='flex-end'>
        <div className={'styles.uploadStep'}>
          <span style={{ fontWeight: 600 }}>Line of insurance</span>
          <Select
            style={{ minWidth: '200px', marginTop: '0.25rem' }}
            selected={loi}
            options={[{ label: 'ALL', icon: '', value: 'all' }, ...SupportedLoi]}
            keySelector={loi => loi.value}
            labelSelector={loi => _.capitalize(loi.label)}
            logoSelector={loi => loi.icon}
            onChange={handleLoiChange}
          />
        </div>
        <div>
          <span style={{ fontWeight: 600 }}>Carrier</span>
          <CarrierDropdown
            showAll
            style={{ marginTop: '0.25rem' }}
            selected={carrier.id}
            onChange={handleCarrierChange}
            loi={loi}
          />
        </div>
      </Box>
      <Dialog
        onClose={() => setConfirmationDialogId(null)}
        open={confirmDialogInvitationId != null}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle>
          <strong>Review & Confirm Invitation Upload {confirmLoading && <Loading />}</strong>
        </DialogTitle>
        <DialogContent dividers>
          <Table
            sort={{
              field: 'firstName',
              desc: true
            }}
            rowKeySelector={(row, idx) => idx + ''}
            columns={[
              {
                title: 'First Name',
                field: 'firstName',
                sortable: true
              },
              { title: 'Last Name', field: 'lastName', sortable: true },
              {
                title: 'Email',
                field: 'email',
                sortable: true,
                render: row => (
                  <Link target='_blank' rel='noreferrer' href={'mailto:' + row.email}>
                    {row.email}
                  </Link>
                )
              }
            ]}
            data={(
              (confirmDialogInvitationId &&
                invitations.find(row => row.id === confirmDialogInvitationId)?.invitationDetails) ||
              []
            ).filter(row => row.firstName || row.lastName || row.email)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='text' onClick={() => setConfirmationDialogId(null)}>
            <u>Cancel</u>
          </Button>
          <Button onClick={handleConfirmInvitation}>Confirm Invitation Upload</Button>
        </DialogActions>
      </Dialog>
      <Table
        style={{ marginTop: '1.5rem' }}
        rowKeySelector={row => row.id}
        columns={[
          {
            title: 'Upload Date',
            field: 'uploadDate'
          },
          {
            title: 'File Name',
            field: 'docUrl',
            sortable: true,
            render: row => <PresignedUrlLink url={row.docUrl} />
          },
          {
            title: 'Action',
            field: 'action',
            render: row => {
              if (row.confirmedDate != null) {
                return (
                  <div>
                    Confirmed by {row.confirmedBy} (
                    <Link target='_blank' rel='noreferrer' href={'mailto:' + row.confirmedByEmail}>
                      {row.confirmedByEmail}
                    </Link>
                    );
                    <br />
                    {row.confirmedDate}
                  </div>
                )
              }
              return (
                <Button onClick={() => loadConfirmationDialog(row.id)}>
                  Review & Confirm Invitation Upload
                </Button>
              )
            }
          }
        ]}
        data={invitations}
      />
      <Pagination
        style={{
          marginTop: '0.25rem'
        }}
        count={totalPages}
        page={currentPage}
        onChange={(event, page) => {
          setCurrentPage(page)
        }}
      />
    </div>
  )
}

export default InvitationConfirmation
