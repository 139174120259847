import React from 'react'

import { InfoIcon } from 'components/icons'
import { LightTooltip } from 'components/tooltip'

const AppointmentsTooltip = () => {
  return (
    <LightTooltip
      placement='top-start'
      title='These are states which the carrier has told us you can sell in.
      Some of them will appear in your National Producer Insurance Registry (NIPR) record and some of
      them will not. The states that do not show up are called ‘Just In Time’  (JIT) states.
      These states will sync with NIPR when you start making sales.'
    >
      <div style={{ marginLeft: '0.25rem' }}>
        <InfoIcon style={{ width: '1.25rem', display: 'block' }} />
      </div>
    </LightTooltip>
  )
}

const JustInTimeAppointmentsTooltip = () => {
  return (
    <LightTooltip
      placement='top-start'
      title='These states will not sync into NIPR until you make your first sale. Afterwards, these states will become Appointed States.'
    >
      <div style={{ marginLeft: '0.25rem' }}>
        <InfoIcon style={{ width: '1.25rem', display: 'block' }} />
      </div>
    </LightTooltip>
  )
}

export { AppointmentsTooltip, JustInTimeAppointmentsTooltip }
