import { Action } from 'easy-peasy'

import { BaseUserInfo, BaseUserInfoActionModel, Role, commonUserActions } from './base_user_info'

export interface UserInfo extends BaseUserInfo {
  loaded: boolean
  callCenter: string
  roles: Role[]
  intercomUserHash: string
  isNiprPulled?: boolean
}

export interface UserInfoModel extends UserInfo, BaseUserInfoActionModel {
  update: Action<UserInfoModel, Partial<UserInfo>>
}

export const userInfoModel: UserInfoModel = {
  loaded: false,
  id: '',
  assuranceAgentId: '',
  firstName: '',
  npn: '',
  lastName: '',
  email: '',
  callCenter: '',
  roles: [],
  permissionGroups: [],
  intercomUserHash: '',
  loi: [],
  ...(commonUserActions() as any)
}
