import React from 'react'

import { Grid } from '@material-ui/core'

import Warning from 'components/box/warning'

import Button from '../../../../components/button'
import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {}

const ReleaseRequired = (props: Props) => {
  const { doRestart } = props

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Release Required</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <Warning>
          <strong>Note:</strong> We are unable to upload your profile into the Humana system.
        </Warning>
      </Grid>
      <Grid item>
        Some agencies have policies where they do not release agents. In these cases, you will need
        to send an email request to Humana’s Agent Support Team at agentsupport@humana.com for a
        delayed release. The delayed release will be processed 90 days from the day you request it.
        <br />
        <br />
        If you have questions or concerns, please connect with Humana at 1-800-309-3163 from
        Monday-Friday, 8 AM – 7 PM (Eastern Standard Time).
        <br />
        <br />
        You can also contact us by using the ‘Need Help’ button on the bottom right corner of your
        screen to speak with an Onboarding Specialist.
      </Grid>
      <Grid container item justify='flex-end' spacing={1}>
        <Grid item>
          <Button onClick={doRestart}>Restart Appointment Process</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReleaseRequired
