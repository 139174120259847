import gql from 'graphql-tag'

const APPOINTMENT_REQUESTS = gql`
  query latestCarrierAppointmentRequest($planYear: Float!, $loi: String!, $carrier: String!) {
    me {
      carrierAppointmentRequests(
        filter: { carrier: $carrier, loi: $loi, planYear: $planYear }
        sortBy: { direction: DESC, field: CREATED_AT }
        first: 1
      ) {
        edges {
          node {
            id
            planYear
            status
            carrier {
              name
              loi
              id
            }
          }
        }
      }
    }
  }
`

export default APPOINTMENT_REQUESTS
