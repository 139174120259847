import styled from 'styled-components'

const Note = styled.div`
  padding: 1rem;
  background: #fff7e0;
  border: 1px solid #ffeab2;
  border-radius: 0.25rem;
  color: #883d07;
`

export default Note
