import React from 'react'

import { Link } from '@material-ui/core'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper, { CommonChecklistProps } from '../commons/instructions_wrapper'

interface Props extends CommonChecklistProps {}

const Checklist = (props: Props) => {
  return (
    <InstructionsWrapper {...props}>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Get Carrier Portal Credentials
          </span>
        }
      >
        Let us know you want an invitation to get started with appointment by filling out the{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360047045852-How-Do-I-Get-Appointed-With-Oscar-to-Offer-ACA-Plans-'
        >
          Assurance Help: How do I get appointed with Oscar?
        </Link>
        <br />
        <br />
        1. Please click on this link to get started:{' '}
        <Link target='_blank' rel='noreferrer' href='https://business.hioscar.com/create-account'>
          https://business.hioscar.com/create-account
        </Link>
        <br />
        <br />
        2. Check Broker as you are being appointed as a Broker under the Assurance agency.
        <br />
        <br />
        3. If you do not know your NPN, please click this link to look up your NPN:{' '}
        <Link target='_blank' rel='noreferrer' href='https://nipr.com/help/look-up-your-npn'>
          https://nipr.com/help/look-up-your-npn
        </Link>
        <br />
        <br />
        4. Once you create an account, you should receive an email to your Assurance email account.
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            2. Complete Appointment Process
          </span>
        }
      >
        1. Follow the steps outlined in the document to complete the process and submit all the
        required information and documents.
        <br />
        <br />
        2. You need to upload your own Errors and Omissions Coverage.
        <br />
        <br />
        3. If you are in your menu page, you do not need to do anything under the commission tab, as
        your commissions are paid by Assurance and not directly by Oscar.
        <br />
        <br />
        4. Once your appointment has been approved by Oscar, you will receive a confirmation email
        from Oscar with your writing number.
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>3. Check Appointment Status</span>
        }
      >
        All of your Affordable Care Act (ACA) appointments are housed in one place.{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://join.assurance.com/agent/aca_appointments'
        >
          Check ACA carrier appointment status
        </Link>
        .
      </Accordion>
    </InstructionsWrapper>
  )
}

export default Checklist
