import React from 'react'

import { Link } from '@material-ui/core'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper from '../commons/instructions_wrapper'

interface Props {
  carrierName: string
  loi: string
}

const UnitedHealthACAChecklist = (props: Props) => {
  return (
    <InstructionsWrapper {...props}>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Complete Appointment Process
          </span>
        }
      >
        <strong>Instructions:</strong>
        <br />
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/4406894654868-How-to-Get-Appointed-with-UnitedHealthcare-for-ACA'
        >
          How do I get appointed with UnitedHealthCare for ACA?
        </Link>
        <br />
        <br />
        If you have NOT received an invitation to onboard with UnitedHealthcare, please click
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://assuranceiq.formstack.com/forms/uhc_aca_request'
        >
          here
        </Link>{' '}
        to request the UHC ACA onboarding invitation.
        <br />
        <br />
        You must be FFM certified to continue this process - if you are NOT FFM certified for 2022,
        please follow the instructions{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/4406841533204-How-to-get-FFM-certified-for-2022-for-Returning-Agents-'
        >
          here
        </Link>{' '}
        to get FFM certified:
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>2. Application Process</span>
        }
      >
        You should recieve an email from:{' '}
        <Link target='_blank' rel='noreferrer' href='mailto:UHC_Contracting@sircon.com'>
          UHC_Contracting@sircon.com
        </Link>{' '}
        (Sircon is the 3rd party vendor UHC uses to complete contracting).
        <br />
        <br />
        Please note, if you have not received an email, please check your junk/spam folders. If you
        have NOT received an email invitation to begin contracting, Assurance will announce when
        appointments reopen.
        <br />
        <br />
        Also, please complete contracting as soon as you receive your email as the invitation link
        is only active for two weeks.
        <br />
        <br />
        Once you Exit and UnitedHealthcare has received your application, you will receive a
        confirmation email. Please save this email for your records.
        <br />
        <br />
        Should you have any questions regarding your contract, please contact the Producer Help Desk
        at UnitedHealthcare.
      </Accordion>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>3. Check Appointment Status</span>
        }
      >
        All of your Affordable Care Act (ACA) appointments are housed in one place.
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://join.assurance.com/agent/aca_appointments'
        >
          Check ACA carrier appointment status.
        </Link>
      </Accordion>
    </InstructionsWrapper>
  )
}

export default UnitedHealthACAChecklist
