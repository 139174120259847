import React from 'react'

import { LOI } from 'store/carriers'
import _ from 'lodash'

import Select from '../select'
import SupportedLoi from '../../screens/loi_data'

interface Props {
  loi: LOI
  onChange: (loi: LOI) => void
  className?: string
  style?: React.CSSProperties
  disabledSelector?: (loi: LOI) => boolean
  hiddenSelector?: (loi: LOI) => boolean
}

const LOIDropdown = (props: Props) => {
  const { loi, hiddenSelector, disabledSelector, className, style, onChange } = props

  return (
    <Select
      className={className}
      style={{ minWidth: '200px', ...style }}
      selected={loi}
      disabledSelector={option => disabledSelector?.(option.label as LOI)}
      options={SupportedLoi.filter(d => !hiddenSelector?.(d.label as LOI))}
      keySelector={loi => loi.label}
      labelSelector={loi => _.capitalize(loi.label)}
      logoSelector={loi => loi.icon}
      onChange={onChange}
    />
  )
}

export const getConservativeLoi = (loi: LOI) => SupportedLoi.find(opt => opt.label === loi)?.value

export default LOIDropdown
