import React, { useState } from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import CheckboxButton from 'components/checkbox_button'
import Image from 'components/image'
import { RoundCheckedIcon, RoundUncheckedIcon, WarningIcon } from 'components/icons'

import Accordion from '../../commons/checklist_accordion'
import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const getIcon = (checked?: boolean) => {
  const props = {
    style: {
      width: '1.25rem',
      height: '1.25rem'
    }
  }
  return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
}

const redTextStyle = { color: '#ff4237' }
const warningIconStyle = {
  width: '1.25rem',
  marginRight: '0.375rem'
}
const Paperwork = (props: Props) => {
  const { pageData = {}, doNext } = props

  const { userInput = {} } = pageData

  const [registrationEmail, setRegistrationEmail] = useState(userInput.registrationEmail)
  const [registerBrokerPortal, setRegisterBrokerPortal] = useState(userInput.registerBrokerPortal)
  const [completedPaperwork, setCompletedPaperwork] = useState(userInput.completedPaperwork)
  const [nextClicked, setNextClicked] = useState(false)

  const handleNext = () => {
    setNextClicked(true)
    const allCompleted = registrationEmail && registerBrokerPortal && completedPaperwork
    if (allCompleted) doNext?.('next')
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Appointment - Paperwork</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item container>
        {nextClicked && !registrationEmail && (
          <Grid item container>
            <Grid item>
              <WarningIcon style={warningIconStyle} />
            </Grid>
            <Grid item style={redTextStyle}>
              You must complete this action item
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(registrationEmail)}
            title={<span style={{ display: 'flex', alignItems: 'center' }}>1. Check Email</span>}
          >
            <Grid container direction='column' spacing={2}>
              <Grid item>Please check your Assurance email account for an email.</Grid>
              <Grid item>
                <p>From: ambetterproducers@centene.com</p>
                <p>
                  Subject: <strong>Welcome to Ambetter</strong>
                </p>
                <p>
                  Recruiter Name: <strong>ASSURANCE IQ</strong>
                </p>
              </Grid>
              <Grid item>
                a. We have initiated your subagent appointment through our agency. You should have
                received an email from ambetterproducers@centene.com with the subject line{' '}
                <strong>"Welcome to Ambetter"</strong> in your email inbox.
              </Grid>
              <Grid item>
                Example of email below. If you do not receive an email in your inbox, please check
                your spam/junk folder as well. You only have 5 days to complete it, so please start
                the process as soon as you can.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/ambetter/welcome_to_ambetter_email.png'
                />
              </Grid>
              <Grid container item justify='flex-end'>
                <CheckboxButton
                  checked={registrationEmail || false}
                  onChange={value => setRegistrationEmail(value)}
                  label='I Received a Registration Email'
                />
              </Grid>
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid item container>
        {nextClicked && !registerBrokerPortal && (
          <Grid item container>
            <Grid item>
              <WarningIcon style={warningIconStyle} />
            </Grid>
            <Grid item style={redTextStyle}>
              You must complete this action item
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(registerBrokerPortal)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                2. Log Into Broker Portal, or Register an Account for Broker Portal
              </span>
            }
          >
            <Grid container direction='column' spacing={2}>
              <Grid item>
                If you are a new Broker to Ambetter, you will be directed to the Registration Page
                to createa password and obtain your username
              </Grid>
              <Grid item>
                If you are an existing Ambetter Broker adding additional contracts, you will be
                directed to Login Page.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/ambetter/ambetter_broker_portal_register.png'
                />
              </Grid>
              <Grid container item justify='flex-end'>
                <CheckboxButton
                  checked={registerBrokerPortal || false}
                  onChange={value => setRegisterBrokerPortal(value)}
                  label='I’ve Logged Into Producer World'
                />
              </Grid>
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid item container>
        {nextClicked && !completedPaperwork && (
          <Grid item container>
            <Grid item>
              <WarningIcon style={warningIconStyle} />
            </Grid>
            <Grid item style={redTextStyle}>
              You must complete this action item
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(completedPaperwork)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                3. Complete and Submit Paperwork
              </span>
            }
          >
            <Grid container direction='column' spacing={2}>
              <Grid item>
                Once you register or login, you will be redirected to the application.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/ambetter/ambetter_application.png'
                  style={{ marginBottom: '1.5rem' }}
                />
              </Grid>
              <Grid item>You must enter DOB in this format MM-DD-YYYY, NPN and SSN.</Grid>
              <Grid item>
                Confirm basic info on next screen. Please make sure you enter your resident address.
                Note: The address needs to match the address on your license or your application
                will pend and not be processed.
              </Grid>
              <Grid item style={redTextStyle}>
                If the Broker is a Producer, they must answer if they are contracting through an
                Agency. If yes, the Broker has to enter the ETIN for the Agency.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/ambetter/contracting_through_agency.png'
                  style={{ marginBottom: '1.5rem' }}
                />
              </Grid>
              <Grid item>
                After you enter all the details and click on Next, the call to NIPR will be made to
                bring in all the Licenses, Appointments, Certifications and Addresses. All your
                licenses will be displayed and there will be a green tick next to the states that
                you were invited for and have a license for in that state.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/ambetter/producer_license.png'
                  style={{ marginBottom: '1.5rem' }}
                />
              </Grid>
              <Grid item>
                After NIPR returns the states, you can click on Continue. The Contact Residence and
                Business Address will be returned. You can validate the address, make changes if
                needed and click on Next.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/ambetter/address_contact.png'
                  style={{ marginBottom: '1.5rem' }}
                />
              </Grid>
              <Grid item>
                Every Broker must have E&O Insurance of at least $1,000,000. If you do not have E&O
                Insurance, the application will be rejected. After entering the E&O details, click
                on Continue.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/ambetter/error_omission.png'
                  style={{ marginBottom: '1.5rem' }}
                />
              </Grid>
              <Grid item>
                You must answer the License confirmation Questionnaire and enter your FFM user ID
                then click on Continue. Your FFM User ID will be your username used on the
                portal.cms.gov website.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/ambetter/license_confirmation.png'
                  style={{ marginBottom: '1.5rem' }}
                />
              </Grid>
              <Grid item>
                You must answer the Criminal History Questionnaire and click on Continue. If you
                answer Yes to any of the questions you can continue with the flow, but the
                application will be set to manual review. If you select Yes for Bankruptcy, the
                application will be rejected.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/ambetter/criminal_history.png'
                  style={{ marginBottom: '1.5rem' }}
                />
              </Grid>
              <Grid item>After that, sign the contract e-document. Click Next to continue.</Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/ambetter/document_sign.png'
                  style={{ marginBottom: '1.5rem' }}
                />
              </Grid>
              <Grid item>
                Before exiting the screen, you should see a confirmation that your application has
                been submitted.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/ambetter/application_submitted.png'
                />
              </Grid>
              <Grid container item justify='flex-end'>
                <CheckboxButton
                  checked={completedPaperwork || false}
                  onChange={value => setCompletedPaperwork(value)}
                  label='I Completed and Submitted All Required Paperwork'
                />
              </Grid>
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container item justify='flex-end' spacing={1} alignItems='center'>
        {nextClicked && (!registrationEmail || !registerBrokerPortal || !completedPaperwork) && (
          <Grid item>
            <Grid container>
              <Grid item>
                <WarningIcon style={warningIconStyle} />
              </Grid>
              <Grid item style={redTextStyle}>
                Action Items Incomplete
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Button onClick={handleNext}>Next: Carrier Review</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Paperwork
