const CarrierTags = {
  'Plan D': {
    Icon: '/images/carrier_tags/medicare/medicare-part_d.svg',
    Label: 'PART D'
  },
  'Medicare Advantage': {
    Icon: '/images/carrier_tags/medicare/medicare-advantage.svg',
    Label: 'ADVANTAGE'
  },
  'Medicare Supplement': {
    Icon: '/images/carrier_tags/medicare/supplement.svg',
    Label: 'SUPPLEMENT'
  },
  'Chronic Special Needs Plan': {
    Icon: '/images/carrier_tags/medicare/medicare-chronic_special_needs.svg',
    Label: 'CHRONIC'
  },
  'Dual Special Needs Plan': {
    Icon: '/images/carrier_tags/medicare/medicare-dual_special_needs.svg',
    Label: 'DUAL'
  },
  'Medicare Ancillary Plan': {
    Icon: '/images/carrier_tags/medicare/medicare-ancillary.svg',
    Label: 'ANCILLARY'
  },
  'Affordable Care Act plan': {
    Icon: '/images/carrier_tags/health/aca.svg',
    Label: 'AFFORDABLE CARE'
  },
  Dental: {
    Icon: '/images/carrier_tags/health/dental.svg',
    Label: 'DENTAL'
  },
  'Hospital Indemnity': {
    Icon: '/images/carrier_tags/health/hi.svg',
    Label: 'INDEMNITY'
  },
  'Other Ancillaries': {
    Icon: '/images/carrier_tags/health/other_ancillaries.svg',
    Label: 'ANCILLARIES'
  },
  'Short Term Medical': {
    Icon: '/images/carrier_tags/health/stm.svg',
    Label: 'SHORT TERM'
  },
  Vision: {
    Icon: '/images/carrier_tags/health/vision.svg',
    Label: 'VISION'
  }
}

export default CarrierTags
