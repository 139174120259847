import { Action, State, action, createStore, createTypedHooks } from 'easy-peasy'

import { AhipStatusModel, ahipStatusModel } from './ahip_status'
import {
  AppointmentRequestsWorkflowStatusModel,
  appointmentRequestsWorkflowStatusModel
} from './workflow_status'
import {
  CarrierAppointmentRequestsModel,
  carrierAppointmentRequestsModel
} from './carrier_appointment_requests'
import { CarrierAppointmentsModel, carrierAppointmentsModel } from './carrier_appointments'
import { CarrierInfoModel, carrierInfoModel } from './carriers'
import { ErrorsAndOmissionsModel, errorsAndOmissionsModel } from './errors_and_omissions'
import { InvitationModel, invitationModel } from './invitation'
import { LoginUserInfoModel, loginUserInfoModel } from './login_user_info'
import { StateLicensesModel, stateLicensesModel } from './state_license'
import { UserInfoModel, userInfoModel } from './user_info'

export interface Model {
  errorsAndOmissions: ErrorsAndOmissionsModel
  userInfo: UserInfoModel
  loginUserInfo: LoginUserInfoModel
  carrierInfo: CarrierInfoModel
  carrierAppointments: CarrierAppointmentsModel
  carrierAppointmentRequests: CarrierAppointmentRequestsModel
  stateLicenses: StateLicensesModel

  appointmentRequestsWorkflowStatus: AppointmentRequestsWorkflowStatusModel
  invitations: InvitationModel
  ahipStatus: AhipStatusModel
  reset: Action<Model, any>
}

const store = createStore<Model>({
  errorsAndOmissions: errorsAndOmissionsModel,
  userInfo: userInfoModel,
  loginUserInfo: loginUserInfoModel,
  carrierInfo: carrierInfoModel,
  carrierAppointments: carrierAppointmentsModel,
  stateLicenses: stateLicensesModel,
  appointmentRequestsWorkflowStatus: appointmentRequestsWorkflowStatusModel,
  carrierAppointmentRequests: carrierAppointmentRequestsModel,
  invitations: invitationModel,
  ahipStatus: ahipStatusModel,
  reset: action((state, newState) => ({
    ...newState
  }))
})

const initialState = store.getState()

export const resetStore = () => {
  store.dispatch.reset(initialState)
}

export const getCarrierInfo = (carrierName: string) => {
  return store.getState().carrierInfo.items.find(carrier => carrier.name === carrierName)
}

const { useStoreActions, useStoreState, useStoreDispatch, useStore } = createTypedHooks<Model>()

export { useStoreActions, useStoreState, useStoreDispatch, useStore }

export default store
