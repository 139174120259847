import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Grid, Link, Typography } from '@material-ui/core'

import Box from 'components/box'
import Button from 'components/button'
import CheckboxButton from 'components/checkbox_button'
import Image from 'components/image'
import InfoBox from 'components/box/info_box'
import Message from 'components/message/Message'

import Accordion from '../../../commons/checklist_accordion'
import { PlateProps } from '../../../commons/plate'
import TimeToComplete from '../../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    'Producer Number'?: string
    userInput?: {
      accountCreated?: boolean
    }
  }
}

const CarrierPortalAccess = (props: Props) => {
  const { plateName, doUpdate, pageData = {} } = props

  const { userInput = {} } = pageData

  const [accountCreated, setAccountCreated] = useState(userInput.accountCreated)
  const [showError, setShowError] = useState(false)

  const producerNumber = pageData['Producer Number']

  const history = useHistory()

  const allCompleted = !!accountCreated

  const handleNext = () => {
    if (!allCompleted) {
      setShowError(true)
      return
    }
    const data = {
      pageData: {
        ...pageData,
        userInput: {
          accountCreated
        }
      },
      progress: {
        name: plateName,
        status: allCompleted ? 'checked' : 'error'
      },
      nextProgress: 'CarrierPortalAccess',
      nextPage: {
        name: 'CarrierPortalAccess'
      }
    }
    doUpdate?.('next', data)
    history.push('/carrier_appointments')
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Carrier Portal Access</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item container direction='column' spacing={1}>
        <Grid item>
          <InfoBox>
            <strong>Note:</strong> This is a <strong>CRITICAL</strong> step! You need to have access
            to the Mutual Of Omaha portal to complete enrolments. Please create an account by
            following the steps below. If you already have an account, please hit “done”!
          </InfoBox>
        </Grid>
        <Grid item>
          <Accordion
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <strong>1. Your Mutual Of Omaha Production Number</strong>
              </span>
            }
          >
            Your Mutual of Omaha Producer Number is:
            <br />
            <Typography style={{ fontSize: '1.5rem' }}>
              <strong>{producerNumber}</strong>
            </Typography>
            <br />
            Keep a copy of your Mutual of Omaha producer number for your records.
          </Accordion>
        </Grid>
        <Grid item>
          <Accordion
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <strong>2. Create an Account</strong>
              </span>
            }
          >
            a. Log in to Mutual of Omaha Portal by clicking on this{' '}
            <Link rel='noreferrer' target='_blank' href={'https://www.mutualofomaha.com/'}>
              Link
            </Link>
            . Click on “Sign in” at the top right of the screen. Navigate and click to the ”Sales
            Professional Access”.
            <Image
              style={{ width: '60%', marginTop: '0.5rem' }}
              alt='sign in to portal'
              src='/images/carriers/life/mutual_of_omaha/smartflow/moo_smartflow_1.png'
            />
            <br />
            ii. Click on ”Register”, then enter your Producer Number (<i>see section 1, above</i>)
            in the ”Production Number” field and click continue.
            <Image
              style={{ width: '100%', marginTop: '0.5rem', border: '2px solid black' }}
              alt='welcome and continue'
              src='/images/carriers/life/mutual_of_omaha/smartflow/moo_smartflow_2.png'
            />
            <Image
              style={{ width: '100%', marginTop: '0.5rem', border: '2px solid black' }}
              alt='date of birth'
              src='/images/carriers/life/mutual_of_omaha/smartflow/moo_smartflow_3.png'
            />
            <br />
            iii. Enter your Date of Birth. After reviewing terms and conditions, check the ”I Accept
            Terms and Conditions” checkbox then click continue.
            <Image
              style={{ width: '100%', marginTop: '0.5rem', border: '2px solid black' }}
              alt='fill create account form'
              src='/images/carriers/life/mutual_of_omaha/smartflow/moo_smartflow_4.png'
            />
            <br />
            b. Complete all the fields on ”Let's create your account” page.{' '}
            <strong>Use your Assurance email address</strong> to sign up.{' '}
            <i>
              Please record your username and password as you will need it to sign in to the
              eApplication during sales.
            </i>
            <Image
              style={{ width: '100%', marginTop: '0.5rem', border: '2px solid black' }}
              alt='fill create account form'
              src='/images/carriers/life/mutual_of_omaha/smartflow/moo_smartflow_5.png'
            />
            <Grid container item justify='flex-end'>
              <CheckboxButton
                checked={accountCreated || false}
                onChange={value => setAccountCreated(value)}
                label='I have created my Account'
              />
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container item justify='flex-end'>
        <Box width='100%' display='flex' alignItems='center' justifyContent='flex-end'>
          {showError && !allCompleted && (
            <Box mr='1rem'>
              <Message type={'warning'}>Action items incomplete</Message>
            </Box>
          )}
          <Button onClick={handleNext}>Done</Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default CarrierPortalAccess
