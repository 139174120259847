import React from 'react'

import { Grid, Link } from '@material-ui/core'

import Button from 'components/button'
import InfoBox from 'components/box/info_box'
import { RoundCheckedIcon } from 'components/icons'
import SuccessBox from 'components/box/success_box'
import { carrierFullName } from 'store/carriers'
import { get_yy_mm_dd_hh_mm } from 'utils/utils'

import { PlateProps } from '../../../commons/plate'
import TimeToComplete from '../../../commons/time_to_complete'

const blueStyle = { color: '#005DAA' }

const TrainingComplted = (props: PlateProps) => {
  const { carrierName, pageData, dateStarted, timeToComplete, dateLastSynced, doNext } = props

  const lastSynced = dateLastSynced ? new Date(dateLastSynced) : undefined
  const syncDate = lastSynced ? get_yy_mm_dd_hh_mm(lastSynced) : 'Unknown'
  const carrier = carrierFullName(carrierName)
  const appointed_states = pageData.appointed_states ? pageData.appointed_states : 'Unknown'

  const handleNext = () => {
    const data = {}
    doNext?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Mandatory Assurance Training</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={dateStarted} timeToComplete={timeToComplete} />
      </Grid>
      <Grid item>
        {pageData.background_check_status === 'complete' ? (
          <SuccessBox>
            <strong>Congratulations!</strong> You're now appointed in the following states with{' '}
            {carrier}: {appointed_states}.
          </SuccessBox>
        ) : (
          <InfoBox>
            <strong>
              Status of Review by {carrier} : {pageData.background_check_status}
            </strong>
            <br />
            Your background check is in review by the {carrier} Team.{' '}
            <em>Last Updated on {syncDate}</em>
            <br />
            <br />
            It takes about 14 days for the carrier to review your background and paperwork. If it
            has been longer than that, please reach out to to the Assurance contracting team at{' '}
            <Link target='_blank' href={'mailto:lifecarrierappointments@assurance.com'}>
              lifecarrierappointments@assurance.com
            </Link>{' '}
            and they will inquire on your behalf.
          </InfoBox>
        )}
      </Grid>
      <Grid item>
        <br />
        <Grid container spacing={1}>
          <Grid item>
            <RoundCheckedIcon blue />
          </Grid>
          <Grid item>
            Training Check - <strong style={blueStyle}>Completed</strong>
          </Grid>
        </Grid>
        <br />
        Good Job on completing the mandatory Assurance Training on Assurance University. No further
        action is required from your side.
        <br />
        <br />
        Sit back, relax, and we will update you once {carrier} Contracting team completes their
        review.
      </Grid>
      <br />
      {pageData.background_check_status === 'complete' && (
        <Grid container item justifyContent='flex-end'>
          <Button onClick={handleNext}>Next: Sync Data</Button>
        </Grid>
      )}
    </Grid>
  )
}

export default TrainingComplted
