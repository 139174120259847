import React, { useContext, useEffect, useState } from 'react'

import { Alert } from '@material-ui/lab'
import { Grid, Link, Snackbar } from '@material-ui/core'

interface SnackbarLink {
  href: string
  text: string
}

interface SnackbarInfo {
  severity: 'success' | 'info' | 'warning' | 'error'
  message: string
  link?: SnackbarLink | undefined
}

interface ContextInfo {
  snackbarInfo: SnackbarInfo | undefined
  lastSnackbarInfo: SnackbarInfo | undefined
  setSnackbarInfo: (snackbarInfo: SnackbarInfo | undefined) => void
}

const Context = React.createContext<ContextInfo>({
  snackbarInfo: undefined,
  lastSnackbarInfo: undefined,
  setSnackbarInfo: () => undefined
})

interface Props {
  children?: React.ReactNode
}

const SnackbarProvider = (props: Props) => {
  const [lastSnackBar, setLastSnackbar] = useState<SnackbarInfo | null>()

  const [contextInfo, setContextInfo] = useState<Omit<ContextInfo, 'setSnackbarInfo'>>({
    snackbarInfo: undefined,
    lastSnackbarInfo: undefined
  })

  const snackbarInfo = contextInfo.snackbarInfo

  const setSnackbarInfo = (snackbarInfo: SnackbarInfo | undefined) => {
    setContextInfo({ ...contextInfo, snackbarInfo })
  }

  useEffect(() => {
    if (!snackbarInfo) {
      return
    }
    setLastSnackbar(snackbarInfo)
  }, [snackbarInfo])

  return (
    <Context.Provider
      value={{
        ...contextInfo,
        setSnackbarInfo
      }}
    >
      <Snackbar
        open={snackbarInfo != null}
        onClose={() => {
          setSnackbarInfo(undefined)
        }}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant='filled'
          onClose={() => {
            setSnackbarInfo(undefined)
          }}
          severity={lastSnackBar?.severity}
        >
          {lastSnackBar?.message}
          {lastSnackBar?.link && (
            <Link
              target='_blank'
              rel='noreferrer'
              href={lastSnackBar.link.href}
              style={{ marginLeft: '.5rem', color: '#00f' }}
            >
              {lastSnackBar.link.text}
            </Link>
          )}
        </Alert>
      </Snackbar>
      {props.children}
    </Context.Provider>
  )
}

const useSnackbar = () => {
  const context = useContext(Context)

  return context.setSnackbarInfo
}

export default SnackbarProvider
export { useSnackbar }
