import React from 'react'

import { Grid, Link } from '@material-ui/core'

import Button from 'components/button'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const InvitationSent = (props: Props) => {
  const { doNext } = props

  const handleNext = () => {
    doNext?.('next')
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Invitation Sent</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        Invitation has been sent to your email. Please check your {''}
        <Link target='_blank' rel='noreferrer' href='https://outlook.office365.com/mail/'>
          <strong>@Assurance.com</strong>
        </Link>
        {''} email account for:
        <br />
        <br />
        <div>
          <div>
            <strong>From:</strong> donotreply@contracting.aetna.com
          </div>
          <div>
            <strong>Subject:</strong> You have been invited to contract with Aetna Medicare!
          </div>
          <div>
            <strong>Recruiter Name:</strong> ASSURANCE IQ
          </div>
        </div>
        <br />
        You may now continue to the Appointment - Paperwork Process.
        <br />
        <br />
        Sometimes, you may experience errors inside the Aetna portal. The most common one is that
        you have duplicate invitations sent to you. In this case, delete all but the latest one sent
        by Assurance IQ. You can only proceed when there is ONE open invitation.
      </Grid>
      <Grid container item justify='flex-end' spacing={1}>
        <Grid item>
          <Button onClick={handleNext}>Next: Appointment</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InvitationSent
