import React from 'react'

import EmailInviteSent from './email_invite_sent'
import InvitationSent from './invitation_sent'
import { PlateProps } from '../../commons/plate'
import RequestEmailInvite from './request_email_invite'

enum PageName {
  RequestInvitation = 'RequestInvitation',
  EmailInviteSent = 'EmailInviteSent',
  InvitationSent = 'InvitationSent'
}

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  plateName: PageName
}

const Index = (props: Props) => {
  switch (props.pageName) {
    case PageName.EmailInviteSent:
      return <EmailInviteSent {...props} />
    case PageName.RequestInvitation:
      return <RequestEmailInvite {...props} />
    case PageName.InvitationSent:
      return <InvitationSent {...props} />
    default:
      return <div>Unknown page {props.pageName}</div>
  }
}

export default Index
