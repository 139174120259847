import DataSync from 'screens/carrier_appointments/commons/flow/plates/data_sync'

import BackgroundCheck from './background_check'
import CanceledByAgent from '../../commons/flow/plates/canceled_by_agent'
import Cancelled from '../../commons/flow/plates/cancelled'
import CarrierReviewAndAIQTraining from './carrier_review_aiq_training'
import Eligibility from './eligibility'
import Paperwork from './paperwork'

export const PlateLabelMap = {
  Eligibility: 'Check Eligibility',
  Paperwork: 'Complete Paperwork',
  BackgroundCheck: 'Complete Background Check',
  CarrierReviewAndAIQTraining: 'Lumico Review & Assurance Training',
  DataSync: 'Sync Data',
  CanceledByAgent: 'Canceled by Agent',
  Cancelled: 'Cancelled',
  Exception: 'Exception'
}

export const PlateComponentMap = {
  Eligibility: Eligibility,
  Paperwork: Paperwork,
  BackgroundCheck: BackgroundCheck,
  CarrierReviewAndAIQTraining: CarrierReviewAndAIQTraining,
  DataSync: DataSync,
  CanceledByAgent,
  Cancelled
}
