import React, { useCallback, useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'

import { DiamondWarningIcon, InvalidIcon, ResidentIcon, RoundCheckedIcon } from 'components/icons'
import US_STATES, { abbreviatedStateName } from 'utils/us_states'
import * as d3 from 'd3'
import styled from 'styled-components'

import { US_STATES_GEO_DATA } from './data'

interface Props<Row, TooltipProps> {
  id?: string
  className?: string
  style?: Record<string, string | number>
  data: Row[]
  tooltipProps: TooltipProps
  colorSelector: (row: Row) => string
  stateSelector: (row: Row) => string
  tooltipRender: (tooltipProps: TooltipProps) => React.ReactElement
}

const Main = styled.div`
  position: relative;
`

const TooltipContainer = styled.div`
  position: absolute;
  right: 0;
`

const HoverTooltip = styled.div`
  position: absolute;
  padding: 10px;
  visibility: hidden;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  pointer-events: none;
`

const Legends = styled.div`
  position: absolute;
  label {
    font-weight: 600;
  }
  > * + * {
    margin-top: 12px;
  }
`

const Legend = styled.div`
  display: flex;
  align-items: center;
`

const Chart = <Row, TooltipProps>(props: Props<Row, TooltipProps>) => {
  const { id, className, style, stateSelector, colorSelector, tooltipRender, tooltipProps } = props

  const svgRef = useRef<SVGSVGElement>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)
  const hoverTooltipRef = useRef<HTMLDivElement>(null)
  const legendRef = useRef<HTMLDivElement>(null)

  const redraw = useCallback(() => {
    const svg = d3.select(svgRef.current!)
    const tooltip = d3.select(tooltipRef.current!)
    const hoverTooltip = d3.select(hoverTooltipRef.current!)

    const svgBounds = svg.node().getBoundingClientRect()

    const width = svgBounds.width
    const height = svgBounds.height

    const projection = d3.geoAlbersUsa().fitSize([width, height], US_STATES_GEO_DATA)

    const path = d3.geoPath().projection(projection)

    svg.attr('width', width).attr('height', height)

    const updateSelection = svg.selectAll('path').data(US_STATES_GEO_DATA.features)

    updateSelection.exit().remove()

    const findMatchingRow = geo_datum =>
      props.data.find(row => US_STATES[stateSelector(row)] === geo_datum.properties.name)

    const getColor = geo_datum => {
      const matchingRow = findMatchingRow(geo_datum)
      if (matchingRow) {
        return colorSelector(matchingRow)
      }
      return '#fafafa'
    }

    updateSelection
      .enter()
      .append('path')
      .merge(updateSelection)
      .attr('d', path)
      .style('stroke', '#ccc')
      .style('stroke-width', '1')
      .style('fill', function (d) {
        return getColor(d)
      })
      .style('transform', function () {
        const node = d3.select(this)
        return `translateX(${0}px)`
      })
      .on('mouseover', function (event, datum) {
        const node = d3.select(this)
        const color = getColor(datum)
        node.style('fill', d3.rgb(color).darker(0.8))
        hoverTooltip.style('visibility', 'visible')
        hoverTooltip.html(
          datum.properties.name + ' (' + abbreviatedStateName(datum.properties.name) + ')'
        )
        const [left, top] = d3.pointer(event)
        hoverTooltip
          .style('left', left + 'px')
          .style('top', top - hoverTooltip.node().clientHeight - 10 + 'px')
      })
      .on('mousemove', function (event, datum) {
        const [left, top] = d3.pointer(event)
        hoverTooltip
          .transition(100)
          .style('left', left + 'px')
          .style('top', top - hoverTooltip.node().clientHeight - 10 + 'px')
      })
      .on('mouseout', function (event, datum) {
        const node = d3.select(this)
        node.style('fill', getColor(datum))
        hoverTooltip.style('visibility', 'hidden')
      })

    tooltip.style('visibility', window.innerWidth > 1650 ? 'visible' : 'hidden')
  }, [props.data, stateSelector, colorSelector])

  useEffect(() => {
    redraw()

    window.addEventListener('resize', redraw)

    return () => {
      window.removeEventListener('resize', redraw)
    }
  }, [redraw])

  return (
    <Main id={id} className={className} style={style}>
      <TooltipContainer ref={tooltipRef}>{tooltipRender(tooltipProps)}</TooltipContainer>
      <HoverTooltip ref={hoverTooltipRef} />
      <Legends ref={legendRef}>
        <label>State Status</label>
        <Legend>
          <ResidentIcon /> &nbsp;Resident State
        </Legend>
        <Legend>
          <InvalidIcon /> &nbsp;Inactive
        </Legend>
        <Legend>
          <RoundCheckedIcon /> &nbsp;Active
        </Legend>
      </Legends>
      <svg ref={svgRef} style={{ width: '100%', height: '100%' }} />
    </Main>
  )
}

export default Chart
