import { Action, Computed, action } from 'easy-peasy'

import { Carrier, CarrierInfo, LOI } from './carriers'

export enum CarrierStatus {
  NotAppointed = 'NOT_APPOINTED',
  Appointed = 'APPOINTED',
  Terminated = 'TERMINATED'
}

interface CarrierTag {
  name: string
  loi: LOI
}

export interface InternalAppointmentDetail {
  id: string
  fein: number
  isInternallyDisabled?: boolean
  disabledReason?: string
  companyName: string
  state: string
  status: CarrierStatus
  loaName: string
  carrierTag: CarrierTag[]
}

export interface JustInTimeStateDetails {
  id: string
  state: string
  status: 'ACTIVE' | 'INACTIVE'
  carrierTag: CarrierTag[]
}

export interface CarrierAppointment {
  planYear: number
  carrier: CarrierInfo
  internalAppointmentDetails: InternalAppointmentDetail[]
  justInTimeStateDetails: JustInTimeStateDetails[]
  statusFullySynced: boolean
  status: CarrierStatus
  metadata: string
}

export interface CarrierAppointmentsModel {
  items: CarrierAppointment[]
  load: Action<
    CarrierAppointmentsModel,
    {
      planYear: number
      appointments: CarrierAppointment[]
    }
  >
}

export const carrierAppointmentsModel: CarrierAppointmentsModel = {
  items: [] as CarrierAppointment[],
  load: action((state, payload) => {
    const items = state.items.filter(row => row.planYear !== payload.planYear)
    state.items = [...items, ...payload.appointments]
  })
}
