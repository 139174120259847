import React from 'react'

import { Grid } from '@material-ui/core'

import Warning from 'components/box/warning'

import { PlateProps } from '../commons/plate'
import TimeToComplete from '../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const ApprovalRequested = (props: Props) => {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Approval Requested</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Warning>
        <strong>Note:</strong> Aetna’s team is reviewing your case.
      </Warning>
      <Grid item>
        No action required from you. If it has been stuck in this stage for over 10 days, please
        contact Aetna to find out what is happening. The Aetna number is 866-714-9301.
      </Grid>
    </Grid>
  )
}

export default ApprovalRequested
