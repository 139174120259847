import React, { useState } from 'react'

import { Grid } from '@material-ui/core'

import Accordion from 'screens/carrier_appointments/commons/checklist_accordion'
import Button from 'components/button'
import CheckboxButton from 'components/checkbox_button'
import Image from 'components/image'
import { PlateProps } from 'screens/carrier_appointments/commons/plate'
import { RoundCheckedIcon, RoundUncheckedIcon, WarningIcon } from 'components/icons'
import TimeToComplete from 'screens/carrier_appointments/commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const getIcon = (checked?: boolean) => {
  const props = {
    style: {
      width: '1.25rem',
      height: '1.25rem'
    }
  }
  return checked ? <RoundCheckedIcon blue {...props} /> : <RoundUncheckedIcon {...props} />
}

const redTextStyle = { color: '#ff4237' }
const warningIconStyle = {
  width: '1.25rem',
  marginRight: '0.375rem'
}
const Paperwork = (props: Props) => {
  const { pageData = {}, doNext } = props

  const { userInput = {} } = pageData

  const [registrationEmail, setRegistrationEmail] = useState(userInput.registrationEmail)
  const [registerBrokerPortal, setRegisterBrokerPortal] = useState(userInput.registerBrokerPortal)
  const [completedPaperwork, setCompletedPaperwork] = useState(userInput.completedPaperwork)
  const [nextClicked, setNextClicked] = useState(false)

  const handleNext = () => {
    setNextClicked(true)
    const allCompleted = registrationEmail && registerBrokerPortal && completedPaperwork
    if (allCompleted) doNext?.('next')
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Appointment - Paperwork</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item container>
        {nextClicked && !registrationEmail && (
          <Grid item container>
            <Grid item>
              <WarningIcon style={warningIconStyle} />
            </Grid>
            <Grid item style={redTextStyle}>
              You must complete this action item
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(registrationEmail)}
            title={<span style={{ display: 'flex', alignItems: 'center' }}>1. Check Email</span>}
          >
            <Grid container direction='column' spacing={2}>
              <Grid item>Please check your Assurance email account for an email from UHO.</Grid>
              <Grid item>
                <p>
                  From: <strong>getappointed@unitedhealthone.com</strong>
                </p>
                <p>
                  Subject: <strong>Invitation link From UnitedHealthOne/Golden Rule</strong>
                </p>
              </Grid>
              <Grid item>
                Example of email below. If you do not receive an email in your inbox, please check
                your spam/junk folder as well.
              </Grid>
              <Grid item>
                Note: Please complete contracting as soon as you receive your email as the{' '}
                <strong>invitation link is only active for fifteen days.</strong>
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/uho/welcome_to_uho_email.png'
                />
              </Grid>
              <Grid container item justify='flex-end'>
                <CheckboxButton
                  checked={registrationEmail || false}
                  onChange={value => setRegistrationEmail(value)}
                  label='I Received a Registration Email'
                />
              </Grid>
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid item container>
        {nextClicked && !registerBrokerPortal && (
          <Grid item container>
            <Grid item>
              <WarningIcon style={warningIconStyle} />
            </Grid>
            <Grid item style={redTextStyle}>
              You must complete this action item
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(registerBrokerPortal)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                2. Log Into VUE Producer Onboarding
              </span>
            }
          >
            <Grid container direction='column' spacing={2}>
              <Grid item>
                a. Once you click on "Click here to start onboarding" in the email, you will be
                redirected to UHO's producer onboarding.
              </Grid>
              <Grid item>
                i. Create a new account or sign in using your current account credentials.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/uho/uho_reference_image_2.png'
                />
              </Grid>
              <Grid item>
                ii. The first time you sign in, you will need to review the terms and conditions,
                click on the agree button and then click NEXT for the next two screens.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/uho/uho_reference_image_3.png'
                />
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/uho/uho_reference_image_4.png'
                />
              </Grid>
              <Grid item>
                iii. Next you will verify that all of your information is correct. This is pulled
                directly from NIPR. You will need to input your DOB only on the top of this screen.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/uho/uho_reference_image_5.png'
                />
              </Grid>
              <Grid container item justify='flex-end'>
                <CheckboxButton
                  checked={registerBrokerPortal || false}
                  onChange={value => setRegisterBrokerPortal(value)}
                  label='I’ve Logged Into VUE Producer Onboarding'
                />
              </Grid>
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid item container>
        {nextClicked && !completedPaperwork && (
          <Grid item container>
            <Grid item>
              <WarningIcon style={warningIconStyle} />
            </Grid>
            <Grid item style={redTextStyle}>
              You must complete this action item
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Accordion
            icon={getIcon(completedPaperwork)}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                3. Complete and Submit Paperwork
              </span>
            }
          >
            <Grid container direction='column' spacing={2}>
              <Grid item>b. Please follow and complete each step accurately.</Grid>
              <Grid item>
                i. You must first make sure to enter your phone number, and email address (Follow
                red asterisks). Next you must click on the circle with the slash through it and then
                click on the box under "IS PRIMARY" for your Business Address. Then click NEXT.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/uho/uho_reference_image_6.png'
                  style={{ marginBottom: '1.5rem' }}
                />
              </Grid>
              <Grid item>
                c. Read over all questions and answer truthfully on this full page. Then click NEXT.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/uho/uho_reference_image_7.png'
                  style={{ marginBottom: '1.5rem' }}
                />
              </Grid>
              <Grid item>
                d. You will be assigning commissions to Assurance IQ. Click "Yes" on assigning
                commissions, assign them to a "Personal Agency" and the agency name to ASSURANCE IQ,
                tax ID 81-1285645. Please be sure to make all the selections as listed below. Then
                click NEXT.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/uho/uho_reference_image_8.png'
                  style={{ marginBottom: '1.5rem' }}
                />
              </Grid>
              <Grid item>
                e. This page is pulled directly from NIPR.com. This should show all states that you
                are licensed in and any lines that you are appointed in. No action is needed here,
                please review and click NEXT.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/uho/uho_reference_image_9.png'
                  style={{ marginBottom: '1.5rem' }}
                />
              </Grid>
              <Grid item>
                f. Next, please select EACH STATE that you are appointed in, and chose OFF EXCHANGE
                ONLY as the option in the drop down. Failure to do this for all the states will not
                complete the appointment in the state. The screen will show pricing of each state,
                but will zero out at the end. These costs are covered by Assurance. Once completed,
                click NEXT.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/uho/uho_reference_image_10.png'
                  style={{ marginBottom: '1.5rem' }}
                />
              </Grid>
              <Grid item>
                g. You are almost there! View and download all of the options listed. This is to
                ensure you are aware of everything that you have signed. Once completed click NEXT.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/uho/uho_reference_image_11.png'
                  style={{ marginBottom: '1.5rem' }}
                />
              </Grid>
              <Grid item>
                h. Type your full name as it is be shown in red in the Full Name space provided as
                well as select today's date. Then click NEXT.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/uho/uho_reference_image_12.png'
                  style={{ marginBottom: '1.5rem' }}
                />
              </Grid>
              <Grid item>i. CONGRATULATIONS, YOUR APPLICATION IS COMPLETE!!</Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/uho/uho_reference_image_13.png'
                  style={{ marginBottom: '1.5rem' }}
                />
              </Grid>
              <Grid item>
                j. From here, sit back and relax! UHO will process your application and send back a
                series of emails confirming each step.
              </Grid>
              <Grid item>
                i. Once you submit your application, you will receive a confirmation email from UHO.
                Another email will follow notifying you that your background check is being ran.
              </Grid>
              <Grid item>
                <Image
                  alt='reference-image'
                  src='/images/carriers/health/uho/uho_reference_image_14.png'
                  style={{ marginBottom: '1.5rem' }}
                />
              </Grid>
              <Grid container item justify='flex-end'>
                <CheckboxButton
                  checked={completedPaperwork || false}
                  onChange={value => setCompletedPaperwork(value)}
                  label='I Completed and Submitted All Required Paperwork'
                />
              </Grid>
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container item justify='flex-end' spacing={1} alignItems='center'>
        {nextClicked && (!registrationEmail || !registerBrokerPortal || !completedPaperwork) && (
          <Grid item>
            <Grid container>
              <Grid item>
                <WarningIcon style={warningIconStyle} />
              </Grid>
              <Grid item style={redTextStyle}>
                Action Items Incomplete
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Button onClick={handleNext}>Next: Carrier Review</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Paperwork
