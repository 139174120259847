const SupportedLoi = [
  { label: 'MEDICARE', icon: '/images/loi/medicare.svg', value: 'med_advantage' },
  { label: 'HEALTH', icon: '/images/loi/health.svg', value: 'health' },
  { label: 'LIFE', icon: '/images/loi/life.svg', value: 'life' },
  { label: 'P&C', icon: '/images/loi/auto_home.svg', value: 'auto_home' }
]

export const canonicalizeLoi = (loi: string) => SupportedLoi.find(d => d.value === loi)?.label

export const getLOIIconPath = (loi: string) => SupportedLoi.find(d => d.label === loi)?.icon

export default SupportedLoi
