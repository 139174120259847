import { Carrier, LOI } from 'store/carriers'

const Carriers = {
  MEDICARE: {
    Aetna: {
      Logo: '/images/carriers/medicare/aetna/logo.svg',
      LogoGrayscale: '/images/carriers/medicare/aetna/logo_grayscale.svg'
    },
    'Aetna Medicare Supplement': {
      Logo: '/images/carriers/medicare/aetna_supplement/logo.svg',
      LogoGrayscale: '/images/carriers/medicare/aetna_supplement/logo_grayscale.svg'
    },
    Anthem: {
      Logo: '/images/carriers/medicare/anthem/logo.svg',
      LogoGrayscale: '/images/carriers/medicare/anthem/logo_grayscale.svg'
    },
    Cigna: {
      Logo: '/images/carriers/medicare/cigna/logo.svg',
      LogoGrayscale: '/images/carriers/medicare/cigna/logo_grayscale.svg'
    },
    UHC: {
      Logo: '/images/carriers/medicare/united_health_care/logo.svg',
      LogoGrayscale: '/images/carriers/medicare/united_health_care/logo_grayscale.svg'
    },
    WellCare: {
      Logo: '/images/carriers/medicare/well_care/logo.svg',
      LogoGrayscale: '/images/carriers/medicare/well_care/logo_grayscale.svg'
    },
    Omaha: {
      Logo: '/images/carriers/medicare/mutual_of_omaha/logo.svg',
      LogoGrayscale: '/images/carriers/medicare/mutual_of_omaha/logo_grayscale.svg'
    },
    SureBridge: {
      Logo: '/images/carriers/medicare/sure_bridge/logo.svg',
      LogoGrayscale: '/images/carriers/medicare/sure_bridge/logo_grayscale.svg'
    },
    Lumico: {
      Logo: '/images/carriers/medicare/lumico/logo.svg',
      LogoGrayscale: '/images/carriers/medicare/lumico/logo_grayscale.svg'
    },
    Humana: {
      Logo: '/images/carriers/medicare/humana/logo.svg',
      LogoGrayscale: '/images/carriers/medicare/humana/logo_grayscale.svg'
    },
    HCSC: {
      Logo: '/images/carriers/medicare/hcsc/logo.svg',
      LogoGrayscale: '/images/carriers/medicare/hcsc/logo_grayscale.svg'
    }
  },
  HEALTH: {
    Ambetter: {
      Logo: '/images/carriers/health/ambetter/logo.svg',
      LogoGrayscale: '/images/carriers/health/ambetter/logo_grayscale.svg'
    },
    Anthem: {
      Logo: '/images/carriers/health/anthem/logo.svg',
      LogoGrayscale: '/images/carriers/health/anthem/logo_grayscale.svg'
    },
    Cigna: {
      Logo: '/images/carriers/health/cigna/logo.svg',
      LogoGrayscale: '/images/carriers/health/cigna/logo_grayscale.svg'
    },
    HCSC: {
      Logo: '/images/carriers/health/hcsc/logo.svg',
      LogoGrayscale: '/images/carriers/health/hcsc/logo_grayscale.svg'
    },
    HII: {
      Logo: '/images/carriers/health/hii/logo.svg',
      LogoGrayscale: '/images/carriers/health/hii/logo_grayscale.svg'
    },
    IHC: {
      Logo: '/images/carriers/health/ihc/logo.svg',
      LogoGrayscale: '/images/carriers/health/ihc/logo_grayscale.svg'
    },
    Molina: {
      Logo: '/images/carriers/health/molina/logo.svg',
      LogoGrayscale: '/images/carriers/health/molina/logo_grayscale.svg'
    },
    Oscar: {
      Logo: '/images/carriers/health/oscar/logo.svg',
      LogoGrayscale: '/images/carriers/health/oscar/logo_grayscale.svg'
    },
    UHC: {
      Logo: '/images/carriers/health/united_health_care/logo.svg',
      LogoGrayscale: '/images/carriers/health/united_health_care/logo_grayscale.svg'
    },
    UHO: {
      Logo: '/images/carriers/health/uho/logo.svg',
      LogoGrayscale: '/images/carriers/health/uho/logo_grayscale.svg'
    },
    UHC_ACA: {
      Logo: '/images/carriers/health/united_health_care_aca/logo.svg',
      LogoGrayscale: '/images/carriers/health/united_health_care_aca/logo_grayscale.svg'
    }
  },
  LIFE: {
    Omaha: {
      Logo: '/images/carriers/life/mutual_of_omaha/logo.svg',
      LogoGrayscale: '/images/carriers/life/mutual_of_omaha/logo_grayscale.svg'
    },
    LGA: {
      Logo: '/images/carriers/life/lga/logo.svg',
      LogoGrayscale: '/images/carriers/life/lga/logo_grayscale.svg'
    },
    Prudential: {
      Logo: '/images/carriers/life/prudential/logo.svg',
      LogoGrayscale: '/images/carriers/life/prudential/logo_grayscale.svg'
    },
    Lumico: {
      Logo: '/images/carriers/life/lumico/logo.svg',
      LogoGrayscale: '/images/carriers/life/lumico/logo_grayscale.svg'
    },
    Protective: {
      Logo: '/images/carriers/life/pcct/logo.svg',
      LogoGrayscale: '/images/carriers/life/pcct/logo_grayscale.svg'
    }
  }
}

export const getCarrierIconPath = (carrier: Carrier, loi: LOI) => Carriers[loi]?.[carrier]?.Logo

export default Carriers
