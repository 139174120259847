import React from 'react'

import { CheckCircle } from '@material-ui/icons'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'

import { PlateProps } from 'screens/carrier_appointments/commons/plate'
import { RoundCheckedIcon, TimerStatusIcon, WarningIcon } from 'components/icons'
import TimeToComplete from 'screens/carrier_appointments/commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const AmbetterLink = (
  <Link
    target='_blank'
    rel='noreferrer'
    href='https://help.assurance.com/hc/en-us/articles/360048891952-How-Do-I-Get-Appointed-With-Ambetter-to-Offer-ACA-Plans-'
  >
    Click to view instructions on How to get appointed with Ambetter to offer ACA plans.
  </Link>
)

const CarrierReview = (props: Props) => {
  const optumLink =
    'https://myassurance-my.sharepoint.com/:p:/r/personal/osciumbata_assurance_com/Documents/Creating%20and%20Optum%20ID.pptx?d=wd0285c7e96e64874910a84d26be788d5&csf=1&web=1&e=rgpRtY'

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>UHO Carrier Review</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        Once everything has been approved by UHO, you will receive a document via postal mail
        confirming that you have been approved. If you would like to find out before the postal mail
        arrives, please allow 5 business days and then you may contact UHO licensing at
        1-800-474-4467 option 5.
      </Grid>
      <Grid item>
        PLEASE NOTE- UHO contracting link is only valid for 15 days. Please be sure to complete the
        process before it expires. If you have missed the 15 day cut off, you will need to email
        healthlicensing@assurance.com, provide your NPN number, and request a new contracting link.
      </Grid>
      <Grid item>
        *ONLY Once you have been approved follow these steps to create an{' '}
        <Link target='_blank' rel='noreferrer' href={optumLink}>
          Optum ID
        </Link>
        .
      </Grid>
    </Grid>
  )
}

export default CarrierReview
