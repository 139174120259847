import styled from 'styled-components'

const Warning = styled.div`
  padding: 1rem;
  background: #f8d7da;
  border: 1px solid #eaabb1;
  border-radius: 0.25rem;
  color: #930103;
`

export default Warning
