import { Action, action } from 'easy-peasy'

export interface ProgressItem {
  name: string
  status?: 'checked' | 'timer' | 'solid' | 'empty' | 'error' | undefined
  timeToComplete?: string
  dateStarted?: string
  dateLastSynced?: string
  hidden?: boolean
}

export interface WorkflowStatus {
  appointmentRequestId: string
  cancellationDate?: string
  cancellationReason?: string
  cancelledBy?: string
  currentProgress: string
  currentTask: string
  pageData?: any
  pageName: string
  progressList: ProgressItem[]
  requestStatus: 'InProgress' | 'Cancelled' | string
}

export interface AppointmentRequestsWorkflowStatusModel {
  items: WorkflowStatus[]
  load: Action<AppointmentRequestsWorkflowStatusModel, WorkflowStatus>
}

export const appointmentRequestsWorkflowStatusModel: AppointmentRequestsWorkflowStatusModel = {
  items: [] as WorkflowStatus[],
  load: action((state, payload) => {
    state.items = state.items.filter(
      row => row.appointmentRequestId !== payload.appointmentRequestId
    )
    state.items.push(payload)
  })
}
