import React from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import Note from 'components/box/note'

interface Props {
  className?: string
  year?: number
  style?: React.CSSProperties
  onStart?: () => void
}

const GetStarted = (props: Props) => {
  const { className, year, style, onStart } = props

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      spacing={4}
      style={{ margin: '90px 24% 0 24%' }}
    >
      <Grid item>
        <h1 style={{ fontSize: '24px', paddingBottom: '12px' }}>FFM Certification for {year}</h1>
        <span>
          In order to sell Affordable Care Act (ACA) plans, the Center for Medicare and Medicaid
          Services (CMS) mandates that you complete the current year's Federally Facilitated
          Marketplace (FFM) Certification. Use this section of your dashboard to sign up for FFM and
          stay up-to-date on your FFM certification progress and completion.
        </span>
      </Grid>
      <Grid item>
        <Note>
          <strong>Note:</strong> it can take 5-7 business days after completing your certification
          for the status to show up in our system, so please be sure to start early.
        </Note>
      </Grid>
      <Grid item>
        <Button style={{ minWidth: '216px' }} onClick={onStart}>
          Get Started
        </Button>
      </Grid>
    </Grid>
  )
}

export default GetStarted
