import React from 'react'

import { Grid, Link } from '@material-ui/core'

import Warning from 'components/box/warning'
import { carrierFullName } from 'store/carriers'

import { PlateProps } from '../../../commons/plate'
import { join_life_email } from '../common'

const RequestTerminated = (props: PlateProps) => {
  const { carrierName } = props

  const carrier = carrierFullName(carrierName)

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Appointment Terminated by {carrier} </h2>
      </Grid>

      <Grid item>
        <Warning>
          <strong>Current Status:</strong> Lumico has canceled your appointment request. This
          usually happens when one of the links that are sent by the carrier or on behalf of the
          carrier have expired. Or, the carrier requires additional information.{' '}
        </Warning>
      </Grid>
      <Grid item>
        Please email{' '}
        <Link target='_blank' href={'mailto:' + join_life_email}>
          {join_life_email}
        </Link>{' '}
        for next steps.
        <br />
        <br />
        If Lumico indicates that your appointment process needs to be restarted, please select
        "Cancel Appointment" in the upper-right of this screen to restart the process.
      </Grid>
    </Grid>
  )
}

export default RequestTerminated
