import React, { useState } from 'react'

import { FormHelperText, Grid, TextField, TextareaAutosize } from '@material-ui/core'

import { API_ENDPOINT } from 'utils/environment-variables'
import Button from 'components/button'

const LumicoAgentInfoGetApi = () => {
  const [emailAddress, setEmailAddress] = useState('')
  const [apiResult, setApiResult] = useState('')
  const [statusCode, setStatusCode] = useState(0)

  const getApiData = () => {
    fetch(API_ENDPOINT + '/api/admin_utils/lumico_agent_info', {
      method: 'post',
      headers: {
        Authorization: `${localStorage.getItem('authToken')}`
      },
      body: JSON.stringify({
        agentEmail: emailAddress
      })
    })
      .then(result => {
        setStatusCode(result.status)
        return result.json()
      })
      .then(data => {
        setApiResult(JSON.stringify(data, null, 3))
      })
      .catch(error => {
        setApiResult(error)
      })
  }

  return (
    <Grid container style={{ padding: 20 }} spacing={2} direction='column'>
      <Grid item>
        <TextField
          id='email'
          label='Email'
          variant='outlined'
          size='small'
          onChange={e => setEmailAddress(e.target.value)}
        />
        <FormHelperText>Enter agent's Assurance email address</FormHelperText>
      </Grid>
      <Grid item>
        <Button onClick={getApiData}>Get Lumico API Data</Button>
      </Grid>
      <Grid item>
        Status code:{' '}
        <strong style={statusCode === 200 ? { color: 'green' } : { color: 'red' }}>
          {statusCode ? statusCode : ''}
        </strong>
      </Grid>
      <Grid item>
        <TextareaAutosize readOnly minRows={20} style={{ width: '50%' }} value={apiResult} />
      </Grid>
    </Grid>
  )
}

export default LumicoAgentInfoGetApi
