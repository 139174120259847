import React from 'react'

import { makeStyles } from '@material-ui/core'

import { RoundCheckedIcon, TimerStatusIcon, WarningIcon } from '../../../../components/icons'

export type ProgressStatus = 'checked' | 'timer' | 'solid' | 'empty' | 'error' | undefined

interface Props {
  children?: React.ReactNode
  first?: boolean
  last?: boolean
  visited?: boolean
  isRightmostVisited?: boolean
  className?: string
  progressStatus: ProgressStatus
}

const useStyles = makeStyles(theme => ({
  // style rule
  root: (props: Props) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 1.5rem',
    position: 'relative'
  }),
  circle: (props: Props) => ({
    zIndex: 1,
    width: '2rem',
    height: '2rem',
    borderRadius: '50%'
  }),
  empty: (props: Props) => ({
    backgroundColor: theme.palette.grey[100]
  }),
  solid: (props: Props) => ({
    backgroundColor: theme.palette.primary.main
  }),
  barLeft: (props: Props) => ({
    position: 'absolute',
    right: 'calc(50% + 0.75rem)',
    left: '0px',
    top: '1rem',
    transform: 'translateY(-50%)',
    backgroundColor: props.visited ? theme.palette.primary.main : '#eee',
    height: '0.25rem'
  }),
  barRight: (props: Props) => ({
    position: 'absolute',
    right: '0px',
    left: 'calc(50% + 0.75rem)',
    top: '1rem',
    transform: 'translateY(-50%)',
    backgroundColor:
      props.visited && !props.isRightmostVisited ? theme.palette.primary.main : '#eee',
    height: '0.25rem'
  }),
  label: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '0.75rem',
    lineHeight: '1.125rem'
  }
}))

export const getIconForProgressStatus = (status?: ProgressStatus) => {
  const style = {
    width: '1.25rem',
    marginRight: '0.375rem'
  }
  switch (status) {
    case 'error':
      return <WarningIcon style={style} />
    case 'checked':
      return <RoundCheckedIcon blue style={style} />
    case 'timer':
      return <TimerStatusIcon style={style} />
    default:
      return <TimerStatusIcon style={style} />
  }
}

const Item = (props: Props) => {
  const { progressStatus, className, children } = props

  const classes = useStyles(props)

  let progressIcon: React.ReactNode

  if (progressStatus === 'checked') {
    progressIcon = <RoundCheckedIcon blue className={classes.circle} />
  } else if (progressStatus === 'error') {
    progressIcon = <WarningIcon className={classes.circle} />
  } else if (progressStatus === 'timer') {
    progressIcon = <TimerStatusIcon className={classes.circle} />
  } else if (progressStatus === 'solid') {
    progressIcon = <div className={classes.circle + ' ' + classes.solid} />
  } else {
    progressIcon = <div className={classes.circle + ' ' + classes.empty} />
  }

  return (
    <div className={[classes.root, className].join(' ')}>
      {!props.first && <div className={classes.barLeft} />}
      {!props.last && <div className={classes.barRight} />}
      {progressIcon}
      <div className={classes.label}>{children}</div>
    </div>
  )
}

export default Item
