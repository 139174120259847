import React from 'react'

import { Link } from '@material-ui/core'

import Note from 'components/box/note'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper from '../commons/instructions_wrapper'

interface Props {
  carrierName: string
  loi: string
}

const UhcHealthChecklist = (props: Props) => {
  return (
    <InstructionsWrapper {...props}>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Request for an Appointment Invitation
          </span>
        }
      >
        As a new agent, you should already have the appointment email in your inbox. You will
        receive an email from{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://assuranceiq.formstack.com/forms/united_u65_appointment_request'
        >
          getappointed@unitedhealthone.com
        </Link>{' '}
        with the subject line- Invitation Mail from GRI: AGENT NAME. Follow the instructions
        provided to complete the process.
        <br />
        <Note>
          PLEASE NOTE- UHO contracting link is only valid for 15 days. Please be sure to complete
          the link before it expires. If you have missed the 15 day cut off, you will need to email{' '}
          <Link target='_blank' rel='noreferrer' href={'mailto:healthlicensing@assurance.com'}>
            healthlicensing@assurance.com
          </Link>{' '}
          provide your NPN number, and request a new contracting link.
        </Note>
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            2. Complete Appointment Paperwork
          </span>
        }
      >
        <strong>Instructions:</strong>
        <br />
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360047653392-How-do-I-get-contracted-with-United-Health-UHO-'
        >
          How do I get appointed with United Health (UHO)?
        </Link>
        <br />
        <br />
        Follow the instructions to complete the appointment paperwork.
        <br />
        <br />
        Once the request has been submitted, please allow up to 3 business days to receive the email
        from UHO.
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            3. Carrier Portal Credentials
          </span>
        }
      >
        <strong>Instructions:</strong>
        <br />
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://myassurance-my.sharepoint.com/:p:/r/personal/osciumbata_assurance_com/Documents/Creating%20and%20Optum%20ID.pptx?d=wd0285c7e96e64874910a84d26be788d5&csf=1&web=1&e=rgpRtY'
        >
          Assurance Help: How do I get appointed with UHO? Section: Carrier Portal Credentials
        </Link>
        <br />
        <br />
        Once everything has been approved by UHO, you will receive a document via postal mail
        confirming that you have been approved. If you would like to find out before the postal mail
        arrives, please allow 5 business days and then you may contact UHO licensing at
        1-800-474-4467 (choose Option 5 on the phone).
        <br />
        <br />
        ONLY Once you have been approved should you follow the steps to create an Optum ID.
      </Accordion>
    </InstructionsWrapper>
  )
}

export default UhcHealthChecklist
