import React from 'react'

import { Paper } from '@material-ui/core'

import { LicenseData } from 'store/state_license'
import styled from 'styled-components'

import { ResidentIcon } from '../../components/icons'

interface Props {
  agentFirstName?: string
  agentLastName?: string
  npn?: string
  license: LicenseData
}

const Main = styled(Paper)`
  margin: 0.25rem;
  padding: 1.5rem;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 0.25rem;
  > * + * {
    margin-top: 0.75rem;
  }
`

const Separator = styled.div`
  margin: 24px 0;
  margin-left: -24px;
  border-top: 1px solid #ccc;
  width: calc(100% + 48px);
`

const GeneralLicensingInfo = (props: Props) => {
  const { agentFirstName = '', agentLastName = '', license, npn } = props

  return (
    <Main elevation={3} id='state_licenses_licensing_info'>
      <h3 style={{ fontSize: '24px', fontWeight: 500 }}>General Licensing Info</h3>
      <Separator />
      <div>
        <strong>Name</strong>
        <div>
          {agentLastName}, {agentFirstName}
        </div>
      </div>
      <div>
        <strong>NPN</strong>
        <div>{npn || '-'}</div>
      </div>
      <div>
        <strong>Resident License</strong>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {<ResidentIcon />} {license.state || '-'}
        </div>
      </div>
      <div>
        <strong>License Class Name</strong>
        <div>{license.className || '-'}</div>
      </div>
      <div>
        <strong>License Number</strong>
        <div>{license.number || '-'}</div>
      </div>
    </Main>
  )
}

export default GeneralLicensingInfo
