import React from 'react'

import { Dialog, DialogActions, DialogContent, DialogTitle, List } from '@material-ui/core'

import Button from 'components/button'
import { Carrier, carrierFullName } from 'store/carriers'

interface Props {
  open?: boolean
  carrierName: Carrier
  onConfirm?: () => void
  onClose?: () => void
}

const ConfirmRequestInvitation = (props: Props) => {
  const { carrierName, open, onConfirm, onClose } = props

  return (
    <Dialog onClose={onClose} open={!!open}>
      <DialogTitle>
        <strong>Confirmation</strong>
      </DialogTitle>
      <DialogContent dividers>
        <List>
          <strong>
            Are you ready to get appointed with {carrierFullName(carrierName)} under the Assurance
            IQ hierarchy?
          </strong>
          <br />
          <br />
          Assurance does not grant immediate releases once you have gone through appointments with
          the carrier. In order to get released from the Assurance IQ hierarchy, you will need to
          request a delayed release with the carrier.
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={onClose}>
          <u>Go Back</u>
        </Button>
        <Button onClick={onConfirm}>I confirm I am ready</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmRequestInvitation
