import React from 'react'

import { makeStyles } from '@material-ui/core'

import Item, { ProgressStatus } from './item'

interface Props {
  currentItemKey?: string
  style?: React.CSSProperties
  items: Array<{
    label: string
    status?: ProgressStatus
    key: string
  }>
}

const useStyles = makeStyles({
  // style rule
  root: {
    display: 'flex'
  },
  item: {
    flex: 1
  }
})

const Stages = (props: Props) => {
  const { style, items, currentItemKey } = props

  const classes = useStyles()

  const rightMostVisited = [...items]
    .reverse()
    .find(row => row.status && ['checked', 'solid', 'error', 'timer'].includes(row.status))

  const rightMostIndex = items.findIndex(item => item === rightMostVisited)

  return (
    <div className={classes.root} style={style}>
      {items.map((item, idx) => (
        <Item
          className={classes.item}
          key={item.key}
          first={idx === 0}
          last={idx + 1 === items.length}
          visited={idx <= rightMostIndex}
          isRightmostVisited={item === rightMostVisited}
          progressStatus={item.status}
        >
          <span
            style={{
              textDecoration: currentItemKey === item.key ? 'underline' : void 0
            }}
          >
            {item.label}
          </span>
        </Item>
      ))}
    </div>
  )
}

export default Stages
