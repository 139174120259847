import React from 'react'

import styled from 'styled-components'

import CarrierTags from '../carrier_tags'
import Hoverable from '../hoverable'

const Main = styled.div`
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 8px;
  }
`

const HoverableRow = styled.div`
  justify-content: center;
  display: flex;
  > * + * {
    margin-left: 8px;
  }
`

interface Props {
  carrierTags: string[]
  style?: React.CSSProperties
}

const CarrierTagsView = (props: Props) => {
  const { style, carrierTags } = props

  const grouped = carrierTags.reduce<Record<string, string[]>>((acc, tag, idx) => {
    const group = Math.floor(idx / 3)
    acc[group] = acc[group] || []
    acc[group].push(tag)
    return acc
  }, {})

  return (
    <Main style={style}>
      {Object.values(grouped).map((group, idx) => (
        <HoverableRow key={idx}>
          {group.map(carrierTag => (
            <Hoverable
              key={carrierTag}
              iconSrc={(CarrierTags[carrierTag] || {}).Icon}
              label={(CarrierTags[carrierTag] || {}).Label}
            />
          ))}
        </HoverableRow>
      ))}
    </Main>
  )
}

export default CarrierTagsView
