import React from 'react'

import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'

import Button from 'components/button'
import { RoundCheckedIcon, TimerStatusIcon, WarningIcon } from 'components/icons'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const AnthemLink = (
  <Link
    target='_blank'
    rel='noreferrer'
    href='https://help.assurance.com/hc/en-us/articles/360048891952-How-Do-I-Get-Appointed-With-Anthem-to-Offer-ACA-Plans-'
  >
    Click to view instructions on How to get appointed with Anthem to offer ACA plans.
  </Link>
)

const getIconFromStatus = (status?: string) => {
  const style = {
    width: '1.25rem',
    marginRight: '0.375rem'
  }
  switch (status) {
    case 'error':
      return <WarningIcon style={style} />
    case 'checked':
      return <RoundCheckedIcon blue style={style} />
    case 'timer':
      return <TimerStatusIcon style={style} />
    default:
      return <TimerStatusIcon style={style} />
  }
}

const CarrierReview = (props: Props) => {
  const { plateName, doUpdate, progressItems } = props
  const appointment = progressItems.find(item => item.name === 'Appointment')!

  let status: string = ''

  if (appointment.status === 'error') {
    status = 'error'
  } else if (appointment.status === 'checked') {
    status = 'finished'
  } else {
    status = 'timer'
  }

  const goto = (nextProgress: string, nextPage: string) => {
    const data = {
      nextProgress,
      nextPage: {
        name: nextPage
      },
      progress: {
        name: plateName,
        status: 'solid'
      },
      pageData: {}
    }
    doUpdate?.('back', data)
  }

  const backLink = (label: string, nextProgress: string, nextPage: string) => {
    return (
      <Button
        variant='text'
        style={{
          marginLeft: '0.2rem',
          padding: 0
        }}
        onClick={() => goto(nextProgress, nextPage)}
      >
        <u>{label}</u>
      </Button>
    )
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Anthem Carrier Review</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      {status === 'finished' && (
        <>
          <Grid item>
            Well done!
            <br />
            <br />
            Your work here is done for now. Sit back, relax, and we will update your progress.
            <br />
            <br />
            Note: if this is taking longer than expected, make sure you review the instructions
            again and check if you’ve completed all the components to your appointment paperwork.
            <br />
            <br />
            {AnthemLink}
          </Grid>
          <Grid item container>
            {getIconFromStatus(appointment.status)} Appointment Paperwork
          </Grid>
        </>
      )}
      {status === 'error' && (
        <>
          <Grid item>
            Oh no, it looks like you have incomplete parts in either of your appointment paperwork
            and certification.
            <br />
            <br />
            {AnthemLink}
          </Grid>
          <Grid item container>
            {getIconFromStatus(appointment.status)} Appointment Paperwork -
            {backLink('Revisit Start Registration', 'Appointment', 'Appointment')}
          </Grid>
        </>
      )}
      {status === 'timer' && (
        <>
          <Grid item>
            You marked yourself as completed in both sections.
            <br />
            <br />
            {AnthemLink}
          </Grid>
          <Grid item container>
            {getIconFromStatus(appointment.status)} Appointment Paperwork -
            {backLink('Revisit Start Registration', 'Appointment', 'Appointment')}
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default CarrierReview
