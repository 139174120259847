import gql from 'graphql-tag'

const NIPR_REPLAY_MUTATION = gql`
  mutation ($input: AgentInfoInput!) {
    niprReplay(input: $input) {
      replayId
      accountErrors {
        field
        message
        code
      }
      errors {
        field
        message
      }
    }
  }
`

export default NIPR_REPLAY_MUTATION
