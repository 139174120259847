import React from 'react'

import { Box, makeStyles } from '@material-ui/core'

import { Loading as BaseLoading, LoadingProps } from '@assuranceiq/react-components'

interface Props {
  children?: React.ReactNode
}

const AssuranceLoading = (props: Props) => {
  const { children } = props

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <img style={{ width: '100px' }} src={'/images/assurance_logo_grey.svg'} />
      <label style={{ marginTop: '1rem', color: '#ccc' }}>{children}</label>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    '& > div': {
      height: '12px',
      '& > div': {
        top: 0
      }
    }
  }
}))

export const Loading = (props: LoadingProps) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <BaseLoading {...props} />
    </div>
  )
}

export default AssuranceLoading
