import React from 'react'

import Select from 'components/select'

import { CertificateProviders, StageItem, YearOptionItem } from './certification'

interface YearDropdownProps {
  selectedOption?: YearOptionItem
  yearOptions: YearOptionItem[]
  optionChanges: (year: number) => void
}

const YearDropdown = (props: YearDropdownProps) => {
  const { selectedOption, yearOptions, optionChanges } = props
  return (
    <>
      {selectedOption && (
        <Select
          style={{ minWidth: '250px' }}
          options={yearOptions || []}
          keySelector={item => item.year}
          labelSelector={item => item.text}
          selected={selectedOption.year}
          onChange={optionChanges}
        />
      )}
    </>
  )
}

export default YearDropdown
