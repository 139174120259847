import React from 'react'

import Box from 'components/box'

interface Props {
  label?: React.ReactNode
  children?: React.ReactNode
}

const LabeledComponent = (props: Props) => {
  return (
    <Box spacing='0.5rem'>
      <label style={{ fontWeight: 600 }}>{props.label}</label>
      {props.children}
    </Box>
  )
}

export default LabeledComponent
