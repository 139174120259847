import React from 'react'

import { Carrier, LOI } from 'store/carriers'

import { PlateComponentMap, PlateLabelMap } from './plate_map'
import SmartFlow from '../../commons/flow'

interface Props {
  planYear: number
}

const CignaFlow = (props: Props) => {
  const { planYear } = props

  return (
    <SmartFlow
      planYear={planYear}
      loi={LOI.Health}
      carrierName={Carrier.Cigna}
      plateComponentMap={PlateComponentMap}
      plateLabelMap={PlateLabelMap}
      shouldShowCancelDropdown={statusResponse =>
        statusResponse.currentProgress === 'Eligibility' ||
        (statusResponse.currentProgress === 'Invitation' &&
          !['InvitationSent', 'EmailInviteSent'].includes(statusResponse.pageName))
      }
    />
  )
}

export default CignaFlow
