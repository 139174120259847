import React, { useEffect } from 'react'

import { Grid } from '@material-ui/core'

import Warning from 'components/box/warning'

interface DataSyncProps {
  getWorkflowUpdates: () => Promise<void>
}

const DataSync = (props: DataSyncProps) => {
  const { getWorkflowUpdates } = props

  useEffect(() => {
    getWorkflowUpdates()
  }, [])

  return (
    <Grid container direction='column' spacing={4} style={{ margin: '30px 24% 0 24%' }}>
      <Grid item>
        <h1 style={{ fontSize: '24px', paddingBottom: '12px' }}>Data Sync</h1>
      </Grid>
      <Grid container alignItems='center' direction='column' spacing={4}>
        <Grid item>
          <img
            src='/images/certification/ffm/ffm-data-sync-image.png'
            alt='ffm-data-sync'
            style={{ maxWidth: '411px' }}
          />
        </Grid>
        <Grid item>
          <Warning>
            <strong>Status:</strong> FFM certification is not complete. After you have completed
            your certification, please allow 5-7 business days for status to update in our system.
          </Warning>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DataSync
