import { Computed, action, computed } from 'easy-peasy'

export type Role = 'admin' | 'developer' | 'agent' | string

export interface PermissionGroup {
  name: string
  id: string
}

export interface AgentSkill {
  name: string
  loi: string
  status: string
  isEnabled: boolean
  qualifiedOverride: boolean
}

export interface BaseUserInfo {
  id: string
  npn: string
  loi: string[]
  firstName: string
  lastName: string
  assuranceAgentId: string
  email: string
  permissionGroups: PermissionGroup[]
  profilePictureLink?: string
  carrierData: Map<string, any>
  skills: AgentSkill[]
}

export interface BaseUserInfoActionModel extends BaseUserInfo {
  isAdmin: Computed<BaseUserInfo, boolean>
  hasPermissionGroup: Computed<BaseUserInfo, (permissionGroup: string) => boolean>
}

export const hasAdminPermissionGroup = (groups: PermissionGroup[]) => {
  return (
    ['Staff', 'Superuser', 'ContractingSpecialist'].find(permissionGroup =>
      groups.find(row => row.name === permissionGroup)
    ) != null
  )
}

export const commonUserActions = () => ({
  isAdmin: computed<any>(state => hasAdminPermissionGroup(state.permissionGroups)),
  hasPermissionGroup: computed<any>(
    state => permissionGroup =>
      state.permissionGroups.find(row => row.name === permissionGroup) != null
  ),
  update: action((state, data) => {
    for (const [property, value] of Object.entries(data)) {
      state[property] = value
    }
  })
})
