import gql from 'graphql-tag'

const STATUS_FILE_QUERY = gql`
  query statusFiles($first: Int!, $offset: Int!, $sortBy: CarrierStatusFileSortingInput) {
    statusFiles(first: $first, offset: $offset, sortBy: $sortBy) {
      totalCount
      edges {
        node {
          id
          statusFile
          uploader {
            id
            email
            firstName
            lastName
          }
          carrier {
            id
            name
            avaliableState
            loi
            states
          }
          metadata
          updatedAt
          createdAt
        }
      }
    }
  }
`

export default STATUS_FILE_QUERY
