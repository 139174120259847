import React from 'react'

import Box from 'components/box'
import Button from 'components/button'
import { InfoIcon, MoreDotsIcon } from 'components/icons'

import BaseCard from './card_commons'
import CardButton from './card_button'
import FlippableCard from './flippable_card_with_carrier_tags'
import { LightTooltip } from '../../../../components/tooltip'
import TerminatedTooltip from './terminated_tooltip'

interface Props {
  disabled?: boolean
  information?: string
  logoUrl: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  carrierTags: string[]
  availableStates: number
  cardId?: string
  terminated?: boolean
  carrierName: string
  onSupportedProductsClick?: () => void
}

const EligibilityCard = (props: Props) => {
  const {
    carrierName,
    information,
    disabled,
    terminated,
    onClick,
    logoUrl,
    availableStates,
    cardId
  } = props

  return (
    <BaseCard style={{ boxShadow: 'none' }} cardId={cardId}>
      {terminated && (
        <div
          style={{
            position: 'absolute',
            borderRadius: '50%',
            padding: '0.25rem',
            right: 0,
            top: 0,
            transform: 'translate(50%, -50%)'
          }}
        >
          <TerminatedTooltip carrier={carrierName} />
        </div>
      )}
      {information && (
        <div
          style={{
            position: 'absolute',
            borderRadius: '50%',
            right: 0,
            top: 0,
            transform: 'translate(50%, -50%)'
          }}
        >
          <LightTooltip placement='top-start' title={information}>
            <div style={{ marginLeft: '0.25rem' }}>
              <InfoIcon style={{ width: '1.625rem', display: 'block' }} />
            </div>
          </LightTooltip>
        </div>
      )}
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        style={{ flex: 1, textAlign: 'center' }}
        padding='0.5rem 0'
        spacing={'1rem'}
      >
        <img style={{ maxWidth: '70%', height: '48px' }} alt='logo' src={logoUrl} />
        <div>Available in {availableStates} states</div>
        <Box display='flex' justifyContent='space-around'>
          <Button
            style={{ padding: '0.25rem', minWidth: '0' }}
            variant='text'
            onClick={props.onSupportedProductsClick}
          >
            <MoreDotsIcon />
          </Button>
        </Box>
      </Box>
      <CardButton disabled={disabled} onClick={onClick}>
        Check Eligibility
      </CardButton>
    </BaseCard>
  )
}

const FlippableCheckEligibilityCard = (props: Props) => {
  const buttonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    props.onClick && props.onClick(e)
  }

  return (
    <FlippableCard
      carrierTags={props.carrierTags}
      footer={
        <CardButton disabled={props.disabled} onClick={buttonClick}>
          Check Eligibility
        </CardButton>
      }
    >
      <EligibilityCard {...props} onClick={buttonClick} />
    </FlippableCard>
  )
}

export default FlippableCheckEligibilityCard
