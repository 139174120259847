import React from 'react'

import { Link } from '@material-ui/core'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper from '../commons/instructions_wrapper'

interface Props {
  carrierName: string
  loi: string
}

const CignaHealthChecklist = (props: Props) => {
  return (
    <InstructionsWrapper {...props}>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Request for an Appointment Invitation
          </span>
        }
      >
        Let us know you want an invitation to get started with appointment by filling out the{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://assuranceiq.formstack.com/forms/cigna_appointment_request'
        >
          Cigna Invitation Request Form
        </Link>
        .
        <br />
        <br />
        Once you submit your information, please allow 5-7 business days to receive forms from
        Cigna.
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            2. Complete Appointment Process
          </span>
        }
      >
        <strong>Instructions:</strong>
        <br />
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360047826391-How-Do-I-Get-Appointed-With-Cigna-to-Offer-ACA-Plans-S'
        >
          Assurance Help: How do I get appointed with Cigna?
        </Link>
        <br />
        <br />
        An email will be sent to you from{' '}
        <Link target='_blank' rel='noreferrer' href={'mailto:producerexpress@sircon.com'}>
          producerexpress@sircon.com
        </Link>{' '}
        , with the subject line "Cigna Producer Onboarding Invitation". On this email you will find
        your own unique link that you must click on to start the process. You will then be directed
        to a log in page to change your password and authorize your entry. Complete the process and
        background check forms truthfully.
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>3. Check Appointment Status</span>
        }
      >
        Your Affordable Care Act (ACA) appointment status is in one place.{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://join.assurance.com/agent/aca_appointments'
        >
          Check my ACA appointment statuses
        </Link>
        .
      </Accordion>
    </InstructionsWrapper>
  )
}

export default CignaHealthChecklist
