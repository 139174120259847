import React from 'react'

import { Link } from '@material-ui/core'

import Note from 'components/box/note'
import Warning from 'components/box/warning'

import Accordion from '../commons/checklist_accordion'
import InstructionsWrapper from '../commons/instructions_wrapper'

interface Props {
  carrierName: string
  loi: string
}

const Checklist = (props: Props) => {
  return (
    <InstructionsWrapper {...props}>
      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            1. Request for an Appointment Invitation
          </span>
        }
      >
        Let us know you want an invitation to get started with appointment by filling out the{' '}
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://assuranceiq.formstack.com/forms/surebridge_request'
        >
          SureBridge Invitation Request Form
        </Link>
        .
        <Warning>
          <strong>Caution:</strong> If you initiate your appointment with SureBridge by following
          the above link, you've indicated your interest in working with SureBridge directly.
        </Warning>
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            2. Complete Appointment Process
          </span>
        }
      >
        <strong>Full Instructions:</strong>
        <br />
        <Link
          target='_blank'
          rel='noreferrer'
          href='https://help.assurance.com/hc/en-us/articles/360037196512-How-do-I-get-appointed-with-SureBridge-'
        >
          Assurance Help: How do I get appointed with SureBridge?
        </Link>
        <br />
        <br />
        After requesting an invite, it can take up to 3 days to receive an invite from SureBridge.
        If you meet SureBridge's requirements, you will receive a welcome email to your Assurance
        email address. In that email, there will be a link that will guide you to the SureBridge
        portal to complete your appointment. The link above provides you with a step-by-step process
        to getting appointed.
      </Accordion>

      <Accordion
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>3. Wait for Data to Sync</span>
        }
      >
        If you are experiencing difficulties with the Medicare onboarding experience on the
        Assurance Platform, please click the 'Need Help' button in the bottom right corner of your
        screen.
        <br />
        <Note>
          If you have questions on form completion, please contact{' '}
          <strong>Surebridge Licensing and Credentialing</strong> at <strong>1-888-797-4447</strong>
          .
        </Note>
      </Accordion>
    </InstructionsWrapper>
  )
}

export default Checklist
