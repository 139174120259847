import React from 'react'

import { Grid, Link } from '@material-ui/core'

import Button from 'components/button'
import Note from 'components/box/note'

import { PlateProps } from '../../../commons/plate'
import TimeToComplete from '../../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const InvitationSent = (props: Props) => {
  const { doNext } = props

  const handleNext = () => {
    doNext?.('next')
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Invitation Sent</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        <Note>
          <strong>Note:</strong> It can take up to 7 business days to get the appointment ID. After
          you get it, you will be taken to Cigna's{' '}
          <Link rel='noreferrer' target='_blank' href={'https://px.sircon.com/'}>
            Onboarding Portal
          </Link>
          :
        </Note>
      </Grid>
      <Grid item>
        Assurance IQ's licensing team has requested for an invitation on your behalf. Please check
        your {''}
        <Link target='_blank' rel='noreferrer' href='https://outlook.office365.com/mail/'>
          <strong>@Assurance.com</strong>
        </Link>
        {''} email account for:
        <br />
        <br />
        <div>
          <strong>From:</strong>{' '}
          <Link rel='noreferrer' target='_blank' href={'producerexpress@sircon.com'}>
            producerexpress@sircon.com
          </Link>
        </div>
        <div>
          <strong>Subject:</strong> Onboarding Invitation
        </div>
        <br />
        Once you receive the invitation, come back to this page and see what to do next.
      </Grid>
      <Grid container item justify='flex-end' spacing={1}>
        <Grid item>
          <Button onClick={handleNext}>Next: Appointment</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InvitationSent
