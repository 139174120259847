import React, { useEffect, useState } from 'react'

import { API_ENDPOINT } from 'utils/environment-variables'
import Box from 'components/box'
import { Loading } from 'components/loading'

interface Props {
  active?: boolean
}

const Reporting = (props: Props) => {
  const [url, setUrl] = useState<string | undefined>()
  const [loading, setLoading] = useState(true)
  const { active } = props

  useEffect(() => {
    fetch(API_ENDPOINT + '/api/reports/rts_new', {
      method: 'post',
      headers: {
        Authorization: `${localStorage.getItem('authToken')}`
      }
    })
      .then(result => result.json())
      .then(d => {
        const url = d.url
        setUrl(url)
      })
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      {loading && (
        <Box
          position='absolute'
          style={{
            left: '50%',
            transform: 'translateX(-50%)'
          }}
          display='flex'
          justifyContent={'space-around'}
          marginTop={'5rem'}
        >
          <div>
            <Loading /> Loading...
          </div>
        </Box>
      )}
      {url && (
        <iframe
          onLoad={() => {
            setLoading(false)
          }}
          style={{
            border: '1px solid #ccc',
            width: active ? '100%' : '0%',
            transition: '.1s',
            height: 'calc(100vh - 14rem)'
          }}
          src={url}
        />
      )}
    </div>
  )
}

export default Reporting
