import React from 'react'

import Box from 'components/box'
import Button from 'components/button'
import CircularProgressBar from 'components/circular_progress_bar'
import { MoreDotsIcon } from 'components/icons'
import styled from 'styled-components'

import BaseCard from './card_commons'
import CardButton from './card_button'
import FlippableCard from './flippable_card_with_carrier_tags'

const Detail = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  span {
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
  }
`

interface Props {
  logoUrl: string
  carrierTags: string[]
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  currentProgress: string
  progressPercent?: number
  cardId?: string
  onSupportedProductsClick?: () => void
  disabled?: boolean
}

const ProgressCard = (props: Props) => {
  const { logoUrl, currentProgress, progressPercent = 0, onClick, cardId } = props

  return (
    <BaseCard cardId={cardId}>
      <Box
        display='flex'
        flexDirection='column'
        flex={1}
        alignItems='center'
        justifyContent='space-around'
        padding='0.5rem 0'
      >
        <div
          style={{
            position: 'relative'
          }}
        >
          <CircularProgressBar percent={progressPercent} />
          <Detail>
            <img style={{ maxWidth: '90px', maxHeight: '2.5rem' }} alt='logo' src={logoUrl} />
            <span>{currentProgress}</span>
          </Detail>
        </div>
        <Box display='flex' justifyContent='space-around'>
          <Button
            style={{ padding: '0.25rem', minWidth: '0' }}
            variant='text'
            onClick={props.onSupportedProductsClick}
          >
            <MoreDotsIcon />
          </Button>
        </Box>
      </Box>
      <CardButton disabled={!!props.disabled} onClick={onClick}>
        Continue Process
      </CardButton>
    </BaseCard>
  )
}

const FlippableProgressCard = (props: Props) => {
  const buttonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    props.onClick?.(e)
  }

  return (
    <FlippableCard
      carrierTags={props.carrierTags}
      footer={
        <CardButton disabled={!!props.disabled} onClick={props.onClick}>
          Continue Process
        </CardButton>
      }
    >
      <ProgressCard {...props} onClick={buttonClick} />
    </FlippableCard>
  )
}

export default FlippableProgressCard
