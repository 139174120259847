import React from 'react'

import { Grid, Link } from '@material-ui/core'

import Image from 'components/image'
import Note from 'components/box/note'

import Accordion from '../commons/checklist_accordion'

interface Props {
  className?: string
  style?: React.CSSProperties
}

const PreviewCertificationTraining = (props: Props) => {
  return (
    <Grid container direction='column' spacing={2} style={props.style}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Preview Certification Training</h2>
      </Grid>
      <Grid item>
        <Note>
          <strong>Note:</strong> Get an advance preview of how to proceed with the “Complete
          Certification” stage.
        </Note>
      </Grid>
      <Grid item>
        <Accordion
          title={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              1. Register with Humana’s Agent Portal
            </span>
          }
        >
          <div>
            a. Find the email sent to you by Humana in order to collect your SAN number.
            <br />
            <br />
            <Image
              alt='reference-image'
              src='/images/carriers/medicare/humana/appointment/Humana Certification.jpg'
              style={{ width: '90%' }}
            />
          </div>
          <div>
            b. Go to{' '}
            <Link
              rel='noreferrer'
              target='_blank'
              href='https://slservices.humana.com/AgentRegistration/StepOnePage.aspx'
            >
              https://slservices.humana.com/AgentRegistration/StepOnePage.aspx
            </Link>{' '}
            and click "Get Started".
            <br />
            <br />
            <Image
              alt='get-started-image'
              src='/images/carriers/medicare/humana/appointment/Humana Certification get started.jpg'
              style={{ width: '90%' }}
            />
          </div>
          <div>
            c. Fill out the information shown
            <ul>
              <li>Select Agent/Broker</li>
              <li>Fill in your legal name as it appears on your Resident State License</li>
              <li>
                Your SAN Code goes into the Humana Agent Number (you get this number after you
                complete your Background check). Do not copy and paste, type it in.
              </li>
              <li>The new Assurance Agency Business Zip Code is 98104.</li>
            </ul>
            <Image
              alt='fill-out-information'
              src='/images/carriers/medicare/humana/appointment/Humana Certification fill agent info.jpg'
              style={{ width: '90%' }}
            />
          </div>
          <div>
            d. On the "Agent Registration - Account Set up: Step 2 of 2" page:
            <ul>
              <li>
                Create a User ID that contains letters and numbers only. It cannot contain spaces or
                special characters ($,@,#, etc.). The User ID must be 6 to 15 characters.
              </li>
              <li>
                Create a Password that is 8 to 15 characters. It cannot contain spaces or
                punctuation. It can include special characters ($,@, #, etc.). It must contain 1
                number. It must contain one letter.
              </li>
              <li>Confirm Password you typed above in order to confirm it.</li>
              <li>Enter your E-mail Address.</li>
              <li>Confirm E-mail Address.</li>
              <li>
                Add a Security Question by clicking on the drop-down menu and review all of the
                possible security questions. Click on the question you want to set as your security
                question.
              </li>
              <li>Enter the answer to the security question in Security Response.</li>
              <li>
                Click the "Submit" button.
                <br />
                <Image
                  alt='confirm-password'
                  src='/images/carriers/medicare/humana/appointment/Humana Certification confirm password.jpg'
                  style={{ width: '90%', margin: '0.5rem 0' }}
                />
              </li>
            </ul>
          </div>
          <div>
            e. You will then receive a confirmation page thanking you for registering.
            <br />
            <br />
            <div style={{ marginLeft: '2ch' }}>
              Click the "Continue" button.
              <Image
                alt='finish-registration-continue'
                src='/images/carriers/medicare/humana/appointment/Humana Certification finish continue.jpg'
                style={{ width: '90%', margin: '0.5rem 0' }}
              />
            </div>
          </div>
        </Accordion>
      </Grid>
      <Grid item>
        <Accordion
          title={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              2. Complete Online Education
            </span>
          }
        >
          You can now access the Agent Portal. You can click the “Education” link to begin your
          online course work.
          <br />
          <br /> Scroll down on the instructions article to
          <Link
            href='https://help.assurance.com/hc/en-us/articles/360044124271'
            target='_blank'
            rel='noreferrer'
          >
            Download the How to Complete Certification Guide from Humana
          </Link>
          <br />
          <br />
          Complete your online education and confirm your completion here.
        </Accordion>
      </Grid>
    </Grid>
  )
}

export default PreviewCertificationTraining
