import { JSX } from 'react'

import Ahip from './ahip'
import { AhipPageTypes, AhipPlateMap, AhipStageKeys } from './ahip/ahip.d'
import Ffm from './ffm'
import { FfmPageTypes, FfmPlateMap, FfmStageKeys } from './ffm/ffm.d'

export enum CertificateProviders {
  ahip = 'AHIP',
  ffm = 'FFM'
}

export enum CertificateWorkflowStatus {
  NOT_STARTED = 'NotStarted',
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed'
}

export const ProviderSettings = {
  [CertificateProviders.ahip]: {
    component: Ahip,
    plateMap: AhipPlateMap
  },
  [CertificateProviders.ffm]: {
    component: Ffm,
    plateMap: FfmPlateMap
  }
}

export interface CertificationProps {
  provider: CertificateProviders
}

export interface YearOptionItem {
  year: number
  text: string
  page: AhipPageTypes | FfmPageTypes
}

export enum StageItemStatus {
  CHECKED = 'checked',
  SOLID = 'solid',
  EMPTY = 'empty',
  ERROR = 'error',
  TIMER = 'timer'
}

export interface StageItem {
  hidden: boolean
  key: string
  status: StageItemStatus
}

export interface WorkflowStepDetails {
  page_name: string
  page_status: CertificateWorkflowStatus
  page_data: any
}

export interface WorkFlowData {
  workflow_status: CertificateWorkflowStatus
  steps: WorkflowStepDetails[]
}

export interface AhipWorkFlowUpdateData {
  workflow_status: CertificateWorkflowStatus
  current_step_page_name: string
}

export interface FfmWorkflowUpdateData {
  workflow_status: CertificateWorkflowStatus
  current_step_page_name: string
}
