import React from 'react'

import { Grid, Link } from '@material-ui/core'

import Button from 'components/button'
import Image from 'components/image'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const InvitationSent = (props: Props) => {
  const { doNext } = props

  const handleNext = () => {
    doNext?.('next')
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Invitation Request sent to WellCare</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        We have sent a request to continue your appointment with WellCare.
        <br />
        <br />
        Often times, agents don't receive an email as indicated below. If punching in your NPN
        number works when creating a new account, then it means WellCare has processed your
        invitation (without an official email to you). You can then click to the next step of the
        process.
        <br />
        <br />
        Other times, agents get an email from Centene. Please check your {''}
        <Link target='_blank' rel='noreferrer' href='https://outlook.office365.com/mail/'>
          <strong>@Assurance.com</strong>
        </Link>
        {''} email account for:
        <br />
        <br />
        <div>
          <div>
            <strong>From</strong>: producerservices@wellcare.com
          </div>
          <div>
            <strong>Subject</strong>: Welcome to Centene!
          </div>
        </div>
        <br />
        <i>
          Reminder: Centene is WellCare's parent company so sometimes, you'll get emails from
          Centene!
        </i>
      </Grid>
      <Grid item>
        <Image
          alt='reference-image'
          src='/images/carriers/medicare/well_care/appointment/confirm_email_invite.jpg'
          style={{ width: '90%' }}
        />
      </Grid>
      <Grid container item justify='flex-end' spacing={1}>
        <Grid item>
          <Button onClick={handleNext}>Next: Certification</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InvitationSent
