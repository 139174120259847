import React from 'react'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import Note from 'components/box/note'
import { carrierFullName } from 'store/carriers'

import { PlateProps } from '../../../commons/plate'
import TimeToComplete from '../../../commons/time_to_complete'

interface Props extends PlateProps {
  nextLabel: string
}

const NotAppointed = (props: Props) => {
  const { nextLabel, carrierName, doNext } = props

  const handleNext = () => {
    const data = {}
    doNext?.('next', data)
  }
  const carrier = carrierFullName(carrierName)
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Eligibility - Currently Not Appointed with {carrier}</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        We just pulled the information from the National Insurance Producer Registry (NIPR).
      </Grid>
      <Grid item>
        <Note>
          <strong>Current Status:</strong> You are currently NOT appointed with {carrier}
        </Note>
      </Grid>
      <Grid item>
        <br />
        To be ready-to-sell with {carrier}, you will need to complete the following:
        <br />
        <br />
        <ol>
          <li>
            <strong>Assurance Requirements</strong> - Product Training on Assurance University. This
            was assigned to you during onboarding and can be completed at any time during this
            process.
          </li>
          <li>
            <strong>Carrier Requirements</strong> - Completion of Appointment Paperwork and
            Background Check
          </li>
        </ol>
        <br />
        <br />
        Let's get started!
      </Grid>
      <br />
      <Grid container item justifyContent='flex-end'>
        <Button onClick={handleNext}>Next: {nextLabel}</Button>
      </Grid>
    </Grid>
  )
}

export default NotAppointed
