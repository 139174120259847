import Activate from './activate'
import BackgroundCheck from './background_check'
import CanceledByAgent from './canceled_by_agent'
import Cancelled from './cancelled'
import CompleteCertificationTraining from './complete_certification_training'
import DataSync from './data_sync'
import Eligibility from './eligibility'
import Exception from './exception'
import Invitation from './invitation'
import ReviewBackground from './review_background'
import ReviewByHumana from './review_by_humana'

export const PlateLabelMap = {
  Eligibility: 'Check Eligibility',
  Invitation: 'Request Invite',
  Appointment: 'Complete Paperwork',
  ReviewByHumana: 'Review by Humana',
  CompleteBackground: 'Complete Background',
  ReviewBackground: 'Review Background',
  ReviewApplication: 'Review Application',
  CompleteCertification: 'Complete Certification',
  DataSync: 'Sync Data',
  CanceledByAgent: 'Canceled by Agent',
  Cancelled: 'Cancelled',
  ActivateByHumana: 'Activate by Humana',
  Exception: 'Exception Error'
}

export const PlateComponentMap = {
  Eligibility: Eligibility,
  Invitation,
  CompleteBackground: BackgroundCheck,
  ReviewByHumana,
  ReviewBackground,
  CompleteCertification: CompleteCertificationTraining,
  DataSync,
  ActivateByHumana: Activate,
  CanceledByAgent,
  Cancelled,
  Exception
}
