import { useEffect } from 'react'

import { useQuery } from '@apollo/client'
import { useStoreActions, useStoreState } from 'store'

import USER_QUERY from '../graphql/user.gql'

export const useAgentLoader = () => {
  const userInfo = useStoreState(actions => actions.userInfo)

  const addErrorsAndOmissions = useStoreActions(actions => actions.errorsAndOmissions.load)
  const updateUserInfo = useStoreActions(actions => actions.userInfo.update)
  const addCarrierInfo = useStoreActions(actions => actions.carrierInfo.load)
  const addStateLicenses = useStoreActions(actions => actions.stateLicenses.load)
  const addAhipStatus = useStoreActions(actions => actions.ahipStatus.load)

  const userQuery = useQuery(USER_QUERY, {
    fetchPolicy: 'standby'
  })

  useEffect(() => {
    userQuery
      .refetch()
      .then(response => {
        const data = response.data.me
        addErrorsAndOmissions(data.errorsAndOmissions)
        addAhipStatus(data.ahipStatus)
        updateUserInfo({
          loaded: true,
          id: data.id,
          npn: data.npn,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          callCenter: data.callCenter,
          assuranceAgentId: data.assuranceAgentId,
          intercomUserHash: data.intercomUserHash,
          isNiprPulled: data.isNiprPulled,
          loi: data.loi,
          permissionGroups: data.permissionGroups,
          carrierData: (data.carrierData && JSON.parse(data.carrierData)) || {},
          skills: data.skills
        })
        addCarrierInfo(data.carriers.edges.map(edge => edge.node))
        addStateLicenses({
          activeLicenses: data.activeStates,
          totalLicenses: data.totalStates,
          licenses: data.stateLicenses.edges.map(row => ({
            ...row.node,
            loas: row.node.loas.edges.map(d => d.node)
          }))
        })
      })
      .catch(e => {
        console.error(e)
      })
  }, [])

  return !userInfo.loaded
}
