import React from 'react'

import { Divider } from '@material-ui/core'
import ListItemText from '@material-ui/core/ListItemText'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import Button from '../button'

// TODO: Throws console error saying endIcon and label are not defined in undefined
// Material-UI: The key `endIcon` provided to the classes prop is not implemented in undefined.
const StyledButton = withStyles({
  root: {
    width: '100%'
  },
  endIcon: {
    marginLeft: 'auto'
  },
  label: {
    '&> svg:first-child': {
      marginRight: '0.375rem'
    }
  }
})(Button)

const StyledMenu = withStyles({
  paper: {}
})((props: MenuProps) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(theme => ({
  root: {
    padding: '10px 20px',
    '&:focus': {
      backgroundColor: '#eaeaea',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {}
    }
  }
}))(MenuItem)

interface Props<OptionType> {
  className?: string
  style?: Record<string, string | number>
  options: (OptionType | '-')[]
  selected?: any
  keySelector: (option: OptionType) => string | number
  buttonRender?: () => React.ReactElement
  titleSelector?: (option?: OptionType) => React.ReactNode
  labelSelector: (option: OptionType) => React.ReactNode
  disabledSelector?: (option: OptionType) => boolean | undefined
  onChange?: (option: any) => void
}

const Dropdown = <OptionType,>(props: Props<OptionType>) => {
  const {
    className,
    options,
    style,
    buttonRender,
    selected,
    labelSelector,
    disabledSelector,
    keySelector,
    titleSelector,
    onChange
  } = props

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const optionsFiltered = options
    .filter(option => option !== '-')
    .map(option => option as OptionType)
  const selectedOption = optionsFiltered.find(option => keySelector(option) === selected)

  return (
    <div className={className} style={style}>
      {buttonRender ? (
        React.cloneElement(buttonRender(), { onClick: handleClick })
      ) : (
        <StyledButton
          onClick={handleClick}
          variant='outlined'
          color='default'
          endIcon={
            <FontAwesomeIcon
              icon={faChevronDown}
              style={{ marginLeft: '0.5rem', fontSize: '1.125rem' }}
            />
          }
        >
          {(titleSelector && titleSelector(selectedOption)) ||
            (selectedOption && labelSelector(selectedOption))}
        </StyledButton>
      )}
      <StyledMenu
        disableAutoFocusItem
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map(option =>
          option === '-' ? (
            <StyledMenuItem style={{ pointerEvents: 'none' }}>
              <Divider style={{ width: '100%' }} />
            </StyledMenuItem>
          ) : (
            <StyledMenuItem
              selected={keySelector(option) === selected}
              key={keySelector(option)}
              disabled={disabledSelector?.(option)}
              onClick={() => {
                onChange && onChange(keySelector(option))
                handleClose()
              }}
            >
              <ListItemText primary={labelSelector(option)} />
            </StyledMenuItem>
          )
        )}
      </StyledMenu>
    </div>
  )
}

export default Dropdown
