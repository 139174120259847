import React from 'react'

import { Paper } from '@material-ui/core'

import Button from 'components/button'
import { FollowLink } from 'components/icons'
import styled from 'styled-components'

const Main = styled(Paper)`
  padding: 1.5rem;
  text-align: center;
  > * + * {
    margin-top: 0.625rem;
  }
  > strong {
    display: block;
  }
`
interface Props {
  id: string
}

const Partner = (props: Props) => {
  const { id } = props

  return (
    <Main id={id} variant='outlined'>
      <strong style={{ textAlign: 'left' }}>
        To obtain an Errors & Omissions policy,
        <br />
        please visit our partner:
      </strong>
      <img width='180' alt='partner-aon' src='/images/NAPA.png' />
      <Button
        style={{
          position: 'relative',
          textAlign: 'center',
          width: '100%'
        }}
        onClick={() => {
          window.open('https://www.napa-benefits.org/', '_blank')
        }}
        endIcon={
          <FollowLink
            style={{
              position: 'absolute',
              width: '16px',
              right: '5px',
              top: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          />
        }
      >
        <span>Visit NAPA</span>
      </Button>
    </Main>
  )
}

export default Partner
