import React from 'react'

import { Eligibility as BaseEligibility } from '../../../commons/flow/plates/eligibility'
import { EligibilityProps } from '../../../commons/flow/plates/eligibility'
import NotAppointed from '../../../commons/flow/plates/eligibility/not_appointed'

const Eligibility = (props: EligibilityProps) => {
  const { pageName } = props

  switch (pageName) {
    case 'NotAppointedWithCarrier':
      return <NotAppointed {...props} nextLabel='Invitation' />

    default:
      return <BaseEligibility {...props} />
  }
}

export default Eligibility
