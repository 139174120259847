import customFetch, { Params } from './custom_fetch'

const PREFIX = '/api/carrier_appointment/workflow'

const workflowFetch = (params: Params) =>
  customFetch({
    ...params,
    url: PREFIX + params.url
  })

export default workflowFetch
