import React from 'react'

import { Grid } from '@material-ui/core'

import Note from 'components/box/note'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const WrongLineOfAuthority = (props: Props) => {
  const residentState = 'CA'
  const requiredLineOfAuthority = 'LOA2'

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Ineligible due to Wrong Line of Authority</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        We just pulled the information from the National Insurance Producer Registry.
      </Grid>
      <Grid item>
        <Note>
          <strong>Current Status:</strong> Your Resident License is in {residentState} and this
          state requires you to have a {requiredLineOfAuthority} Line of Authority under the state
          license to proceed.
        </Note>
      </Grid>
      <Grid item>
        Please head to the state’s website to purchase the appropriate Line of Authority. Once you
        do, allow a few days for NIPR to update.
        <br />
        <br />
        Then, try to <strong>Check Eligibility</strong> again by clicking on the{' '}
        <strong>Cancel Appointment</strong> at the top right hand corner of this screen.
      </Grid>
    </Grid>
  )
}

export default WrongLineOfAuthority
