import React, { useState } from 'react'

import {
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  makeStyles
} from '@material-ui/core'

import Note from 'components/box/note'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

enum CarrierStatus {
  ReleaseLetter = 'releaseLetter',
  UnknownUpline = 'unknownUpline',
  NoReleaseLetter = 'noReleaseLetter'
}

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    carrierStatus: CarrierStatus
  }
}

const useStyles = makeStyles(theme => ({
  button: () => ({
    width: 'auto'
  })
}))

const PreviouslyAppointed = (props: Props) => {
  const { pageData, doNext } = props

  const classes = useStyles()

  const [choice, setChoice] = useState<CarrierStatus | undefined>(pageData?.carrierStatus)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChoice(e.target.value as CarrierStatus)
  }

  const handleNext = () => {
    const data = {
      carrierStatus: choice
    }
    doNext?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>
          Eligibility - Previously Appointed with Humana OR Awaiting Your Certification Completion
        </h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        We just pulled the information from the National Insurance Producer Registry (NIPR).
      </Grid>
      <Grid item>
        <Note>
          <strong>Current Status:</strong> You are appointed with Humana under an upline that is not
          Assurance IQ.
          <br />
          <br />
          If that is not the case and you have confirmed you are under Assurance IQ, then you may
          have Training Certifications that have to be completed. You should proceed per the
          instructions on{' '}
          <Link
            target='_blank'
            rel='noreferrer'
            href={
              'https://help.assurance.com/hc/en-us/articles/360044124271-How-do-I-get-appointed-with-Humana-#h_01F2SPRKEM7CPGQPJFRQR2BVK2'
            }
          >
            How to Complete Certifications for Humana Appointment
          </Link>{' '}
        </Note>
      </Grid>
      <Grid item>
        <strong>A release letter is required to proceed.</strong>
        <RadioGroup value={choice || ''} onChange={handleChange}>
          <FormControlLabel
            value={CarrierStatus.ReleaseLetter}
            control={<Radio color='primary' />}
            label='I have a Release letter from my previous agency'
          />
          <FormControlLabel
            value={CarrierStatus.NoReleaseLetter}
            control={<Radio color='primary' />}
            label='I do not have a Release letter from my previous agency'
          />
          <FormControlLabel
            value={CarrierStatus.UnknownUpline}
            control={<Radio color='primary' />}
            label='I don’t know who my upline is right now'
          />
        </RadioGroup>
      </Grid>
      <Divider style={{ margin: '1rem 0' }} />
      {choice === CarrierStatus.ReleaseLetter && (
        <Grid item>
          Assurance cannot submit your release letter on your behalf. You need to email the release
          letter directly to Humana’s Agent Support Team at agentsupport@humana.com. It can take a
          few days for Humana’s data to sync with NIPR.
          <br />
          <br />
          If you have questions or concerns, please connect with Humana at 1-800-309-3163 from
          Monday-Friday, 8 PM – 7 PM (Eastern Standard Time).
          <br />
          <br />
          When you confirm your termination with Humana under your previous agency, click{' '}
          <strong>Cancel Appointment</strong> in the top right corner of this screen. You can start
          this <strong>Eligibility Check</strong> again.
        </Grid>
      )}
      {choice === CarrierStatus.NoReleaseLetter && (
        <Grid item>
          Assurance IQ is unable to progress you furtner. You should try to request your agency for
          a Release letter or to request your termination from Humana.
          <br />
          <br />
          Some agencies have policies where they do not release agents. In these cases, you will
          need to send an email request to Humana’s Agent Support Team at agentsupport@humana.com
          for a delayed release. The delayed release will be processed 90 days from the day you
          request it.
          <br />
          <br />
          If you have questions or concerns, please connect with Humana at{' '}
          <strong>1-800-309-3163</strong> from Monday-Friday, 8 PM – 7 PM (Eastern Standard Time).
          <br />
          <br />
          When you confirm your termination with Humana under your previous agency, click{' '}
          <strong>Cancel Appointment</strong> in the top right corner of this screen. You can start
          this <strong>Eligibility Check</strong> again.
        </Grid>
      )}
      {choice === CarrierStatus.UnknownUpline && (
        <Grid item>
          We are unable to progress you further.
          <br />
          <br />
          You need to find out who your upline is. You can find out by emailing Humana’s Agent
          Support Team at{' '}
          <Link target='_blank' rel='noreferrer' href={'mailto:agentsupport@humana.com'}>
            agentsupport@humana.com
          </Link>{' '}
          or call <strong>1-800-309-3163</strong> from Monday-Friday.
        </Grid>
      )}
    </Grid>
  )
}

export default PreviouslyAppointed
