import React from 'react'

import Button from '../button'
import Checkbox from '../checkbox'

interface Props {
  checked?: boolean
  onChange?: (newValue: boolean) => void
  label?: React.ReactNode
}

const CheckboxButton = (props: Props) => {
  const { label, checked, onChange } = props

  return (
    <Button
      style={{ borderRadius: '0.25rem' }}
      color={checked ? 'primary' : 'secondary'}
      onClick={() => onChange?.(!checked)}
    >
      <Checkbox
        checked={checked || false}
        onChange={e => {
          onChange?.(e.target.checked)
        }}
        label={label}
      />
    </Button>
  )
}

export default CheckboxButton
