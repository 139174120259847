import { PageType, StageItem, WorkFlowData, WorkflowStepDetails } from '../certification.d'

export enum FfmStageKeys {
  CREATE_LINK_ACCOUNT = 'LoginToCmsAccount',
  COMPLETE_CERTIFICATION = 'Certification',
  SYNC_DATA = 'SyncData'
}

export enum FfmPageTypes {
  TRANSITION = 'transition_period',
  WORKFLOW = 'workflow'
}

export const FfmPlateMap = {
  [FfmStageKeys.CREATE_LINK_ACCOUNT]: 'Create Account',
  [FfmStageKeys.COMPLETE_CERTIFICATION]: 'Complete Certification',
  [FfmStageKeys.SYNC_DATA]: 'Sync Data'
}

export enum FfmWorkflowActions {
  GET_STARTED = 'GetStarted',
  LOGIN_TO_CMS_ACCOUNT_CONTINUE = 'LoginToCmsAccountContinue',
  SYNC_DATA = 'SyncData'
}

export interface FfmProps {
  page: PageType
  onStageUpdate: (items: StageItem[], currentProgressKey?: FfmStageKeys) => void
  year: number
  optionChanges: (year: number) => void
}

export interface FfmWorkflowProps {
  onStageUpdate: (items: StageItem[], currentProgressKey?: FfmStageKeys) => void
  year: number
}

export interface FfmWorkflowStepDetails extends WorkflowStepDetails {
  page_name: FfmStageKeys
}

export interface FfmWorkFlowData extends WorkFlowData {
  steps: FfmWorkflowStepDetails[]
}

export interface CertificationFinalExamInfo {
  results: CertificationFinalExamResults[]
}

export interface CertificationFinalExamResults {
  applicable_plan_year: number
  individual_marketplace_end_date: string
  individual_registration_completion_date: string
  npn_valid_current_plan_year_only: string
}
