import React, { useState } from 'react'

import {
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  makeStyles
} from '@material-ui/core'

import Note from 'components/box/note'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'

enum CarrierStatus {
  ReleaseLetter = 'releaseLetter',
  UnknownUpline = 'unknownUpline',
  NoReleaseLetter = 'noReleaseLetter'
}

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
  pageData?: {
    carrierStatus: CarrierStatus
  }
}

const useStyles = makeStyles(theme => ({
  button: () => ({
    width: 'auto'
  })
}))

const PreviouslyAppointed = (props: Props) => {
  const { pageData, doNext } = props

  const classes = useStyles()

  const [choice, setChoice] = useState<CarrierStatus | undefined>(pageData?.carrierStatus)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChoice(e.target.value as CarrierStatus)
  }

  const handleNext = () => {
    const data = {
      carrierStatus: choice
    }
    doNext?.('next', data)
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Eligibility - Already Appointed with Wellcare</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        We just pulled the information from the National Insurance Producer Registry (NIPR).
      </Grid>
      <Grid item>
        <Note>
          <strong>Current Status:</strong> You are appointed with Wellcare under an upline that is
          not Assurance IQ. <br />
          <br />
          If that is not the case and you have confirmed you are under Assurance IQ, then you may
          have Training Certifications that have to be completed. You you should proceed per the
          instructions on{' '}
          <Link
            target='_blank'
            rel='noreferrer'
            href={
              'https://help.assurance.com/hc/en-us/articles/360034341432-How-do-I-get-appointed-with-WellCare-'
            }
          >
            How to Complete Certifications for WellCare Appointment
          </Link>{' '}
        </Note>
      </Grid>
      <Grid item>
        <strong>A release letter is required to proceed.</strong>
        <RadioGroup value={choice || ''} onChange={handleChange}>
          <FormControlLabel
            value={CarrierStatus.ReleaseLetter}
            control={<Radio color='primary' />}
            label='I have a Release letter from my previous agency'
          />
          <FormControlLabel
            value={CarrierStatus.NoReleaseLetter}
            control={<Radio color='primary' />}
            label='I do not have a Release letter from my previous agency'
          />
          <FormControlLabel
            value={CarrierStatus.UnknownUpline}
            control={<Radio color='primary' />}
            label='I don’t know who my upline is right now'
          />
        </RadioGroup>
      </Grid>
      <Divider style={{ margin: '1rem 0' }} />
      {choice === CarrierStatus.ReleaseLetter && (
        <>
          <Grid item>
            Assurance IQ is unable to progress you further.
            <br />
            <br />
            To proceed with Assurance IQ, please call <strong>
              WellCare Licensing Team
            </strong> at <strong>1-866-822-1339.</strong>
            <br />
            <br />
            Let the WellCare representative know:{' '}
            <strong>
              <i>“I want to terminate my appointment with WellCare.”</i>
            </strong>
            <br />
            <br />
            During specific times of the year, carriers block transfering between agencies. You are
            terminating the appointment and not trying to ‘transfer appointment to be under
            Assurance IQ’. We will walk you through the appointment process through Assurance IQ.
          </Grid>
          <Divider style={{ margin: '1rem 0' }} />
          <Grid item>
            Please note that it can take up to 2 weeks for the full termination to update in NIPR.
            <br />
            <br />
            To check if your NIPR WellCare appointment has been terminated, click{' '}
            <strong>Cancel Appointment</strong> in the top right corner of this screen to start this{' '}
            <strong>Eligibility Check</strong> again. When you can proceed, the system will prompt
            you to request an invitation from Assurance IQ. You cannot proceed until then.
          </Grid>
        </>
      )}
      {choice === CarrierStatus.NoReleaseLetter && (
        <>
          <Grid item>
            Assurance IQ is unable to progress you furtner.
            <br />
            <br />
            To proceed with Assurance IQ, you should obtain a release letter from your current
            agency and call <strong>WellCare Licensing Team</strong> at{' '}
            <strong>1-866-822-1339</strong> to terminate your current appointment.
            <br />
            <br />
            Let the WellCare representative know:{' '}
            <strong>
              <i>“I want to terminate my appointment with WellCare.”</i>
            </strong>
            <br />
            <br />
            During specific times of the year, carriers block transfering between agencies. You are
            terminating the appointment and not trying to ‘transfer appointment to be under
            Assurance IQ’. We will walk you through the appointment process through Assurance IQ.
          </Grid>
          <Divider style={{ margin: '1rem 0' }} />
          <Grid item>
            Please note that it can take up to 2 weeks for the full termination to update in NIPR.
            <br />
            <br />
            To check if your NIPR WellCare appointment has been terminated, click{' '}
            <strong>Cancel Appointment</strong> in the top right corner of this screen to start this{' '}
            <strong>Eligibility Check</strong> again. When you can proceed, the system will prompt
            you to request an invitation from Assurance IQ. You cannot proceed until then.
          </Grid>
        </>
      )}
      {choice === CarrierStatus.UnknownUpline && (
        <>
          <Grid item>
            Assurance IQ is unable to progress you furtner.
            <br />
            <br />
            To proceed with Assurance IQ, please call <strong>
              WellCare Licensing Team
            </strong> at <strong>1-866-822-1339</strong> and find out.
            <br />
            <br />
            Once you find out, and if you still want to continue, let the WellCare representative
            know:{' '}
            <strong>
              <i>“I want to terminate my appointment with WellCare.”</i>
            </strong>
            <br />
            <br />
            During specific times of the year, carriers block transfering between agencies. You are
            terminating the appointment and not trying to ‘transfer appointment to be under
            Assurance IQ’. We will walk you through the appointment process through Assurance IQ.
          </Grid>
          <Divider style={{ margin: '1rem 0' }} />
          <Grid item>
            Please note that it can take up to 2 weeks for the full termination to update in NIPR.
            <br />
            <br />
            To check if your NIPR WellCare appointment has been terminated, click{' '}
            <strong>Cancel Appointment</strong> in the top right corner of this screen to start this{' '}
            <strong>Eligibility Check</strong> again. When you can proceed, the system will prompt
            you to request an invitation from Assurance IQ. You cannot proceed until then.
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default PreviouslyAppointed
