import React from 'react'

import { Grid } from '@material-ui/core'

import Note from 'components/box/note'

import { PlateProps } from '../commons/plate'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const ReviewApplication = (props: Props) => {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Review Application</h2>
      </Grid>
      <Grid item>
        <Note>
          <strong>Note:</strong> Your Aetna case has been assigned to business team member for
          processing.
        </Note>
      </Grid>
      <Grid item>
        No action required from you. If it has been stuck in this stage for over 10 days, please
        contact Aetna to find out what is happening. The Aetna number is 866-714-9301.
      </Grid>
    </Grid>
  )
}

export default ReviewApplication
