import React from 'react'

import Grid from '@material-ui/core/Grid'

import { PlateProps } from '../../commons/plate'
import TimeToComplete from '../../commons/time_to_complete'
import { getIconForProgressStatus } from '../../commons/stages/item'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const CarrierReview = (props: Props) => {
  const { progressItems } = props
  const appointment = progressItems.find(item => item.name === 'Paperwork')!

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Mutual Of Omaha Carrier Review</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        Well done!
        <br />
        <br />
        Your work here is done for now. Sit back, relax, and we will update your progress.
        <br />
        <br />
        Note: This process could take up to 2 weeks. If it has been longer than that, please reach
        out to your sales leader and they will inquire on your behalf. Once you are appointed, you
        may receive an email from Mutual of Omaha notifying you that they are appointed, but an
        appointment does not mean they have access to the eAPP. Access to the eApp is separate and
        takes about a week after appointment clears.
        <br />
        <br />
        Once you have access, you will receive instructions in the Carrier Portal Access step with
        steps on how to access the Mutual of Omaha eApp.
      </Grid>
      <Grid item container>
        {getIconForProgressStatus(appointment.status)} Appointment Paperwork
      </Grid>
    </Grid>
  )
}

export default CarrierReview
