import React from 'react'

import styled from 'styled-components'

interface Props {
  iconSrc: string
  open?: boolean
  label?: string
  className?: string
  style?: React.CSSProperties
}

const Main = styled.div`
  display: flex;
  align-items: center;
  background: #eee;
  border-radius: 24px;
  color: #071f2b;
  font-weight: 800;
  font-size: 12px;
  width: min-content;
  white-space: nowrap;
  padding: 4px;
  cursor: default;
  overflow: hidden;
  > * + * {
    margin-left: 4px;
  }
  img {
    width: 18px;
    height: 18px;
  }
  > span {
    padding-right: 6px;
  }
  ${props =>
    !props.open &&
    `
    max-width: 26px;
    &:hover {
      max-width: 110px;
    }
    transition: max-width 0.5s;
  `}
`

const Hoverable = (props: Props) => {
  const { className, style, iconSrc, label, open } = props

  return (
    <Main className={className} style={style} open={open}>
      {iconSrc && <img alt={label} src={iconSrc} />}
      <span>{label}</span>
    </Main>
  )
}

export default Hoverable
