import React from 'react'

import { CalendarClock } from 'components/icons'

import { get_yy_mm_dd_hh_mm } from '../../../utils/utils'

/*
  started: '03/24/2021, 23:35:53'
*/
interface Props {
  started?: string
  timeToComplete?: string
}

const TimeToComplete = (props: Props) => {
  const { started, timeToComplete } = props

  const date = started != null ? new Date(started) : undefined

  const readableTime = date ? get_yy_mm_dd_hh_mm(date) : ''

  return (
    <div
      style={{
        color: '#666',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <div>Started: {readableTime}</div>
      {timeToComplete && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <CalendarClock style={{ marginRight: '0.2rem' }} /> Time to Complete: {timeToComplete}{' '}
          {(timeToComplete || '').replace('~', '').trim() !== '1' ? 'days' : 'day'}
        </div>
      )}
    </div>
  )
}

export default TimeToComplete
