import { Carrier, LOI } from 'store/carriers'

export interface HelpInfo {
  phoneNumber: string
  instructionsLink: string
}

export const HelpDesk = {
  [LOI.Health]: {
    [Carrier.Molina]: {
      phoneNumber: '1-855-885-3179',
      instructionsLink:
        'https://help.assurance.com/hc/en-us/articles/4408791712916-How-to-get-appointed-to-sell-Molina-Healthcare'
    }
  },
  [LOI.Life]: {
    [Carrier.MutualOfOmaha]: {
      phoneNumber: '1-XXX-XXX-XXXX'
    }
  }
}
