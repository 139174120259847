import { Carrier } from 'store/carriers'
import _ from 'lodash'

export const SmartflowMedicareCarriers = [
  Carrier.Aetna,
  Carrier.Anthem,
  Carrier.Humana,
  Carrier.WellCare,
  Carrier.UnitedHealthCare
]

export const SmartflowHealthCarriers: Carrier[] = [
  Carrier.Anthem,
  Carrier.Ambetter,
  Carrier.HCSC,
  Carrier.Cigna,
  Carrier.UnitedHealthCareACA,
  Carrier.UnitedHealthOne,
  Carrier.UnitedHealthCare,
  Carrier.Oscar,
  Carrier.Molina
]

export const SmartflowLifeCarriers: Carrier[] = [
  Carrier.MutualOfOmaha,
  Carrier.LGA,
  Carrier.Prudential,
  Carrier.Protective,
  Carrier.Lumico
]

export const SmartflowCarriers = _.union(
  SmartflowMedicareCarriers,
  SmartflowHealthCarriers,
  SmartflowLifeCarriers
)

export enum CardType {
  Eligibility,
  Instructions,
  Progress,
  ViewDetails,
  ComingSoon,
  Unavailable,
  Terminated,
  Closed,
  ReCertification,
  Hidden
}

const CarriersTagsInfo = [
  { name: 'Advantage', value: 'Medicare Advantage', loi: 'med_advantage' },
  { name: 'Ancillary Plan', value: 'Medicare Ancillary Plan', loi: 'med_advantage' },
  { name: 'Chronic Special Needs Plan', value: 'Chronic Special Needs Plan', loi: 'med_advantage' },
  { name: 'Dual Special Needs Plan', value: 'Dual Special Needs Plan', loi: 'med_advantage' },
  { name: 'Plan D', value: 'Plan D', loi: 'med_advantage' },
  { name: 'Supplement', value: 'Medicare Supplement', loi: 'med_advantage' },
  { name: 'Affordable Care Act', value: 'Affordable Care Act plan', loi: 'health' },
  { name: 'Dental', value: 'Dental', loi: 'health' },
  { name: 'Hospital Indemnity', value: 'Hospital Indemnity', loi: 'health' },
  { name: 'Other Ancillaries', value: 'Other Ancillaries', loi: 'health' },
  { name: 'Short Term Medical', value: 'Short Term Medical', loi: 'health' },
  { name: 'Vision', value: 'Vision', loi: 'health' }
]

export { CarriersTagsInfo }
