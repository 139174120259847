import React from 'react'

import AssuranceLoading from 'components/loading'

import NotAppointed from './not_appointed'
import { PlateProps } from '../../../commons/plate'

interface Props extends PlateProps {
  pageName: 'CheckEligibilitySpinner' | 'NotAppointedWithCarrier' | string
}

const Eligibility = (props: Props) => {
  const { pageName } = props

  switch (pageName) {
    case 'CheckEligibilitySpinner':
      return (
        <div style={{ marginTop: '4rem' }}>
          <AssuranceLoading>Running eligibility check...</AssuranceLoading>
        </div>
      )

    case 'NotAppointedWithCarrier':
      return <NotAppointed {...props} />
    default:
      return <div>Unknown page {pageName}</div>
  }
}

export default Eligibility
