import React from 'react'

import { Eligibility as BaseEligibility } from '../../../commons/flow/plates/eligibility'
import { EligibilityProps } from '../../../commons/flow/plates/eligibility'
import NotAppointed from './not_appointed'
import PreviouslyAppointed from './previously_appointed'
import Terminated from './terminated'

const Eligibility = (props: EligibilityProps) => {
  const { pageName } = props
  const nextLabel = 'Start Paperwork'

  switch (pageName) {
    case 'NotAppointedWithCarrier':
      return <NotAppointed {...props} nextLabel={nextLabel} />
    case 'PromptForCarrierStatus':
      return <PreviouslyAppointed {...props} nextLabel={nextLabel} />
    case 'TerminatedWithCause':
    case 'TerminatedWithoutCause':
      return <Terminated {...props} />
    default:
      return <BaseEligibility {...props} />
  }
}

export default Eligibility
