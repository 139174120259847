import { API_ENDPOINT } from 'utils/environment-variables'

export interface Params {
  url: string
  method?: string
  withAuth?: boolean
  data?: any
}

const customFetch = (params: Params) => {
  const { url, withAuth = true, data } = params

  const method = params.method || (data ? 'POST' : 'GET')

  const fetch_params = { headers: {} }

  fetch_params['method'] = method
  if (withAuth) {
    if (!localStorage.getItem('authToken')) {
      // user logged out
      return Promise.reject({
        status: 401
      })
    }
    fetch_params['headers']['Authorization'] = `${localStorage.getItem('authToken')}`
  }

  if (data) {
    if (data instanceof FormData) {
      fetch_params['body'] = data
    } else {
      fetch_params['headers']['Content-Type'] = 'application/json'
      fetch_params['body'] = JSON.stringify(data)
    }
  }

  const effectivePath = `${API_ENDPOINT}${url}`

  return fetch(effectivePath, fetch_params).then(d => {
    if (Math.floor(d.status / 100) !== 2) {
      throw d
    }
    return d
  })
}

export default customFetch
